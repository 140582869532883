const postAppMessage = (msg: any) => {
    if (window.webkit != undefined) {
        if (window.webkit.messageHandlers.appInterface != undefined) {
            window.webkit.messageHandlers.appInterface.postMessage(msg);
        }
    }
    if (window.appInterface != undefined) {
        window.appInterface.postMessage(msg);
    }
};

export default postAppMessage;
