import React, { useEffect, useState } from 'react';
import { useLoading } from '../../../../../context/LoadingContext';
import api from '../../../../../apis';

import './assets/style.css';

import TopHeader from './components/topHeader';
import Footer from './components/footer';

const topLogoNav = () => {
    return (
        <nav
            id="main-navigation-wrapper"
            className="navbar navbar-default navbar-expand-lg navbar-light"
        >
            <div className="container" style={{width:'1140px'}}>
                <div className="logo navbar-brand">
                    <a
                        href="/Utrade"
                        className="logo-desktop"
                    >
                        <img
                            src="https://www.utrade.com.my/img/logo.png"
                            alt="UOB Kay Hian"
                        />
                    </a>
                    <a
                        href="/Utrade"
                        className="logo-mobile"
                    >
                        <img
                            src="https://www.utrade.com.my/img/logo.png"
                            alt="Awesome Image"
                        />
                    </a>
                </div>
                <div className="navbar-header d-none">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#main-navigation"
                        aria-controls="main-navigation"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
            </div>
        </nav>
    );
};

const LoginBody = () => {
    const { loading, setLoading } = useLoading();

    const [uid, setUserId] = useState('');
    const [pwd, setUserPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        setErrMsg('');
    }, [uid, pwd]);

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await setLoading(true);

        let result = await api.userLogin({
            username: uid,
            password: pwd
        });

        if (result != false && result.status == 0) {
            window.location.reload();
            // navigate('/');
        } else {
            setLoading(false);
        }
    };

    return (
        <section className="contact_us-second">
            <div className="row">
                <div className="col-lg-6 d-none d-lg-block">
                    <div className="no-gutter1">
                        <div id="bannerDiv" className="col-lg-10 col-sm-12">
                            <div
                                id="loginCarousel"
                                className="carousel slide"
                                data-ride="carousel"
                            >
                                <ol className="carousel-indicators">
                                    <li
                                        data-target="#loginCarousel"
                                        data-slide-to="384"
                                        className="active"
                                    ></li>
                                </ol>
                                <div
                                    className="carousel-inner"
                                    style={{ height: '348px' }}
                                >
                                    <div className="carousel-item active">
                                        <a
                                            href="#"
                                        >
                                            <img
                                                data-u="image"
                                                className="img-fluid banner"
                                                src="https://www.utrade.com.my/img/Banner/hero-2023-fata-nov.png"
                                                alt="Stock Trading Masterclass: Fundamental &amp; Technical Analysis"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <a
                                    className="carousel-control-prev"
                                    href="#loginCarousel"
                                    role="button"
                                    data-slide="prev"
                                >
                                    <span
                                        className="carousel-control-prev-icon"
                                        aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a
                                    className="carousel-control-next"
                                    href="#loginCarousel"
                                    role="button"
                                    data-slide="next"
                                >
                                    <span
                                        className="carousel-control-next-icon"
                                        aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-xs-12 keep-in">
                    <form
                        name="loginForm"
                        id="loginForm"
                        method="POST"
                        autoComplete="off"
                        onSubmit={handleLogin}
                    >
                        <div className="login">
                            <p className="title">Log in</p>
                            <span
                                id="failed_msg_span"
                                className="mag-left-30px d-none bg-danger"
                            >
                                <small>
                                    <b id="failed_msg"></b>
                                </small>
                            </span>
                            <section id="loginSection" className="">
                                <input
                                    type="text"
                                    id="UserID"
                                    name="UserID"
                                    placeholder="User ID"
                                    autoFocus
                                    className="height-35px font-size-13px"
                                    value={uid}
                                    onChange={(e) => setUserId(e.target.value)}
                                />
                                <i className="fa fa-user"></i>
                                <span
                                    id="rfv_userid"
                                    className="mag-left-30px d-none bg-danger"
                                >
                                    <small>
                                        <b>* User ID is required</b>
                                    </small>
                                </span>
                            </section>
                            <section id="passwordSection" className="">
                                <input
                                    type="password"
                                    id="Password"
                                    name="Password"
                                    placeholder="Password"
                                    className="height-35px font-size-13px"
                                    maxLength={16}
                                    value={pwd}
                                    onChange={(e) => setUserPwd(e.target.value)}
                                />
                                <i className="fa fa-key"></i>
                                <span
                                    id="rfv_password"
                                    className="mag-left-30px d-none bg-danger"
                                >
                                    <small>
                                        <b>* Password is required</b>
                                    </small>
                                </span>
                            </section>
                            <section id="loginTypeSection" className="">
                                <table className="wid-96p">
                                        <td>
                                            <table className="wid-40p">
                                                <tr className="height-25px">
                                                </tr>
                                            </table>
                                        </td>
                                </table>
                            </section>
                            <section id="forgetPwdSection" className="" style={{fontSize: '13px', marginLeft:'30px',}}>
                                <h6 style={{fontSize: '13px'}}>
                                    By logging in, you accept the Terms of use and agree to be bound by them.
                                </h6>
                                <u>Forgot your User ID or Password?</u>
                            </section>
                            <section id="loginButtonSection">
                                <p id="p_login">
                                    <button
                                        type="submit"
                                        id="btn_login"
                                        name="btn_login"
                                        style={{fontSize: '14px',}}
                                    >
                                        <i className="fa fa-lock"></i>
                                        &nbsp;Log in
                                    </button>
                                </p>
                            </section>
                        </div>
                        <div id="webecos_hidden_div">
                            <input type="hidden" id="uId" name="uId" />
                            <input
                                type="hidden"
                                id="exchng"
                                name="exchng"
                                value="SGX"
                            />
                            <input type="hidden" id="symbol" name="symbol" />
                            <input
                                type="hidden"
                                id="infoMode"
                                name="infoMode"
                                value="LV"
                            />
                            <input type="hidden" id="id" name="id" value="RS" />
                            <input
                                type="hidden"
                                id="idx"
                                name="idx"
                                value="AN"
                            />
                        </div>
                        <div id="iress_hidden_div">
                            <input
                                type="hidden"
                                id="furl"
                                name="furl"
                                value=""
                            />
                            <input
                                type="hidden"
                                id="title"
                                name="title"
                                value="Foreign Market by UOB Kay Hian"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

const UtradeLogin = () => {
    return (
        <div className="utradeBody">
            <TopHeader />
            {topLogoNav()}
            <div className="container" style={{width:'1140px'}}>
                <div className="row-fluid">
                    <div id="page-wrapper">
                        <div  style={{paddingTop: '80px', paddingBottom: '80px'}}>{LoginBody()}</div>                        
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default UtradeLogin;
