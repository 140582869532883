import { handleRequestGet } from '../axios';
import { parseResult, queryParamStr } from './GeneralFunc';

export const getEstatementList = async (acc_id = null, { year = null }) => {
    let url = '/client/account/' + acc_id + '/estatement?';

    let queryString = '';
    queryString += queryParamStr('year', year, true);
    let fullUrl = url + queryString;

    let result = await handleRequestGet(fullUrl, {});

    return parseResult(result).estatements || [];
};
