import { createContext, useContext, useState, useEffect } from 'react';
import ScreenLoader from '../components/general/loader';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import { Modal } from 'antd';

const antIcon = (
    <LoadingOutlined
        style={{ fontSize: 14, width: '1em', height: '1em' }}
        spin
    />
);

const LoadingContext = createContext({});

interface Props {
    children: React.ReactNode;
}

export const LoadingProvider = ({ children }: Props) => {
    const [loading, setLoading] = useState(false);
    const [notiLoading, setNotiLoading] = useState(false);
    const [notiExpired, setNotiExpired] = useState(false);
    const [notiExpiredMsg, setNotiExpiredMsg] = useState(null);

    //timeout set session expired to hide
    useEffect(() => {

        if(!notiExpired)
        {
            var body = document.querySelector('body');
            body.style.setProperty('overflow', 'initial');
        }
       
    }, [notiExpired]);

    const handleResetNoti = () =>{
        setNotiExpired(false)
        setNotiExpiredMsg(null)
    }

   
    return (
        <LoadingContext.Provider
            value={{
                loading,
                setLoading,
                notiLoading,
                setNotiLoading,
                notiExpired,
                setNotiExpired,
                setNotiExpiredMsg
            }}
        >
            {<ScreenLoader display={loading} />}
            <div className="loading-notify-container">
                <div
                    className={
                        'notify ' + (notiLoading == true ? ' active' : 'close')
                    }
                >
                    <span id="notifyType" className=" ">
                        <div>
                            <Spin indicator={antIcon} />
                        </div>
                        <div className="pt-1"> Loading...</div>
                    </span>
                </div>
            </div>
            {/* <div className="loading-notify-container">
                <div
                    className={
                        'notify ' + (notiExpired == true ? ' active' : 'close')
                    }
                >
                    <span id="notifyType" className=" ">
                        <div className="pt-1">
                            {' '}
                            <div className="d-flex gap-2">
                                <WarningTwoTone
                                    twoToneColor="#ed0d0d"
                                    style={{
                                        fontSize: '16px',
                                        paddingTop: '2px'
                                    }}
                                />

                                <div> Session Expired</div>
                            </div>
                        </div>
                    </span>
                </div>
            </div> */}

            {notiExpired && (
                <Modal
                    title=""
                    maskClosable={false}
                    open={true}
                    okText="Reditrect To Login"
                    onOk={async (e) => handleResetNoti()}
                    okButtonProps={{ style: { display: 'none' } }}
                    cancelText="Close"
                    onCancel={(e) => handleResetNoti()}
                    // cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <div className="row ">
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="d-flex gap-2">
                                <WarningTwoTone
                                    twoToneColor="#ed0d0d"
                                    style={{
                                        fontSize: '16px',
                                        paddingTop: '2px'
                                    }}
                                />

                                <div style={{paddingRight:"1rem"}}>
                                    {' '}

                                    {
                                        notiExpiredMsg ?
                                        notiExpiredMsg
                                        :
                                        <>Automatically logged out due to session
                                        expired.</>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}

            {children}
        </LoadingContext.Provider>
    );
};

export const useLoading = () => {
    const context = useContext<any>(LoadingContext);
    if (!context) {
        throw new Error('useLoading must be used within LoadingProvider');
    }
    return context;
};
