import { createRef, useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
// import { pwdEncrypt } from '../crypto/pwdCrypto';
import { useLoading } from '../../../context/LoadingContext';
import { useGlobalVariable } from '../../../context/GlobalVariableProvider';
import '../../asset/styles/login-style.css';

import api from '../../../apis';
import bgImg from '../../asset/images/login/login.jpg';
import bgImg1 from '../../asset/images/login/bg-1.png';
import bgImg2 from '../../asset/images/login/bg-2.png';
import bgImg3 from '../../asset/images/login/bg-3.png';
import bgImg4 from '../../asset/images/login/bg-4.png';

import ForgetPassword from '../public/ForgetPassword';

const PgLogin = () => {
    //set background image
    const [bgImage, setBgImg] = useState(bgImg);
    const { loading, setLoading } = useLoading();
    const { showTnc, setShowTnc } = useGlobalVariable();

    const [displayForgetPassword, setDisplayForgetPassword] = useState(false);

    //const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    // const from = location.state?.from?.pathname || "/";

    const userRef = useRef<HTMLDivElement>();
    const errRef = createRef<HTMLDivElement>();

    const [uid, setUserId] = useState('');
    const [pwd, setUserPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current?.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [uid, pwd]);

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await setLoading(true);

        let result = await api.userLogin({
            username: uid,
            password: pwd
        });

        if (result != false && result.status == 0) {
            // console.log('login result', result);
            // if (result?.data?.show_tnc == true) {
            //     alert('show tnc');
            //     setShowTnc(true);
            // }
            window.location.reload();

            // navigate('/');
        } else {
            setLoading(false);
        }
    };

    return (
        <>
            {displayForgetPassword && (
                <ForgetPassword
                    setModelOpen={(status: any) =>
                        setDisplayForgetPassword(status)
                    }
                />
            )}
            <div className="login-page-container">
                <div
                    className="login-page-bg"
                    style={{ backgroundImage: `url(${bgImage})` }}
                ></div>
                <div className="container">
                    <div className="row justify-content-center align-items-center login-form-container">
                        <div
                            className="col-lg-8 col-12 login-form-block"
                            style={{ backgroundImage: `url(${bgImage})` }}
                        >
                            <div className="row h-100">
                                <div className="col-md-6 col-12"></div>
                                <div className="col-md-6 col-12 login-form">
                                    <div className="h-100 d-flex flex-column justify-content-center align-items-center p-5">
                                        <div className="title text-center">
                                            Log in or sign up in seconds
                                        </div>
                                        <form
                                            method="POST"
                                            className="pt-3"
                                            onSubmit={handleLogin}
                                        >
                                            <div className="form-group custom-form-group">
                                                <input
                                                    className="form-control "
                                                    placeholder="User ID"
                                                    name="username"
                                                    type="text"
                                                    autoFocus
                                                    required
                                                    value={uid}
                                                    onChange={(e) =>
                                                        setUserId(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="form-group custom-form-group">
                                                <input
                                                    className="form-control "
                                                    placeholder="Password"
                                                    name="password"
                                                    type="password"
                                                    required
                                                    value={pwd}
                                                    onChange={(e) =>
                                                        setUserPwd(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-check pl-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                        />
                                                        <label className="form-check-label">
                                                            Remember me
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <a
                                                        className="custom-href"
                                                        onClick={() =>
                                                            setDisplayForgetPassword(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        Forgot password?
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="pt-4">
                                                <button
                                                    type="submit"
                                                    className="btn custom-btn-primary"
                                                >
                                                    Login &nbsp;
                                                    <i className="fa-solid fa-angle-right" />
                                                </button>
                                            </div>
                                            <div className="pt-3">
                                                <button className="btn custom-btn-secondary">
                                                    Create an Account &nbsp;
                                                    <i className="fa-solid fa-angle-right" />
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <div className="footer-container">
                        © 2022 FiNext Sdn. Bhd.
                    </div>
                </footer>
            </div>
        </>
    );
};

export default PgLogin;
