import { useState, useEffect } from 'react';
import '../../asset/styles/side-menu-container-style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import sidebarSource from '../nav-config';
import { useLoading } from '../../../context/LoadingContext';

interface Props {
    position?: object;
}

interface siderPosition {
    left?: number;
}

const Index = (props: Props) => {
    const { loading, setLoading } = useLoading();
    let navigate = useNavigate();
    const location = useLocation();

    const [sidebarProps, setSidebarProps] = useState([]);
    const [sidebarPosition, setSidebarPosition] = useState<siderPosition>({
        left: 0
    });

    useEffect(() => {
        let lastSegment = location.pathname;
        initSideOptions(sidebarSource, lastSegment);
    }, [location]);

    const initSideOptions = (data: any, lastSegment: String) => {
        let foundPage = false;
        let newArr: any = [];

        //check selected option and highlight it
        for (var i = 0; i < sidebarSource.length; i++) {
            let row = data[i];
            data[i].selected = false;
            if (row.url == lastSegment) {
                data[i].selected = true;
                foundPage = true;
            }

            if (row.subMenu && row.subMenu.length > 0) {
                //check submenu data
                for (var z = 0; z < row.subMenu.length; z++) {
                    //default set to false;
                    data[i].subMenu[z].selected = false;
                    let innerRow = row.subMenu[z];
                    if (innerRow.url == lastSegment) {
                        //selected submenu option
                        data[i].subMenu[z].selected = true;
                        //selected parent option
                        data[i].selected = true;

                        foundPage = true;
                    }
                }
            }

            newArr.push(row);
        }

        if (foundPage == false && data.length > 0) {
            data[0].selected = true;
        }

        setSidebarProps(newArr);
    };

    const redirect = async (url: string) => {
        if (url && sideBarOpen == true) {
            await setLoading(true);
            navigate(url);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (props.position) {
            setSidebarPosition(props.position);
        }
    }, [props.position]);

    const handleSideBar = () => {
        var cFocusEl = document.activeElement.tagName || null;
        // alert(cFocusEl);
        if (
            cFocusEl == 'SELECT' ||
            cFocusEl == 'INPUT' ||
            cFocusEl == 'TEXTAREA'
        ) {
        } else setSideBarOpen(true);
    };

    const [sideBarOpen, setSideBarOpen] = useState(false);

    // useEffect(() => {
    //     console.log('sideBarOpen', sideBarOpen);
    // }, [sideBarOpen]);

    return (
        <div
            className="left-menu-containter"
            style={
                {
                    // left:sidebarPosition.left+"px"
                }
            }
        >
            <div
                className={'side-nav ' + (sideBarOpen ? ' show ' : ' ')}
                // onClick={() => setSideBarOpen(true)}
                onClick={() => handleSideBar()}
                onMouseLeave={() => setSideBarOpen(false)}
            >
                {/* <div className="sidebar">
                    <ul className="nav-links">
                        <li>
                            <div className="iocn-link">
                                <a href="#">
                                    <i className="bx bx-plug"></i>
                                    <span className="link_name">Plugins</span>
                                </a>
                                <i className="bx bxs-chevron-down arrow"></i>
                            </div>
                            <ul className="sub-menu">
                                <li>
                                    <a className="link_name" href="#">
                                        Plugins
                                    </a>
                                </li>
                                <li>
                                    <a href="#">UI Face</a>
                                </li>
                                <li>
                                    <a href="#">Pigments</a>
                                </li>
                                <li>
                                    <a href="#">Box Icons</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div> */}
                {/* <li className="nav-item">
                    <SbAdminDropdown
                        id="Trade"
                        label="Trade"
                        data={[
                            {
                                href: '/login',
                                label: 'Login'
                            },
                            {
                                href: '/',
                                label: 'Register'
                            }
                        ]}
                    />
                </li> */}
                {sidebarProps.map(function (row, i) {
                    let className = 'item';
                    if (row.selected == true) {
                        className = 'item active';
                    }

                    let compt = (
                        <div className={className} key={i}>
                            <div
                                className="item-content"
                                onClick={(e) => {
                                    redirect(row.url);

                                    if (row.url) setSideBarOpen(false);
                                }}
                            >
                                <img src={row.icon} />{' '}
                                <div className="link" id={row.url}>
                                    {row.label}
                                </div>
                            </div>

                            {/* render if submenu data exist */}
                            {row.subMenu && (
                                <div className="sub-menu">
                                    {row.subMenu &&
                                        row.subMenu.length > 0 &&
                                        row.subMenu.map(function (row, i) {
                                            let className = 'item';
                                            //check selected option
                                            if (row.selected == true) {
                                                className = 'item active';
                                            }

                                            return (
                                                <div
                                                    key={i}
                                                    className={className}
                                                    onClick={(e) => {
                                                        redirect(row.url);
                                                        setSideBarOpen(false);
                                                    }}
                                                >
                                                    <div id={row.url}>
                                                        {row.label}
                                                    </div>
                                                </div>
                                            );
                                        })}

                                    {/* <div className="item active">
                                        <div id={row.url}>{row.label}</div>
                                    </div>
                                    <div className="item">
                                        <div id={row.url}>{row.label}</div>
                                    </div> */}
                                </div>
                            )}
                        </div>
                    );

                    return compt;
                })}
            </div>
        </div>
    );
};

export default Index;
