// @ts-nocheck
import { Outlet, useLocation } from 'react-router-dom';
import { useLayoutEffect, useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';

import SideMenuLayout from './SideMenuLayout';
import TopMenuLayout from './TopMenuLayout';

const Layout = (props: any) => {
    const [isIframe, setIsIframe] = useState(false);
    let location = useLocation();
    /* @ts-ignore */
    const { auth, setAuth } = useAuth();
    const [mode, setMode] = useState(null);
    const [theme, setTheme] = useState('basic');
    // let layout = auth.layout;

    useLayoutEffect(() => {
        const query = new URLSearchParams(location.search);
        const mode = query.get('mode');
        const theme = query.get('theme');

        setMode(mode);

        if (theme) setTheme(theme);
    }, []);

    //default set to sidemenu mode
    const [layout, setLayout] = useState(auth.layout || 'side');
    useEffect(() => {
        if (auth.layout) setLayout(auth.layout);
    }, [auth.layout]);

    // resize listener
    useLayoutEffect(() => {
        function updateSize() {
            if (window.innerWidth <= 600) {
                console.log(window.innerWidth);
                //mobile view
                setLayout('top');
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    // side menu,top menu
    return mode == 'mobile' ? (
        <div
            className={
                'container ' +
                (theme == 'dark' ? ' layout-container dark ' : '')
            }
        >
            {' '}
            <div className="page-content">
                <Outlet context={{}} />
            </div>
        </div>
    ) : (
        <>{layout == 'top' ? <TopMenuLayout /> : <SideMenuLayout />}</>
    );
};

export default Layout;
