import React from 'react';
import { TopBannerV2 } from "../General/TopBanner";

const Bos = () => {
    const setMinHeight = {
        minHeight: '270px'
    };

    return (
        <div className="BosProduct">
            <TopBannerV2
                data={{
                    title: 'FiNext',
                    name: 'BO System',
                    details:
                        'FiNext BO System (FIBOS) is architect for both institutional and retail businesses, offering Multi-Market, Multi-Currency, Multi Asset Class and languages capability. ',
                    imageURL: '/img/FiNext/BOS/FNX_Mbackground_cut.jpg',
                    style: false,
                    background: true,
                }}
            />

            {/* Description */}
            <div className="section">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-4">
                            <h2 className="heading">
                                <span style={{ textTransform: 'capitalize' }}>
                                    FiNext
                                </span>{' '}
                                BO System
                            </h2>
                        </div>
                        <div className="col-lg-8">
                            <h5 className="mb-3">The system encompasses:</h5>
                            <ul className="text-black-50">
                                <li>Business Intelligence (BI) Dashboard </li>
                                <li>Client & Account Management </li>
                                <li>Trade Management </li>
                                <li>Clearing and Settlement </li>
                                <li>Margin Financing </li>
                                <li>Nominees </li>
                            </ul>
                            {/* <p className="text-black-50"> */}
                            <p className="text-black-50 text-justify">
                                This integrated system streamlines post-trade
                                processing across various markets, currencies,
                                asset classes, and business entities, will help
                                in improved company efficiency and productivity
                                while maintaining cost-effectiveness.
                            </p>
                        </div>
                    </div>

                    <div className="row g-1">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                            <div className="single-portfolio">
                                <a>
                                    <img
                                        src="/img/FiNext/BOS/FNX_CTer.jpg"
                                        alt="Image"
                                        className="img-fluid"
                                    />
                                    <div className="contents">
                                        <h3>BOS</h3>
                                        {/* <div className="cat">FIBOS</div> */}
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="single-portfolio">
                                <a>
                                    <img
                                        src="/img/FiNext/BOS/FNX_BOS2_Db.png"
                                        alt="Image"
                                        className="img-fluid"
                                    />
                                    <div className="contents">
                                        <h3>BOS</h3>
                                        {/* <div className="cat">FIBOS</div> */}
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="single-portfolio">
                                <a>
                                    <img
                                        src="/img/FiNext/BOS/FNX_FIBOS_CounterList.png"
                                        alt="Image"
                                        className="img-fluid"
                                    />
                                    <div className="contents">
                                        <h3>BOS</h3>
                                        {/* <div className="cat">FIBOS</div> */}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* end description */}

            <div className="section sec-team pb-5 bg-light">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-5">
                            {/* <span className="d-block subheading mb-3">
                                 BI Dashboard
                            </span> */}
                            <h2 className="heading">
                                BI Dashboard for Data Analysis
                            </h2>
                        </div>
                        <div className="col-lg-6">
                            <p className="text-black-50 text-justify">
                                The role based BI dashboard offers a real-time,
                                at-a-glance overview of KPIs and important
                                metrics for data-driven decision-making,
                                enabling organizations to identify growth and
                                improvement opportunities.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="person">
                                <img
                                    src="/img/FiNext/BOS/FNX_BI.jpg"
                                    alt="Image"
                                    className="img-fluid rounded-circle mx-auto mb-5"
                                    style={{height:"380px",padding:"0px",}}
                                />
                                <h3 className="mb-3">Customizable Dashboard</h3>
                                {/* <span className="d-block text-black-50 mb-3">
                                    CEO, Partner
                                </span> */}
                                <p className="text-black-50 text-justify">
                                    Our dashboard is fully customizable,
                                    allowing you to analyze data the way you
                                    want.
                                </p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="person">
                                <img
                                    src="/img/FiNext/BOS/FNX_TDS_Stk.jpg"
                                    alt="Image"
                                    className="img-fluid rounded-circle mx-auto mb-5"
                                    style={{height:"380px",padding:"10px",}}
                                />
                                <h3 className="mb-3">Trading Data Analysis</h3>
                                <p className="text-black-50 text-justify">
                                    Analyze your trading data with our dashboard
                                    to enhance your decision-making
                                    capabilities.
                                </p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="person">
                                <img
                                    src="/img/FiNext/BOS/FNX_TDS.jpg"
                                    alt="Image"
                                    className="img-fluid rounded-circle mx-auto mb-5"
                                    style={{height:"380px",padding:"10px",}}
                                />
                                <h3 className="mb-3">
                                    Real-time Market Analysis
                                </h3>
                                <p className="text-black-50 text-justify">
                                    Our BI dashboard provides real-time
                                    information, enabling you to stay ahead of
                                    the curve.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-7 mx-auto text-center">
                            {/* <span className="d-block subheading mb-3">
                                Advantages
                            </span> */}
                            <h2 className="heading">
                                Seamless Back-office Integration
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div
                                className="testimonial bg-light"
                                style={setMinHeight}
                            >
                                <div className="d-flex author align-items-center">
                                    <div className="text mb-3">
                                        <h3>Efficiency & Automation</h3>
                                        <p className="mb-1"></p>
                                    </div>
                                </div>
                                <p className="text-black-50 text-justify">
                                    Our rule based workflow integration provides
                                    you with unparalleled efficiency and
                                    automation in your workflow practices,
                                    allowing you to work smarter not harder.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="testimonial bg-light"
                                style={setMinHeight}
                            >
                                <div className="d-flex author align-items-center">
                                    <div className="text mb-3">
                                        <h3>Common Access Control</h3>
                                        <p className="mb-1"></p>
                                    </div>
                                </div>
                                <p className="text-black-50 text-justify">
                                    Our Single Access Control (SSO) across
                                    multiple systems design, eradicating
                                    challenges faced by IT and business users in
                                    access control maintenance and simplified
                                    auditing works.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="testimonial bg-light"
                                style={setMinHeight}
                            >
                                <div className="d-flex author align-items-center">
                                    <div className="text mb-3">
                                        <h3>
                                            Seamless Third-party Interface
                                            (API/SWIFT/FIX)
                                        </h3>
                                        <p className="mb-1"></p>
                                    </div>
                                </div>
                                <p className="text-black-50 text-justify">
                                    Our file input supports all major file
                                    formats with a user-defined structure. It is
                                    intelligent and capable of interacting with
                                    web services or APIs.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="testimonial bg-light"
                                style={setMinHeight}
                            >
                                <div className="d-flex author align-items-center">
                                    <div className="text mb-3">
                                        <h3>
                                            Auto STP End to End – No Touch
                                            Process
                                        </h3>
                                        <p className="mb-1"></p>
                                    </div>
                                </div>
                                <p className="text-black-50 text-justify">
                                    The Straight Through Processing (STP) for
                                    core process enabled contact, contra or setoff
                                    process automatically via drop copy or file
                                    uploads without human intervention.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section bg-light">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-10 mx-auto text-center">
                            <span className="d-block subheading mb-3"></span>
                            <h2 className="heading">
                                Customizable Risk Management
                            </h2>
                        </div>
                    </div>
                    <div className="row mt-5 px-5">
                        <div className="col-md-6 col-lg-4">
                            <div className="feature">
                                <img
                                    src="/components/asset/landing/images/svg/006-clipboard.svg"
                                    alt="Image"
                                    className="img-fluid"
                                />
                                <h3 className="heading-h3 mb-3">
                                    Customizable Risk Assessment
                                </h3>
                                <p className="text-black-50">
                                    All firms are different. We work with you to
                                    identify and assess your specific risk
                                    requirements.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="feature">
                                <img
                                    src="/components/asset/landing/images/svg/003-engineer.svg"
                                    alt="Image"
                                    className="img-fluid"
                                />
                                <h3 className="heading-h3 mb-3">
                                    Real-time Monitoring
                                </h3>
                                <p className="text-black-50">
                                    Our customizable real-time risk monitoring
                                    will constantly monitor the possible
                                    violations and gives you an optimal view and
                                    alert of your risk at any one time.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="feature">
                                <img
                                    src="/components/asset/landing/images/svg/002-crane.svg"
                                    alt="Image"
                                    className="img-fluid"
                                />
                                <h3 className="heading-h3 mb-3">
                                    Seamless Integration
                                </h3>
                                <p className="text-black-50">
                                    You can easily integrate our risk management
                                    solution with other systems that you may
                                    already have in place.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-7 mx-auto text-center">
                            {/* <span className="d-block subheading mb-3">
                                Advantages
                            </span> */}
                            <h2 className="heading">Compliance Standards</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div
                                className="testimonial bg-light"
                                style={setMinHeight}
                            >
                                <div className="d-flex author align-items-center">
                                    <div className="text mb-3">
                                        <h3>Adherence to Regulations</h3>
                                        <p className="mb-1"></p>
                                    </div>
                                </div>
                                <p className="text-black-50 text-justify">
                                    Our solutions are fully compliant with the
                                    latest regulatory standards and adhere to
                                    the strictest industry compliance
                                    requirements.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div
                                className="testimonial bg-light"
                                style={setMinHeight}
                            >
                                <div className="d-flex author align-items-center">
                                    <div className="text mb-3">
                                        <h3>Customizable Compliance</h3>
                                        <p className="mb-1"></p>
                                    </div>
                                </div>
                                <p className="text-black-50 text-justify">
                                    We offer customizable compliance solutions,
                                    enabling you to tailor our systems to your
                                    compliance requirements.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div
                                className="testimonial bg-light"
                                style={setMinHeight}
                            >
                                <div className="d-flex author align-items-center">
                                    <div className="text mb-3">
                                        <h3>Deep Audit Logs</h3>
                                        <p className="mb-1"></p>
                                    </div>
                                </div>
                                <ul className="text-black-50 text-justify">
                                    <li>
                                        Multi-level detailed audit logs will be
                                        captured
                                    </li>
                                    <li>
                                        System Activity and User Activity audit
                                        logs will be maintained for analysis
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bos;
