import { handleRequestPost, handleRequestGet } from '../axios';
import { parseResult, queryParamStr, queryParamStrArr } from './GeneralFunc';

export const getTradeHistory = async (
    a_ids = null,
    {
        // currency = 1,
        // type = null, //0 = gain, 1=lose
        side = null, //1=buy,2=sell
        stock = null,
        dateStart = null,
        dateEnd = null,
        size = 10,
        // accounts = null,
        sides = null,
        exchanges = null,
        stocks = null
        // page = 1
    },
    page = 1
) => {
    let url = '/client/trade?';
    let queryString = '';

    queryString += queryParamStr('size', size, true);
    queryString += queryParamStr('page', page);

    queryString += queryParamStrArr('accounts[]', a_ids);
    queryString += queryParamStrArr('exchanges[]', exchanges);
    queryString += queryParamStrArr('stocks[]', stocks);

    // queryString += queryParamStr('person', a_id); //first param

    // queryString += queryParamStr('currency', currency, true);
    // queryString += queryParamStr('type', type);
    queryString += queryParamStr('stock', stock);
    queryString += queryParamStr('side', side);
    queryString += queryParamStr('range[start]', dateStart);
    queryString += queryParamStr('range[end]', dateEnd);

    let fullUrl = url + queryString;
    let result = await handleRequestGet(fullUrl, {});

    console.log('fullUrl', fullUrl);
    // console.log(result);

    return parseResult(result);
};

export const getTradeHistorySummary = async (
    acc_ids = null,
    {
        side = null, //1=buy,2=sell
        stock = null,
        dateStart = null,
        dateEnd = null,
        // accounts = null,
        sides = null,
        exchanges = null,
        stocks = null
        // page = 1
    }
) => {
    let url = '/client/trade/summary?';
    let queryString = '';
    queryString += queryParamStr('stock', stock, true);
    queryString += queryParamStr('side', side);

    queryString += queryParamStrArr('accounts[]', acc_ids);
    queryString += queryParamStrArr('exchanges[]', exchanges);
    queryString += queryParamStrArr('stocks[]', stocks);

    queryString += queryParamStr('range[start]', dateStart);
    queryString += queryParamStr('range[end]', dateEnd);

    let fullUrl = url + queryString;
    let result = await handleRequestGet(fullUrl, {});

    console.log('fullUrl', fullUrl);
    console.log(result);

    return parseResult(result);
};
