import { useState, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../../apis';

import FormInput from '../../general/form-control/form-item/input';
import { useLoading } from '../../../context/LoadingContext';

import { Modal } from 'antd';

const Index = (props: any) => {
    let location = useLocation();
    let navigate = useNavigate();

    const { setLoading } = useLoading();
    const [token, setToken] = useState(null);
    const [redirectToLoginModal, setRedirectToLoginModal] = useState(false);

    useLayoutEffect(() => {
        const query = new URLSearchParams(location.search);
        const token = query.get('token');

        api.sessValidateForgetPassword({ reset_token: token }).then((res) => {
            console.log('res', res);

            if (res && res.status == 1) {
                alert(res?.message);
                navigate('/login');
            } else {
                setToken(token);
            }
        });
    }, []);

    const [inputs, setInputs] = useState<any>({});
    const [errors, setErrors] = useState<any>({});

    //validation settings
    const [rules, setRules] = useState<any>({
        password: {
            required: true,
            error_message: 'Please input password'
        },
        confirm_password: {
            required: true,
            same_value: 'password',
            same_value_error_message:
                'New password and confirm password not match',
            error_message: 'Please input confirm password'
        }
    });

    const onInputChange = (id: string, newValue: string) => {
        setInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    const formValidation = (rules: any, inputs: any) => {
        if (!rules) return { valid: true, errors: {} };

        let valid = true;
        let errors: any = {};

        //validate rule
        for (var key in rules) {
            if (rules.hasOwnProperty(key)) {
                let item = rules[key];
                if (item.required == true && !inputs[key]) {
                    let error = item.error_message || 'Required';
                    errors[key] = error;
                    valid = false;
                } else if (item.requireIfExist) {
                    //if specific input exist,current input become required
                    let requireIfExist = item.requireIfExist;
                    let relatedkey = requireIfExist.key;
                    let relatedValue = requireIfExist.value;

                    //match type start check error
                    if (
                        relatedkey &&
                        relatedValue &&
                        inputs[relatedkey] &&
                        inputs[relatedkey] == relatedValue
                    ) {
                        if (!inputs[key]) {
                            let error = item.error_message || 'Required';
                            errors[key] = error;
                            valid = false;
                        }
                    }
                } else {
                    if (item.type == 'email') {
                        let value = inputs[key];
                        if (
                            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                value
                            )
                        ) {
                            valid = false;
                            errors[key] = 'Please enter valid email address';
                        }
                    }
                } //match value with

                if (item.same_value && inputs[key]) {
                    let same_value = item.same_value;

                    if (inputs[key] != inputs[same_value]) {
                        let error = item.same_value_error_message || 'Required';
                        errors[key] = error;
                        valid = false;
                    }
                } else {
                }

                // requireIfExist: { key: 'mode', value: 2 }
            }
        }

        return {
            valid,
            errors
        };
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        console.log('inputs', inputs);
        inputs.reset_token = token;

        setLoading(true);
        let { valid, errors } = formValidation(rules, inputs);
        setErrors(errors);
        console.log(errors);
        if (valid == false) {
            //scroll to first error
            if (Object.keys(errors).length > 0) {
                let firstKey = Object.keys(errors)[0];
                let element = document.getElementById(firstKey);
                element?.scrollIntoView();
            }
            setLoading(false);
        } else {
            setLoading(true);

            let result = await api.sessUpdateForgetPassword(inputs);
            if (result.status == 0) {
                // alert('Password changed successfully');
                if (props.setModelOpen) props.setModelOpen(false);

                setToken(null);
                setRedirectToLoginModal(true);
            } else if (result.status == 1) {
                let message = result?.message;

                if (message) {
                    if (typeof message === 'string') {
                        alert(message);
                    } else {
                        let text = '';
                        for (var key in message) {
                            if (message.hasOwnProperty(key)) {
                                let sentence = message[key];
                                if (key == 'new_password') {
                                    sentence = sentence.replace(
                                        '"new_password"',
                                        'New password'
                                    );
                                }
                                text += sentence + '\n';
                            }
                        }
                        alert(text);
                    }
                }
            }
            console.log('changePassword', result);

            setLoading(false);
        }
    };

    const redirectToLogin = () => {
        navigate('/login');
    };

    return (
        <>
            {' '}
            {token && (
                <Modal
                    title=""
                    maskClosable={false}
                    open={true}
                    okText="Submit"
                    onOk={async (e) => handleSubmit(e)}
                    // okButtonProps={{ style: { display: 'none' } }}
                    // cancelText="Close"
                    onCancel={() => {
                        if (props.setModelOpen) props.setModelOpen(false);
                    }}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <form>
                        <div className="row ">
                            <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                                <h5>Reset password</h5>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                                <div className="form-input-container ">
                                    <div className="label">New Password:</div>
                                    <FormInput
                                        id="password"
                                        type="password"
                                        value={inputs.password || ''}
                                        onChange={({ target }: any) =>
                                            onInputChange(
                                                target.id,
                                                target.value
                                            )
                                        }
                                        error={errors.password}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                                <div className="form-input-container ">
                                    <div className="label">
                                        Confirm Password:
                                    </div>
                                    <FormInput
                                        id="confirm_password"
                                        type="password"
                                        value={inputs.confirm_password || ''}
                                        onChange={({ target }: any) =>
                                            onInputChange(
                                                target.id,
                                                target.value
                                            )
                                        }
                                        error={errors.confirm_password}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal>
            )}
            {redirectToLoginModal && (
                <Modal
                    title=""
                    maskClosable={false}
                    open={true}
                    okText="Reditrect To Login"
                    onOk={async (e) => redirectToLogin(e)}
                    // okButtonProps={{ style: { display: 'none' } }}
                    // cancelText="Close"
                    onCancel={() => {
                        if (props.setModelOpen) props.setModelOpen(false);
                    }}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <div className="row ">
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <h5>Password Changed!</h5>
                        </div>
                        <div className="pt-3 col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div>Click here to continue login</div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default Index;
