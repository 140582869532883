export function numberWithCommas(x, decimalPlace = 0) {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalPlace, // when both set to 2 is 2 decimal places
        maximumFractionDigits: decimalPlace
    });

    return formatter.format(x);

    // if (x != 0 && (!x || typeof x !== 'number')) return x;

    // if (decimalPlace == null && decimalPlace != 0) {
    //     var parts = x.toString().split('.');
    //     const numberPart = parts[0];
    //     const decimalPart = parts[1];
    //     const thousands = /\B(?=(\d{3})+(?!\d))/g;

    //     return (
    //         numberPart.replace(thousands, ',') +
    //         (decimalPart ? '.' + decimalPart : '')
    //     );

    //     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // } else {
    //     // return x.toFixed(decimalPlace).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    //     var parts = x.toFixed(decimalPlace).split('.');
    //     const numberPart = parts[0];
    //     const decimalPart = parts[1];
    //     const thousands = /\B(?=(\d{3})+(?!\d))/g;
    //     return (
    //         numberPart.replace(thousands, ',') +
    //         (decimalPart ? '.' + decimalPart : '')
    //     );
    // }
}

export function testNumberWithCommas(x, decimalPlace = 0) {
    if (x != 0 && (!x || typeof x !== 'number')) return x;

    // if (!decimalPlace) {
    //     var parts = x.toString().split('.');
    //     const numberPart = parts[0];
    //     const decimalPart = parts[1];
    //     const thousands = /\B(?=(\d{3})+(?!\d))/g;

    //     return (
    //         numberPart.replace(thousands, ',') +
    //         (decimalPart ? '.' + decimalPart : '')
    //     );
    // } else {
    // return x.toFixed(decimalPlace).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    var parts = x.toFixed(decimalPlace).split('.');
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;

    return (
        numberPart.replace(thousands, ',') +
        (decimalPart ? '.' + decimalPart : '')
    );
    // }
}
