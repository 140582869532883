import { useState, useEffect } from 'react';

import { useLoading } from '../../../context/LoadingContext';

import api from '../../../apis';

import DropdownSelect from '../../general/form-control/dropdown-select';
import DetailTable from '../../general/info-control/table-detail';
import { formGroupTableData } from '../../general/info-control/table-detail/helper';

import TreeSelect from '../../general/form-control/tree-select';
import { parseAPIResultToData } from '../../general/form-control/tree-select/helper';

const acc_summary_table_format = [
    { id: 'u_username', label: 'User ID', value: '' },
    { id: 'p_fullname', label: 'Username', value: '' },
    { id: 'd_p_fullname_code', label: 'Dealer', value: '' },
    // { id: 'p_nationality', label: 'Nationality', value: '' },
    {
        id: 'last_trade_time',
        label: 'Last Trade Date',
        value: '',
        type: 'timestamp'
    },
    {
        id: 'a_status',
        label: 'Status',
        value: '',
        statusToText: 'accountStatus'
    }
];

const acc_detail_table_format = [
    {
        id: 'at_trustacct_cash',
        label: 'Trust Account (Cash)',
        type: 'value',
        value: ''
    },
    {
        id: 'at_curr_trdlimit',
        label: 'Available Trading Limit',
        type: 'value',
        value: ''
    },
    {
        id: 'at_purchasecontracts',
        label: 'Purchase Contracts',
        type: 'value',
        value: ''
    },
    {
        id: 'at_purchasecontracts_overdue',
        label: 'Overdue Purchase Contracts',
        type: 'value',
        value: ''
    },
    {
        id: 'at_salescontracts',
        label: 'Sales Contracts',
        type: 'value',
        value: ''
    },
    { id: 'at_contra_gain', label: 'Contra Gains', type: 'value', value: '' },
    { id: 'at_contra_loss', label: 'Contra Losses', type: 'value', value: '' }
];

const Index = () => {
    const { loading, setLoading } = useLoading();

    useEffect(() => {}, []);

    const initialAvailabelOptions = (result: any) => {
        updateTreeSelect(result);
    };

    const [searchInputs, setSearchInputs] = useState({});
    const [userAccTreeData, setUserAccTreeData] = useState<any>([]);
    const [treeSelectLoading, setTreeSelectLoading] = useState<boolean>(false);

    const [currentAccSumaryData, setCurrentAccSummaryData] = useState(
        acc_summary_table_format || []
    );

    const [currentAccDetailData, setCurrentAccDetailData] = useState(
        acc_detail_table_format || []
    );

    useEffect(() => {}, [searchInputs]);

    //tree select start
    const onTreeKeyinChange = (keywords: any) => {
        setTreeSelectLoading(true);
        if (keywords.value || keywords.value == '')
            api.searchClientNameAccount(keywords.value).then((result: any) => {
                setTreeSelectLoading(false);
                if (result.clients) initialAvailabelOptions(result.clients);
            });
    };

    const onTreeSelectChange = async (selectInputObject: any) => {
        if (selectInputObject.value) {
            //get account_id
            setLoading(true);
            let userSummaryResult = await api.getClientProfile(
                selectInputObject.value
            );

            let userAccDetailResult = await api.getClientAccDetail(
                selectInputObject.value
            );
            //console.log(userAccDetailResult);
            if (userSummaryResult.account) {
                let account = userSummaryResult.account;
                updateAccSummaryComponent(account);
            }

            if (userAccDetailResult.account) {
                console.log('userAccDetailResult');
                console.log(userAccDetailResult.account);
                let account = userAccDetailResult.account;
                updateAccDetailComponent(account);
            }
            setLoading(false);
        } else {
            //  setCurrentUserInfo(customer_info_table_data);
            //  setCurrentAccDetail(acc_detail_data);
            setCurrentAccSummaryData(acc_summary_table_format);
            setCurrentAccDetailData(acc_detail_table_format);
        }
    };

    const updateTreeSelect = (result: any) => {
        // console.log(result);
        let newArr = parseAPIResultToData(result);
        setUserAccTreeData(newArr);
    };
    //tree select end

    const updateAccSummaryComponent = (result: any) => {
        console.log('result', result);
        let parseResult = parseCurrentAccSummary(result);
        let tableData = formGroupTableData(
            acc_summary_table_format,
            parseResult
        );
        // alert(JSON.stringify(tableData));
        setCurrentAccSummaryData(tableData);
    };

    const parseCurrentAccSummary = (result: any) => {
        if (result.d_p_fullname && result.d_code) {
            result.d_p_fullname_code =
                result.d_p_fullname + ' (' + result.d_code + ')';
        }
        return result;
    };

    const updateAccDetailComponent = (result: any) => {
        let tableData = formGroupTableData(acc_detail_table_format, result);

        setCurrentAccDetailData(tableData);
    };

    return (
        <div className="pt-3 text-left ">
            <div className="row">
                <div className="col-12 main-title">Account Summary</div>
                <div className="col-xxl-2 col-lg-3 col-md-6 col-12">
                    <div className="input-container">
                        <div className="label">Username / Account No </div>
                        <TreeSelect
                            treeMap={['username', 'accNo']}
                            data={userAccTreeData}
                            setLoading={treeSelectLoading}
                            onSelectChange={(selectInputObject: any) =>
                                onTreeSelectChange(selectInputObject)
                            }
                            keyinChange={(keywords: string) =>
                                onTreeKeyinChange(keywords)
                            }
                        />
                    </div>
                </div>
                <div className="col-xxl-2 col-lg-3 col-md-6 col-12">
                    <div className="input-container ">
                        <div className="label">Currency </div>

                        <select className="custom" defaultValue={'myr'}>
                            <option value="myr">Ringgit Malaysia</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                {' '}
                <div className="col-md-5 col-12 pt-5">
                    <DetailTable
                        data={currentAccSumaryData}
                        titleProps={{
                            label: 'User Summary',
                            bgColor: '#3268f6'
                        }}
                    />
                </div>
                <div className="col-md-7 col-12 pt-5">
                    <DetailTable
                        titleProps={{
                            label: 'Account Details',
                            bgColor: '#439782'
                        }}
                        data={currentAccDetailData}
                        itemProps={{
                            className: 'col-md-6 col-12'
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Index;
