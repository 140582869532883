import { useState, useEffect, useMemo } from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import FormInput from '../../general/form-control/form-item/input';
import FormSelect from '../../general/form-control/form-item/select';
import FormDatePicker from '../../general/form-control/form-item/date-picker';
import FormTimePicker from '../../general/form-control/form-item/time-picker';
import FormInputFile from '../../general/form-control/form-item/input-file';
import FormCheckboxGroup from '../../general/form-control/form-item/checkbox-group';

import RadioButtonGroup from '../../general/form-control/radio-button-group';

import TreeSelect from '../../general/form-control/tree-select';

import api from '../../../apis';
import { useLoading } from '../../../context/LoadingContext';
import { numberWithCommas } from '../../general/convertor';

import { parseAPIResultToData } from '../../general/form-control/tree-select/helper';

const Index = (props: any) => {
    const navigate = useNavigate();
    const { setLoading } = useLoading();

    useEffect(() => {
        // setLoading(true);
        // api.getBankList().then((result: any) => {
        //     setLoading(false);
        //     // console.log('getBankList');
        //     // console.log(result);
        //     setBankList(result);
        //     // setStockData(result[0].stocks);
        // });
        // setLoading(false);
    }, []);

    useEffect(() => {
        setCurrentAccNo(props.currentAccNo);
    }, [props.currentAccNo]);

    useEffect(() => {
        setCurrentUsername(props.currentUsername);
    }, [props.currentUsername]);

    useEffect(() => {
        setCurrentAccCode(props.currentAccCode);
    }, [props.currentAccCode]);

    //validation settings
    const [rules, setRules] = useState<any>({
        mode: { required: true, error_message: 'Please input type' },
        cheque_no: {
            requireIfExist: { key: 'mode', value: 2 }, //require if mode = 2
            error_message: 'Please input chequre no'
        },
        cheque_date: {
            requireIfExist: { key: 'mode', value: 2 }, //require if mode = 2
            error_message: 'Please select cheque date'
        },
        datetime: {
            required: true,
            error_message: 'Please select bank in date'
        },
        bankinhour: {
            required: true,
            error_message: 'Please select bank in time'
        },
        amount: { required: true, error_message: 'Please input amount' },
        currency: { required: false, error_message: 'Please select currency' },
        drawee_bank: {
            required: true,
            error_message: 'Please select drawee bank'
        },
        deposit_bank: {
            required: true,
            error_message: 'Please select depopsit bank'
        },
        deposit_bank_branch: {
            required: true,
            error_message: 'Please select bank branch'
        },
        file: { required: true, error_message: 'Please upload file' }
    });

    const depositFormat = {
        mode: null,
        datetime: null,
        amount: null,
        currency: 1,
        deposit_bank: null,
        deposit_bank_branch: null,
        file: null,
        drawee_bank: null,
        cheque_no: '',
        cheque_date: ''
    };
    const [currentAccNo, setCurrentAccNo] = useState<any>('');
    const [currentAccCode, setCurrentAccCode] = useState<any>('');
    const [currentUsername, setCurrentUsername] = useState<any>('');

    const [userAccTreeData, setUserAccTreeData] = useState<any>([]);
    const [treeSelectLoading, setTreeSelectLoading] = useState<boolean>(false);

    const [inputs, setInputs] = useState<any>(depositFormat || {});
    const [errors, setErrors] = useState<any>({});

    const [bankList, setBankList] = useState<any>([]);

    const [pendingList, setPendingList] = useState<any>([]);
    const [totalDepositAmount, setTotalDepositAmount] = useState<number>(0);

    const onInputChange = (id: string, newValue: string) => {
        setInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    //tree select start
    const onTreeKeyinChange = (keywords: any) => {
        // console.log('onTreeKeyinChange');
        // console.log(keywords);
        setTreeSelectLoading(true);
        if (keywords.value || keywords.value == '')
            api.searchClientNameAccount(keywords.value).then((result: any) => {
                console.log(result);
                setTreeSelectLoading(false);
                if (result.clients) updateTreeSelect(result.clients);
            });
    };

    const onTreeSelectChange = async (selectInputObject: any) => {
        if (selectInputObject.value) {
            setCurrentAccNo(selectInputObject.value);
            setCurrentAccCode(selectInputObject.label);

            console.log(selectInputObject);
            // requestGetAccountContractAPI(selectInputObject.value, inputs);
        } else {
            setCurrentAccNo(null);
            setCurrentAccCode(null);
            setTableData([]);
        }
    };

    const updateTreeSelect = (result: any) => {
        console.log(result);
        let newArr = parseAPIResultToData(result);
        setUserAccTreeData(newArr);
    };
    //tree select end

    const handleInputCondition = () => {
        // permanent_resident_status
    };

    useEffect(() => {
        console.log(inputs);
    }, [inputs]);

    const formValidation = (rules: any, inputs: any) => {
        if (!rules) return { valid: true, errors: {} };

        let valid = true;
        let errors: any = {};

        //validate rule
        for (var key in rules) {
            if (rules.hasOwnProperty(key)) {
                let item = rules[key];
                if (item.required == true && !inputs[key]) {
                    let error = item.error_message || 'Required';
                    errors[key] = error;
                    valid = false;
                } else if (item.requireIfExist) {
                    //if specific input exist,current input become required
                    let requireIfExist = item.requireIfExist;
                    let relatedkey = requireIfExist.key;
                    let relatedValue = requireIfExist.value;

                    //match type start check error
                    if (
                        relatedkey &&
                        relatedValue &&
                        inputs[relatedkey] &&
                        inputs[relatedkey] == relatedValue
                    ) {
                        if (!inputs[key]) {
                            let error = item.error_message || 'Required';
                            errors[key] = error;
                            valid = false;
                        }
                    }
                } else {
                }

                // requireIfExist: { key: 'mode', value: 2 }
            }
        }

        return {
            valid,
            errors
        };
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        console.log(pendingList);
        let result = await api.submitDepositManualBulk(currentAccNo, {
            pendingList
        });
        console.log('handleSubmit');
        console.log(result);
        if (result.status === 0) {
            alert('Thank you, the form was submitted successfully');
            //refresh page
            navigate(0);
        } else {
            alert('submit failed');
        }
    };

    const checkResidentStatusMalaysian = () => {
        let permanent_resident_status = inputs.permanent_resident_status;

        if (permanent_resident_status == 'N') return 'Resident';
        else if (permanent_resident_status == 'Y') {
            let time_residing = inputs.time_residing;

            if (time_residing == '> 182 days*') return 'Non-Resident';
            else if (time_residing == '< 182 days*') return 'Resident';
        }

        return null;
    };

    const checkResidentStatusNonMalaysian = () => {
        let permanent_resident_status_my = inputs.permanent_resident_status_my;

        if (permanent_resident_status_my == 'N') return 'Non-Resident';
        else if (permanent_resident_status_my == 'Y') {
            let time_residing = inputs.time_residing;

            if (time_residing == '> 182 days*') return 'Resident';
            else if (time_residing == '< 182 days*') return 'Non-Resident';
        }

        return null;
    };

    return (
        <div className="data-correction-eform w-100 text-left pt-3">
            {/* <div className="row">
                <div className="col-12 main-title"></div>
                <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12">
                    <div className="input-container">
                        <div className="label">Username / Account No </div>
                        <TreeSelect
                            treeMap={['username', 'accNo']}
                            data={userAccTreeData}
                            setLoading={treeSelectLoading}
                            keyinChange={(keywords: any) =>
                                onTreeKeyinChange(keywords)
                            }
                            onSelectChange={(selectInputObject: any) =>
                                onTreeSelectChange(selectInputObject)
                            }
                            onSelectChangeParentValue={(parentValue: any) => {
                                setCurrentUsername(parentValue || '');
                            }}
                        />
                    </div>
                </div>
                <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12">
                    <div className="input-container ">
                        <div className="label">Name</div>
                        <input
                            id="username"
                            className="custom border-bottom"
                            type="text"
                            value={currentUsername}
                            // onChange={({ target }) =>
                            //     onInputChange(target.id, target.value)
                            // }
                            disabled
                        />
                    </div>
                </div>
            </div> */}
            <div className="">
                {' '}
                <h4>DATA CORRECTION REQUEST FORM</h4>
            </div>
            <div className="pt-3 ">
                <div className="form-section">
                    Section 1 : INDIVIDUAL CLIENT (Please complete the relevant
                    fields in BLOCK letters)
                </div>
                <div className="row pt-5">
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-6 col-12">
                        <div className="input-container ">
                            <div className="label">Name</div>
                            <input
                                id="username"
                                className="custom border-bottom"
                                type="text"
                                value={currentUsername}
                                // onChange={({ target }) =>
                                //     onInputChange(target.id, target.value)
                                // }
                                disabled
                            />
                        </div>
                    </div>
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-6 col-12">
                        <div className="input-container ">
                            <div className="label">NRIC / PASSPORT NO</div>
                            <input
                                id="username"
                                className="custom border-bottom"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="row pt-2">
                    {' '}
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-6 col-12">
                        <div className="input-container ">
                            <div className="label">CLIENT CODE</div>
                            <input
                                id="username"
                                className="custom border-bottom"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-6 col-12">
                        <div className="input-container ">
                            <div className="label">CDS ACCOUNT NO. </div>
                            <input
                                id="username"
                                className="custom border-bottom"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>
            <hr className="mt-5 mb-5" />
            <div className="">
                <div className="form-title dark-text-white">
                    Please update/correct my personal data below (Note: please
                    tick at the relevant box where the data need to be
                    corrected):
                </div>
                <div className="row pt-3">
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-6 col-12">
                        <div className="form-input-container ">
                            <div className="label">NAME</div>
                            <FormInput
                                id="name"
                                type="text"
                                value={inputs.name || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.name}
                            />
                        </div>
                    </div>
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-6 col-12">
                        <div className="form-input-container ">
                            <div className="label">NRIC / PASSPORT NO</div>
                            <FormInput
                                id="nric"
                                type="text"
                                value={inputs.nric || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.nric}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xxxl-8 col-xl-10 col-lg-12 col-md-6 col-12">
                        <div className="form-input-container ">
                            <div className="label">MAILING ADDRESS</div>
                            <FormInput
                                id="address"
                                type="text"
                                value={inputs.address || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.address}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-6 col-12">
                        <div className="form-input-container ">
                            <div className="label">POST CODE</div>
                            <FormInput
                                id="postcode"
                                type="text"
                                value={inputs.postcode || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.postcode}
                            />
                        </div>
                    </div>
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-6 col-12">
                        <div className="form-input-container ">
                            <div className="label">TOWN / STATE / COUNTRY</div>
                            <FormInput
                                id="town"
                                type="text"
                                value={inputs.town || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.town}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-6 col-12">
                        <div className="form-input-container ">
                            <div className="label">TELEPHONE NO</div>
                            <PhoneInput
                                country={'my'}
                                value={inputs.tel_no || ''}
                                inputClass="custom-tel"
                                inputStyle={{
                                    height: '42px',
                                    width: 'inherit'
                                }}
                                prefix=""
                                //   onChange={phone => this.setState({ phone })}
                            />
                            {/* <FormInput
                                id="tel"
                                type="text"
                                value={inputs.tel || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                placeholder="60108913692"
                                error={errors.tel}
                            /> */}
                        </div>
                    </div>
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-6 col-12">
                        <div className="form-input-container ">
                            <div className="label">MOBILE NO</div>
                            <PhoneInput
                                country={'my'}
                                value={''}
                                inputClass="custom-tel"
                                inputStyle={{
                                    height: '42px',
                                    width: 'inherit'
                                }}
                                prefix=""
                                //   onChange={phone => this.setState({ phone })}
                            />
                            {/* <FormInput
                                id="mobileno"
                                type="text"
                                value={inputs.mobileno || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.mobileno}
                            /> */}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xxxl-8 col-xl-10 col-lg-10 col-md-12 col-12">
                        <div className="form-input-container ">
                            <div className="label">E-MAIL ADDRESS</div>
                            <FormInput
                                id="email"
                                type="text"
                                value={inputs.email || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.email}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                        <div className="form-input-container ">
                            <div className="label">NATIONALITY</div>
                            <FormInput
                                id="nationality"
                                type="text"
                                value={inputs.nationality || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.nationality}
                            />
                        </div>
                    </div>
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                        <div className="form-input-container ">
                            <div className="label">
                                PERMENANT RESIDENCY (PR)
                            </div>
                            <FormInput
                                id="permenantResidency"
                                type="text"
                                value={inputs.permenantResidency || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.permenantResidency}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                        <div className="form-input-container ">
                            <div className="label">
                                POLITICALLY EXPOSED PERSON (PEP)
                            </div>
                            <RadioButtonGroup
                                id="PEP"
                                data={[
                                    { label: 'YES', value: 'Y' },
                                    {
                                        label: 'NO',
                                        value: 'N'
                                    }
                                ]}
                                onChange={(id: string, value: string) =>
                                    onInputChange(id, value)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <hr className="mt-5 mb-5" />
            <div className="">
                <div className="form-title dark-text-white">
                    OCCUPATION & FINANCIAL DETAILS (MANDATORY UPDATE FOR MARGIN
                    CLIENT-CCRIS REPORTING)
                </div>
                <div className="row">
                    <div className="col-xxxl-12 col-xl-12 col-lg-5 col-md-12 col-12 pb-2">
                        <div className="form-input-container ">
                            <div className="label">EMPLOYMENT STATUS</div>
                            <div className="d-flex align-items-end gap-3">
                                <RadioButtonGroup
                                    id="employmentStatus"
                                    data={[
                                        { label: 'EMPLOYEE', value: 'Y' },
                                        {
                                            label: 'SELF-EMPLOYED',
                                            value: 'N'
                                        },
                                        {
                                            label: 'OTHERS',
                                            value: 'N',
                                            inputText: true
                                        }
                                    ]}
                                    onChange={(id: string, value: string) =>
                                        onInputChange(id, value)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xxxl-8 col-xl-10 col-lg-10 col-md-12 col-12">
                        <div className="form-input-container ">
                            <div className="label">
                                NAME OF COMPANY / EMPLOYER
                            </div>
                            <FormInput
                                id="companyEmployer"
                                type="text"
                                value={inputs.companyEmployer || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.companyEmployer}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xxxl-8 col-xl-10 col-lg-10 col-md-12 col-12">
                        <div className="form-input-container ">
                            <div className="label">
                                ADDRESS OF COMPANY/ EMPLOYER
                            </div>
                            <FormInput
                                id="companyEmployerAddress"
                                type="text"
                                value={inputs.companyEmployerAddress || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.companyEmployerAddress}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                        <div className="form-input-container ">
                            <div className="label">
                                DURATION OF EMPLOYMENT / BUSINESS
                            </div>
                            <FormInput
                                id="business"
                                type="text"
                                value={inputs.business || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.business}
                            />
                        </div>
                    </div>
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                        <div className="form-input-container ">
                            <div className="label">POSITION</div>
                            <FormInput
                                id="position"
                                type="text"
                                value={inputs.position || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.position}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xxxl-8 col-xl-10 col-lg-10 col-md-12 col-12">
                        <div className="form-input-container ">
                            <div className="label">NATURE OF BUSINESS</div>
                            <FormInput
                                id="natureBusiness"
                                type="text"
                                value={inputs.natureBusiness || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.natureBusiness}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <hr className="mt-5 mb-5" />
            <div className="row">
                <div className="col-xxxl-8 col-xl-10 col-lg-12 col-md-12 col-12 pb-2">
                    <div className="form-input-container ">
                        <div className="label">ANNUAL PERSONAL INCOME </div>
                        <div className="d-flex align-items-end gap-3 pt-3">
                            <RadioButtonGroup
                                id="annualPersonalIncome"
                                data={[
                                    {
                                        label: 'BELOW RM25,000',
                                        value: 'BELOW RM25,000'
                                    },
                                    {
                                        label: 'RM25,001 - RM50,000',
                                        value: 'RM25,001 - RM50,000'
                                    },
                                    {
                                        label: 'RM50,001 - RM100,000',
                                        value: 'RM50,001 - RM100,000'
                                    },

                                    {
                                        label: 'RM100,001 - RM200,000',
                                        value: 'RM100,001 - RM200,000'
                                    },
                                    {
                                        label: 'RM200,001 -RM300,000',
                                        value: 'RM200,001 -RM300,000'
                                    },
                                    {
                                        label: 'RM300,001 – RM500,000',
                                        value: 'RM300,001 – RM500,000'
                                    },
                                    {
                                        label: 'RM500,001 – RM1 million',
                                        value: 'RM500,001 – RM1 million'
                                    },
                                    {
                                        label: 'ABOVE RM1 million',
                                        value: 'ABOVE RM1 million',
                                        inputText: true
                                    }
                                ]}
                                onChange={(id: string, value: string) =>
                                    onInputChange(id, value)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xxxl-8 col-xl-10 col-lg-12 col-md-12 col-12 pb-2">
                    <div className="form-input-container ">
                        <div className="label">ESTIMATED NET WORTH </div>
                        <div className="d-flex align-items-end gap-3 pt-3">
                            <RadioButtonGroup
                                id="estimatedNetWorth"
                                data={[
                                    {
                                        label: 'BELOW RM25,000',
                                        value: 'BELOW RM25,000'
                                    },
                                    {
                                        label: 'BELOW RM100,000',
                                        value: 'BELOW RM100,000'
                                    },
                                    {
                                        label: 'RM100,001 - RM250,000',
                                        value: 'RM100,001 - RM250,000'
                                    },
                                    {
                                        label: 'RM250,001 - RM500,000',
                                        value: 'RM250,001 - RM500,000'
                                    },
                                    {
                                        label: 'RM500,001 - RM1,000,000',
                                        value: 'RM500,001 - RM1,000,000'
                                    },
                                    {
                                        label: 'RM1,000,001 to RM 3million',
                                        value: 'RM1,000,001 to RM 3million'
                                    },
                                    {
                                        label: 'ABOVE RM 3million ',
                                        value: 'ABOVE RM 3million ',
                                        inputText: true
                                    }
                                ]}
                                onChange={(id: string, value: string) =>
                                    onInputChange(id, value)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xxxl-8 col-xl-10 col-lg-12 col-md-12 col-12 pb-2">
                    <div className="form-input-container ">
                        <div className="label">SOURCE OF WEALTH/ FUNDS</div>
                        <div className="pt-3">
                            <FormCheckboxGroup
                                id="annual_personal_income"
                                data={[
                                    {
                                        label: 'SALARY / BUSINESS INCOME',
                                        value: 'SALARY / BUSINESS INCOME'
                                    },
                                    {
                                        label: 'NVESTMENT INCOME',
                                        value: 'NVESTMENT INCOME'
                                    },
                                    {
                                        label: 'INHERITED WEALTH',
                                        value: 'INHERITED WEALTH'
                                    },
                                    {
                                        label: 'DIRECTORSHIP',
                                        value: 'DIRECTORSHIP'
                                    },
                                    {
                                        label: 'SAVINGS',
                                        value: 'SAVINGS'
                                    },
                                    {
                                        label: 'OTHERS',
                                        value: 'OTHERS',
                                        inputText: true
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <hr className="mt-5 mb-5" />
            <div className="row">
                <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                    <div className="form-input-container ">
                        <div className="label">RESIDENT STATUS</div>
                        <FormSelect
                            id="residentStatus"
                            value={inputs.residentStatus || ''}
                            onChange={({ target }: any) =>
                                onInputChange(target.id, target.value)
                            }
                            error={errors.residentStatus}
                        >
                            <option value={''} disabled>
                                Please select
                            </option>
                            <option value={'malaysian'}>Malaysian</option>
                            <option value={'nonMalaysian'}>
                                Non-Malaysian
                            </option>
                        </FormSelect>
                    </div>
                </div>
            </div>
            {/* if malaysian */}
            {inputs.residentStatus == 'malaysian' && (
                <>
                    <div className="row pt-3">
                        <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                            <div className="form-input-container ">
                                <div className="label">
                                    Permanent Resident Status of a country
                                    Outside Malaysia
                                </div>
                                <div className="d-flex align-items-end gap-3">
                                    <RadioButtonGroup
                                        id="permanent_resident_status"
                                        data={[
                                            {
                                                label: 'No',
                                                value: 'N'
                                            },
                                            {
                                                label: 'Yes',
                                                value: 'Y'
                                            }
                                        ]}
                                        onChange={(
                                            id: string,
                                            value: string
                                        ) => {
                                            onInputChange(id, value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {inputs.permanent_resident_status == 'Y' && (
                            <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                                <div className="form-input-container ">
                                    <div className="label">
                                        Length of time residing outside Malaysia
                                    </div>
                                    <div className="d-flex align-items-end gap-3">
                                        <RadioButtonGroup
                                            id="time_residing"
                                            data={[
                                                {
                                                    label: '> 182 days*',
                                                    value: '> 182 days*'
                                                },
                                                {
                                                    label: '< 182 days*',
                                                    value: '< 182 days*'
                                                }
                                            ]}
                                            onChange={(
                                                id: string,
                                                value: string
                                            ) => onInputChange(id, value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="row pt-3">
                        {' '}
                        <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                            <div className="input-container ">
                                <div className="label">Residency Status</div>
                                <input
                                    id="residency_status"
                                    className="custom border-bottom"
                                    type="text"
                                    value={checkResidentStatusMalaysian() || ''}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {/* non malaysian */}
            {inputs.residentStatus == 'nonMalaysian' && (
                <>
                    <div className="row pt-3">
                        <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                            <div className="form-input-container ">
                                <div className="label">
                                    Permanent Resident Status in Malaysia
                                </div>
                                <div className="d-flex align-items-end gap-3">
                                    <RadioButtonGroup
                                        id="permanent_resident_status_my"
                                        data={[
                                            {
                                                label: 'No',
                                                value: 'N'
                                            },
                                            {
                                                label: 'Yes',
                                                value: 'Y'
                                            }
                                        ]}
                                        onChange={(
                                            id: string,
                                            value: string
                                        ) => {
                                            onInputChange(id, value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {inputs.permanent_resident_status_my == 'Y' && (
                            <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                                <div className="form-input-container ">
                                    <div className="label">
                                        Length of time residing outside Malaysia
                                    </div>
                                    <div className="d-flex align-items-end gap-3">
                                        <RadioButtonGroup
                                            id="time_residing"
                                            data={[
                                                {
                                                    label: '> 182 days*',
                                                    value: '> 182 days*'
                                                },
                                                {
                                                    label: '< 182 days*',
                                                    value: '< 182 days*'
                                                }
                                            ]}
                                            onChange={(
                                                id: string,
                                                value: string
                                            ) => onInputChange(id, value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="row pt-3">
                        {' '}
                        <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                            <div className="input-container ">
                                <div className="label">Residency Status</div>
                                <input
                                    id="residency_status"
                                    className="custom border-bottom"
                                    type="text"
                                    value={
                                        checkResidentStatusNonMalaysian() || ''
                                    }
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className="row pt-3">
                <div className="col-xxxl-8 col-xl-10 col-lg-10 col-md-12 col-12">
                    {' '}
                    *Computation of 182 days is based on per calendar years and
                    need not be on a continuous basis. Per calendar year means
                    from 1st January 20X1 to 31st December 20X1.
                </div>
            </div>
            <hr className="mt-5 mb-5" />
            <div className="row">
                <div className="form-title">
                    FAMILY MEMBER / NEXT OF KIN DETAILS
                </div>
                <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                    <div className="form-input-container ">
                        <div className="label">CLIENT CODE</div>
                        <FormInput
                            id="family_client_code"
                            type="text"
                            value={inputs.family_client_code || ''}
                            onChange={({ target }: any) =>
                                onInputChange(target.id, target.value)
                            }
                            error={errors.family_client_code}
                        />
                    </div>
                </div>
                <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                    <div className="form-input-container ">
                        <div className="label">RELATIONSHIP</div>
                        <FormInput
                            id="family_relationship"
                            type="text"
                            value={inputs.family_relationship || ''}
                            onChange={({ target }: any) =>
                                onInputChange(target.id, target.value)
                            }
                            error={errors.family_relationship}
                        />
                    </div>
                </div>
                <div className="col-xxxl-8 col-xl-10 col-lg-10 col-md-12 col-12">
                    <div className="form-input-container ">
                        <div className="label">NAME</div>
                        <FormInput
                            id="family_name"
                            type="text"
                            value={inputs.family_name || ''}
                            onChange={({ target }: any) =>
                                onInputChange(target.id, target.value)
                            }
                            error={errors.family_name}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                {' '}
                <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                    <div className="form-input-container ">
                        <div className="label">NRIC NO.</div>
                        <FormInput
                            id="family_nric"
                            type="text"
                            value={inputs.family_nric || ''}
                            onChange={({ target }: any) =>
                                onInputChange(target.id, target.value)
                            }
                            error={errors.family_nric}
                        />
                    </div>
                </div>
                <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                    <div className="form-input-container ">
                        <div className="label">TELEPHONE NO</div>
                        <PhoneInput
                            country={'my'}
                            value={''}
                            inputClass="custom-tel"
                            inputStyle={{
                                height: '42px',
                                width: 'inherit'
                            }}
                            prefix=""
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xxxl-8 col-xl-10 col-lg-12 col-md-12 col-12 pb-2">
                    <div className="form-input-container ">
                        <div className="label">EMPLOYMENT STATUS</div>
                        <div className="d-flex align-items-end gap-3 w-100 ">
                            <RadioButtonGroup
                                id="estimatedNetWorth"
                                data={[
                                    {
                                        label: 'EMPLOYEE',
                                        value: 'EMPLOYEE'
                                    },
                                    {
                                        label: 'SELF-EMPLOYED',
                                        value: 'SELF-EMPLOYED'
                                    },
                                    {
                                        label: 'OTHERS',
                                        value: 'OTHERS',
                                        inputText: true
                                    }
                                ]}
                                onChange={(id: string, value: string) =>
                                    onInputChange(id, value)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {' '}
                <div className="col-xxxl-8 col-xl10 col-lg-10 col-md-12 col-12">
                    <div className="form-input-container ">
                        <div className="label">NAME & ADDRESS OF COMPANY </div>
                        <FormInput
                            id="family_company_name"
                            type="text"
                            value={inputs.family_company_name || ''}
                            onChange={({ target }: any) =>
                                onInputChange(target.id, target.value)
                            }
                            error={errors.family_company_name}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                {' '}
                <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                    <div className="form-input-container ">
                        <div className="label">POST CODE</div>
                        <FormInput
                            id="family_post_code"
                            type="text"
                            value={inputs.family_post_code || ''}
                            onChange={({ target }: any) =>
                                onInputChange(target.id, target.value)
                            }
                            error={errors.family_post_code}
                        />
                    </div>
                </div>
                <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                    <div className="form-input-container ">
                        <div className="label">TOWN / STATE / COUNTRY</div>
                        <FormInput
                            id="family_town"
                            type="text"
                            value={inputs.family_town || ''}
                            onChange={({ target }: any) =>
                                onInputChange(target.id, target.value)
                            }
                            error={errors.family_town}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                {' '}
                <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                    <div className="form-input-container ">
                        <div className="label">POSITION</div>
                        <FormInput
                            id="family_position"
                            type="text"
                            value={inputs.family_position || ''}
                            onChange={({ target }: any) =>
                                onInputChange(target.id, target.value)
                            }
                            error={errors.family_position}
                        />
                    </div>
                </div>
                <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                    <div className="form-input-container ">
                        <div className="label">
                            POLITICALLY EXPOSED PERSON (PEP)
                        </div>
                        <div className="d-flex align-items-end gap-3 w-100 ">
                            <RadioButtonGroup
                                id="family_pep"
                                data={[
                                    {
                                        label: 'YES',
                                        value: 'Y'
                                    },
                                    {
                                        label: 'NO',
                                        value: 'N'
                                    }
                                ]}
                                onChange={(id: string, value: string) =>
                                    onInputChange(id, value)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <hr className="mt-5 mb-5" />
            {/* personal data section */}
            <div className="row">
                <div className="form-title">
                    ADDITION OF PERSONAL DATA <br />{' '}
                    <small>
                        (* Please indicate the personal data to be added and
                        attach additional sheets where necessary)
                    </small>{' '}
                </div>
                <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                    <div className="form-input-container ">
                        <div className="label">Personal Data Item</div>
                        <FormInput
                            id="personal_data_item"
                            type="textarea"
                            value={inputs.personal_data_item || ''}
                            onChange={({ target }: any) =>
                                onInputChange(target.id, target.value)
                            }
                            error={errors.personal_data_item}
                        />
                    </div>
                </div>
                <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                    <div className="form-input-container ">
                        <div className="label">Personal Data to be added </div>
                        <FormInput
                            id="personal_data_to_added"
                            type="textarea"
                            value={inputs.personal_data_to_added || ''}
                            onChange={({ target }: any) =>
                                onInputChange(target.id, target.value)
                            }
                            error={errors.personal_data_to_added}
                        />
                    </div>
                </div>
            </div>
            {/* - */}

            {/* DELETION OF PERSONAL DATA  */}
            <div className="row">
                <div className="form-title">
                    DELETION OF PERSONAL DATA <br />{' '}
                    <small>
                        (Please indicate the personal data to be deleted and
                        attach additional sheets where necessary)
                    </small>{' '}
                </div>
                <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                    <div className="form-input-container ">
                        <div className="label">Personal Data Item</div>
                        <FormInput
                            id="personal_deleteion_data_item"
                            type="textarea"
                            value={inputs.personal_deleteion_data_item || ''}
                            onChange={({ target }: any) =>
                                onInputChange(target.id, target.value)
                            }
                            error={errors.personal_deleteion_data_item}
                        />
                    </div>
                </div>
                <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                    <div className="form-input-container ">
                        <div className="label">Reason(s) for Deletion</div>
                        <FormInput
                            id="personal_reason_deletion"
                            type="textarea"
                            value={inputs.personal_reason_deletion || ''}
                            onChange={({ target }: any) =>
                                onInputChange(target.id, target.value)
                            }
                            error={errors.personal_reason_deletion}
                        />
                    </div>
                </div>
            </div>
            {/* - */}

            <hr className="mt-5 mb-5" />
            <div className="">
                <div className="p-3 border rounded shadow-sm dark-text-white">
                    <div
                        style={{
                            float: 'left',
                            width: '35px',
                            height: '100%'
                        }}
                    >
                        <div className="pt-2">
                            <input
                                className="custom-checkbox "
                                // name="chkRed"
                                type="checkbox"
                                style={{
                                    width: '22px',
                                    height: '22px',
                                    cursor: 'pointer'
                                }}
                            />
                        </div>
                    </div>
                    <div style={{ width: 'auto', paddingLeft: '35px' }}>
                        <div style={{ textAlign: 'justify' }}>
                            I hereby certify that the information given in this
                            form and any documents submitted enclosed are true
                            and accurate. I understand that (i) it will be
                            necessary for Vestrade Investment Bank Berhad
                            (“VIBB”) to verify my / the Third Party Requestor’s
                            identity, and (ii) that VIBB may contact me in order
                            to verify the personal data to be corrected. I also
                            understand that any and/or all personal data
                            provided by me in this Personal Data Correction
                            Request Form will be collected and processed by VIBB
                            as personal data in accordance with the Personal
                            Data Protection Act 2010 and VIBB’s Privacy Policy
                            as may be amended from time to time. Copies of the
                            current and prevailing privacy policy may be
                            obtained from the VIBB website (www.vibb.com).
                            Further, I hereby warrant and represent to VIBB that
                            prior to submission of this form the consent of all
                            persons named under the family member/next of kin
                            details section (“ Relevant Data Subjects”) have
                            been obtained, for VIBB’s collection, processing,
                            holding and use of the personal information of the
                            Relevant Data Subjects in accordance with the
                            Personal Data Protection Act 2010 and VIBB’s Privacy
                            Policy as may be amended from time to time.{' '}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {' '}
                <div className="col-12 pt-5">
                    <button className="btn-primary-custom btn-md" type="submit">
                        Submit &nbsp;&nbsp;
                        <i
                            className="fas fa-chevron-right "
                            aria-hidden="true"
                        ></i>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Index;
