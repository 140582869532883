import { useState, useEffect, useMemo } from 'react';

import api from '../../../apis';
import moment from 'moment';

import { useLoading } from '../../../context/LoadingContext';

import ReactTable from '../../general/form-control/react-table';
import TreeSelect from '../../general/form-control/tree-select';

import { parseAPIResultToData } from '../../general/form-control/tree-select/helper';

import { numberWithCommas } from '../../general/convertor';

import DateRangePicker from '../../general/form-control/date-rangepicker';

import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const Index = () => {
    const { setLoading } = useLoading();

    useEffect(() => {}, []);

    const [currentAccNo, setCurrentAccNo] = useState('');
    const [currentUsername, setCurrentUsername] = useState('');

    const [userAccTreeData, setUserAccTreeData] = useState<any>([]);
    const [treeSelectLoading, setTreeSelectLoading] = useState<boolean>(false);

    const [inputs, setInputs] = useState({ paymentType: '' });

    useEffect(() => {
        console.log(inputs);
        // if (currentAccNo) {
        //     requestGetAccountContractAPI(currentAccNo, searchInputs);
        // }
    }, [inputs]);

    const onInputChange = (id: string, newValue: string) => {
        setInputs((prevState) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    const onDateChange = ({ id, timestamp }: any) => {
        let cdate = '';
        if (timestamp) {
            cdate = moment(timestamp, 'DD-MM-YYYY').format('YYYY-MM-DD');
        }
        onDropdownChange(id, cdate);
    };

    const onDropdownChange = (id: string, newValue: string) => {
        setInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    //tree select start
    const onTreeKeyinChange = (keywords: any) => {
        // console.log('onTreeKeyinChange');
        // console.log(keywords);
        setTreeSelectLoading(true);
        if (keywords.value || keywords.value == '')
            api.searchClientNameAccount(keywords.value).then((result: any) => {
                console.log(result);
                setTreeSelectLoading(false);
                if (result.clients) updateTreeSelect(result.clients);
            });
    };

    const onTreeSelectChange = async (selectInputObject: any) => {
        if (selectInputObject.value) {
            setCurrentAccNo(selectInputObject.value);

            requestGetAccountContractAPI(selectInputObject.value, inputs);
        } else {
            // setTableData([]);
        }
    };

    const requestGetAccountContractAPI = async (
        accNo: any,
        searchInputs: any
    ) => {
        setLoading(true);
        console.log(searchInputs);
        let result = await api.getAccountContra(accNo, searchInputs);
        console.log(result);
        if (result.contra) {
            let contra = result.contra;
            console.log(contra);
            //setTableData(contra);
            //  updateAccSummaryComponent(account);
        }
        setLoading(false);
    };

    const updateTreeSelect = (result: any) => {
        console.log(result);
        let newArr = parseAPIResultToData(result);
        setUserAccTreeData(newArr);
    };
    //tree select end

    const handleSubmit = (e: any) => {
        e.preventDefault();
    };

    const tblColumns = useMemo(
        () => [
            {
                Header: 'Payment Date',
                accessor: 'payment_date'
            },
            {
                Header: 'Exchange',
                accessor: 'exchange'
            },
            {
                Header: 'Symbol',
                accessor: 'symbol'
            },
            {
                Header: 'Type',
                accessor: 'type'
            },
            {
                Header: 'CCY',
                accessor: 'ccy'
            },
            {
                Header: <div className="text-right">Dividend</div>,
                accessor: 'dividend',
                Cell: (v: any) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value, 2)}
                            </div>
                        );
                }
            },
            {
                Header: 'Status',
                accessor: 'status'
            }
        ],
        []
    );

    const dummyData = [
        {
            payment_date: '22-06-2022',
            exchange: 'Bursa',
            symbol: 'INARI',
            type: 'Dividend',
            ccy: 'MYR',
            dividend: '1.00',
            status: 'Successful'
        },
        {
            payment_date: '22-06-2022',
            exchange: 'Bursa',
            symbol: 'AJINOMOTO',
            type: 'Dividend',
            ccy: 'MYR',
            dividend: '2.50',
            status: 'Successful'
        },
        {
            payment_date: '22-06-2022',
            exchange: 'NASDAQ',
            symbol: 'INARI',
            type: 'Dividend',
            ccy: 'HKD',
            dividend: '1.00',
            status: 'Successful'
        },
        {
            payment_date: '22-06-2022',
            exchange: 'NASDAQ',
            symbol: 'INARI',
            type: 'Dividend',
            ccy: 'USD',
            dividend: '1.00',
            status: 'Successful'
        },
        {
            payment_date: '22-06-2022',
            exchange: 'NASDAQ',
            symbol: 'INARI',
            type: 'Dividend',
            ccy: 'USD',
            dividend: '1.00',
            status: 'Successful'
        },
        {
            payment_date: '22-06-2022',
            exchange: 'HKEX',
            symbol: 'NVIDIA',
            type: 'Dividend',
            ccy: 'USD',
            dividend: '3.50',
            status: 'Successful'
        },
        {
            payment_date: '22-06-2022',
            exchange: 'HKEX',
            symbol: 'APPLE',
            type: 'Dividend',
            ccy: 'USD',
            dividend: '3.50',
            status: 'Successful'
        },
        {
            payment_date: '22-06-2022',
            exchange: 'HKEX',
            symbol: 'LI AUTO',
            type: 'Dividend',
            ccy: 'USD',
            dividend: '10.00',
            status: 'Successful'
        }
    ];

    return (
        <form onSubmit={handleSubmit}>
            <div className="pt-3 text-left ">
                <div className="row">
                    {/* filter */}
                    <div className="col-xxxl-2 col-xl-2 col-lg-3 col-md-4 col-12">
                        <div className="col-12 main-title">
                            Corporate Action
                        </div>
                        <div className="col-xxxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            {' '}
                            <div className="input-container">
                                <div className="label">
                                    Username / Account No{' '}
                                </div>
                                <TreeSelect
                                    treeMap={['username', 'accNo']}
                                    data={userAccTreeData}
                                    setLoading={treeSelectLoading}
                                    keyinChange={(keywords: any) =>
                                        onTreeKeyinChange(keywords)
                                    }
                                    onSelectChange={(selectInputObject: any) =>
                                        onTreeSelectChange(selectInputObject)
                                    }
                                    onSelectChangeParentValue={(
                                        parentValue: any
                                    ) => {
                                        setCurrentUsername(parentValue || '');
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-xxxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            {' '}
                            <div className="input-container ">
                                <div className="label">Name </div>
                                <input
                                    id="dealer"
                                    className="custom border-bottom"
                                    type="text"
                                    value={currentUsername}
                                    // value={currentUsername}
                                    // onChange={({ target }) =>
                                    //     onInputChange(target.id, target.value)
                                    // }
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-xxxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            {' '}
                            <div className="input-container ">
                                <div className="label">Dealer / Remisier </div>
                                <input
                                    id="dealer"
                                    className="custom border-bottom"
                                    type="text"
                                    value="Lee Mai Ser (ENV007)"
                                    // value={currentUsername}
                                    // onChange={({ target }) =>
                                    //     onInputChange(target.id, target.value)
                                    // }
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-xxxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            {' '}
                            <div className="input-container ">
                                <div className="label"> Type </div>
                                <select className="custom" defaultValue={''}>
                                    <option value="">All</option>
                                    <option value="Dividend">Dividend</option>
                                    <option value="Bonus issue">
                                        Bonus issue
                                    </option>
                                    <option value="Right issue">
                                        Right issue
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div className="col-xxxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="input-container sideFilter">
                                <div className="label"> Date </div>
                                <DateRangePicker
                                    onDateChange={(obj: any) =>
                                        onDateChange(obj)
                                    }
                                />
                                {/* <RangePicker
                                placeholder={['', '']}
                                className="w-100 daterangepicker custom"
                                format="DD-MM-YYYY"
                                onChange={(dates, dateStrings) => {
                                    console.log(dateStrings);

                                    onDateChange({
                                        id: 'dateStart',
                                        timestamp: dateStrings[0]
                                    });

                                    onDateChange({
                                        id: 'dateEnd',
                                        timestamp: dateStrings[1]
                                    });
                                }}
                            /> */}
                                {/* <div
                                className="d-flex w-100"
                                style={{ gap: '20px' }}
                            >
                                <div className="w-50">
                                    <Datepicker
                                        id="dateStart"
                                        placeholder="from"
                                        // value={searchInputs.dateStart}
                                        // onChange={onDateChange}
                                    />
                                </div>
                                <div className="w-50">
                                    <Datepicker
                                        id="dateEnd"
                                        placeholder="to"
                                        // value={searchInputs.dateEnd}
                                        // onChange={onDateChange}
                                    />
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div className="col-xxl-12 col-md-12 col-12 ">
                            <div className="input-container ">
                                <div className="label"> </div>
                                <button
                                    className="btn-primary-custom btn-md"
                                    type="submit"
                                    style={{ width: '100%', maxWidth: '100%' }}
                                >
                                    Export &nbsp;&nbsp;
                                    <i
                                        className="fas fa-chevron-right "
                                        aria-hidden="true"
                                    ></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* filter end div */}

                    {/* body */}
                    <div className="col-xxxl-10 col-xl-10 col-lg-9 col-md-8 col-12">
                        <div className="col-md-12 col-12 pt-3">
                            <ReactTable
                                columns={tblColumns}
                                data={dummyData}
                                pageSize={8}
                                currentPage={1}
                                totalRecords={8}
                                hasPagination
                            />
                        </div>
                    </div>
                    {/* body end div*/}
                </div>
            </div>
        </form>
    );
};

export default Index;
