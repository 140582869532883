import { userLogin, userLogout } from './models/UserLogin';
import { TDSAuthUrl } from './models/TDS';
import {
    searchClientNameAccount,
    getClientProfile,
    getClientAccDetail,
    getAccountContra,
    getMultiAccountContra,
    getAccountContract,
    getAccountContractDetail,
    getAccountContractSummary,
    getMultiAccountContract,
    getMultiAccountContractSummary,
    getMultiAccountContractDetail
} from './models/Account';

import { getTrustDetail } from './models/Trust';

import { getTradeHistory, getTradeHistorySummary } from './models/Trade';

import {
    heartbeat,
    getSessAck,
    getSessFeedAuth,
    changePassword,
    sessForgetPassword,
    sessValidateForgetPassword,
    sessUpdateForgetPassword,
    agreeTnc
} from './models/Session';
import { getEstatementList } from './models/Estatement';

import {
    validateManualDeposit,
    submitFPX,
    submitDepositManualBulk,
    submitWithdrawal,
    submitSettlement,
    submitMultiSettlement,
    getFundingHistory,
    getMultiFundingHistory
} from './models/Funding';

import {
    getExchangeOptions,
    getStockOptions,
    getBankList,
    getEstatementTypes,
    getCurrency,
    getResearchCategory,
    getBroadcastType
} from './models/Property';
import { uploadFile, getTempFile, getFile } from './models/File';

import { getPortfolio } from './models/Portfolio';
import {
    getTncDetail,
    getResearchList,
    getAnnoucementList
} from './models/Broadcast';

//available api function
function availableFunc() {
    return {
        userLogin,
        userLogout,
        // TDSAuthUrl,//abadon
        searchClientNameAccount,
        getClientProfile,
        getClientAccDetail,
        getAccountContra,
        getMultiAccountContra,

        getAccountContract, //contract information, settlement
        getAccountContractDetail,
        getAccountContractSummary, //get summary detail

        getMultiAccountContract, //multiple accounts contract
        getMultiAccountContractSummary,
        getMultiAccountContractDetail,

        //-----funding related-----//
        validateManualDeposit,
        submitFPX,
        submitDepositManualBulk,
        submitWithdrawal,
        submitSettlement,
        submitMultiSettlement,
        getFundingHistory,
        getMultiFundingHistory,

        //-----trust related-----//
        getTrustDetail,

        //-----trade related-----//
        getTradeHistory,
        getTradeHistorySummary,

        //-----get property-----//
        getExchangeOptions,
        getStockOptions,
        getBankList,
        getEstatementTypes,
        getCurrency,
        getResearchCategory,
        getBroadcastType,

        //-----upload file-----//
        uploadFile,
        getTempFile,
        getFile,

        //-----session related-----//
        heartbeat,
        getSessAck,
        getSessFeedAuth,
        changePassword,
        agreeTnc,

        //-----estatement related-----//
        getEstatementList,

        //-----portfolio related-----//
        getPortfolio,

        //----forget password related ---//
        sessForgetPassword,
        sessValidateForgetPassword,
        sessUpdateForgetPassword,

        //----broadcast ---//
        getTncDetail,
        getResearchList,
        getAnnoucementList
    };
}
export default availableFunc();
