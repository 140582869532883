import { useState, useEffect, useRef } from 'react';

import './index.css';
import { parseAPIResultToData } from './helper';
import api from '../../../../apis';

import { Tree, TreeSelect } from 'antd';
const TreeNode = Tree.TreeNode;

const Index = (props: any) => {
    const [treeData, setTreeData] = useState([]);

    const [searchKey, setSearchKey] = useState('');
    const [treeExpandedKeys, setTreeExpandedKeys] = useState();
    const [dataMap, setDataMap] = useState({});

    useEffect(() => {
        requestSearchClientNameAccount();
    }, []);

    useEffect(() => {
        if (searchKey || searchKey == '') {
            const delayDebounceFn = setTimeout(() => {
                requestSearchClientNameAccount();
            }, 600);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchKey]);

    const requestSearchClientNameAccount = () => {
        return api.getExchangeOptions(searchKey).then((result: any) => {
            // console.log('getExchangeOptions');
            // console.log(result);
            // let result = result;
            if (result) {
                let parseResult = parseAPIResultToData(result);
                let data = parseResult.data;
                let parentsKeys = parseResult.parentsKeys;
                let mapping = parseResult.mapping;

                // console.log('parseResult', parseResult);
                // console.log('mapping', mapping);
                // console.log(data);
                // setTreeData([]);
                //setLoading(true);
                //setTreeData(parseResult);
                setTreeData((prevState) => [...data]);
                setTreeExpandedKeys(parentsKeys);
                setDataMap(mapping);
                //setLoading(false);
                // treeData = parseResult;
            }

            // setTreeSelectLoading(false);
            // if (result.clients) updateTreeSelect(result.clients);
        });
    };

    const onChange = (newValue: string[]) => {
        let newArrResult = [];

        for (var i = 0; i < newValue.length; i++) {
            newArrResult.push(dataMap[newValue[i]]);
        }

        if (props.onChange) {
            props.onChange(newArrResult);
        }

        console.log('onChange ', newValue);
        console.log('newArrResult ', newArrResult);
    };

    const onSearch = (newValue: string) => {
        //console.log('onSearch ', newValue);
        setSearchKey(newValue);
        //setTreeData([{ title: 'Expand to load', key: '1' }]);
        // setValue(newValue);
    };

    const onTreeExpand = (expandedKeys) => {
        setTreeExpandedKeys(expandedKeys);
    };

    return (
        <TreeSelect
            className="custom"
            onChange={onChange}
            onSearch={onSearch}
            onTreeExpand={onTreeExpand}
            treeExpandedKeys={treeExpandedKeys}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            // loadData={onLoadData}
            style={{ width: '100%' }}
            treeDefaultExpandAll={true}
            treeCheckable={true}
            allowClear={true}
            treeData={treeData.map((d, i) => ({
                title: d.title,
                value: d.value,
                match: d.match,
                treeDefaultExpandAll: true,
                checkable: true,
                key: d.key
                // children: d.children
            }))}
        />
    );
};

export default Index;
