import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import moment from 'moment';

let oneDay = 60 * 60 * 24 * 1000;
let todayTimestamp =
    Date.now() -
    (Date.now() % oneDay) +
    new Date().getTimezoneOffset() * 1000 * 60;

export default class MyDatePicker extends Component<any> {
    state = {
        getMonthDetails: []
    };

    constructor(props) {
        super(props);
        let date = new Date();
        if (this.props.value) {
            date = new Date(this.props.value);
        }
        let year = date.getFullYear();
        let month = date.getMonth();

        this.state = {
            year,
            month,
            //selectedDay: todayTimestamp,
            selectedDay: this.props.value
                ? moment.unix(this.props.value).format('YYYY-MM-DD')
                : null,
            monthDetails: this.getMonthDetails(year, month),
            inputRef: React.createRef()
        };

        // console.log(new Date(this.props.value));
        // inputRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log(prevState);
        // console.log(this.state.selectedDay);
        // if (this.state.selectedDay) {
        //     if (this.props.reset == true) {
        //         alert('start reset');
        //     }
        // }
        // if (this.state.value == undefined || this.state.value == '') {
        //     if (this.state.inputRef.current.value && prevProps.value) {
        //         alert(this.state.value);
        //         console.log(prevProps);
        //         //this.state.inputRef.current.value = '';
        //     }
        // }
        // this.state.inputRef.current.value = dateString;
    }

    componentDidMount() {
        window.addEventListener('click', this.addBackDrop);
        if (this.state.selectedDay) this.setDateToInput(this.state.selectedDay);

        if (this.props.onChange) {
            this.props.onChange({
                id: this.props.id,
                timestamp: this.state.selectedDay
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.addBackDrop);
    }

    addBackDrop = (e) => {
        if (
            this.state.showDatePicker &&
            !ReactDOM.findDOMNode(this).contains(e.target)
        ) {
            this.showDatePicker(false);
        }
    };

    showDatePicker = (showDatePicker = true) => {
        this.setState({ showDatePicker });
    };

    /**
     *  Core
     */

    daysMap = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ];
    monthMap = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    getDayDetails = (args) => {
        let date = args.index - args.firstDay;
        let day = args.index % 7;
        let prevMonth = args.month - 1;
        let prevYear = args.year;
        if (prevMonth < 0) {
            prevMonth = 11;
            prevYear--;
        }
        let prevMonthNumberOfDays = this.getNumberOfDays(prevYear, prevMonth);
        let _date =
            (date < 0
                ? prevMonthNumberOfDays + date
                : date % args.numberOfDays) + 1;
        let month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;
        let timestamp = new Date(args.year, args.month, _date).getTime();
        return {
            date: _date,
            day,
            month,
            timestamp,
            dayString: this.daysMap[day]
        };
    };

    getNumberOfDays = (year, month) => {
        return 40 - new Date(year, month, 40).getDate();
    };

    getMonthDetails = (year, month) => {
        let firstDay = new Date(year, month).getDay();
        let numberOfDays = this.getNumberOfDays(year, month);
        let monthArray = [];
        let rows = 6;
        let currentDay = null;
        let index = 0;
        let cols = 7;

        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < cols; col++) {
                currentDay = this.getDayDetails({
                    index,
                    numberOfDays,
                    firstDay,
                    year,
                    month
                });
                monthArray.push(currentDay);
                index++;
            }
        }
        return monthArray;
    };

    isCurrentDay = (day) => {
        return day.timestamp === todayTimestamp;
    };

    isSelectedDay = (day) => {
        return day.timestamp === this.state.selectedDay;
    };

    getDateFromDateString = (dateValue) => {
        let dateData = dateValue.split('-').map((d) => parseInt(d, 10));
        if (dateData.length < 3) return null;

        let year = dateData[0];
        let month = dateData[1];
        let date = dateData[2];
        return { year, month, date };
    };

    getMonthStr = (month) =>
        this.monthMap[Math.max(Math.min(11, month), 0)] || 'Month';

    getDateStringFromTimestamp = (timestamp) => {
        //input string format
        return moment(timestamp).format('DD-MM-YYYY');
        // let dateObject = new Date(timestamp);
        // let month = dateObject.getMonth() + 1;
        // let date = dateObject.getDate();
        // return (
        //     dateObject.getFullYear() +
        //     '-' +
        //     (month < 10 ? '0' + month : month) +
        //     '-' +
        //     (date < 10 ? '0' + date : date)
        // );
    };

    setDate = (dateData) => {
        let selectedDay = new Date(
            dateData.year,
            dateData.month - 1,
            dateData.date
        ).getTime();
        this.setState({ selectedDay });
        if (this.props.onChange) {
            this.props.onChange({
                id: this.props.id,
                timestamp: selectedDay
            });
        }
    };

    updateDateFromInput = () => {
        let dateValue = this.state.inputRef.current.value;
        let dateData = this.getDateFromDateString(dateValue);
        if (dateData !== null) {
            this.setDate(dateData);
            this.setState({
                year: dateData.year,
                month: dateData.month - 1,
                monthDetails: this.getMonthDetails(
                    dateData.year,
                    dateData.month - 1
                )
            });
        }
    };

    setDateToInput = (timestamp) => {
        let dateString = this.getDateStringFromTimestamp(timestamp);
        this.state.inputRef.current.value = dateString;

        this.showDatePicker(false);
    };

    onDateClick = (day) => {
        this.setState({ selectedDay: day.timestamp }, () =>
            this.setDateToInput(day.timestamp)
        );
        if (this.props.onChange) {
            this.props.onChange({
                id: this.props.id,
                timestamp: day.timestamp
            });
        }
    };

    setYear = (offset) => {
        let year = this.state.year + offset;
        let month = this.state.month;
        this.setState({
            year,
            monthDetails: this.getMonthDetails(year, month)
        });
    };

    setMonth = (offset) => {
        let year = this.state.year;
        let month = this.state.month + offset;
        if (month === -1) {
            month = 11;
            year--;
        } else if (month === 12) {
            month = 0;
            year++;
        }
        this.setState({
            year,
            month,
            monthDetails: this.getMonthDetails(year, month)
        });
    };

    /**
     *  Renderers
     */

    renderCalendar() {
        let days = this.state.monthDetails.map((day, index) => {
            return (
                <div
                    className={
                        'c-day-container ' +
                        (day.month !== 0 ? ' disabled' : '') +
                        (this.isCurrentDay(day) ? ' highlight' : '') +
                        (this.isSelectedDay(day) ? ' highlight-green' : '')
                    }
                    key={index}
                >
                    <div className="cdc-day">
                        <span onClick={() => this.onDateClick(day)}>
                            {day.date}
                        </span>
                    </div>
                </div>
            );
        });

        return (
            <div className="c-container">
                <div className="cc-head">
                    {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map(
                        (d, i) => (
                            <div key={i} className="cch-name">
                                {d}
                            </div>
                        )
                    )}
                </div>
                <div className="cc-body">{days}</div>
            </div>
        );
    }

    render() {
        return (
            <div className="MyDatePicker">
                <div
                    className="mdp-input"
                    onClick={() => this.showDatePicker(true)}
                >
                    <input
                        type="text"
                        onChange={this.updateDateFromInput}
                        ref={this.state.inputRef}
                        placeholder={this.props.placeholder || ''}
                        disabled
                    />
                    <i
                        className={
                            'fas ' +
                            (this.state.showDatePicker == true
                                ? ' fa-chevron-up '
                                : ' fa-chevron-down ')
                        }
                    ></i>
                </div>

                <div
                    className={
                        'mdp-container ' +
                        (this.state.showDatePicker ? ' show ' : '')
                    }
                >
                    <div className="mdpc-head">
                        {/* <div className="mdpch-button">
                                <div
                                    className="mdpchb-inner"
                                    onClick={() => this.setYear(-1)}
                                >
                                    <span className="mdpchbi-left-arrows"></span>
                                </div>
                            </div> */}
                        <div className="mdpch-button">
                            <div
                                className="mdpchb-inner"
                                onClick={() => this.setMonth(-1)}
                            >
                                <span className="mdpchbi-left-arrow"></span>
                            </div>
                        </div>
                        <div className="mdpch-container">
                            <div className="mdpchc-month">
                                {this.getMonthStr(this.state.month)}{' '}
                                {this.state.year}
                            </div>
                            {/* <div className="mdpchc-year">
                                    {this.state.year}
                                </div> */}
                        </div>
                        <div className="mdpch-button">
                            <div
                                className="mdpchb-inner"
                                onClick={() => this.setMonth(1)}
                            >
                                <span className="mdpchbi-right-arrow"></span>
                            </div>
                        </div>
                        {/* <div
                                className="mdpch-button"
                                onClick={() => this.setYear(1)}
                            >
                                <div className="mdpchb-inner">
                                    <span className="mdpchbi-right-arrows"></span>
                                </div>
                            </div> */}
                    </div>
                    <div className="mdpc-body">{this.renderCalendar()}</div>
                </div>
            </div>
        );
    }
}
