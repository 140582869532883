import { useState, useEffect, useMemo } from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import FormInput from '../../general/form-control/form-item/input';
import FormSelect from '../../general/form-control/form-item/select';
import FormDatePicker from '../../general/form-control/form-item/date-picker';
import FormTimePicker from '../../general/form-control/form-item/time-picker';
import FormInputFile from '../../general/form-control/form-item/input-file';
import FormCheckboxGroup from '../../general/form-control/form-item/checkbox-group';

import RadioButtonGroup from '../../general/form-control/radio-button-group';

import TreeSelect from '../../general/form-control/tree-select';

import api from '../../../apis';
import { useLoading } from '../../../context/LoadingContext';
import { numberWithCommas } from '../../general/convertor';

import { parseAPIResultToData } from '../../general/form-control/tree-select/helper';

const Index = (props: any) => {
    const navigate = useNavigate();
    const { setLoading } = useLoading();

    useEffect(() => {
        // setLoading(true);
        // api.getBankList().then((result: any) => {
        //     setLoading(false);
        //     // console.log('getBankList');
        //     // console.log(result);
        //     setBankList(result);
        //     // setStockData(result[0].stocks);
        // });
        // setLoading(false);
    }, []);

    useEffect(() => {
        setCurrentAccNo(props.currentAccNo);
    }, [props.currentAccNo]);

    useEffect(() => {
        setCurrentUsername(props.currentUsername);
    }, [props.currentUsername]);

    useEffect(() => {
        setCurrentAccCode(props.currentAccCode);
    }, [props.currentAccCode]);

    //validation settings
    const [rules, setRules] = useState<any>({
        mode: { required: true, error_message: 'Please input type' },
        cheque_no: {
            requireIfExist: { key: 'mode', value: 2 }, //require if mode = 2
            error_message: 'Please input chequre no'
        },
        cheque_date: {
            requireIfExist: { key: 'mode', value: 2 }, //require if mode = 2
            error_message: 'Please select cheque date'
        },
        datetime: {
            required: true,
            error_message: 'Please select bank in date'
        },
        bankinhour: {
            required: true,
            error_message: 'Please select bank in time'
        },
        amount: { required: true, error_message: 'Please input amount' },
        currency: { required: false, error_message: 'Please select currency' },
        drawee_bank: {
            required: true,
            error_message: 'Please select drawee bank'
        },
        deposit_bank: {
            required: true,
            error_message: 'Please select depopsit bank'
        },
        deposit_bank_branch: {
            required: true,
            error_message: 'Please select bank branch'
        },
        file: { required: true, error_message: 'Please upload file' }
    });

    const depositFormat = {
        mode: null,
        datetime: null,
        amount: null,
        currency: 1,
        deposit_bank: null,
        deposit_bank_branch: null,
        file: null,
        drawee_bank: null,
        cheque_no: '',
        cheque_date: ''
    };
    const [currentAccNo, setCurrentAccNo] = useState<any>('');
    const [currentAccCode, setCurrentAccCode] = useState<any>('');
    const [currentUsername, setCurrentUsername] = useState<any>('');

    const [userAccTreeData, setUserAccTreeData] = useState<any>([]);
    const [treeSelectLoading, setTreeSelectLoading] = useState<boolean>(false);

    const [inputs, setInputs] = useState<any>(depositFormat || {});
    const [errors, setErrors] = useState<any>({});

    const [bankList, setBankList] = useState<any>([]);

    const [pendingList, setPendingList] = useState<any>([]);
    const [totalDepositAmount, setTotalDepositAmount] = useState<number>(0);

    const onInputChange = (id: string, newValue: string) => {
        setInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    //tree select start
    const onTreeKeyinChange = (keywords: any) => {
        // console.log('onTreeKeyinChange');
        // console.log(keywords);
        setTreeSelectLoading(true);
        if (keywords.value || keywords.value == '')
            api.searchClientNameAccount(keywords.value).then((result: any) => {
                console.log(result);
                setTreeSelectLoading(false);
                if (result.clients) updateTreeSelect(result.clients);
            });
    };

    const onTreeSelectChange = async (selectInputObject: any) => {
        if (selectInputObject.value) {
            setCurrentAccNo(selectInputObject.value);
            setCurrentAccCode(selectInputObject.label);

            console.log(selectInputObject);
            // requestGetAccountContractAPI(selectInputObject.value, inputs);
        } else {
            setCurrentAccNo(null);
            setCurrentAccCode(null);
            setTableData([]);
        }
    };

    const updateTreeSelect = (result: any) => {
        console.log(result);
        let newArr = parseAPIResultToData(result);
        setUserAccTreeData(newArr);
    };
    //tree select end

    const handleInputCondition = () => {
        // permanent_resident_status
    };

    useEffect(() => {
        console.log(inputs);
    }, [inputs]);

    const formValidation = (rules: any, inputs: any) => {
        if (!rules) return { valid: true, errors: {} };

        let valid = true;
        let errors: any = {};

        //validate rule
        for (var key in rules) {
            if (rules.hasOwnProperty(key)) {
                let item = rules[key];
                if (item.required == true && !inputs[key]) {
                    let error = item.error_message || 'Required';
                    errors[key] = error;
                    valid = false;
                } else if (item.requireIfExist) {
                    //if specific input exist,current input become required
                    let requireIfExist = item.requireIfExist;
                    let relatedkey = requireIfExist.key;
                    let relatedValue = requireIfExist.value;

                    //match type start check error
                    if (
                        relatedkey &&
                        relatedValue &&
                        inputs[relatedkey] &&
                        inputs[relatedkey] == relatedValue
                    ) {
                        if (!inputs[key]) {
                            let error = item.error_message || 'Required';
                            errors[key] = error;
                            valid = false;
                        }
                    }
                } else {
                }

                // requireIfExist: { key: 'mode', value: 2 }
            }
        }

        return {
            valid,
            errors
        };
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        console.log(pendingList);
        let result = await api.submitDepositManualBulk(currentAccNo, {
            pendingList
        });
        console.log('handleSubmit');
        console.log(result);
        if (result.status === 0) {
            alert('Thank you, the form was submitted successfully');
            //refresh page
            navigate(0);
        } else {
            alert('submit failed');
        }
    };

    const checkResidentStatusMalaysian = () => {
        let permanent_resident_status = inputs.permanent_resident_status;

        if (permanent_resident_status == 'N') return 'Resident';
        else if (permanent_resident_status == 'Y') {
            let time_residing = inputs.time_residing;

            if (time_residing == '> 182 days*') return 'Non-Resident';
            else if (time_residing == '< 182 days*') return 'Resident';
        }

        return null;
    };

    const checkResidentStatusNonMalaysian = () => {
        let permanent_resident_status_my = inputs.permanent_resident_status_my;

        if (permanent_resident_status_my == 'N') return 'Non-Resident';
        else if (permanent_resident_status_my == 'Y') {
            let time_residing = inputs.time_residing;

            if (time_residing == '> 182 days*') return 'Resident';
            else if (time_residing == '< 182 days*') return 'Non-Resident';
        }

        return null;
    };

    return (
        <div className="data-correction-eform w-100 text-left pt-3 dark-text-white">
            {/* <div className="row">
                <div className="col-12 main-title"></div>
                <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12">
                    <div className="input-container">
                        <div className="label">Username / Account No </div>
                        <TreeSelect
                            treeMap={['username', 'accNo']}
                            data={userAccTreeData}
                            setLoading={treeSelectLoading}
                            keyinChange={(keywords: any) =>
                                onTreeKeyinChange(keywords)
                            }
                            onSelectChange={(selectInputObject: any) =>
                                onTreeSelectChange(selectInputObject)
                            }
                            onSelectChangeParentValue={(parentValue: any) => {
                                setCurrentUsername(parentValue || '');
                            }}
                        />
                    </div>
                </div>
                <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12">
                    <div className="input-container ">
                        <div className="label">Name</div>
                        <input
                            id="username"
                            className="custom border-bottom"
                            type="text"
                            value={currentUsername}
                            // onChange={({ target }) =>
                            //     onInputChange(target.id, target.value)
                            // }
                            disabled
                        />
                    </div>
                </div>
            </div> */}
            <div className="">
                {' '}
                <h4>
                    APPLICATION FOR CLIENT TRUST ACCOUNT (CTA) / eCREDIT
                    FACILITY{' '}
                </h4>
            </div>
            <div className="pt-3 ">
                <div className="form-section">PERSONAL DATA</div>
                <div className="row pt-5">
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-6 col-12">
                        <div className="input-container ">
                            <div className="label">CLIENT NAME</div>
                            <input
                                id="username"
                                className="custom border-bottom"
                                type="text"
                                value={currentUsername}
                                // onChange={({ target }) =>
                                //     onInputChange(target.id, target.value)
                                // }
                                disabled
                            />
                        </div>
                    </div>

                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-6 col-12">
                        <div className="input-container ">
                            <div className="label">CLIENT CODE</div>
                            <input
                                id="client_code"
                                className="custom border-bottom"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="row pt-3">
                    {' '}
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-6 col-12">
                        <div className="input-container ">
                            <div className="label">REM/DR CODE </div>
                            <input
                                id="user_rem_code"
                                className="custom border-bottom"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>{' '}
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-6 col-12">
                        <div className="input-container ">
                            <div className="label">EMAIL ADDRESS </div>
                            <input
                                id="user_email"
                                className="custom border-bottom"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>
            <hr className="mt-5 mb-5" />
            <div className="">
                <div className="form-title">
                    I/We request to apply the facility/ies offered by Vestrade
                    Investment Bank Berhad (Company No. 10209-W) as follows:-
                </div>
                <div className="form-title">
                    <i>Please tick √ whichever is applicable</i>
                </div>
                <div className="row text-justify">
                    <div className="col-xxxl-8 col-xl-10 col-lg-12 col-md-12 col-12 pb-2 ">
                        <div className="form-input-container ">
                            {/* <div className="label">SOURCE OF WEALTH/ FUNDS</div> */}
                            <div className="pt-3">
                                <FormCheckboxGroup
                                    id="applicable_type"
                                    itemClass="col-xxxl-12 col-xl-12 col-lg-12 col-12 align-item-center"
                                    data={[
                                        {
                                            label: (
                                                <>
                                                    <b>
                                                        Client Trust Account
                                                        ("CTA") –{' '}
                                                    </b>
                                                    Please credit my/our
                                                    proceeds/ contra gain due to
                                                    me/us into trust account on
                                                    due date to earn interest
                                                    (with minimum credit balance
                                                    &gt; RM1000). My/our credit
                                                    balance in trust account
                                                    will be withdrawn upon
                                                    request basis. (Part A:
                                                    Letter Of Lien And
                                                    Authorisation To Set Off
                                                    From/ Credit Into Trust
                                                    Account & Consent For CDS
                                                    Straight Through Process )
                                                </>
                                            ),
                                            value: ''
                                        },
                                        {
                                            label: (
                                                <>
                                                    <b>eCredit Facility –</b>{' '}
                                                    Please auto credit my/our
                                                    proceeds/ contra gain/ trust
                                                    withdrawal into my bank
                                                    account on payment date
                                                    (Part B: Payment
                                                    Instruction){' '}
                                                </>
                                            ),
                                            value: ''
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="mt-5 mb-5" />
            <div className="">
                <div className="form-title">TERMS AND CONDITIONS</div>
                <div className="row">
                    <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12 text-justify">
                        In CONSIDERATION of Vestrade Investment Bank Berhad (
                        VIBB or “you”) agreeing to allow me/us to apply the
                        above facility/ies, I/We agree to abide by the Terms and
                        Conditions contained in Parts A to E herein and as
                        included and amended from time to time governing such an
                        account.
                    </div>
                </div>
            </div>
            <hr className="mt-5 mb-5" />
            <div className="">
                <div className="form-title">
                    {' '}
                    Part A : LETTER OF LIEN AND AUTHORISATION TO SET OFF FROM /
                    CREDIT INTO TRUST ACCOUNT
                </div>
                <div className="row text-justify">
                    <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12 pt-3">
                        IN CONSIDERATION of Vestrade Investment Bank Berhad
                        ("VIBB");
                    </div>
                    <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12 pt-3">
                        <ol className="ol-custom-text-upper">
                            <li>
                                agreeing, granting or making available or
                                continuing to grant or make available to me/ us
                                a trading facility in the form of trading limits
                                in my/ our trading account at my/our request and
                                for my/our benefit, such trading limits
                                determined at your absolute discretion and in no
                                way creating a contractual obligation on your
                                part to me/us; and{' '}
                            </li>
                            <li>
                                from time to time placing out for and on behalf
                                of me/us the Ringgit Malaysia or any of (and not
                                necessarily all) the foreign currency amounts in
                                my/our trust and any other account(s) maintained
                                with VIBB, in any money instruments with a
                                licensed financial institution of your choice,
                                and for which I/ We may earn interest at such
                                rate as you deem fit,
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="row text-justify">
                    <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12 pt-3">
                        I/We HEREBY UNDERTAKE AND COVENANT with you and for the
                        benefit of your successors-in-title and assigns and
                        persons deriving title under you as follows:-
                    </div>
                    <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12 pt-3">
                        <ol className="ol-custom-number">
                            <li>
                                I/We have deposited and/or hereby deposit or
                                will cause immediately or from time to time
                                cause to be deposited with you into my/our CDS
                                account, ordinary shares in any local public
                                listed companies and into your appointed foreign
                                broker’s custody account ordinary shares in
                                foreign public listed companies (“Shares”) and
                                I/ We HEREBY AGREE that you shall have a lien on
                                the said Shares as security for the payment and
                                satisfaction to you by me/us of all my/our
                                obligations and liabilities to you under all
                                accounts and facilities with you and you may
                                apply the same in set-off or settlement thereof
                                forthwith upon demand by you for the repayment
                                of any of those obligations and liabilities.
                            </li>
                            <li>
                                I/ We hereby warrant and represent to you as
                                follows:-
                                <ol className="ol-custom-roman pt-3">
                                    {' '}
                                    <li>
                                        That I/ We am the legal and beneficial
                                        owner(s) of the Shares and no other
                                        person, firm, company, corporation or
                                        other body corporate has any claim,
                                        right, title or interest whatsoever
                                        legal or equitable in and to the Shares:
                                    </li>
                                    <li>
                                        That other than the security created
                                        herein, there is no mortgage, pledge,
                                        lien, charge, assignment, hypothecation
                                        or other security interest or
                                        encumbrances of any kind upon the Shares
                                        or any of them and I/We have no
                                        knowledge of any fact which would or
                                        might invalidate the lien on the Shares
                                        pursuant to this Letter, and
                                    </li>
                                    <li>
                                        That this Letter constitutes legal,
                                        valid and binding obligations on me/us
                                        and my/our heirs and personal
                                        representatives or successors in title
                                        on accordance with the terms hereof.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                As the legal and beneficial owner of the Shares,
                                I/We agree and do hereby give you all my/our
                                rights title and interests in and to the Shares
                                and as a continuing security for all and any sum
                                of money owing to you and for the performance of
                                my/our obligations and the discharge of my/our
                                contingent liabilities under all accounts and
                                facilities with you.
                            </li>
                            <li>
                                I/We agree that you may, at any time without
                                notice, in making a demand, notwithstanding any
                                settlement of account or other matter
                                whatsoever, combine or consolidate all or any
                                then existing accounts including accounts in the
                                name of your nominee, (whether in trust or
                                pledged for me/us) and set off or transfer any
                                sum standing to the credit of any one or more
                                such accounts towards satisfaction of any
                                obligations or liabilities to VIBB whether the
                                liabilities are present, future, actual,
                                contingent primary or collateral several or
                                joint.
                            </li>
                            <li>
                                Until the ultimate balance by me/us has been
                                paid or satisfied in full or so long as any
                                liability and/or obligation remains outstanding
                                by me to you whatsoever or howsoever, you shall
                                have a lien on all the Shares whether the Shares
                                are held in your custody or otherwise and you
                                shall be entitled to retain the Shares deposited
                                by me/us now and/or subsequently and/or from
                                time to time and Shares shall be held by you as
                                a continuing security to you for the balance
                                owing, the said liability and/or obligation.{' '}
                            </li>
                            <li>
                                You are authorised to sell, assign, transfer or
                                otherwise dispose of the Shares without demand
                                for payment or notice or further consent of any
                                person at such times in such manner and
                                generally on any terms and for such
                                consideration as you in your absolute discretion
                                think fit without being under any responsibility
                                to me for the price obtained thereby in any of
                                the following events:-{' '}
                                <ol className="ol-custom-roman pt-3">
                                    {' '}
                                    <li>
                                        on default being made in payment for
                                        transactions in securities carried out
                                        by you for me/us or payment of any sums
                                        from time to time due to you under
                                        my/our trading account(s) or any of
                                        them;
                                    </li>
                                    <li>
                                        on the failure to repay on demand any
                                        money obligation or liability due owing
                                        or incurred to you by me/us with all
                                        interests, charges and expenses
                                        (including legal charges or charges of
                                        your nominee or agent) in relation to
                                        me/us, any of my/our trading account(s)
                                        with you, the Shares or any matter
                                        hereunder;
                                    </li>
                                </ol>
                                but so that you are under no obligation to sell
                                or otherwise dispose of the Shares and are not
                                liable to me/us for any loss that I/We may
                                sustain as a result of your delay or failure to
                                do so. You are hereby irrevocably authorised to
                                execute and sign any document as my/our agent
                                and to do any such act or thing on my/our
                                behalf.
                            </li>
                            <li>
                                I/We agree that you shall have the right to
                                object to a withdrawal of the whole or any part
                                of the Shares. Notwithstanding the aforesaid,
                                you agree that I/We shall be entitled to sell
                                the Shares at any time provided always that the
                                proceeds thereof shall be subject to the lien
                                created by this Letter and you shall be entitled
                                to retain the same EXCEPT we both agree that
                                I/We am entitled to collect the proceeds thereof
                                upon you being satisfied that the aggregate net
                                value of the remaining Shares, taken at the
                                price and valuation applicable to Shares held as
                                security, is maintained at all times at a sum
                                equal to the moneys and liabilities from time to
                                time hereby secured including any contingent
                                liabilities, I/We agree that I/We shall if
                                required by you, deposit further Shares, should
                                the value of the Shares fall short of the moneys
                                and liabilities hereby secured.
                            </li>

                            <li>
                                <b>
                                    Set-off all amounts in my trust and any
                                    other account(s) maintained with "VIBB"
                                    Credit Balance”) against Purchase Contracts{' '}
                                </b>
                                <ol className="ol-custom-text pt-3">
                                    {' '}
                                    <li>
                                        You are authorised to set-off
                                        immediately (i.e. automatic set-off) in
                                        accordance with the Bursa Malaysia
                                        Securities Bhd’s Fixed Delivery &
                                        Settlement System (“FDSS”), the Credit
                                        Balance or any part thereof:
                                        <ol className="ol-custom-roman pt-3">
                                            <li>
                                                against any amount(s) due to you
                                                for settlement of all purchase
                                                contract(s) under my/our
                                                securities trading account(s)
                                                maintained with you; and/or
                                            </li>
                                            <li>
                                                against any amount(s) due to you
                                                for settlement of all purchase
                                                contract(s) under my/our
                                                securities trading account(s)
                                                maintained with you; and/or
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        Notwithstanding the provision in
                                        Paragraph 8 (a) herein, you may at your
                                        absolute discretion immediately set-off
                                        in accordance with the FDSS, the Credit
                                        Balance or any part thereof, for purpose
                                        of settlement of any such purchase
                                        contract(s) you deem appropriate without
                                        reference to me in the event :
                                        <ol className="ol-custom-roman pt-3">
                                            <li>
                                                the Credit Balance is
                                                insufficient to settle all
                                                my/our purchase contracts
                                                executed through you; and
                                            </li>
                                            <li>
                                                no express instruction is given
                                                by me/us or my/our dealer’s
                                                representative in respect of any
                                                such particular contract(s) that
                                                I/We wish to settle, by payment
                                                by the applicable due date as
                                                may be prescribed by Bursa
                                                Malaysia from time to time.{' '}
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        Upon exercising such set-off in
                                        paragraph 8 (b) herein, if there is any
                                        outstanding amount due to you, you may
                                        at your absolute discretion force sell
                                        any securities that remain unpaid in
                                        accordance with the FDSS.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>
                                    Set-off Credit Balance against Contra Losses
                                    / Debit Notes/ Corporate Action subscription{' '}
                                </b>
                                <ol className="ol-custom-text pt-3">
                                    <li>
                                        You are authorised to immediately
                                        set-off the Credit Balance or any part
                                        thereof, against any outstanding amount
                                        under any of my account(s) maintained
                                        with you (including without limitation
                                        contra losses, debit notes and all
                                        expenses reasonably incurred by you in
                                        compliance with this letter).
                                    </li>
                                    <li>
                                        Notwithstanding the provisions in
                                        paragraph 9 (a) herein, in the event the
                                        Credit Balance is insufficient to the
                                        entire such outstanding amount, you may
                                        at your absolute discretion, set-off the
                                        Credit Balance or any part thereof
                                        against any part of the outstanding
                                        amount as you deem appropriate without
                                        reference to me/us.{' '}
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Credit into Trust Account for Sales Proceeds /
                                Contra Gains
                                <ol className="ol-custom-text pt-3">
                                    <li>
                                        To credit into the trust account in the
                                        following manner:
                                        <ol className="ol-custom-roman pt-3">
                                            <li>
                                                in accordance with the FDSS, all
                                                proceeds of sales; and{' '}
                                            </li>
                                            <li>
                                                not later than 2 trading days
                                                for contra gains.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>Condition Precedent</b>
                                <p>I/We hereby agree and declare that:</p>
                                <ol className="ol-custom-roman pt-3">
                                    <li>
                                        I/We am the beneficial owner; or have
                                        been duly authorised by the Board of
                                        Directors to give the foregoing
                                        authorisation, in respect of my/our
                                        account(s) maintained with you;
                                    </li>
                                    <li>
                                        You may, but shall not be obliged to,
                                        place the Credit Balance, or any part
                                        thereof in any money market instruments
                                        for and on my/our behalf, but in any
                                        event only if:
                                        <ol className="ol-custom-text pt-3">
                                            <li>
                                                it is free from any encumbrances
                                                (including all commission
                                                charges, interest, expenses and
                                                fees) arising from securities
                                                transaction under my/our account
                                                and subject to the clearance of
                                                cheque;
                                            </li>
                                            <li>
                                                for each applicable currency,
                                                the Credit Balance (of that
                                                currency) is maintained at an
                                                amount of not less than one
                                                thousand (1,000) currency units
                                                (“the Minimum Balance”) under my
                                                account; and
                                            </li>
                                            <li>
                                                {' '}
                                                the total Credit Balance and the
                                                individual Credit Balance for
                                                each applicable foreign currency
                                                is not less than the minimum
                                                amount which the relevant
                                                institution may require for the
                                                purpose of such placement.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        any amount paid into my/our account by
                                        way of cheque must be cleared before any
                                        placement in money market instruments
                                        made.{' '}
                                    </li>
                                    <li>
                                        Payment for withdrawal from trust
                                        account will be made on the next trading
                                        day after receipt of such request from
                                        me/us or my/our dealer’s representative,
                                        subject to the prior clearance of cheque
                                        for any payment into such account.
                                    </li>
                                    <li>
                                        you are entitled to :
                                        <ol className="ol-custom-text pt-3">
                                            <li>
                                                deposit any Credit Balance with
                                                any licensed financial
                                                institution of your choice even
                                                if such licensed financial
                                                institution does not offer the
                                                highest rate of interest at the
                                                relevant times; and
                                            </li>
                                            <li>
                                                vary the Minimum Balance herein
                                                at your absolute discretion and
                                                from time to time by giving
                                                notice in writing.{' '}
                                            </li>
                                            <li>
                                                deduct any fee and expenses as
                                                you may deem fit (“the Fees”)
                                                from any part of the Credit
                                                Balance or interest earned
                                                thereon, if any.{' '}
                                            </li>
                                            <li>
                                                vary the rate of the Fees or
                                                vary the time and manner of
                                                payment of the Fees without
                                                notice.{' '}
                                            </li>
                                            <li>vary the rate of interest. </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                I/We hereby agree to be bound by all the terms
                                and conditions of this letter which shall take
                                effect on the date hereof and that this Letter
                                shall not derogate any of my obligations to VIBB
                                and shall be in addition to any other security
                                held by you.{' '}
                            </li>
                        </ol>
                    </div>
                </div>

                <div className="row text-justify">
                    <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12 pt-3">
                        CONSENT FOR CDS-STRAIGHT THROUGH PROCESS
                    </div>
                    <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12 pt-3">
                        I/We hereby consent to the disclosure by Bursa Malaysia
                        Depository Sdn. Bhd. ("Bursa Depository") to VIBB, and
                        to such agents, service providers and sub-contractors of
                        VIBB as informed by VIBB to Bursa Depository, of
                        information or documents relating to my/our affairs and
                        in particular, relating to my/our securities account
                        (for example balances, account particulars and
                        transactions). This consent shall be valid until revoked
                        by me/ us and upon the receipt of the written revocation
                        by VIBB. I/ We agree to release Bursa Depository from
                        any loss or liability arising from or in connection with
                        this authorisation except for loss or liability that
                        I/we may suffer as a result of any act, statement or
                        omission that was not done in good faith by Bursa
                        Depository. I/We hereby appoint VIBB as its agent for
                        the purpose of communicating the aforesaid consent and
                        release to Bursa Depository.
                    </div>
                </div>
            </div>

            <hr className="mt-5 mb-5" />
            <div className="">
                <div className="form-title"> Part B: PAYMENT INSTRUCTIONS</div>

                <div className="row pt-3">
                    <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                        In consideration of you agreeing to this request, I/we
                        hereby agreed that:-{' '}
                        <ol className="ol-custom-text pt-3">
                            <li>
                                VIBB will NOT be held responsible for any losses
                                incurred due to my/our provision of any Account
                                details.
                            </li>
                            <li>
                                {' '}
                                In rendering the service of crediting directly
                                payment of any nature (the “Proceeds”) into the
                                Account, VIBB shall need only to exercise
                                reasonable care and diligence.
                            </li>
                            <li>
                                I/We unconditionally and irrevocably accept(s)
                                and agree(s) without limitation, that VIBB shall
                                not be under any liability for any loss, damage,
                                cost and/or expense of whatsoever nature which
                                arose directly/indirectly as a result of any
                                delay or error in crediting the Proceeds into
                                the Account by VIBB.
                            </li>
                            <li>
                                the above payment instruction shall remain in
                                force until notified otherwise by me/us in
                                writing, provided always that VIBB shall be
                                entitled, with notice to me/us and at its sole
                                and absolute discretion make payment to me/us by
                                any other mode.{' '}
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                        <b>Termination</b>
                    </div>
                    <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12 dark-text-white">
                        I/We hereby agree that VIBB has the absolute discretion
                        to terminate the DF facility granted to me/us in the
                        event of any failure by me/us to observe any of the
                        Terms and Conditions stipulated herein without assigning
                        any reasons thereto.{' '}
                    </div>
                </div>

                <div className="row">
                    <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                        <div className="pt-3">
                            <div className="p-3 border rounded shadow-sm dark-text-white">
                                <div
                                    style={{
                                        float: 'left',
                                        width: '35px',
                                        height: '100%'
                                    }}
                                >
                                    <div className="pt-2">
                                        <input
                                            className="custom-checkbox "
                                            // name="chkRed"
                                            type="checkbox"
                                            style={{
                                                width: '22px',
                                                height: '22px',
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: 'auto',
                                        paddingLeft: '35px'
                                    }}
                                >
                                    <div
                                        className="pt-2"
                                        style={{ textAlign: 'justify' }}
                                    >
                                        I/We hereby authorize VIBB to directly
                                        credit my/our payment to my/our bank
                                        account as follows (“the Account”):-
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pt-3">
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                        <div className="form-input-container ">
                            <div className="label">
                                NAME OF BANK ("THE BANK"){' '}
                            </div>
                            <FormInput
                                id="client_bank_name"
                                type="text"
                                value={inputs.client_bank_name || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.client_bank_name}
                            />
                        </div>
                    </div>
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                        <div className="form-input-container ">
                            <div className="label">BRANCH</div>
                            <FormInput
                                id="client_bank_branch"
                                type="text"
                                value={inputs.client_bank_branch || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.client_bank_branch}
                            />
                        </div>
                    </div>
                    <div className="col-xxxl-4 col-xl-5 col-lg-5 col-md-12 col-12">
                        <div className="form-input-container ">
                            <div className="label">ACCOUNT NUMBER </div>
                            <FormInput
                                id="client_account_no"
                                type="text"
                                value={inputs.client_account_no || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.client_account_no}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <hr className="mt-5 mb-5" />

            <div className="row">
                {' '}
                <div className="col-12 pt-5">
                    <button className="btn-primary-custom btn-md" type="submit">
                        Submit &nbsp;&nbsp;
                        <i
                            className="fas fa-chevron-right "
                            aria-hidden="true"
                        ></i>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Index;
