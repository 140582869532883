import { useState, useEffect, useMemo } from 'react';

import api from '../../../apis';
import moment from 'moment';

import { useLoading } from '../../../context/LoadingContext';

import ReactTable from '../../general/form-control/react-table';
import statusText from '../../../constants/property';
import { parseAPIResultToData } from '../../general/form-control/tree-select/helper';
import { numberWithCommas } from '../../general/convertor';

import UserAccTreeSelect from '../../general/form-control/user-acc-tree-select';
import { usePagination } from '../../tables/helper';

const Index = () => {
    const { setLoading } = useLoading();

    useEffect(() => {}, []);

    const [searchInputs, setSearchInputs] = useState<any>({
        currency: null,
        type: '' //get first key
    });
    const [currentAccNo, setCurrentAccNo] = useState([]);

    useEffect(() => {
        console.log(searchInputs);
        if (currentAccNo.length > 0) {
            requestGetAccountContractAPI(currentAccNo, 1);
        } else {
            setTableData([]);
        }
    }, [currentAccNo, searchInputs]);

    const [tableData, setTableData] = useState([]);
    const { tablePagination, setTablePagination } = usePagination();
    // const [tablePagination, setTablePagination] = useState<any>({
    //     currentPage: 1,
    //     totalRecords: 0
    // });

    const onDropdownChange = (id: string, newValue: string) => {
        setSearchInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Contract No',
                accessor: 'ctr_contrano'
            },
            {
                Header: 'Contra Date',
                accessor: 'ctr_contradate',
                Cell: (v: any) => {
                    if (v.value) return moment(v.value).format('DD-MM-YYYY');
                }
            },
            {
                Header: 'Due Date',
                accessor: 'ctr_duedate',
                Cell: (v: any) => {
                    if (v.value) return moment(v.value).format('DD-MM-YYYY');
                }
            },
            {
                Header: 'Stock Code',
                accessor: 'ctr_stockcode'
            },
            {
                Header: 'Stock Name',
                accessor: 't_name'
            },
            {
                Header: <div className="text-right">Contra Qty</div>,
                accessor: 'ctr_quantity',
                Cell: (v) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value)}
                            </div>
                        );
                }
            },
            {
                Header: <div className="text-right">Contra Value</div>,
                accessor: 'ctr_value',
                Cell: (v) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value, 2)}
                            </div>
                        );
                }
            },

            {
                Header: <div className="text-right">Service Charges</div>,
                accessor: 'ctr_charges',
                Cell: (v) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value, 2)}
                            </div>
                        );
                }
            }
        ],
        []
    );

    const requestGetAccountContractAPI = async (accNo: any, cPage = 1) => {
        // setCurrentAccNo(accNo);
        setLoading(true);
        console.log(searchInputs);
        let result = await api.getMultiAccountContra(accNo, searchInputs);
        console.log(result);
        if (result.contra) {
            let contra = result.contra;
            console.log(contra);
            setTableData(contra);
            //  updateAccSummaryComponent(account);
        }

        if (result.total) {
            setTablePagination({
                currentPage: cPage,
                totalRecords: result.total
            });
        }

        setLoading(false);
    };

    const updateTreeSelect = (result: any) => {
        console.log(result);
        let newArr = parseAPIResultToData(result);
        setUserAccTreeData(newArr);
    };
    //tree select end

    return (
        <div className="pt-3 text-left">
            <div className="row">
                <div className="col-12 main-title">Contra</div>
                <div className="col-12 search-container ">
                    {/* <div className="input-container">
                        <div className="label">Name </div>
                        <DropdownSelect
                            id="userInfo"
                            data={[
                                {
                                    value: 'Chin Woo',
                                    label: 'Chin Woo'
                                },
                                {
                                    value: 'Chin Wu Lui',
                                    label: 'Chin Wu Lui'
                                },
                                {
                                    value: 'Chinny Chin',
                                    label: 'Chinny Chin'
                                }
                            ]}
                            onDropdownChange={(id, newValue) =>
                                onDropdownChange(id, newValue)
                            }
                        />
                    </div>
                    <div className="input-container ">
                        <div className="label">Account No </div>
                        <DropdownSelect
                            id="accNo"
                            data={[
                                {
                                    value: '8SC1701R',
                                    label: '8SC1701R'
                                },
                                {
                                    value: '701R8SC1',
                                    label: '701R8SC1'
                                },
                                {
                                    value: 'SC1701R8',
                                    label: 'SC1701R8'
                                }
                            ]}
                            onDropdownChange={(id, newValue) =>
                                onDropdownChange(id, newValue)
                            }
                        />
                    </div> */}
                </div>
                <div className="col-xxl-2 col-lg-3 col-md-6 col-12">
                    {' '}
                    <div className="input-container">
                        <div className="label">Username / Account No </div>
                        <UserAccTreeSelect
                            onChange={(value: string[]) => {
                                console.log('UserAccTreeSelect', value);
                                setCurrentAccNo(value);
                            }}
                        />
                        {/* <TreeSelect
                            treeMap={['username', 'accNo']}
                            data={userAccTreeData}
                            setLoading={treeSelectLoading}
                            keyinChange={(keywords: any) =>
                                onTreeKeyinChange(keywords)
                            }
                            onSelectChange={(selectInputObject: any) =>
                                onTreeSelectChange(selectInputObject)
                            }
                        /> */}
                    </div>
                </div>
                {/* <div className="col-xxl-2 col-lg-3 col-md-6 col-12">
                    {' '}
                    <div className="input-container ">
                        <div className="label">Currency </div>
                        <select className="custom">
                            {statusText.currenciesList.map((item, i) => {
                                return (
                                    <option value={item.c_id}>
                                        {item.c_name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div> */}
                <div className="col-xxl-2 col-lg-3 col-md-6 col-12">
                    {' '}
                    <div className="input-container ">
                        <div className="label">Contra Type</div>
                        <select
                            id="type"
                            className="custom"
                            value={searchInputs.type}
                            onChange={(e) =>
                                onDropdownChange(e.target.id, e.target.value)
                            }
                        >
                            <option value={''}>All</option>
                            {Object.entries(statusText.contraTypes).map(
                                ([key, value]) => {
                                    return <option value={key}>{value}</option>;
                                }
                            )}
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 col-12 pt-5">
                    <ReactTable
                        columns={columns}
                        data={tableData}
                        pageSize={10}
                        currentPage={tablePagination.currentPage}
                        totalRecords={tablePagination.totalRecords}
                        pageChange={(cPage: number) =>
                            requestGetAccountContractAPI(currentAccNo, cPage)
                        }
                        hasPagination
                    />
                </div>
            </div>
        </div>
    );
};

export default Index;
