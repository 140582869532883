import { useState, useEffect, useMemo } from 'react';

import api from '../../../apis';
import RadioButtonGroup from '../../general/form-control/radio-button-group';

import { useLoading } from '../../../context/LoadingContext';
import TreeSelect from '../../general/form-control/tree-select';
import { parseAPIResultToData } from '../../general/form-control/tree-select/helper';

import DepositForm from '../../forms/funding-deposit-fpx-form';
import ManualTransferForm from '../../forms/funding-manual-transfer-form';

const Index = () => {
    const { setLoading } = useLoading();

    useEffect(() => {}, []);

    const [currentAccNo, setCurrentAccNo] = useState<any>('');
    const [currentAccCode, setCurrentAccCode] = useState<any>('');
    const [currentUsername, setCurrentUsername] = useState('');

    const [userAccTreeData, setUserAccTreeData] = useState<any>([]);
    const [treeSelectLoading, setTreeSelectLoading] = useState<boolean>(false);

    const [tableData, setTableData] = useState([]);

    const [inputs, setInputs] = useState<any>({ paymentType: '' });

    useEffect(() => {
        console.log(inputs);
        // if (currentAccNo) {
        //     requestGetAccountContractAPI(currentAccNo, searchInputs);
        // }
    }, [inputs]);

    const onInputChange = (id: string, newValue: string) => {
        setInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    //tree select start
    const onTreeKeyinChange = (keywords: any) => {
        // console.log('onTreeKeyinChange');
        // console.log(keywords);
        setTreeSelectLoading(true);
        if (keywords.value || keywords.value == '')
            api.searchClientNameAccount(keywords.value).then((result: any) => {
                console.log(result);
                setTreeSelectLoading(false);
                if (result.clients) updateTreeSelect(result.clients);
            });
    };

    const onTreeSelectChange = async (selectInputObject: any) => {
        if (selectInputObject.value) {
            setCurrentAccNo(selectInputObject.value);
            setCurrentAccCode(selectInputObject.label);

            console.log(selectInputObject);
            // requestGetAccountContractAPI(selectInputObject.value, inputs);
        } else {
            setCurrentAccNo(null);
            setCurrentAccCode(null);
            setTableData([]);
        }
    };

    const updateTreeSelect = (result: any) => {
        console.log(result);
        let newArr = parseAPIResultToData(result);
        setUserAccTreeData(newArr);
    };
    //tree select end

    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/* filter */}
                <div className="col-xxxl-2 col-xl-2 col-lg-3 col-md-4 col-12">
                    <div className="col-12 main-title">Deposit</div>
                    <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                        <div className="input-container">
                            <div className="label">Username / Account No </div>
                            <TreeSelect
                                treeMap={['username', 'accNo']}
                                data={userAccTreeData}
                                setLoading={treeSelectLoading}
                                keyinChange={(keywords: any) =>
                                    onTreeKeyinChange(keywords)
                                }
                                onSelectChange={(selectInputObject: any) =>
                                    onTreeSelectChange(selectInputObject)
                                }
                                onSelectChangeParentValue={(
                                    parentValue: any
                                ) => {
                                    setCurrentUsername(parentValue || '');
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                        <div className="input-container ">
                            <div className="label">Name</div>
                            <input
                                id="username"
                                className="custom border-bottom"
                                type="text"
                                value={currentUsername}
                                // onChange={({ target }) =>
                                //     onInputChange(target.id, target.value)
                                // }
                                disabled
                            />
                        </div>
                    </div>
                    <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12" >
                        <div className="input-container paymentSetting">
                            <div className="label">Payment Mode</div>
                            <RadioButtonGroup
                                id="paymentType"
                                itemClass="w-auto"
                                data={[
                                    { label: 'FPX', value: 'fpx' },
                                    {
                                        label: 'Manual Transfer',
                                        value: 'manual_transfer'
                                    }
                                ]}
                                onChange={(id: string, value: string) =>
                                    onInputChange(id, value)
                                }
                                style={{paddingLeft: '0px'}}
                            />
                        </div>
                    </div>
                </div>
                {/* filter end div */}

                {/* body */}
                <div className="col-xxxl-10 col-xl-10 col-lg-9 col-md-8 col-12">
                    {inputs.paymentType == 'fpx' && (
                        <DepositForm
                            currentAccNo={currentAccNo}
                            currentUsername={currentUsername}
                        />
                    )}
                    {inputs.paymentType == 'manual_transfer' && (
                        <ManualTransferForm
                            currentAccNo={currentAccNo}
                            currentUsername={currentUsername}
                            currentAccCode={currentAccCode}
                        />
                    )}
                </div>
                {/* body end div*/}
            </div>
        </div>
    );
};

export default Index;
