import { message } from 'antd';
import './index.css';

const key = 'updatable';
export const showNotiLoader = () => {
    message.loading({
        content: 'Loading...',
        key: key,
        duration: 30
    });
};

export const hideNotiLoader = () => {
    message.loading({
        content: 'Loading...',
        key: key,
        duration: 0.0001
    });
};

export const showNotiLoaderError = () => {
    message.error({
        content: 'Something went wrong...',
        key: 'error',
        duration: 8
    });
};
