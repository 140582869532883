import { handleRequestGet } from '../axios';
import { parseResult, queryParamStr, queryParamStrArr } from './GeneralFunc';

export const getExchangeOptions = async (keywords = null) => {
    let url = '/exchange/options?size=100&page=1';

    if (keywords) {
        url += '&term=' + keywords;
    }

    let result = await handleRequestGet(url, {});
    return parseResult(result).exchanges || [];
};

export const getStockOptions = async ({
    exchanges = null,
    term = null,
    size = 50,
    page = 1
}) => {
    let url = '/stock/options?';

    let queryString = '';
    queryString += queryParamStr('page', page, true);
    queryString += queryParamStr('size', size);
    queryString += queryParamStr('term', term);
    queryString += queryParamStrArr('exchanges[]', exchanges);

    let fullUrl = url + queryString;

    let result = await handleRequestGet(fullUrl, {});
    console.log(fullUrl);
    // console.log(result);

    return parseResult(result).stocks || [];
};

export const getBankList = async (keywords = null) => {
    let url = '/bank';
    let result = await handleRequestGet(url, {});
    console.log(result);
    return parseResult(result).banks || [];
};

export const getEstatementTypes = async (keywords = null) => {
    let url = '/property/estatement/types';
    let result = await handleRequestGet(url, {});
    console.log(result);
    return parseResult(result).types || [];
};

export const getCurrency = async () => {
    let url = '/currency?size=100&page=1';
    let result = await handleRequestGet(url, {});
    console.log(result);
    return parseResult(result).currencies || [];
};

export const getResearchCategory = async () => {
    let url = '/property/broadcast/research/category';
    let result = await handleRequestGet(url, {});
    console.log(result);
    return parseResult(result).categories || [];
};

export const getBroadcastType = async () => {
    let url = '/property/broadcast/type?type=announcement';
    let result = await handleRequestGet(url, {});
    console.log(result);
    return parseResult(result).types || [];
};
