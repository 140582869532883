const Address = {
    KL : 'B-01-05, Vertical Business Suite Avenue 3, Bangsar South City, No. 8, Jalan Kerinchi, 59200 Kuala Lumpur, Malaysia.',
    Penang: 'Udini Square, Block 2-03-21, Lebuh Tunku Kudin 3, 11700 Gelugor, Penang',
}

const Phone = {
    number: '+603-2241 3239',
}

const Email = {
    emailaddress: 'support@finext.com.my',
}

export {Address, Phone, Email}