// @ts-nocheck
import { createRef, useRef, useState, useEffect, useContext } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

// import '../../asset/styles/layout-style.css';

import useAuth from '../../../hooks/useAuth';

import Header from '../Header';
import Sidebar from '../../navigation/Sidebar';

const Layout = (props) => {
    const [sidebarPosition, setSidebarPosition] = useState(null);
    const [isIframe, setIsIframe] = useState(false);

    /* @ts-ignore */
    const { auth } = useAuth();
    const mode = auth.mode;

    // const isIframe = props.isIframe||true;

    useEffect(() => {
        // if(!sidebarPosition)
        // {
        //     let sideBar = document.getElementById('siderbar-col');
        //     //console.log(sideBar.getBoundingClientRect())
        //     let left = sideBar.getBoundingClientRect().left
        //     setSidebarPosition({
        //         left
        //     })
        //     function updateSize() {
        //         let left = sideBar.getBoundingClientRect().left
        //         setSidebarPosition({
        //             left
        //         })
        //     }
        //     window.addEventListener('resize', updateSize);
        //     updateSize();
        //     return () => window.removeEventListener('resize', updateSize)
        // }
        //console.log("answer",sideBar.getBoundingClientRect()); //here no offsetTop is no there in current
        //console.log(inputRef.current.getBoundingClientRect());
    }, []);

    return (
        <div className="App">
            <div
                className={'layout-container ' + (mode == 'dark' ? 'dark' : '')}
            >
                <div className="main-container fluid-container overflow-hidden  tds-iframe-container">
                    <div className="row gx-0 ">
                        <Header />
                    </div>
                    <div className=" " style={{ display: 'flex' }}>
                        <div className="" id="siderbar-col">
                            <Sidebar position={sidebarPosition} />
                        </div>
                        <div
                            className={
                                'side-menu-page-container' +
                                (isIframe == false ? ' pl-4 pr-4 ' : ' ')
                            }
                        >
                            <div
                                className={
                                    'page-content pb-1' +
                                    (isIframe == false ? ' ' : ' iframe-block ')
                                }
                            >
                                <Outlet context={{ setIsIframe }} />
                            </div>
                            {isIframe == false && (
                                <footer className="pt-5 pb-3">
                                    <div className="footer-container protected">
                                        © 2022 FiNext Sdn. Bhd.
                                    </div>
                                </footer>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
