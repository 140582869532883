import { useState, useEffect, useRef } from 'react';
import './index.css';

interface PropsData {
    id: string;
    data: Array<dataFormat>;
    onChange?: any;
    itemClass?: any;
    specialInputClass?: any;
}

type dataFormat = {
    label: string;
    value: string;
    inputText?: boolean;
};

const Index = (props: PropsData) => {
    useEffect(() => {}, []);
    const [currentValue, setValue] = useState('');
    const [data, setData] = useState(props.data || []);

    useEffect(() => {
        if (props.data && props.data.length > 0 && props.onChange) {
            props.onChange(props.id, props.data[0].value);
        }
    }, []);

    const handleOnchange = (e: any) => {
        setValue(e.target.value);
        if (props.onChange) {
            props.onChange(props.id, e.target.value);
        }
    };

    let itemClass = props.itemClass || ' col-xxxl-4 col-xl-4 col-lg-6 col-12  ';
    let specialInputClass =
        props.specialInputClass || ' col-xxxl-8 col-xl-8 col-lg-6 col-12 ';

    return (
        <div
            id={props.id}
            className="row custom-button-group pt-2 w-100"
            onChange={(e) => handleOnchange(e)}
        >
            {data.map((item, key) => {
                // console.log(item.inputText);
                return (
                    <div
                        className={
                            'item ' +
                            (item.inputText == true
                                ? specialInputClass
                                : itemClass)
                        }
                    >
                        <input
                            type="radio"
                            value={item.value}
                            name={props.id || 'custom'}
                            defaultChecked={key == 0 ? true : false}
                        />
                        <div className="title">{item.label}</div>
                        {item.inputText == true && (
                            <div className="d-flex align-items-center specify-input">
                                (specify:
                                <input
                                    id=""
                                    type="text"
                                    style={{
                                        height: '30px'
                                    }}
                                    // value={currentUsername}
                                    className="custom border-bottom w-auto"
                                    // onChange={({ target }) =>
                                    //     onInputChange(target.id, target.value)
                                    // }
                                />
                                )
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default Index;
