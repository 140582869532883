// @ts-nocheck
import { createRef, useRef, useState, useEffect, useContext } from 'react';
import Chart from 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';

const Index = () => {
    useEffect(() => {
        var ctx = document.getElementById('myDoughnutChart').getContext('2d');

        let newChart = new Chart(ctx, {
            type: 'doughnut',
            plugins: [
                {
                    // beforeDraw: function (chart) {
                    // 	const datasetMeta = chart.getDatasetMeta(0);
                    // 	const innerRadius = datasetMeta.controller.innerRadius;
                    // 	const outerRadius = datasetMeta.controller.outerRadius;
                    // 	const heightOfItem = outerRadius - innerRadius;
                    // 	const countOfData = chart.getDatasetMeta(0).data.length;
                    // 	const additionalRadius = Math.floor(heightOfItem / countOfData);
                    // 	const weightsMap = datasetMeta.data
                    // 		.map(v => v.circumference)
                    // 		.sort((a, b) => a - b)
                    // 		.reduce((a, c, ci) => {
                    // 		a.set(c, ci + 1);
                    // 		return a;
                    // 		}, new Map());
                    // 	datasetMeta.data.forEach(dataItem => {
                    // 		const weight = weightsMap.get(dataItem.circumference);
                    // 		dataItem.outerRadius = innerRadius + additionalRadius * weight;
                    // 	});
                    // 	//draw inner width
                    // 	let width = chart.width
                    // 	let height = chart.height;
                    // 	const radius = innerRadius-17;
                    // 	chart.ctx.beginPath();
                    // 	chart.ctx.arc(width/2, height/2, radius, 0.5,2.1 * Math.PI, false);
                    // 	//chart.ctx.arc(width/2, height/2, radius, 0, 1 * Math.PI, false);
                    // 	chart.ctx.lineWidth = 12;
                    // 	chart.ctx.strokeStyle = '#212533';
                    // 	//chart.ctx.strokeStyle = '#F1F3F9';
                    // 	chart.ctx.stroke();
                    // }
                }
            ],

            data: {
                labels: ['Stock', 'Futures', 'Funds', 'Cash'], // change this - array of labels
                datasets: [
                    {
                        backgroundColor: [
                            '#3268F6',
                            '#05CEB8',
                            '#F8CC46',
                            '#CF05C3'
                        ], // change this - array of colors
                        data: [63.8, 15.6, 13.8, 6.8], // change this - array of value
                        borderWidth: 0
                    }
                ]
            },

            options: {
                layout: {
                    padding: 10
                },

                plugins: {
                    legend: false,
                    datalabels: {
                        display: false
                    }
                },
                animation: {
                    animateScale: true,
                    animateRotate: true,
                    onProgress: function () {}
                },
                maintainAspectRatio: false,
                responsive: true
            }
        });

        // newChart.data.datasets[0].data =  [1,2,3,4,5]
        // newChart.update();

        return () => {
            newChart.destroy();
        };
    }, []);

    return (
        <div style={{ width: '250px' }}>
            <canvas id="myDoughnutChart" />
        </div>
    );
};

export default Index;
