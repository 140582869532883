import { handleRequestPost, handleRequestGet } from '../axios';
import { setLogin, getUserDetail } from '../../hooks/handleCookie';
import { parseResult } from './GeneralFunc';

export const userLogin = async ({ username, password }) => {
    let url = '/sess/login';
    let result = await handleRequestPost(url, {
        username: username,
        password: password
    });

    // console.log(result);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult.status == 0) {
            let data = paresedResult.data;
            // data.username = username;

            await setLogin(data);
            // //console.log(result)
        } else {
            alert(paresedResult.message);
        }

        return paresedResult;
    } catch (error) {}

    return false;
};

export const userLogout = async () => {
    let url = '/sess/logout';
    let result = await handleRequestPost(url, {});

    console.log('logout', result);
    // console.log(result)

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult.status == 0) {
            return true;
        }
    } catch (error) {}

    return false;
};
