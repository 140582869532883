import React from 'react';
import { useState, useEffect } from 'react';
import { Email } from '../../../../../../constants/landing';
import { TopBannerWordOnly } from '../General/TopBanner';
// import { render } from '@react-email/render';
// import nodemailer from 'nodemailer';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const SendEmail = Email.emailaddress;
    const [errorMessage, setErrorMessage] = useState('');

    // const transporter = nodemailer.createTransport({
    //     host: 'smtp.forwardemail.net' ,
    //     port: 465,
    //     secure: true,
    //     auth: {
    //         user: 'my_user',
    //         pass: 'my_password'
    //     }
    // });

    const sendEmail = (e) => {
        e.preventDefault();
        console.log(e.target);
    };

    const handleName = (e) => {
        setName(e.target.value);
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);
    };

    const handlePhone = (e) => {
        setPhone(e.target.value);
    };

    const handleSubject = (e) => {
        setSubject(e.target.value);
    };

    const handleMessage = (e) => {
        setMessage(e.target.value);
    };

    const btnSendEmail = (e) => {
        e.preventDefault();
        if (
            name.trim() === '' ||
            phone.trim() === '' ||
            email.trim() === '' ||
            subject.trim() === '' ||
            message.trim() === ''
        ) {
            // Input is empty
            // alert('Please insert required information');
            setErrorMessage('Please insert required information');
            return;
        } else setErrorMessage('');

        window.location.href = `mailto:${SendEmail}?subject=${subject}&body=Hi%2C%0A%0A${message}%0A%0APhone Number : ${phone}%0AEmail Addresss : ${email}%0A%0AThanks%2C%0A${name}%0A%0A`;
    };

    // const options = {
    //     from: 'you@example.com',
    //     to: email,
    //     subject: subject,
    //     html: '' //emailHtml,
    // };

    return (
        <div className="contactPage">
            <TopBannerWordOnly
                data={{
                    title: 'Reach Us',
                    description1: 'Ready to revolutionize the way you do business?',
                    description2: 'Contact us today to discover how our solutions can help you take your trading to the next level.'
                }}
            />
            {/* <div
                className="hero hero_pt bg-light img-right"
                style={{ height: '50vh' }}
            >
                <div className="container">
                    <div
                        className="row align-items-center"
                        style={{ height: '50vh' }}
                    >
                        <div
                            className="col-lg-6 mx-auto text-center"
                            style={{ paddingTop: '5vh' }}
                        >
                            <h1
                                className="heading mb-3"
                            >
                                Reach Us
                            </h1>
                            <p
                                className="text-black-50"
                            >
                                Ready to revolutionize the way you do business?
                            </p>
                            <p
                                className="mb-5 text-black-50"
                            >
                                Contact us today to discover how our solutions
                                can help you take your trading to the next
                                level.
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* contact us form */}
            <div className="section sec-contact">
                <div className="container">
                    <div className="row mb-5 justify-content-center text-center">
                        <div className="col-lg-5">
                            <h2 className="heading">Get in touch</h2>
                            {/* <p className="text-black-50">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p> */}
                        </div>
                    </div>
                    <form className="row px-5">
                        <div className="col-md-6 col-lg-6">
                            <div className="mb-3">
                                <label htmlFor="name" className="ps-3 mb-2">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    placeholder="Your Name"
                                    onChange={(e) => handleName(e)}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6">
                            <div className="mb-3">
                                <label htmlFor="email" className="ps-3 mb-2">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Email"
                                    onChange={(e) => handleEmail(e)}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6">
                            <div className="mb-3">
                                <label htmlFor="phone" className="ps-3 mb-2">
                                    Phone
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="phone"
                                    placeholder="Phone Number"
                                    onChange={(e) => handlePhone(e)}
                                    pattern="^(\+?6?01)[02-46-9]-*[0-9]{7}$|^(\+?6?01)[1]-*[0-9]{8}$"
                                />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6">
                            <div className="mb-3">
                                <label htmlFor="subject" className="ps-3 mb-2">
                                    Subject
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="subject"
                                    placeholder="Subject"
                                    onChange={(e) => handleSubject(e)}
                                />
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-12">
                            <div className="mb-3">
                                <label htmlFor="message" className="ps-3 mb-2">
                                    Message
                                </label>
                                <textarea
                                    className="form-control"
                                    name=""
                                    id="message"
                                    placeholder="Message"
                                    cols={30}
                                    rows={3}
                                    onChange={(e) => handleMessage(e)}
                                ></textarea>
                            </div>
                        </div>
                        <div className="col-md-12 text-center mt-3">
                            {/* <div className="loading">Loading</div> */}
                            <div
                                className="error-message"
                                id="error"
                                style={{ fontSize: '14px' }}
                            >
                                {errorMessage}
                            </div>
                            {/* <div className="sent-message">
                                Your message has been sent. Thank you!
                            </div> */}
                        </div>
                        <div className="col-md-12 text-center mt-3">
                            <input
                                type="submit"
                                value="Send message"
                                className="btn btn-primary"
                                onClick={btnSendEmail}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;
