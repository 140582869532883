import { createRoot } from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';

// import './index.css';
import App from './App';

//import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthProvider';
import { GlobalVariableProvider } from './context/GlobalVariableProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const root = createRoot(document.getElementById('root') as HTMLDivElement);
root.render(
    //   <React.StrictMode>
    <CookiesProvider>
        <BrowserRouter>
            <GlobalVariableProvider>
                <AuthProvider>
                    <Routes>
                        <Route path="/*" element={<App />} />
                    </Routes>
                </AuthProvider>
            </GlobalVariableProvider>
        </BrowserRouter>
    </CookiesProvider>
    //   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
