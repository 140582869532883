export function parseAPIResultToData(result: any) {
    let newArr = [];
    let parentsKeys = [];
    let mapping = {};

    result.forEach(function (item) {
        let value = item.t_code + (item.t_name ? ' - ' + item.t_name : '');
        let row = {
            title: value,
            value: value,
            // key: item.t_id,
            checkable: true
        };

        if (!mapping[value]) {
            mapping[value] = item.t_id;
        }

        parentsKeys.push(item.t_name);
        newArr.push(row);
    });

    return {
        data: newArr,
        parentsKeys: parentsKeys,
        mapping
    };
}
