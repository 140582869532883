export function parseAPIResultToData(result) {
    let newArr = [];
    result.forEach(function (item) {
        let row = {
            props: {
                value: item.p_fullname,
                label: item.p_fullname
            }
        };

        if (item.accounts && item.accounts.length > 0) {
            let children = item.accounts;
            let newChildrenArr = [];
            children.forEach(function (item) {
                let row = {
                    value: item.a_id,
                    label: item.a_code
                };
                newChildrenArr.push(row);
            });

            row['children'] = newChildrenArr;
        }
        newArr.push(row);
    });

    return newArr;
}
