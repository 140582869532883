// import useAuth from "./useAuth";

import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const setLogin = async (userDetail) => {
    cookies.set('user', userDetail, { path: '/', maxAge: 2147483647 });
    return true;
};

const setLogout = () => {
    return cookies.remove('user');
};

const getUserDetail = () => {
    return cookies.get('user');
};

export { setLogin, setLogout, getUserDetail };
