import { createContext, useContext, useState } from 'react';

const GlobalVariableContext = createContext({});

interface Props {
    children: React.ReactNode;
}

export const GlobalVariableProvider = ({ children }: Props) => {
    const [showTnc, setShowTnc] = useState(false);

    return (
        <GlobalVariableContext.Provider value={{ showTnc, setShowTnc }}>
            {children}
        </GlobalVariableContext.Provider>
    );
};

export const useGlobalVariable = () => {
    return useContext(GlobalVariableContext);
};

export default GlobalVariableContext;
