import { useState, useEffect } from 'react';
import './index.css';
import moment from 'moment';

import { numberWithCommas } from '../../../general/convertor';
import ConvertStatusToText from '../../status-to-text';

//required bootstrap

//props format
interface PropsData {
    data?: Array<DataFormat>;
    titleProps?: TitleProps;
    itemProps?: ItemProps;
    labelProps?: ItemProps;
    itemsClass?: string;
    valueProps?: any;
    labelTextProps?: any;
}

//data format
interface DataFormat {
    id: any;
    value?: string;
    label?: string;
    type?: string;
    idsBetween?: string;
}

//title part property
type TitleProps = {
    label?: string;
    bgColor?: string;
    className?: any;
};

//item property
type ItemProps = {
    bottomLine?: boolean;
    className?: string;
};

const Index = (props: PropsData) => {
    useEffect(() => {
        let titleProps = props.titleProps || null;

        if (titleProps) {
            let label = titleProps.label || '';
            let bgColor = titleProps.bgColor || '';
            let className = titleProps.className || '';

            setTitleProps((prevState) => ({
                ...prevState,
                label,
                bgColor,
                className
            }));
        }

        let itemProps = props.itemProps || null;
        if (itemProps) {
            let bottomLine = itemProps.bottomLine == false ? false : true;
            let className = itemProps.className || '';

            setItemProps((prevState) => ({
                ...prevState,
                bottomLine,
                className
            }));
        }

        let labelProps = props.labelProps || null;
        if (labelProps) {
            let className = labelProps.className || '';
            setLabelProps((prevState) => ({
                ...prevState,
                className
            }));
        }
    }, []);

    const [titleProps, setTitleProps] = useState({
        label: '',
        bgColor: ''
    });

    const [itemProps, setItemProps] = useState({
        bottomLine: true,
        className: '' // item className
    });

    const [labelProps, setLabelProps] = useState({
        className: '' // item className
    });

    let data = props.data || [];
    // let itemsClass = props.itemsClass || ''; //change item class props

    return (
        <div className=" table-detail-container h-auto  p-0">
            <div
                className={'title ' + titleProps.className}
                style={{
                    background: titleProps.bgColor ? titleProps.bgColor : ''
                }}
            >
                {titleProps.label ? titleProps.label : ''}
            </div>
            <div className={'row m-0 w-100 '}>
                {/* {JSON.stringify(data)} */}
                {data.map(function (row, i) {
                    // console.log('row', row);

                    let value =
                        typeof row.value == 'number' || row.value
                            ? row.value
                            : null;
                    let type = row.type || null;

                    let statusToText = row.statusToText;

                    if (Number(value)) {
                        if (row.type == 'quantity') {
                            value = numberWithCommas(Number(value)) || '-';
                        } else if (row.type == 'value') {
                            value = numberWithCommas(Number(value), 2) || '-';
                        } else if (type == 'date') {
                            let localtime = moment(value).format('DD-MM-YYYY');
                            value = localtime;
                        }

                        // else if (type == 'value') {
                        //     //convert to decimal with comma
                        //     value = numberWithCommas(Number(value), 2);
                        // } else if (type == 'quantity') {
                        //     //convert to decimal without comma
                        //     value = numberWithCommas(Number(value), 0);
                        // } else if (type == 'timestamp') {
                        //     //convert to decimal without comma
                        //     value = moment(value).format('YYYY-MM-DD HH:MM:SS');
                        // }
                    } else {
                        if (type == 'timestamp') {
                            //convert to decimal without comma
                            if (value)
                                value = moment(value).format(
                                    'YYYY-MM-DD HH:mm:ss'
                                );
                        }
                    }

                    if (statusToText && value) {
                        value = ConvertStatusToText(statusToText, value);
                    }

                    return (
                        <div className={'item ' + itemProps.className} key={i}>
                            <div
                                className={' label ' + labelProps.className}
                                style={
                                    props?.labelTextProps?.style
                                        ? props?.labelTextProps?.style
                                        : {}
                                }
                            >
                                {row.label}
                            </div>
                            <div
                                className={
                                    ' value ' +
                                    (row.type == 'quantity' ||
                                    row.type == 'value'
                                        ? ' text-right '
                                        : ' ') +
                                    labelProps.className
                                }
                                style={
                                    props?.valueProps?.style
                                        ? props?.valueProps?.style
                                        : {}
                                }
                            >
                                {value}
                            </div>
                            <div
                                className={
                                    itemProps.bottomLine == true
                                        ? 'borderBottom'
                                        : ''
                                }
                            ></div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Index;
