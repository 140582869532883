import { useState, useEffect, useMemo } from 'react';

import api from '../../../apis';

import moment from 'moment';
import { useLoading } from '../../../context/LoadingContext';
import ReactTable from '../../general/form-control/react-table';
import statusText from '../../../constants/property';
import TreeSelect from '../../general/form-control/tree-select';
import ExchangeTreeSelect from '../../general/form-control/exchange-tree-select';

import { parseAPIResultToData } from '../../general/form-control/tree-select/helper';

import InputCurrency from '../../general/form-control/property-input/currency';

import { numberWithCommas } from '../../general/convertor';
import { usePagination } from '../../tables/helper';

const Index = () => {
    const { setLoading } = useLoading();
    const { tablePagination, setTablePagination } = usePagination();

    useEffect(() => {}, []);

    const [currentAccNo, setCurrentAccNo] = useState(null);
    const [searchInputs, setSearchInputs] = useState<any>({
        currency: null,
        type: 0
        // currency = 1,
        // stock = 0,
        // rangeStart = null,
        // rangeEnd = null,
        // size = 100,
        // page = 1
    });

    useEffect(() => {
        console.log(searchInputs);
        if (currentAccNo) {
            const delayDebounceFn = setTimeout(() => {
                requestGetAccountContract(currentAccNo, 1);
            }, 400);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchInputs]);

    const onInputChange = (id: string, newValue: string) => {
        setSearchInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    const [tableData, setTableData] = useState([]);
    // const [tablePagination, setTablePagination] = useState<any>({
    //     currentPage: 1,
    //     totalRecords: 0
    // });

    const [currenciesList, setCurrenciesList] = useState(
        statusText.currenciesList
    );

    useEffect(() => {
        //console.log(searchInputs);
    }, [searchInputs]);

    const [userAccTreeData, setUserAccTreeData] = useState<any>([]);
    const [treeSelectLoading, setTreeSelectLoading] = useState<boolean>(false);

    const columns = useMemo(
        () => [
            {
                Header: 'Contract No',
                accessor: 'ctrt_contractno'
            },
            {
                Header: 'Purchase Date',
                accessor: 'ctrt_contractdate',
                Cell: (v: any) => {
                    if (v.value) return moment(v.value).format('DD-MM-YYYY');
                }
            },
            {
                Header: 'Due Date',
                accessor: 'ctrt_duedate',
                Cell: (v) => {
                    if (v.value) return moment(v.value).format('DD-MM-YYYY');
                }
            },
            {
                Header: 'Exchange',
                accessor: 'e_name'
            },
            {
                Header: 'Stock Code',
                accessor: 'ctrt_stockcode'
            },
            {
                Header: 'Stock Name',
                accessor: 't_name'
            },
            {
                Header: 'Currency',
                accessor: 'c_code'
            },
            {
                Header: <div className="text-right">Quantity</div>,
                accessor: 'ctrt_quantity',
                Cell: (v) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value)}
                            </div>
                        );
                }
            },
            {
                Header: <div className="text-right">Price</div>,
                accessor: 'ctrt_price',
                Cell: (v) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value, 3)}
                            </div>
                        );
                }
            },
            {
                Header: <div className="text-right">Contract Value</div>,
                accessor: 'ctrt_value',
                Cell: (v) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value, 2)}
                            </div>
                        );
                }
            },
            {
                Header: <div className="text-right">Interest</div>,
                accessor: 'ctrt_interest',
                Cell: (v) => {
                    if (v.value != undefined)
                        return (
                            <div className="text-right">
                                {v.value.toFixed(2)}
                            </div>
                        );
                }
            },
            {
                Header: 'Status',
                accessor: 'ctrt_settlementdays',
                Cell: (v) => {
                    let value = v.value;

                    if (value == 0) {
                        return (
                            <div>
                                <button className="btn btn-success">
                                    T Day
                                </button>
                            </div>
                        );
                    } else if (value == 1) {
                        return (
                            <div>
                                <button className="btn btn-success">T+1</button>
                            </div>
                        );
                    } else if (value == 2) {
                        return (
                            <div>
                                <button className="btn btn-warning">T+2</button>
                            </div>
                        );
                    } else if (value >= 3) {
                        return (
                            <div>
                                <button className="btn btn-danger">
                                    Overdue
                                </button>
                            </div>
                        );
                    }

                    // switch (v.value) {
                    //     case 1:
                    //         return (
                    //             <div>
                    //                 <button className="btn btn-success">
                    //                     T+1
                    //                 </button>
                    //             </div>
                    //         );
                    //         break;
                    //     case 2:
                    //         return (
                    //             <div>
                    //                 <button className="btn btn-warning">
                    //                     T+2
                    //                 </button>
                    //             </div>
                    //         );
                    //         break;
                    //     case 3:
                    //         return (
                    //             <div>
                    //                 <button className="btn btn-danger">
                    //                     T+3
                    //                 </button>
                    //             </div>
                    //         );
                    //         break;
                    // }
                }
            }
        ],
        []
    );

    //tree select start
    const onTreeKeyinChange = (keywords: any) => {
        // console.log('onTreeKeyinChange');
        // console.log(keywords);
        setTreeSelectLoading(true);
        if (keywords.value || keywords.value == '')
            api.searchClientNameAccount(keywords.value).then((result: any) => {
                console.log(result);
                setTreeSelectLoading(false);
                if (result.clients) updateTreeSelect(result.clients);
            });
    };

    const onTreeSelectChange = (selectInputObject: any) => {
        if (selectInputObject.value) {
            requestGetAccountContract(selectInputObject.value, 1);
        } else {
            console.log('set empty');
            setTableData([]);
        }
    };

    const requestGetAccountContract = async (accNo: any, cPage = 1) => {
        //get account_id
        setLoading(true);
        setCurrentAccNo(accNo);
        let result = await api.getAccountContract(accNo, searchInputs, cPage);
        console.log('onTreeSelectChange');
        console.log(result);
        if (result.contracts) {
            let contracts = result.contracts;
            setTableData(contracts);
            //  updateAccSummaryComponent(account);
        }

        if (result.total) {
            // handlePagination('currentPage', cPage);
            // handlePagination('totalRecords', result.total);
            setTablePagination({
                currentPage: cPage,
                totalRecords: result.total
            });
        }
        setLoading(false);
    };

    const updateTreeSelect = (result: any) => {
        console.log(result);
        let newArr = parseAPIResultToData(result);
        setUserAccTreeData(newArr);
    };
    //tree select end

    return (
        <div className="pt-3 text-left ">
            <div className="row">
                <div className="col-12 main-title">Outstanding Purchase</div>
                <div className="col-xxl-2 col-xl-3 col-lg-4  col-md-6 col-12">
                    <div className="input-container">
                        <div className="label">Username / Account No </div>
                        <TreeSelect
                            treeMap={['username', 'accNo']}
                            data={userAccTreeData}
                            setLoading={treeSelectLoading}
                            onSelectChange={(selectInputObject: any) =>
                                onTreeSelectChange(selectInputObject)
                            }
                            keyinChange={(keywords: string) =>
                                onTreeKeyinChange(keywords)
                            }
                        />
                    </div>
                </div>
                <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-12">
                    <div className="input-container ">
                        <div className="label">Exchange </div>
                        <ExchangeTreeSelect
                            onChange={(value: string[]) => {
                                console.log('ExchangeTreeSelect');
                                console.log(value);
                                onInputChange('exchanges', value);
                            }}
                        />
                    </div>
                </div>
                <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-12">
                    <InputCurrency
                    // onChange={({ target }: any) =>
                    //     onInputChange('currency', target.value)
                    // }
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 col-12 pt-5">
                    <ReactTable
                        columns={columns}
                        data={tableData}
                        pageSize={10}
                        currentPage={tablePagination.currentPage}
                        totalRecords={tablePagination.totalRecords}
                        pageChange={(cPage: number) =>
                            requestGetAccountContract(currentAccNo, cPage)
                        }
                        hasPagination
                    />
                </div>
            </div>
        </div>
    );
};

export default Index;
