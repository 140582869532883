import { useState, useEffect } from 'react';
import '../../asset/styles/customer-profile-style.css';

import { useLoading } from '../../../context/LoadingContext';

import api from '../../../apis';

import TreeSelect from '../../general/form-control/tree-select';
import { parseAPIResultToData } from '../../general/form-control/tree-select/helper';

import DetailTable from '../../general/info-control/table-detail';
import { formGroupTableData } from '../../general/info-control/table-detail/helper';

const Index = () => {
    const { loading, setLoading } = useLoading();
    useEffect(() => {}, []);

    const [searchInputs, setSearchInputs] = useState({});
    const [userAccTreeData, setUserAccTreeData] = useState<any>([]);
    const [treeSelectLoading, setTreeSelectLoading] = useState<boolean>(false);

    useEffect(() => {
        //console.log(searchInputs);
        //updateCurrentUserInfo(searchInputs.username);
        // updateCurrentAccDetail(searchInputs.accNo);
    }, [searchInputs]);

    const initialAvailabelOptions = (result: any) => {
        updateTreeSelect(result);
    };

    const updateTreeSelect = (result: any) => {
        console.log(result);
        let newArr = parseAPIResultToData(result);
        setUserAccTreeData(newArr);
    };

    const onDropdownChange = (id: string, newValue: string) => {
        setSearchInputs((prevState) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    //tree select start
    const onTreeKeyinChange = (keywords: any) => {
        // console.log('onTreeKeyinChange');
        // console.log(keywords);
        setTreeSelectLoading(true);
        if (keywords.value || keywords.value == '')
            api.searchClientNameAccount(keywords.value).then((result: any) => {
                setTreeSelectLoading(false);
                if (result.clients) initialAvailabelOptions(result.clients);
            });
    };

    const onTreeSelectChange = async (selectInputObject: any) => {
        if (selectInputObject.value) {
            //get account_id
            setLoading(true);
            let result = await api.getClientProfile(selectInputObject.value);
            //console.log(result);
            if (result.account) {
                let account = result.account;
                updateCustomerInfoComponent(account);
                updateAccDetailComponent(account);
            }
            setLoading(false);
        } else {
            //reset component data
            updateCustomerInfoComponent([]);
            updateAccDetailComponent([]);
        }
    };
    //tree select end

    // customer profile data start
    const customer_info_table_data = [
        { label: 'User ID', id: 'u_username', value: '' },
        { label: 'Username', id: 'p_fullname', value: '' },
        {
            label: 'NRIC No.',
            id: 'p_nric',
            value: ''
        },
        {
            label: 'Nationality',
            id: 'p_nationality',
            value: ''
        },
        { label: 'Race', id: 'p_race', value: '' },
        {
            label: 'Address',
            id: [
                'p_addressline1',
                'p_addressline2',
                'p_city',
                'p_state',
                'p_postcode',
                'p_country'
            ],
            idsBetween: ', ',
            value: ''
        },
        {
            label: 'Tel No.',
            id: 'p_phone',
            value: ''
        },
        {
            label: 'Handphone No.',
            id: 'p_handphone',
            value: ''
        },
        {
            label: 'Email Address',
            id: 'p_email',
            value: ''
        }
    ];
    const [currentUserInfo, setCurrentUserInfo] = useState(
        customer_info_table_data || []
    );
    //update customer info
    const updateCustomerInfoComponent = (result: any) => {
        console.log(result);
        let tableData = formGroupTableData(customer_info_table_data, result);
        setCurrentUserInfo(tableData);
    };
    // customer profile data end

    // acc detail start
    let acc_detail_data = [
        { id: 'ct_name', label: 'Client Type', value: '' },
        { id: 'a_acctno', label: 'Account No.', value: '' },
        {
            id: 'type',
            label: 'Account Type',
            value: ''
        },
        {
            id: 'a_timecreate',
            label: 'Account Open Date',
            type: 'timestamp',
            value: ''
        },
        {
            id: 'a_status',
            label: 'Status',
            value: '',
            statusToText: 'accountStatus'
        },
        { id: 'd_p_fullname_code', label: 'Dealer', value: '' },
        {
            id: 'd_business_contact',
            ifIdEmpty: 'd_p_handphone',
            label: 'Dealer Contact',
            value: ''
        },
        {
            id: ['bk_code', 'u_bankacctno'],
            label: 'Bank Account No.',
            value: ''
        },
        {
            id: 'a_estatement',
            label: 'E-Statement',
            value: '',
            statusToText: 'eStatementStatus'
        },
        {
            id: 'a_econtract',
            label: 'E-Contract',
            value: '',
            statusToText: 'eContractStatus'
        },
        { id: 'a_idss', label: 'IDSS', value: '', statusToText: 'IDSSStatus' }
    ];
    const [currentAccDetail, setCurrentAccDetail] = useState(
        acc_detail_data || []
    );
    const updateAccDetailComponent = (result: any) => {
        let parseResult = parseCurrentAccDetail(result);
        let tableData = formGroupTableData(acc_detail_data, parseResult);
        setCurrentAccDetail(tableData);
    };
    const parseCurrentAccDetail = (result: any) => {
        //console.log('result', result);

        if (result.d_p_fullname && result.d_code) {
            result.d_p_fullname_code =
                result.d_p_fullname + ' (' + result.d_code + ')';
        }

        return result;
    };
    // acc detail end

    // const formGroupTableData = (originalTableFormat, result) => {
    //     let newTableData = [];
    //     originalTableFormat.forEach(function (item) {
    //         let id = item.id;
    //         let label = item.label;
    //         let value = item.value;
    //         let statusToText = item.statusToText;
    //         let type = item.type;

    //         if (Array.isArray(id)) {
    //             //console.log(typeof id);
    //             let combineValue = '';
    //             for (var i = 0; i < id.length; i++) {
    //                 let key = id[i];
    //                 if (result[key]) {
    //                     combineValue += row + ' ';
    //                 }
    //             }
    //             value = combineValue;
    //         } else {
    //             if (result[id] || result[id] === 0) {
    //                 value = result[id].toString();

    //                 //if datetime covert to localtime
    //                 if (type == 'timestamp') {
    //                     let localtime = moment(value).format(
    //                         'YYYY-MM-DD HH:mm:ss'
    //                     );

    //                     value = localtime;
    //                 }

    //                 //convert status to text
    //                 if (statusToText) {
    //                     value = ConvertStatusToText(statusToText, value);
    //                 }
    //             }
    //         }

    //         newTableData.push({
    //             id,
    //             label,
    //             value
    //         });
    //     });
    //     return newTableData;
    // };

    return (
        <div className="pt-3 text-left ">
            <div className="row">
                <div className="col-12 main-title">Profile</div>
                <div className="col-xxl-2 col-lg-3 col-md-6 col-12 ">
                    <div className="input-container">
                        <div className="label">Username / Account No </div>
                        <TreeSelect
                            treeMap={['username', 'accNo']}
                            data={userAccTreeData}
                            setLoading={treeSelectLoading}
                            onSelectChange={(selectInputObject: any) =>
                                onTreeSelectChange(selectInputObject)
                            }
                            keyinChange={(keywords: any) =>
                                onTreeKeyinChange(keywords)
                            }
                        />
                    </div>
                    {/* <div className="input-container">
                        <div className="label">Username </div>
                        <DropdownSelect
                            id="username"
                            data={[
                                {
                                    value: 'Chin Woo',
                                    label: 'Chin Woo'
                                },
                                {
                                    value: 'Chin Wu Lui',
                                    label: 'Chin Wu Lui'
                                },
                                {
                                    value: 'Chinny Chin',
                                    label: 'Chinny Chin'
                                }
                            ]}
                            onDropdownChange={(id, newValue) =>
                                onDropdownChange(id, newValue)
                            }
                        />
                    </div>
                    <div className="input-container ">
                        <div className="label">Account No </div>
                        <DropdownSelect
                            id="accNo"
                            data={[
                                {
                                    value: '8SC1701R',
                                    label: '8SC1701R'
                                },
                                {
                                    value: '701R8SC1',
                                    label: '701R8SC1'
                                },
                                {
                                    value: 'SC1701R8',
                                    label: 'SC1701R8'
                                }
                            ]}
                            onDropdownChange={(id, newValue) =>
                                onDropdownChange(id, newValue)
                            }
                        />
                    </div> */}
                </div>
            </div>
            <div className="row">
                <div className="col-xxxl-5 col-lg-6 col-md-12 col-12 pt-5">
                    <DetailTable
                        data={currentUserInfo || []}
                        titleProps={{
                            label: 'Customer Info',
                            bgColor: '#3268f6'
                        }}
                        itemProps={{
                            className: 'col-md-6 col-12'
                        }}
                    />
                </div>
                <div className="col-xxxl-7 col-lg-6 col-md-12 col-12 pt-5">
                    <DetailTable
                        titleProps={{
                            label: 'Account Details',
                            bgColor: '#439782'
                        }}
                        data={currentAccDetail || []}
                        itemProps={{
                            className: 'col-md-6 col-12'
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Index;
