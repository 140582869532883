const accountTypes = {
    '0': 'Cash',
    '1': 'Margin',
    '2': 'Cash Upfront',
    '3': 'Collateralized'
};

const accountStatus = {
    '0': 'Inactive',
    '1': 'Active',
    '2': 'Suspended',
    '3': 'Expired',
    '4': 'Dormant',
    '5': 'Closed'
};

const currencyStatus = {
    '0': 'Inactive',
    '1': 'Active'
};

const contraTypes = {
    '0': 'Gain',
    '1': 'Loss'
};

const contractTypes = {
    '0': 'Purchase',
    '1': 'Sales'
};

const tradeSides = {
    '1': 'Buy',
    '2': 'Sell'
};

const stockTypes = {
    '0': 'None',
    '1': 'IPO',
    '2': 'Active',
    '3': 'Suspended',
    '4': 'Unavailable for trade',
    '5': 'Delisted',
    '6': 'Halted',
    '7': 'Restricted',
    '8': 'Opening Delay',
    '9': 'Closing Delay',
    '10': 'Unsubscribe'
};

const eStatementStatus = {
    '0': 'N',
    '1': 'Y'
};

const eContractStatus = {
    '0': 'N',
    '1': 'Y'
};

const IDSSStatus = {
    '0': 'N',
    '1': 'Y'
};

const currenciesList = [
    {
        c_id: 1,
        c_code: 'MYR',
        c_name: 'Ringgit Malaysia'
    }
];

const depositStatus = {
    '0': 'Inactive',
    '1': 'FPX',
    '2': 'Manual Transfer'
};

const manualTransferType = {
    '2': 'Cheque',
    '3': 'Cash'
};

const manualTransferType2 = {
    '1': 'FPX',
    '2': 'Cheque',
    '3': 'Cash'
};

const fundingType = {
    '1': 'Deposit',
    '2': 'Withdrawal',
    '3': 'Settlement'
};

//availabel status type
export default {
    accountTypes,
    accountStatus,
    currencyStatus,
    contraTypes,
    contractTypes,
    stockTypes,
    eStatementStatus,
    eContractStatus,
    IDSSStatus,
    currenciesList,
    depositStatus,
    manualTransferType,
    manualTransferType2,

    // trade related
    tradeSides,
    // funding related
    fundingType
};
