import { useState, useEffect, useLayoutEffect } from 'react';
import { numberWithCommas } from '../../general/convertor';
import '../../asset/styles/portfolio-style.css';

import api from '../../../apis';

import DetailTable from '../../general/info-control/table-detail';

import { formGroupTableData } from '../../general/info-control/table-detail/helper';

import { Line, Bar, Pie } from 'react-chartjs-2';

import ReactTable from '../../general/form-control/react-table';
import ReactTableGroup from '../../general/form-control/react-table-group';
import SearchBox from '../../general/form-control/search-box';

import { ReactComponent as TrendingIC } from '../../asset/images/icon/trending-up.svg';
import { ReactComponent as CalenderIC } from '../../asset/images/icon/calendar.svg';

import UserAccTreeSelect from '../../general/form-control/user-acc-tree-select';
import { usePagination } from '../../tables/helper';

import { Modal } from 'antd';

import postAppMessage from '../../general/appsCallback';
import useAuth from '../../../hooks/useAuth';

const Index = () => {
    const { auth, setAuth } = useAuth();

    useEffect(() => {
        //api.getPortfolio();
    }, []);

    const [currentAccNo, setCurrentAccNo] = useState([]);

    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);

    const { tablePagination, setTablePagination } = usePagination();
    const [tableData, setTableData] = useState([]);

    const [theme, setTheme] = useState('basic');

    useLayoutEffect(() => {
        function updateSize() {
            if (window.innerWidth <= 600) {
                //mobile view
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        if (currentAccNo.length > 0) {
            // setNotiLoading(true);
            // console.log('inputs', searchInputs);
            // showNotiLoader();
            const delayDebounceFn = setTimeout(() => {
                requestGetPortfolioAPI(currentAccNo);
            }, 600);

            return () => clearTimeout(delayDebounceFn);
        } else if (currentAccNo.length == 0) {
            setTableData([]);
            //handleResetTables();
        }
    }, [currentAccNo]);

    useLayoutEffect(() => {
        if (auth.mode == 'dark') {
            setTheme('dark');
        } else {
            setTheme('basic');
        }
    }, [auth]);

    const requestGetPortfolioAPI = async (accNo: any, cPage = 1) => {
        // setNotiLoapi.getPortfolio();
        // console.log(searchInputs);
        let result = await api.getPortfolio(accNo, {}, cPage);
        //console.log('result', result);

        if (result.portfolio) {
            let portfolio = result.portfolio;
            console.log('portfolio', portfolio);
            setTableData(portfolio);
        }
    };

    const lableFontSize = 20;
    // const lineChartData = {
    //     labels: [
    //         'Jan 2022',
    //         '',
    //         'Mar 2022',
    //         '',
    //         'May 2022',
    //         '',
    //         'Jun 2022',
    //         '',
    //         'Sep 2022',
    //         '',
    //         'Nov 2022',
    //         ''
    //     ],
    //     datasets: [
    //         {
    //             label: 'First dataset',
    //             data: [
    //                 0, 10000, 5000, 15000, 10000, 20000, 15000, 25000, 20000,
    //                 30000, 40000
    //             ],
    //             fill: true,
    //             backgroundColor: '#F1F3F9',
    //             borderColor: '#00218F',
    //             lineTension: 0.3
    //         }
    //     ]
    // };

    // const lineChartOptions = {
    //     responsive: true,
    //     scales: {
    //         x: {
    //             grid: {
    //                 display: false
    //                 // color: "rgba(0, 0, 0, 0.05)",
    //             },
    //             ticks: {
    //                 color: '#2B2E38', //text color
    //                 fontStyle: 'bold'
    //             }
    //         },
    //         y: {
    //             grid: {
    //                 color: 'rgba(0, 0, 0, 0.03)',
    //                 drawBorder: false
    //             },
    //             ticks: {
    //                 color: '#2B2E38', //text color
    //                 fontStyle: 'bold',
    //                 // Include a dollar sign in the ticks
    //                 callback: function (value: number, index: any, ticks: any) {
    //                     return (
    //                         'MYR ' +
    //                         value
    //                             .toString()
    //                             .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    //                     );
    //                 }
    //             }
    //         }
    //     },
    //     plugins: {
    //         legend: {
    //             display: false,
    //             labels: {
    //                 color: 'rgb(0, 0, 0,0)' //hide title
    //             }
    //             // title:false
    //         },
    //         tooltips: {
    //             enabled: false
    //         }
    //         //   title: {
    //         // 	display: true,
    //         // 	text: 'Chart.js Line Chart',
    //         //   },
    //     }
    // };

    const returnChartData = {
        labels: [
            'Jan 2022',
            '',
            'Mar 2022',
            '',
            'May 2022',
            '',
            'Jun 2022',
            '',
            'Sep 2022',
            '',
            'Nov 2022',
            ''
        ],
        datasets: [
            {
                label: 'FBMKLCI',
                data: [-3, -1, 5, -3, 12, 17, 16, 9, 14, 16, 23, 34],
                fill: true,
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                borderColor: '#FF9F40',
                lineTension: 0.3
            },
            {
                label: 'TWR',
                data: [-5, -2, 3, -1, 5, 7, 9, 8, 11, 15, 16, 22],
                fill: true,
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                borderColor: '#00218F',
                lineTension: 0.3
            }
        ]
    };

    const returnLineChartcolor1 = theme == 'dark' ? 'white' : '#2B2E38';
    const returnLineChartcolor2 = theme == 'dark' ? 'white' : '';
    const returnLineChartcolor3 = theme == 'dark' ? 'white' : 'rgb(0, 0, 0,1)';
    const returnLineChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false
                    // color: "rgba(0, 0, 0, 0.05)",
                },
                ticks: {
                    color: returnLineChartcolor1, //text color
                    fontStyle: 'bold'
                }
            },
            y: {
                grid: {
                    color: 'rgba(0, 0, 0, 0.03)',
                    drawBorder: false
                },
                ticks: {
                    color: returnLineChartcolor1, //text color
                    fontStyle: 'bold',
                    callback: function (value: number, index: any, ticks: any) {
                        return value + '%';
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    color: returnLineChartcolor3
                }
                // title:false
            },
            tooltips: {
                enabled: false
            },
            title: {
                display: true,
                text: 'Return',
                color: returnLineChartcolor2,
                font: {
                    size: lableFontSize
                }
            }
            //   title: {
            // 	display: true,
            // 	text: 'Chart.js Line Chart',
            //   },
        }
    };

    const drawdownColor1 = theme == 'dark' ? 'white' : '';
    const drawdownColor2 = theme == 'dark' ? 'white' : '#2B2E38';
    const drawdownChartData = {
        labels: ['Oct 2021', 'Mar 2021', 'May 2021'],
        datasets: [
            {
                label: '',
                data: [-12, -1, -4],
                backgroundColor: '#2B52FF',
                barThickness: 10
            }
        ]
    };

    const drawdownChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: 'Drawdown',
                color: drawdownColor1,
                font: {
                    size: lableFontSize
                }
            }
        },
        scales: {
            y: {
                ticks: {
                    color: drawdownColor2, //text color
                    fontStyle: 'bold',
                    callback: function (value: number, index: any, ticks: any) {
                        return value + '%';
                    }
                },
                min: -30
            },
            x: {
                ticks: {
                    color: drawdownColor2 //text color
                }
            }
        }
    };

    const distributionChartData = {
        labels: ['Equity', 'Future', 'Funds'],
        datasets: [
            {
                label: '',
                data: [35, 10, 55],
                backgroundColor: ['#2E59D9', '#FA9C1B', '#AAAAAA'],
                borderColor: 'rgba(255, 255, 255, 1)',
                borderWidth: 1
            }
        ]
    };

    const distributionColor1 = theme == 'dark' ? 'white' : '';
    const distributionChartOptions = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: 'Distribution',
                font: {
                    size: lableFontSize
                },
                color: distributionColor1
            },
            legend: {
                labels: {
                    color: distributionColor1
                }
            }
        }
    };

    const [currentTab, setCurrentTab] = useState('summary');
    const tabChangeCallback = (tab: any) => {
        setCurrentTab(tab);
    };

    const dummyTableData = [
        {
            market: 'BURSA Market Value',
            code: '0166',
            name: 'INARI',
            currency: 'MYR',
            cost: 87500,

            avg_price: 1.75,
            mkt_price: 3.18,
            mkt_value: 159000.0,
            flt_pnl: 71500.0,
            flt_pnl_percentage: 87.71
        },
        {
            market: 'BURSA Market Value',
            code: '1155',
            name: 'MAYBANK',
            currency: 'MYR',
            cost: 65000,

            avg_price: 6.5,
            mkt_price: 8.13,
            mkt_value: 81300,
            flt_pnl: 16300.0,
            flt_pnl_percentage: 25.08
        },
        {
            market: 'HKEX Market Value',
            code: '0166',
            name: 'INARI',
            currency: 'MYR',
            cost: 87500.0,

            avg_price: 1.75,
            mkt_price: 3.18,
            mkt_value: 159000.0,
            flt_pnl: 71500.0,
            flt_pnl_percentage: 81.71
        },
        {
            market: 'HKEX Market Value',
            code: '1155',
            name: 'MAYBANK',
            currency: 'MYR',
            cost: 65000.0,

            avg_price: 6.5,
            mkt_price: 8.13,
            mkt_value: 81300.0,
            flt_pnl: 16300.0,
            flt_pnl_percentage: 25.08
        }
    ];

    const c_info_table_data = [
        { label: 'Name', id: 't_name', value: '' },
        { label: 'Code', id: 't_code', value: '' },
        {
            label: 'Quantity',
            id: 'ap_shareholding',
            value: ''
        },
        {
            label: 'Currency',
            id: 'ap_ccy',
            value: ''
        },
        {
            label: 'Cost',
            id: 'cost',
            value: ''
        },
        {
            label: 'Avg. Price',
            id: 'ap_avgprice',
            value: ''
        },
        {
            label: 'Mkt. Price',
            id: 'mkt_price',
            value: ''
        },
        {
            label: 'Mkt. Value',
            id: 'mkt_value',
            value: ''
        },
        {
            label: 'FLT PnL',
            id: 'flt_pnl',
            value: ''
        },
        {
            label: 'FLT PnL %',
            id: 'flt_pnl_perc',
            value: ''
        }
    ];

    const [currentTableInfo, setCurrentTableInfo] = useState(
        c_info_table_data || []
    );

    const [currentSelectedRow, setCurrentSelectedRow] = useState(null);

    const onTableRowClick = (row) => {
        console.log('row', row);
        let parseRowResult = parseCurrentTableInfo(row);
        //console.log('parseRowResult', parseRowResult);
        let tableData = formGroupTableData(c_info_table_data, parseRowResult);
        setCurrentTableInfo(tableData);
        setCurrentSelectedRow(parseRowResult);
        setOpenDetailModal(true);
    };

    const parseCurrentTableInfo = (result: any) => {
        //console.log('result', result);

        // let r = result;
        // let cost = r.cost || 0;
        // let avg_price = r.avg_price || 0;
        // let quantity = cost / avg_price || 0;

        // result.quantity = quantity;

        return result;
    };

    const handlePortfolioAction = (action) => {
        console.log('currentSelectedRow', currentSelectedRow);
        console.log('action', action);

        postAppMessage({
            type: 'message',
            action: action,
            data: currentSelectedRow
        });

        // console.log('postAppMessage', {
        //     type: 'message',
        //     action: action,
        //     data: currentSelectedRow
        // });
    };

    return (
        <div className="pt-3 text-left portfolio-container">
            <div className="row">
                <div className="col-12 main-title">Portfolio</div>

                <div className="col-xxxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
                    <div className="input-container">
                        <div className="label">Username / Account No </div>
                        <UserAccTreeSelect
                            onChange={(value: string[]) => {
                                console.log('UserAccTreeSelect', value);
                                setCurrentAccNo(value);
                            }}
                        />
                        {/* <TreeSelect
                            treeMap={['username', 'accNo']}
                            data={userAccTreeData}
                            setLoading={treeSelectLoading}
                            keyinChange={(keywords: any) =>
                                onTreeKeyinChange(keywords)
                            }
                            onSelectChange={(selectInputObject: any) =>
                                onTreeSelectChange(selectInputObject)
                            }
                            onSelectChangeParentValue={(parentValue: any) => {
                                setCurrentUsername(parentValue || '');
                            }}
                        /> */}
                    </div>
                </div>
                {/* <div className="col-xxxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
                    {' '}
                    <div className="input-container ">
                        <div className="label"> Client Code </div>
                        <select className="custom" defaultValue={''}>
                            <option value="1">AB1234123123</option>
                        </select>
                    </div>
                </div> */}
                <div className="col-xxxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
                    {' '}
                    <div className="input-container ">
                        <div className="label"> Currency</div>
                        <select className="custom" defaultValue={''}>
                            <option value="1">Ringgit Malaysia</option>
                        </select>
                    </div>
                </div>
                <div className="col-xxxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
                    {' '}
                    <div className="input-container ">
                        <div className="label"> Type</div>
                        <select className="custom" defaultValue={''}>
                            <option value="1">Equity</option>
                        </select>
                    </div>
                </div>
            </div>
            {/* <div className="row">
                <div className="col-12 title">Portfolio</div>
                <div className="col-12">
                    <div className="portfolio-slt-container">
                        <div className="item">
                            <div className="label">Name</div>
                            <select className="form-select custom-form">
                                <option selected>Select Name</option>
                                <option value="1">Chin Wu Lui</option>
                            </select>
                        </div>
                        <div className="item">
                            <div className="label">Client Code</div>
                            <select className="form-select custom-form">
                                <option value="1">AB1234123123</option>
                            </select>
                        </div>
                        <div className="item">
                            <div className="label">Currency</div>
                            <select className="form-select custom-form">
                                <option value="1">Ringgit Malaysia(RM)</option>
                            </select>
                        </div>
                        <div className="item">
                            <div className="label">Type</div>
                            <select className="form-select custom-form">
                                <option value="1">Equity</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="row">
                <div className="col-xxxl-3 col-lg-4 col-12 pt-5 ">
                    <TabsContainer
                        onTabChange={(el: any) => tabChangeCallback(el)}
                    />
                </div>
                {currentTab == 'summary' && (
                    <div
                        className={
                            'col-xxxl-9 col-lg-8 col-12 pt-5 ' +
                            (currentTab == 'summary'
                                ? ' showElement '
                                : ' hideElement ')
                        }
                    >
                        {/* <div className="">
                        <div className="d-flex justify-content-center">
                            <ul
                                className="nav nav-pills mb-3 custom-nav-pills"
                                id="pills-tab"
                                role="tablist"
                            >
                                <li className="nav-item" role="presentation">
                                    <button
                                        className=" active"
                                        id="pills-home-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-home"
                                        aria-selected="true"
                                    >
                                        Balanced
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className=""
                                        id="pills-contact-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-contact"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-contact"
                                        aria-selected="false"
                                    >
                                        Drawdown
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className=""
                                        id="pills-contact-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-contact"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-contact"
                                        aria-selected="false"
                                    >
                                        Distribution
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <div
                            className="tab-content border p-2"
                            id="pills-tabContent"
                        >
                            <div
                                className="tab-pane fade show active"
                                id="pills-home"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab"
                            >
                                <Line
                                    data={lineChartData}
                                    options={lineChartOptions}
                                />
                            </div>
                            <div
                                className="tab-pane fade"
                                id="pills-profile"
                                role="tabpanel"
                                aria-labelledby="pills-profile-tab"
                            >
                                ...
                            </div>
                            <div
                                className="tab-pane fade"
                                id="pills-contact"
                                role="tabpanel"
                                aria-labelledby="pills-contact-tab"
                            >
                                ...
                            </div>
                        </div>
                    </div> */}
                        <div className={'row chart-container'}>
                            <div
                                className="col-md-12 col-12 p-3"
                                style={{ maxHeight: '50vh', height: '40vh' }}
                            >
                                <div className="float-right">
                                    <select>
                                        <option>FBMKLCI</option>
                                        <option>SG-STI</option>
                                        <option>HSI</option>
                                        <option>SZSE</option>
                                        <option>SSE</option>
                                        <option>Nasdaq</option>
                                        <option>NYSE</option>
                                    </select>
                                </div>
                                <Line
                                    data={returnChartData}
                                    options={returnLineChartOptions}
                                />
                            </div>
                            <div className="col-md-6 col-12 p-3">
                                <Bar
                                    options={drawdownChartOptions}
                                    data={drawdownChartData}
                                />
                            </div>
                            <div className="col-md-6 col-12 p-3">
                                <div className=" d-flex justify-content-center">
                                    <div
                                        style={{
                                            width: '50%',
                                            minWidth: '300px'
                                        }}
                                    >
                                        <Pie
                                            options={distributionChartOptions}
                                            data={distributionChartData}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div
                    className={
                        'col-xxxl-9 col-lg-8 col-12 pt-5 ' +
                        (currentTab == 'detail'
                            ? ' showElement '
                            : ' hideElement ')
                    }
                >
                    <div>
                        {/* <div className="search-box-container pb-4 just">
                            <SearchBox />
                        </div> */}
                        {isMobile == false ? (
                            <ReactTableGroup
                                columns={[
                                    {
                                        Header: 'Market',
                                        accessor: 'e_name'
                                        // show: false
                                    },
                                    {
                                        Header: 'Code',
                                        accessor: 't_code',
                                        // Use a two-stage aggregator here to first
                                        // count the total rows being aggregated,
                                        // then sum any of those counts if they are
                                        // aggregated further
                                        aggregate: 'count',
                                        Aggregated: ({ row, value }: any) => {
                                            return `${row.groupByVal}`;
                                        },
                                        colSpan: 2
                                    },
                                    {
                                        Header: 'Name',
                                        accessor: 't_name',
                                        colSpan: 0
                                    },
                                    {
                                        Header: (
                                            <div className="text-right">
                                                Quantity
                                            </div>
                                        ),
                                        accessor: 'ap_shareholding',
                                        Cell: (v: any) => {
                                            return (
                                                <div className="text-right">
                                                    {numberWithCommas(v.value)}
                                                </div>
                                            );
                                        }
                                        // Cell: ({ row }: any) => {
                                        //     let rowData = row?.original;
                                        //     if (!rowData) return <></>;

                                        //     let cost = rowData.cost || 0;
                                        //     let avg_price =
                                        //         rowData.avg_price || 0;
                                        //     let quantity =
                                        //         cost / avg_price || 0;

                                        //     return (
                                        //         <div className="text-right">
                                        //             {numberWithCommas(quantity)}
                                        //         </div>
                                        //     );
                                        // }
                                    },
                                    {
                                        Header: 'Currency',
                                        accessor: 'ap_ccy'
                                    },

                                    {
                                        Header: (
                                            <div className="text-right">
                                                Cost
                                            </div>
                                        ),
                                        accessor: 'cost',
                                        Cell: (v: any) => {
                                            return (
                                                <div className="text-right">
                                                    {numberWithCommas(
                                                        v.value,
                                                        2
                                                    )}
                                                </div>
                                            );
                                        },
                                        // Aggregate the sum of all cost
                                        aggregate: 'sum',
                                        Aggregated: ({ value }: any) => (
                                            <div className="text-right">
                                                {numberWithCommas(value, 2)}
                                            </div>
                                        )
                                    },
                                    {
                                        Header: (
                                            <div className="text-right">
                                                Avg. Price
                                            </div>
                                        ),
                                        accessor: 'ap_avgprice',
                                        Cell: (v: any) => {
                                            if (v.value)
                                                return (
                                                    <div className="text-right">
                                                        {numberWithCommas(
                                                            v.value,
                                                            2
                                                        )}
                                                    </div>
                                                );
                                        }
                                    },
                                    {
                                        Header: (
                                            <div className="text-right">
                                                Mkt. Price
                                            </div>
                                        ),
                                        accessor: 'mkt_price',
                                        Cell: (v: any) => {
                                            return (
                                                <div className="text-right">
                                                    {numberWithCommas(
                                                        v.value,
                                                        2
                                                    )}
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        Header: (
                                            <div className="text-right">
                                                Mkt. Value
                                            </div>
                                        ),
                                        accessor: 'mkt_value',
                                        Cell: (v: any) => {
                                            return (
                                                <div className="text-right">
                                                    {numberWithCommas(
                                                        v.value,
                                                        2
                                                    )}
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        Header: (
                                            <div className="text-right">
                                                FLT PnL
                                            </div>
                                        ),
                                        accessor: 'flt_pnl',
                                        Cell: (v: any) => {
                                            if (v.value)
                                                return (
                                                    <div className="text-right">
                                                        {numberWithCommas(
                                                            v.value,
                                                            2
                                                        )}
                                                    </div>
                                                );
                                        },
                                        // Aggregate the sum of all cost
                                        aggregate: 'sum',
                                        Aggregated: ({ value }: any) => (
                                            <div className="text-right">
                                                {numberWithCommas(value, 2)}
                                            </div>
                                        )
                                    },
                                    {
                                        Header: (
                                            <div className="text-right">
                                                FLT PnL %
                                            </div>
                                        ),
                                        accessor: 'flt_pnl_perc',
                                        Cell: (v: any) => {
                                            if (v.value)
                                                return (
                                                    <div className="text-right">
                                                        {numberWithCommas(
                                                            v.value
                                                        ) + '%'}
                                                    </div>
                                                );
                                        }
                                    },
                                    {
                                        Header: '',
                                        accessor: 'buy_btn',
                                        Cell: ({ row }: any) => {
                                            //check is subrow
                                            return row?.canExpand == false ? (
                                                <div className="d-flex gap-2">
                                                    <div>
                                                        <button
                                                            className="btn  w-auto"
                                                            style={{
                                                                background:
                                                                    '#439782',
                                                                color: 'white'
                                                            }}
                                                        >
                                                            Buy
                                                        </button>
                                                    </div>

                                                    <div>
                                                        <button className="btn btn-danger w-auto">
                                                            Sell
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : null;
                                        }
                                    }
                                    // {
                                    //     Header: 'Sell',
                                    //     accessor: 'sell_btn',
                                    //     Cell: ({ row }: any) => {
                                    //         return row?.canExpand == false ? (
                                    //             <div>
                                    //                 <button className="btn btn-danger w-auto">
                                    //                     Sell
                                    //                 </button>
                                    //             </div>
                                    //         ) : null;
                                    //     }
                                    // }
                                ]}
                                data={tableData}
                                hideColumns={['e_name']}
                                groupbyID={['e_name']}
                            />
                        ) : tableData ? (
                            <ReactTableGroup
                                tableClassname={'mobile-portfolio'}
                                tableStyle={{
                                    tableLayout: 'fixed'
                                }}
                                columns={[
                                    {
                                        Header: 'Market',
                                        accessor: 'e_name'
                                        // show: false
                                    },
                                    {
                                        Header: 'Symbol',
                                        accessor: 't_code', //group by code
                                        // Use a two-stage aggregator here to first
                                        // count the total rows being aggregated,
                                        // then sum any of those counts if they are
                                        // aggregated further
                                        Cell: ({ row }: any) => {
                                            let rowData = row?.original;
                                            // console.log(rowData);
                                            if (!rowData) return <></>;

                                            return (
                                                <div
                                                    className="text-left"
                                                    style={{
                                                        fontSize: '0.8rem',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    {rowData.t_name}
                                                </div>
                                            );
                                        },
                                        aggregate: 'count',
                                        Aggregated: ({ row, value }: any) => {
                                            return (
                                                <div
                                                    // style={{ color: 'white' }}
                                                    className="isAggregated"
                                                >
                                                    {row.groupByVal}
                                                </div>
                                            );
                                        },
                                        colSpan: 2,
                                        width: '25%'
                                    },
                                    {
                                        width: '50%',
                                        colSpan: 0,
                                        Header: (
                                            <div className="text-right">
                                                <div>Qty</div>
                                                <div> FLT PnL(%)</div>
                                            </div>
                                        ),
                                        accessor: 'flt_pnl',
                                        Cell: ({ row }: any) => {
                                            let rowData = row?.original;
                                            if (!rowData) return <></>;

                                            // let cost = rowData.cost || 0;
                                            // let avg_price =
                                            //     rowData.avg_price || 0;
                                            // let quantity =
                                            //     cost / avg_price || 0;
                                            // let flt_pnl = rowData.flt_pnl || 0;
                                            // let flt_pnl_percentage =
                                            //     rowData.flt_pnl_percentage || 0;

                                            return (
                                                <div className="text-right">
                                                    <div>
                                                        {numberWithCommas(
                                                            rowData.ap_shareholding
                                                        )}{' '}
                                                    </div>
                                                    <div>
                                                        {numberWithCommas(
                                                            rowData.flt_pnl
                                                        )}
                                                        ({rowData.flt_pnl_perc}
                                                        %)
                                                    </div>
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        Header: (
                                            <div className="text-right">
                                                <div>Avg. Prc.</div>
                                                <div>Mkt. Prc.</div>
                                            </div>
                                        ),
                                        accessor: 'avg_price',
                                        Cell: ({ row }: any) => {
                                            let rowData = row?.original;
                                            if (!rowData) return <></>;

                                            return (
                                                <div className="text-right">
                                                    <div>
                                                        {rowData.ap_avgprice}
                                                    </div>
                                                    <div>
                                                        {rowData.mkt_price}
                                                    </div>
                                                </div>
                                            );
                                        },
                                        width: '25%'
                                    }
                                ]}
                                data={tableData}
                                onRowClick={onTableRowClick}
                                hideColumns={['e_name']}
                                groupbyID={['e_name']}
                            />
                        ) : null}
                    </div>

                    <Modal
                        title=""
                        maskClosable={true}
                        open={openDetailModal}
                        // okText="Ok"
                        // onOk={async () => {
                        //     setOpenDetailModal(false);
                        // }}
                        okButtonProps={{ style: { display: 'none' } }}
                        cancelText="Close"
                        onCancel={() => {
                            setOpenDetailModal(false);
                        }}
                    >
                        <div className="pt-4">
                            <DetailTable
                                data={currentTableInfo || []}
                                titleProps={{
                                    label: '',
                                    bgColor: '#3268f6',
                                    className: 'd-none '
                                }}
                                labelTextProps={{
                                    style: {
                                        fontWeight: '0'
                                    }
                                }}
                                valueProps={{
                                    style: {
                                        fontSize: '20px'
                                    }
                                }}
                                itemProps={{
                                    className: 'col-md-6 col-6'
                                }}
                            />
                            <div className="d-flex gap-2 w-100 pt-3 justify-content-center">
                                <div>
                                    <button
                                        className="btn  w-auto"
                                        style={{
                                            background: '#439782',
                                            color: 'white'
                                        }}
                                        onClick={() =>
                                            handlePortfolioAction('buy')
                                        }
                                    >
                                        Buy
                                    </button>
                                </div>

                                <div>
                                    <button
                                        className="btn btn-danger w-auto"
                                        onClick={() =>
                                            handlePortfolioAction('sell')
                                        }
                                    >
                                        Sell
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    );
};

const TabsContainer = (props: any) => {
    let infoBoxSource = [
        {
            img: <CalenderIC />,
            title: 'Last Updated',
            value: '8/08/2022 08:08PM'
        },
        {
            img: <CalenderIC />,
            title: 'Start Date',
            value: '8/08/2022'
        }
    ];

    let main_report_data = [
        { label: 'Total Portfolio Value', value: '123,456,789.99' },
        { label: 'Available Cash', value: '123,456,789.99' },
        { label: 'TWR', value: '99.99%' },
        { label: 'Max. Drawdown', value: '99.99%' }
    ];

    let profile_lost_report_data = [
        { label: 'Profit & Loss' },
        { label: 'Floating', value: '123,456,789.99' },
        { label: 'Realized', value: '123,456,789.99' },
        { label: 'Daily', value: '123,456,789.99' },
        { label: 'Monthly', value: '123,456,789.99' },
        { label: 'YTD', value: '123,456,789.99' }
    ];

    let total_profit_loss_data = [
        { label: 'Total Profile', value: '123,456,789.99' },
        { label: 'Total Loss', value: '-123,456,789.99' }
    ];

    let cash_flow_data = [
        { label: 'Cash Flow' },
        { label: 'Deposit', value: '123,456,789.99' },
        { label: 'Withdrawals', value: '123,456,789.99' }
    ];

    const ReportTable = (props: any) => {
        let data = props.data || [];

        return (
            <div className="report-table-container">
                {data.map(function (row: any, i: any) {
                    let colorClass = '';
                    if (row.value && row.value.match('-')) {
                        colorClass = 'red';
                    }

                    return (
                        <div className="row m-0" key={i}>
                            <div className="col-md-6 col-12 label">
                                {row.label}
                            </div>
                            <div
                                className={
                                    'col-md-6 col-12 value ' + colorClass
                                }
                            >
                                {row.value}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const DetailTableComponents = () => {
        return (
            <div className="pl-2 pr-2">
                {' '}
                <div className="pt-4">
                    <ReportTable data={main_report_data} />
                </div>
                <div className="pt-4">
                    <ReportTable data={profile_lost_report_data} />
                </div>
                <div className="pt-4">
                    <ReportTable data={total_profit_loss_data} />
                </div>
                <div className="pt-4">
                    <ReportTable data={cash_flow_data} />
                </div>
                <div className="info-box-container pt-3">
                    <div className="item fullwidth">
                        {/* <img src={trendingIC} /> */}

                        <div className="p-3">
                            <TrendingIC />
                        </div>
                        <div className="detail">
                            <div className="title">Trades</div>
                            <div className="value">999,99</div>
                        </div>
                    </div>
                </div>
                {infoBoxSource.map(function (row, i) {
                    return (
                        <div className="info-box-container pt-3">
                            <div className="item fullwidth">
                                {/* <img src={row.img} /> */}
                                <div className="p-3">{row.img}</div>
                                <div className="detail">
                                    <div className="title">{row.title}</div>
                                    <div className="value">{row.value}</div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const onTabChange = (tab: any) => {
        // alert(tab);
        if (props.onTabChange) {
            props.onTabChange(tab);
        }
    };

    return (
        <div className="">
            <ul
                className="nav nav-tabs custom-nav"
                id="research-reports-tabs"
                role="tablist"
            >
                <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => onTabChange('summary')}
                >
                    <button
                        className="nav-link active"
                        id="summary-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#summary"
                        type="button"
                        role="tab"
                        aria-controls="summary"
                        aria-selected="true"
                    >
                        Summary
                    </button>
                </li>
                <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => onTabChange('detail')}
                >
                    <button
                        className="nav-link"
                        id="detail-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#detail"
                        type="button"
                        role="tab"
                        aria-controls="detail"
                        aria-selected="false"
                    >
                        Details
                    </button>
                </li>
            </ul>
            <div className="tab-content pt-3 fixed-height-container" id="">
                <div
                    className="tab-pane show active"
                    id="summary"
                    role="tabpanel"
                    aria-labelledby="summary-tab"
                >
                    {' '}
                    <div className="">
                        <DetailTableComponents />
                    </div>
                </div>
                <div
                    className="tab-pane "
                    id="detail"
                    role="tabpanel"
                    aria-labelledby="detail-tab"
                >
                    <DetailTableComponents />
                </div>
            </div>
        </div>
    );
};

export default Index;
