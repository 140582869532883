import { Button, Modal, Checkbox, Row, Col } from 'antd';
import { useState, useEffect, useRef } from 'react';
import api from '../../../apis';
import { useCookies } from 'react-cookie';
import Config from '../../../config';

const pdfUrl = Config.fileUrl;
interface Property {
    opened: boolean;
    children?: any;
    onClosed?: any;
    display?: any;
}

const TncModal = (props: Property) => {
    const [cookies, setCookie] = useCookies(['user']);

    const [title, setTitle] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [agreeChecked, setAgreeChecked] = useState(false);
    const [pdfFullUrl, setPdfFullUrl] = useState(null);

    useEffect(() => {
        // alert('on enter');
        //logined
        console.log('cookies', cookies);
        if (cookies.user) {
            setIsModalOpen(cookies.user?.show_tnc);
        }
    }, []);

    useEffect(() => {
        if (isModalOpen == true) {
            api.getTncDetail().then((result: any) => {
                console.log('result', result);
                if (result) {
                    setTitle(result?.title);

                    let fullUrl =
                        pdfUrl + result?.file_content + '#toolbar=0&navpanes=0';
                    setPdfFullUrl(fullUrl);
                }
            });
        }
    }, [isModalOpen]);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onCheckboxChange = (e: any) => {
        // console.log('checked = ', e.target.checked);
        setAgreeChecked(e.target.checked);
    };

    const submitAgree = () => {
        api.agreeTnc().then((result: any) => {
            console.log('result', result);
            if (result.status == 0) {
                setIsModalOpen(false);
                let newcookies = cookies.user;
                newcookies.show_tnc = false;
                setCookie('user', newcookies, { path: '/' });
            }
        });
    };

    return (
        <>
            {pdfFullUrl && (
                <Modal
                    bodyStyle={{
                        overflowY: 'auto',
                        minHeight: '70vh',
                        maxHeight: '70vh',
                        minWidth: '80vw'
                    }}
                    title={title}
                    open={isModalOpen}
                    onCancel={handleCancel}
                    width={'100vw'}
                    footer={[
                        <Row>
                            <Col span={24}>
                                <span className="text-danger">
                                    *You must scroll to the bottom before you
                                    can accpet the terms
                                </span>{' '}
                                &nbsp; &nbsp;
                                <Button
                                    type="primary"
                                    disabled={!agreeChecked}
                                    onClick={submitAgree}
                                >
                                    I AGREE
                                </Button>
                            </Col>
                        </Row>
                    ]}
                >
                    <div style={{ position: 'relative', height: '100vh' }}>
                        <embed
                            src={pdfFullUrl}
                            frameBorder="0"
                            scrolling="auto"
                            // height="100%"
                            height="100%"
                            width="100%"
                        ></embed>
                        <Row justify="end">
                            <div>
                                <Checkbox onChange={onCheckboxChange}>
                                    I accept the Terms and Conditions
                                </Checkbox>
                            </div>
                        </Row>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default TncModal;
