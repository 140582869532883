import { useState, useEffect, useMemo } from 'react';

import api from '../../../apis';
import moment from 'moment';

import { useLoading } from '../../../context/LoadingContext';

import ReactTable from '../../general/form-control/react-table';

import FormSelect from '../../general/form-control/form-item/select';

import UserAccTreeSelect from '../../general/form-control/user-acc-tree-select';

import { parseAPIResultToData } from '../../general/form-control/tree-select/helper';
import { numberWithCommas } from '../../general/convertor';

import { DatePicker } from 'antd';

import property from '../../../constants/property';

import DateRangePicker from '../../general/form-control/date-rangepicker';

const { RangePicker } = DatePicker;

const manualTransferType = property.manualTransferType2;
const fundingType = property.fundingType;

const Index = () => {
    const { setNotiLoading } = useLoading();

    useEffect(() => {}, []);

    const [currentAccNo, setCurrentAccNo] = useState('');
    const [currentUsername, setCurrentUsername] = useState('');

    const [userAccTreeData, setUserAccTreeData] = useState<any>([]);
    const [treeSelectLoading, setTreeSelectLoading] = useState<boolean>(false);

    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState<any>({});

    const [tableData, setTableData] = useState([]);
    const [tablePagination, setTablePagination] = useState<any>({
        currentPage: 1,
        totalRecords: 0
    });

    useEffect(() => {
        if (currentAccNo.length > 0) {
            setNotiLoading(true);
            console.log('inputs', inputs);
            // showNotiLoader();
            const delayDebounceFn = setTimeout(() => {
                requestGetFundingHistoryAPI(currentAccNo, inputs);
            }, 600);

            return () => clearTimeout(delayDebounceFn);
        } else if (currentAccNo.length == 0) {
            handleResetTables();
        }
    }, [currentAccNo, inputs]);

    const handleResetTables = () => {
        //reset data
        setTableData([]);
        setNotiLoading(false);
    };

    const onInputChange = (id: string, newValue: string) => {
        setInputs((prevState) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    const onDropdownChange = (id: string, newValue: string) => {
        setInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    const onDateChange = ({ id, timestamp }: any) => {
        let cdate = '';
        if (timestamp) {
            cdate = moment(timestamp, 'DD-MM-YYYY').format('YYYY-MM-DD');
        }
        onDropdownChange(id, cdate);
    };

    //tree select start
    const onTreeKeyinChange = (keywords: any) => {
        // console.log('onTreeKeyinChange');
        // console.log(keywords);
        setTreeSelectLoading(true);
        if (keywords.value || keywords.value == '')
            api.searchClientNameAccount(keywords.value).then((result: any) => {
                console.log(result);
                setTreeSelectLoading(false);
                if (result.clients) updateTreeSelect(result.clients);
            });
    };

    const onTreeSelectChange = async (selectInputObject: any) => {
        if (selectInputObject.value) {
            setCurrentAccNo(selectInputObject.value);

            requestGetFundingHistoryAPI(selectInputObject.value, inputs);
        } else {
            setTableData([]);
        }
    };

    const requestGetFundingHistoryAPI = async (
        accNo: any,
        searchInputs: any,
        cPage = 1
    ) => {
        setNotiLoading(true);
        console.log(searchInputs);
        let result = await api.getMultiFundingHistory(
            accNo,
            searchInputs,
            cPage
        );
        console.log(result);
        if (result.funds) {
            let funds = result.funds;
            let filterFundsDatas = funds.map(data => ({
                f_timecreate: data.f_timecreate,
                trans_status : {
                    f_transno : data.f_transno,
                    status: data.status,
                },
                type: {
                    mode: data.mode,
                    type: data.type,
                },
                amount: {
                    c_code: data.c_code,
                    f_amount: data.f_amount,
                },
                cheque: {
                    f_refno: data.f_refno,
                    f_chequedate: data.f_chequedate,
                },
                bank: {
                    b1_b_name: data.b1_b_name,
                    b2_b_name: data.b2_b_name
                }
            }));
            console.log(funds);
            console.log(filterFundsDatas);
            setTableData(filterFundsDatas);
            //  updateAccSummaryComponent(account);
        }
        setNotiLoading(false);
    };

    const updateTreeSelect = (result: any) => {
        console.log(result);
        let newArr = parseAPIResultToData(result);
        setUserAccTreeData(newArr);
    };
    //tree select end

    const handleSubmit = (e: any) => {
        e.preventDefault();
    };

    const tblColumns = useMemo(
        () => [
            {
                Header: () => {
                    return (
                        <>
                            <span>Date</span><br/>
                            <span>Time</span>
                        </>
                    );
                },
                id: 'f_timecreate_date',
                accessor: 'f_timecreate',
                Cell: (v: any) => {
                    if (v.value) {
                        return (
                            <>
                                <span>{moment(v.value).format("DD-MM-YYYY")}</span><br />
                                <span>{moment(v.value).format('hh:mm A')}</span>
                            </>
                        );
                    }
                }
            },
            {
                Header:  () => {
                    return (
                        <>
                            <span>Ref. Number</span><br/>
                            <span>Status</span>
                        </>
                    );
                },
                accessor: 'trans_status',
                Cell: (v: any) => {
                    if (v.value) {
                        return (
                            <>
                                <span>{v.value.f_transno}</span><br />
                                <span>{v.value.status}</span>
                            </>
                        );
                    }
                }
            },
            {
                Header: () => {
                    return (
                        <>
                            <span>Trans. Type</span><br/>
                            <span>Funding Type</span>
                        </>
                    );
                },
                accessor: 'type',
                Cell: (v: any) => {
                    if (v.value) {
                        return (
                            <>
                                <span>{v.value.mode}</span><br />
                                <span>{v.value.type}</span>
                            </>
                        );
                    }
                }
            },
            {
                Header: () => {
                    return (
                        <>
                            <span>CCY</span><br/>
                            <span>Amount</span>
                        </>
                    );
                },
                accessor: 'amount',
                Cell: (v: any) => {
                    if (v.value) {
                        return (
                            <>
                                <span>{v.value.c_code}</span><br />
                                <span className="text-right">
                                    {numberWithCommas(v.value.f_amount, 2)}
                                </span>
                            </>
                        );
                    }
                }
            },
            {
                Header: () => {
                    return (
                        <>
                            <span>Cheque No.</span><br/>
                            <span>Cheque Date</span>
                        </>
                    );
                },
                accessor: 'cheque',
                Cell: (v: any) => {
                    if (v.value) {
                        return (
                            <>
                                <span>{v.value.f_refno}</span><br />
                                <span>{v.value.f_chequedate}</span>
                            </>
                        );
                    }
                }
            },
            {
                Header: () => {
                    return (
                        <>
                            <span>Drawee Bank</span><br/>
                            <span>Deposit Bank</span>
                        </>
                    );
                },
                accessor: 'bank',
                Cell: (v: any) => {
                    if (v.value) {
                        return (
                            <>
                                <span>{v.value.b1_b_name}</span><br />
                                <span>{v.value.b2_b_name}</span>
                            </>
                        );
                    }
                }
            }
        ], []
    );

    return (
        <form onSubmit={handleSubmit}>
            <div className="pt-3 text-left ">
                <div className="row">
                    {/* filter */}
                    <div className="col-xxxl-2 col-xl-2 col-lg-3 col-md-4 col-12">
                        <div className="col-12 main-title">History</div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="input-container">
                                <div className="label">
                                    Username / Account No{' '}
                                </div>
                                <UserAccTreeSelect
                                    onChange={(value: string[]) => {
                                        console.log('UserAccTreeSelect', value);
                                        setCurrentAccNo(value);
                                    }}
                                    onChangeUsername={(val: string) =>
                                        setCurrentUsername(val)
                                    }
                                />
                                {/* <TreeSelect
                                    treeMap={['username', 'accNo']}
                                    data={userAccTreeData}
                                    setLoading={treeSelectLoading}
                                    keyinChange={(keywords: any) =>
                                        onTreeKeyinChange(keywords)
                                    }
                                    onSelectChange={(selectInputObject: any) =>
                                        onTreeSelectChange(selectInputObject)
                                    }
                                    onSelectChangeParentValue={(
                                        parentValue: any
                                    ) => {
                                        setCurrentUsername(parentValue || '');
                                    }}
                                /> */}
                            </div>
                        </div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="input-container ">
                                <div className="label">Name</div>
                                <input
                                    id="username"
                                    className="custom border-bottom"
                                    type="text"
                                    value={currentUsername}
                                    // onChange={({ target }) =>
                                    //     onInputChange(target.id, target.value)
                                    // }
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="input-container ">
                                <div className="label">Dealer/ Remisier</div>
                                <input
                                    id="dealer"
                                    className="custom border-bottom"
                                    type="text"
                                    value="Lee Mai Ser (ENV007)"
                                    // value={currentUsername}
                                    // onChange={({ target }) =>
                                    //     onInputChange(target.id, target.value)
                                    // }
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="input-container sideFilter">
                                <div className="label">Date </div>
                                <DateRangePicker
                                    dateStartId="rangeStart"
                                    dateEndId="rangeEnd"
                                    onDateChange={(obj: any) =>
                                        onDateChange(obj)
                                    }
                                />
                                {/* <RangePicker
                                    className="w-100 daterangepicker custom"
                                    format="DD-MM-YYYY"
                                    onChange={(dates, dateStrings) => {
                                        // console.log(dateStrings);
                                        onDateChange({
                                            id: 'rangeStart',
                                            timestamp: dateStrings[0]
                                        });
                                        onDateChange({
                                            id: 'rangeEnd',
                                            timestamp: dateStrings[1]
                                        });
                                    }}
                                /> */}
                            </div>
                        </div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="input-container ">
                                {' '}
                                <div className="label">Transaction Type </div>
                                <FormSelect
                                    id="mode"
                                    value={inputs.mode || ''}
                                    onChange={({ target }: any) =>
                                        onInputChange(target.id, target.value)
                                    }
                                >
                                    <option value={''}>All</option>
                                    {Object.entries(manualTransferType).map(
                                        ([key, value]) => {
                                            return (
                                                <option value={key}>
                                                    {value}
                                                </option>
                                            );
                                        }
                                    )}
                                </FormSelect>
                            </div>
                        </div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="input-container ">
                                {' '}
                                <div className="label">Funding Type </div>
                                <FormSelect
                                    id="type"
                                    value={inputs.type || ''}
                                    onChange={({ target }: any) =>
                                        onInputChange(target.id, target.value)
                                    }
                                >
                                    <option value={''}>All</option>
                                    {Object.entries(fundingType).map(
                                        ([key, value]) => {
                                            return (
                                                <option value={key}>
                                                    {value}
                                                </option>
                                            );
                                        }
                                    )}
                                </FormSelect>
                            </div>
                        </div>
                    </div>
                    {/* filter end div */}

                    {/* body */}
                    <div className="col-xxxl-10 col-xl-10 col-lg-9 col-md-8 col-12">
                        <div className="col-md-12 col-12 pt-3">
                            <ReactTable columns={tblColumns} data={tableData} />
                        </div>
                    </div>
                    {/* body end div*/}
                </div>
            </div>
        </form>
    );
};

export default Index;
