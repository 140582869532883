import { useState, useEffect, useMemo } from 'react';
import '../../asset/styles/research-reposts-style.css';
import { useLocation } from 'react-router-dom';
import api from '../../../apis';
import moment from 'moment';

import { useLoading } from '../../../context/LoadingContext';

import CardList from '../../general/card-list';
import Modal from '../../general/modal';

import TradeWinCashRewardArticle from '../../general/dummy-article/trade-win-cash-reward';
import EventArticle from '../../general/article/eventArticle';
import Datepicker from '../../general/form-control/datepicker';
import Config from '../../../config';

import eventIC1 from '../../asset/images/event/logo_bursa.svg';
import event1 from '../../asset/images/event/1101.png';

import date1 from '../../asset/images/event/date_1.png';
import date2 from '../../asset/images/event/date_2.png';
import date3 from '../../asset/images/event/date_3.png';
import date4 from '../../asset/images/event/date_4.png';
import date5 from '../../asset/images/event/date_5.png';

import DateRangePicker from '../../general/form-control/date-rangepicker';

import useAuth from '../../../hooks/useAuth';

import { DatePicker, Pagination } from 'antd';
const { RangePicker } = DatePicker;

const Index = () => {
    const { state } = useLocation();
    const { setLoading } = useLoading();

    const [modelProps, setModelProps] = useState({});

    const [announcementTypes, setAnnouncementTypes] = useState<any>([]);
    const [annoucementList, setAnnoucementList] = useState([]);

    const [cAnnoucementType, setcAnnoucementType] = useState<any>(null);
    const [dateStart, setDateStart] = useState<any>(null);
    const [dateEnd, setDateEnd] = useState<any>(null);
    const [cTerm, setcTerm] = useState<any>(null);

    const [cPage, setcPage] = useState<any>(1);
    const [totalRecord, setTotalRecord] = useState<any>(10);

    const [inputs, setInputs] = useState({ paymentType: '' });
    const [openModel, setOpenModel] = useState(false);
    
    const { auth, setAuth } = useAuth();
    useEffect(() => {
        const { b_id } = state || {};
        setLoading(true);
        api.getBroadcastType().then((result: any) => {
            if (result) {
                console.log('getBroadcastType', result);

                let announcementTypesArr = [];
                for (const key in result) {
                    let name = result[key];
                    announcementTypesArr.push({
                        key: key,
                        name: name
                    });
                }
                setAnnouncementTypes(announcementTypesArr);
                getAnnoucementList(b_id);
            }
        });
    }, []);

    const getAnnoucementList = (c_b_id = null) => {
        setLoading(true);
        api.getAnnoucementList({
            page: cPage,
            type: parseInt(cAnnoucementType),
            dateStart: dateStart?.date,
            dateEnd: dateEnd?.date,
            term: cTerm?.value
        }).then((result: any) => {
            console.log('getAnnoucementList', result);
            if (result.total) {
                setTotalRecord(result.total);
            }
            if (result.announcements) {
                let arrData = [];
                let list = result.announcements;
                for (var i = 0; i < list.length; i++) {
                    let row = list[i];

                    let title = row.title;
                    let b_publish_date_start = row.b_publish_date_start || null;
                    let description = row.description;
                    let file_thumbnail = row.file_thumbnail
                        ? Config.fileUrl + row.file_thumbnail
                        : event1;

                    let file_content = row.file_content;
                    let htmlfile = Config.fileUrl + file_content;

                    let sourceText = row.source ? row.source + ' • ' : '';
                    let b_id = row.b_id;

                    let obj = {
                        img: file_thumbnail,
                        b_publish_date_start,
                        subTitle: (
                            <>
                                {sourceText +
                                    moment(b_publish_date_start).format(
                                        'DD MMM YYYY'
                                    )}
                            </>
                        ),
                        title: title,
                        file_content: htmlfile,
                        file_type: 'announcement',
                        layout: auth.layout,
                        description
                    };
                    arrData.push(obj);

                    if (b_id == c_b_id) {
                        //selected article
                        setModelProps(obj);
                        setOpenModel(true);
                    }
                }
                setAnnoucementList(arrData);
            }

            setLoading(false);
        });
    };

    useEffect(() => {
        getAnnoucementList();
    }, [cAnnoucementType, cPage]);
    useEffect(() => {
        getAnnoucementList();
    }, [dateEnd]);

    useEffect(() => {
        //typing delay
        const delayDebounceFn = setTimeout(() => {
            if (cTerm) {
                //pass keyin change to parent
                getAnnoucementList();
            }
        }, 700);
        return () => clearTimeout(delayDebounceFn);
    }, [cTerm]);

    //compare changes
    const filterParam = useMemo(() => {
        return { dateStart, dateEnd };
    }, [dateStart, dateEnd]);

    //after update,trigger api
    useEffect(() => {
        console.log(filterParam);
    }, [filterParam]);

    useEffect(() => {
        console.log(inputs);
        // if (currentAccNo) {
        //     requestGetAccountContractAPI(currentAccNo, searchInputs);
        // }
    }, [inputs]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
    };

    const onDateChange = ({ id, timestamp }: any) => {
        let cdate = '';
        if (timestamp) {
            cdate = moment(timestamp, 'DD-MM-YYYY').format('YYYY-MM-DD');
        }

        console.log('id, timestamp', id, timestamp);
        //  onDropdownChange(id, cdate);
        if (id == 'dateStart') {
            setDateStart({ id, date: cdate });
        } else if (id == 'dateEnd') {
            setDateEnd({ id, date: cdate });
        }
    };
    const onTermChange = (id: string, value: string) => {
        setcTerm({ id, value });
    };

    const onDropdownChange = (id: string, newValue: string) => {
        setInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    const rawEventSource = [
        {
            img: date1,
            subTitle: (
                <>
                    <img src={eventIC1} /> Bursa Malaysia • 27 Jul 2022
                </>
            ),
            title: 'Trade & Win Cash Reward of Up to RM153,000 by Bursa Malaysia'
            // desc: 'Gain the latest market insights, engage with corporate leaders from top listed companies, get access to free investment tools to boost your financial planning...'
        },
        {
            img: date2,
            subTitle: (
                <>
                    <img src={eventIC1} /> Bursa Malaysia • 07 Apr 2022
                </>
            ),
            title: 'Bursa Malaysia Invest Shariah Retail Campaign 2022'
            // desc: "Elky is a Dealer's Representative by profession. He has been in the industry since the year 2012, started in Futures & Options, to..."
        },
        {
            img: date3,
            subTitle: (
                <>
                    <img src={eventIC1} />
                    Bursa Malaysia • 16 FEB 2022
                </>
            ),
            title: 'Unrelated Mobile Application'
            // desc: 'Struggling social platform Pinterest has been manifesting a recovery for its share price, and turns out the universe has been listening.'
        },
        {
            img: date4,
            subTitle: (
                <>
                    <img src={eventIC1} /> Bursa Malaysia • 05 FEB 2022
                </>
            ),
            title: 'First Islamic Participating Organisation To Offer Syariah Discre...'
            // desc: 'Gain the latest market insights, engage with corporate leaders from top listed companies, get access to free investment tools to boost your financial planning...'
        },
        {
            img: date5,
            subTitle: (
                <>
                    <img src={eventIC1} /> Bursa Malaysia • 27 JUN 2022
                </>
            ),
            title: 'Trade & Win Cash Reward of Up to RM153,000 by Bursa Malaysia'
            // desc: "Elky is a Dealer's Representative by profession. He has been in the industry since the year 2012, started in Futures & Options, to..."
        }
    ];

    const eventSource = [...rawEventSource, ...rawEventSource];

    const handleArticle = (row: any) => {
        console.log(row);
        setOpenModel((oldValue) => true);
        setModelProps(row);
    };
    const onPaginationChange = (page: any) => {
        setcPage(page);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="pt-3 text-left">
                <div className="row">
                    {/* filter */}
                    <div className="col-xxxl-2 col-xl-2 col-lg-3 col-md-4 col-12">
                        <div className="col-12 main-title">Announcement</div>
                        <div className="col-xxxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            {' '}
                            <div className="input-container ">
                                <div className="label">Announcement Type</div>
                                <select
                                    className="custom"
                                    defaultValue={''}
                                    onChange={(e) => {
                                        setcAnnoucementType(e.target.value);
                                    }}
                                >
                                    <option value={''}>All</option>
                                    {announcementTypes.map((row: any) => {
                                        return (
                                            <option value={row.key}>
                                                {row.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>{' '}
                        <div className="col-xxxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            {' '}
                            <div className="input-container ">
                                <div className="label">Keyword</div>
                                {/* <select
                                    className="custom"
                                    defaultValue={''}
                                ></select> */}
                                <input
                                    id="keyword"
                                    className="custom-keyword "
                                    type="text"
                                    onChange={({ target }) =>
                                        onTermChange(target.id, target.value)
                                    }
                                />
                            </div>
                        </div>{' '}
                        <div className="col-xxxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="input-container sideFilter">
                                <div className="label"> Date </div>
                                <DateRangePicker
                                    onDateChange={(obj: any) =>
                                        onDateChange(obj)
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    {/* body */}
                    <div className="col-xxxl-10 col-xl-10 col-lg-9 col-md-8 col-12">
                        <Modal
                            opened={openModel || false}
                            onClosed={(openModel: boolean) =>
                                setOpenModel(openModel)
                            }
                        >
                            {/* <TradeWinCashRewardArticle /> */}
                            <EventArticle {...modelProps} />
                        </Modal>

                        <div className={'row '}>
                            <CardList
                                data={annoucementList || []}
                                onClick={(row: any) => handleArticle(row)}
                            />
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-center">
                                    {' '}
                                    <Pagination
                                        onChange={onPaginationChange}
                                        defaultCurrent={1}
                                        total={totalRecord}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    );
};

export default Index;
