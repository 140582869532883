import { useState, useLayoutEffect, useMemo } from 'react';
import api from '../../../apis';

import { useParams } from 'react-router-dom';

import CorrectionFrom from '../../forms/eform-data-correction-form';

const Index = () => {
    useLayoutEffect(() => {}, []);

    return <CorrectionFrom />;
};

export default Index;
