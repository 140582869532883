import { useState, useEffect, useRef } from 'react';
import './index.css';

const Index = (props: any) => {
    const [type, setType] = useState<any>(props.type || 'text');
    const [value, setValue] = useState<any>('');

    useEffect(() => {
        if (!props.value) setValue('');
    }, [props.value]);

    return (
        <div className="form-input">
            <input
                id={props.id}
                className={'custom custom-number ' + props.className}
                type={'text'}
                value={value}
                autoComplete="none"
                onChange={(e) => {
                    // if(type)
                    let value = e.target.value.replace(/[^0-9\.]/g, '');
                    if (value.length > 21) return;

                    var parts = value.toString().split('.');
                    let numberPart = parts[0].replace(/^0+/, '');
                    let decimalPart = parts[1];
                    let oriNumber = numberPart;
                    const thousands = /\B(?=(\d{3})+(?!\d))/g;
                    let newVal = numberPart.replace(thousands, ',');

                    if (value.indexOf('.') > -1) {
                        newVal += '.';
                        oriNumber += '.';

                        decimalPart = decimalPart
                            ? decimalPart.substring(0, 2) //limit to 2 decimal places
                            : '';
                        newVal += decimalPart;
                        oriNumber += decimalPart;
                    }

                    // let nvalue = Number(value);
                    if (value == '0' || value == '..') {
                        newVal = '0';
                        oriNumber = '0';
                    }

                    //fill in comma
                    e.target.value = newVal;
                    // alert(decimalPart);
                    setValue(newVal);
                    if (props.onChange) {
                        props.onChange({
                            target: {
                                id: e.target.id,
                                value: oriNumber
                            }
                        });
                    }
                }}
            />
            <div className="input-error">{props.error || ''}</div>
        </div>
    );
};

export default Index;
