import { useState, useEffect, useMemo } from 'react';

import api from '../../../apis';
import FormInput from '../../general/form-control/form-item/input';

import { useLoading } from '../../../context/LoadingContext';

import { Modal } from 'antd';

const Index = (props: any) => {
    const { setLoading } = useLoading();

    useEffect(() => {}, []);

    const [inputs, setInputs] = useState<any>({});
    const [errors, setErrors] = useState<any>({});

    //validation settings
    const [rules, setRules] = useState<any>({
        email: {
            required: true,
            type: 'email',
            error_message: 'Please input email'
        },
        nric: {
            required: true,
            error_message: 'Please input nric'
        }
    });

    const onInputChange = (id: string, newValue: string) => {
        setInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    const formValidation = (rules: any, inputs: any) => {
        if (!rules) return { valid: true, errors: {} };

        let valid = true;
        let errors: any = {};

        //validate rule
        for (var key in rules) {
            if (rules.hasOwnProperty(key)) {
                let item = rules[key];
                if (item.required == true && !inputs[key]) {
                    let error = item.error_message || 'Required';
                    errors[key] = error;
                    valid = false;
                } else if (item.requireIfExist) {
                    //if specific input exist,current input become required
                    let requireIfExist = item.requireIfExist;
                    let relatedkey = requireIfExist.key;
                    let relatedValue = requireIfExist.value;

                    //match type start check error
                    if (
                        relatedkey &&
                        relatedValue &&
                        inputs[relatedkey] &&
                        inputs[relatedkey] == relatedValue
                    ) {
                        if (!inputs[key]) {
                            let error = item.error_message || 'Required';
                            errors[key] = error;
                            valid = false;
                        }
                    }
                } else {
                    if (item.type == 'email') {
                        let value = inputs[key];
                        if (
                            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                value
                            )
                        ) {
                            valid = false;
                            errors[key] = 'Please enter valid email address';
                        }
                    }
                } //match value with

                if (item.same_value && inputs[key]) {
                    let same_value = item.same_value;

                    if (inputs[key] != inputs[same_value]) {
                        let error = item.same_value_error_message || 'Required';
                        errors[key] = error;
                        valid = false;
                    }
                } else {
                }

                // requireIfExist: { key: 'mode', value: 2 }
            }
        }

        return {
            valid,
            errors
        };
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        console.log('inputs', inputs);

        setLoading(true);
        let { valid, errors } = formValidation(rules, inputs);
        setErrors(errors);
        console.log(errors);
        if (valid == false) {
            //scroll to first error
            if (Object.keys(errors).length > 0) {
                let firstKey = Object.keys(errors)[0];
                let element = document.getElementById(firstKey);
                element?.scrollIntoView();
            }
            setLoading(false);
        } else {
            setLoading(true);

            let result = await api.sessForgetPassword(inputs);
            if (result.status == 0) {
                // alert('Password changed successfully');
                if (props.setModelOpen) props.setModelOpen(false);

                alert('Thank you! Please check your email.');
            } else if (result.status == 1) {
                let message = result?.message;

                if (message) {
                    if (typeof message === 'string') {
                        alert(message);
                    } else {
                        let text = '';
                        for (var key in message) {
                            if (message.hasOwnProperty(key)) {
                                let sentence = message[key];
                                if (key == 'new_password') {
                                    sentence = sentence.replace(
                                        '"new_password"',
                                        'New password'
                                    );
                                }
                                text += sentence + '\n';
                            }
                        }
                        alert(text);
                    }
                }
            }
            console.log('changePassword', result);

            setLoading(false);
            //submit to api
            // let contracts = handlePendingContracts(selectedRows);

            // if (contracts.length == 0) {
            //     alert('must select at least 1 item');
            // } else {
            //     let result = await api.submitMultiSettlement({
            //         bank: inputs.bank_list,
            //         contracts
            //     });
            //     console.log('result');
            //     console.log(result);

            //     if (result?.data?.fpx) {
            //         let fpx_url = result.data.fpx;
            //         window.open(fpx_url, '_blank', 'noopener,noreferrer');
            //         alert('Thank you, the form was submitted successfully');
            //         navigate(0);
            //         //requestGetAccountContractAPI(currentAccNo, inputs);
            //     }
            // }
        }

        // let result = await api.submitDepositManualBulk(currentAccNo, {
        //     pendingList
        // });
        // console.log('handleSubmit');
        // console.log(result);
        // if (result.status === 0) {
        //     alert('Thank you, the form was submitted successfully');
        //     //refresh page
        //     navigate(0);
        // } else {
        //     alert('submit failed');
        // }
    };

    return (
        <>
            {' '}
            <Modal
                title=""
                maskClosable={false}
                open={true}
                okText="Submit"
                onOk={async (e) => handleSubmit(e)}
                // okButtonProps={{ style: { display: 'none' } }}
                // cancelText="Close"
                onCancel={() => {
                    if (props.setModelOpen) props.setModelOpen(false);
                }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <form>
                    <div className="row ">
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <h5>Forgot password</h5>
                        </div>
                    </div>
                    <div className="row pt-3">
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div>
                                We will send you instrctions on how to reset
                                your password by email.
                            </div>
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="form-input-container ">
                                <div className="label">Email:</div>
                                <FormInput
                                    id="email"
                                    type="email"
                                    value={inputs.email || ''}
                                    onChange={({ target }: any) =>
                                        onInputChange(target.id, target.value)
                                    }
                                    error={errors.email}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="form-input-container ">
                                <div className="label">Nric:</div>
                                <FormInput
                                    id="nric"
                                    type="text"
                                    value={inputs.nric || ''}
                                    onChange={({ target }: any) =>
                                        onInputChange(target.id, target.value)
                                    }
                                    error={errors.nric}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default Index;
