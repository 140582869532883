export function parseAPIResultToData(result: any) {
    let newArr = [];
    let parentsKeys = [];
    let mapping = {};

    result.forEach(function (item, i) {
        let row = {
            // props: {
            //     value: item.p_fullname,
            //     label: item.p_fullname
            // }
            title: item.e_name,
            value: item.e_name,
            // key: item.e_id,
            checkable: false
        };

        parentsKeys.push(item.e_name);

        if (!mapping[item.e_name]) {
            mapping[item.e_name] = item.e_id;
        }

        newArr.push(row);
    });

    return {
        data: newArr,
        parentsKeys: parentsKeys,
        mapping
    };
}
