import { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import api from '../../../apis';
import { useLoading } from '../../../context/LoadingContext';

import ReactTable from '../../general/form-control/react-table';
import TreeSelect from '../../general/form-control/tree-select';

import { parseAPIResultToData } from '../../general/form-control/tree-select/helper';

const Index = () => {
    const { setLoading } = useLoading();

    const [statementTypeList, setStatementTypeList] = useState<any>([]);
    const [currentAccNo, setCurrentAccNo] = useState('');
    const [currentUsername, setCurrentUsername] = useState('');

    const [year, setYear] = useState('2022');

    const [userAccTreeData, setUserAccTreeData] = useState<any>([]);
    const [treeSelectLoading, setTreeSelectLoading] = useState<boolean>(false);

    const [inputs, setInputs] = useState({ paymentType: '' });

    const [tableData, setTableData] = useState<any>([]);

    useEffect(() => {
        api.getEstatementTypes().then((result: any) => {
            setStatementTypeList(result);
        });
    }, []);

    //compare changes
    const filterParam = useMemo(() => {
        return { year };
    }, [year]);

    //after update,trigger api
    useEffect(() => {
        console.log(filterParam);
        requestGetEstatementList();
    }, [filterParam]);

    useEffect(() => {
        requestGetEstatementList();
    }, [currentAccNo]);

    const requestGetEstatementList = () => {
        if (currentAccNo) {
            setLoading(true);
            api.getEstatementList(currentAccNo, filterParam).then(
                (result: any) => {
                    setLoading(false);
                    console.log('result', result);
                    if (result) {
                        handleEstatementTable(result);
                    }
                }
            );

            // /1665063885680-a30d62b6-e1ae-4ce0-b200-ac223276c3c6.pdf
        }
    };

    //tree select start
    const onTreeKeyinChange = (keywords: any) => {
        // console.log('onTreeKeyinChange');
        // console.log(keywords);
        setTreeSelectLoading(true);
        if (keywords.value || keywords.value == '')
            api.searchClientNameAccount(keywords.value).then((result: any) => {
                console.log(result);
                setTreeSelectLoading(false);
                if (result.clients) updateTreeSelect(result.clients);
            });
    };

    const onTreeSelectChange = async (selectInputObject: any) => {
        if (selectInputObject.value) {
            setCurrentAccNo(selectInputObject.value);

            requestGetAccountContractAPI(selectInputObject.value, inputs);
        } else {
            setTableData([]);
        }
    };

    const requestGetAccountContractAPI = async (
        accNo: any,
        searchInputs: any
    ) => {
        setLoading(true);
        console.log(searchInputs);
        let result = await api.getAccountContra(accNo, searchInputs);
        console.log(result);
        if (result.contra) {
            let contra = result.contra;
            console.log(contra);
            //setTableData(contra);
            //  updateAccSummaryComponent(account);
        }
        setLoading(false);
    };

    const updateTreeSelect = (result: any) => {
        console.log(result);
        let newArr = parseAPIResultToData(result);
        setUserAccTreeData(newArr);
    };
    //tree select end

    const handleSubmit = (e: any) => {
        e.preventDefault();
    };

    const handleEstatementTable = (data: any) => {
        let result = [];
        for (var i = 0; i < data.length; i++) {
            let row = data[i];
            // es_date: '2021-12-31T00:00:00+0800', es_filepath: '1665063885680-a30d62b6-e1ae-4ce0-
            let es_date = row.es_date;
            let es_filepath = row.es_filepath;

            let obj = {
                month: (
                    <a
                        href=""
                        onClick={(e) => {
                            e.preventDefault();
                            return window.open(
                                './file/' + currentAccNo + '/' + es_filepath,
                                '_blank',
                                'noopener,noreferrer'
                            );
                        }}
                    >
                        {/* {es_date} */}
                        {moment(es_date).format('MMM YYYY')}
                    </a>
                )
            };
            result.push(obj);
        }
        setTableData(result);
    };

    const tblColumns = useMemo(
        () => [
            {
                Header: 'Month',
                accessor: 'month'
            }
        ],
        []
    );

    const dummyData = [
        {
            month: <a href="">Jun 2022</a>
        },
        {
            month: <a href="">May 2022</a>
        },
        {
            month: <a href="">Apr 2022</a>
        },
        {
            month: <a href="">Mar 2022</a>
        },
        {
            month: <a href="">Feb 2022</a>
        },
        {
            month: <a href="">Jan 2022</a>
        },
        {
            month: <a href="">Dec 2021</a>
        },
        {
            month: <a href="">Nov 2021</a>
        },
        {
            month: <a href="">Oct 2021</a>
        },
        {
            month: <a href="">Sep 2021</a>
        }
    ];

    return (
        <form onSubmit={handleSubmit}>
            <div className="pt-3 text-left ">
                <div className="row">
                    {/* filter */}
                    <div className="col-xxxl-2 col-xl-2 col-lg-3 col-md-4 col-12">
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            {' '}
                            <div className="input-container">
                                <div className="label">
                                    Username / Account No{' '}
                                </div>
                                <TreeSelect
                                    treeMap={['username', 'accNo']}
                                    data={userAccTreeData}
                                    setLoading={treeSelectLoading}
                                    keyinChange={(keywords: any) =>
                                        onTreeKeyinChange(keywords)
                                    }
                                    onSelectChange={(selectInputObject: any) =>
                                        onTreeSelectChange(selectInputObject)
                                    }
                                    onSelectChangeParentValue={(
                                        parentValue: any
                                    ) => {
                                        setCurrentUsername(parentValue || '');
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            {' '}
                            <div className="input-container ">
                                <div className="label">Name </div>
                                <input
                                    id="dealer"
                                    className="custom border-bottom"
                                    type="text"
                                    value={currentUsername}
                                    // value={currentUsername}
                                    // onChange={({ target }) =>
                                    //     onInputChange(target.id, target.value)
                                    // }
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            {' '}
                            <div className="input-container ">
                                <div className="label">Dealer / Remisier </div>
                                <input
                                    id="dealer"
                                    className="custom border-bottom"
                                    type="text"
                                    value="Lee Mai Ser (ENV007)"
                                    // value={currentUsername}
                                    // onChange={({ target }) =>
                                    //     onInputChange(target.id, target.value)
                                    // }
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            {' '}
                            <div className="input-container ">
                                <div className="label">Statement Type </div>
                                <select className="custom" defaultValue={''}>
                                    {' '}
                                    {Object.entries(statementTypeList).map(
                                        ([key, value]) => {
                                            return (
                                                <option value={key}>
                                                    {value}
                                                </option>
                                            );
                                        }
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="input-container ">
                                <div className="label">Year </div>
                                <select
                                    className="custom"
                                    defaultValue={2022}
                                    onChange={(e: any) =>
                                        setYear(e.target.value)
                                    }
                                >
                                    <option>2022</option>
                                    <option>2021</option>
                                    <option>2020</option>
                                    <option>2019</option>
                                    <option>2018</option>
                                </select>
                            </div>
                        </div>
                        {/* <div className="col-xxl-3 col-lg-5 col-md-6 col-12 ">
                            {' '}
                            <div className="input-container ">
                                <div className="label">Name </div>
                                <input
                                    id="name"
                                    className="custom border-bottom"
                                    type="text"
                                    value={currentUsername}
                                    // onChange={({ target }) =>
                                    //     onInputChange(target.id, target.value)
                                    // }
                                    disabled
                                />
                            </div>
                        </div> */}
                    </div>
                    {/* filter end div */}

                    {/* body */}
                    <div className="col-xxxl-10 col-xl-10 col-lg-9 col-md-8 col-12">
                        {/* <hr className="mt-4 mb-4" /> */}
                        <div className="col-12 main-title">eStatement</div>
                        <div className="row">
                            <hr />
                        </div>
                        <div className="col-12">
                            <b className="dark-text-white">Download PDF</b>
                        </div>
                        {/* Download PDF table */}
                        <div className="col-md-4 col-12 pt-3">
                            <ReactTable
                                columns={tblColumns}
                                data={tableData}
                                // pageSize={10}
                                // currentPage={1}
                                // totalRecords={10}
                                // hasPagination
                            />
                        </div>
                        {/* ....... */}
                    </div>
                    {/* body end div*/}
                </div>
            </div>
        </form>
    );
};

export default Index;
