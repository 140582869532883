import { createRef, useRef, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoading } from '../../../context/LoadingContext';
import sidebarSource from '../../../components/navigation/nav-config';
import { useCookies } from 'react-cookie';
import api from '../../../apis';

const Index = (props:any) => {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const navigate = useNavigate();
    const { setLoading } = useLoading();

    useEffect(() => {}, []);
    const [mobileSideNavPad, setMobileSideNavPad] = useState(0);
    const [openMobileSideNav, setOpenMobileSideNav] = useState(
        props.openMobileSideNav
    );
    useEffect(() => {
        //set mobile view padding top
        var elmnt = document.querySelector('.header-container') as HTMLElement;
        setMobileSideNavPad(elmnt.offsetHeight);
    }, []);

    useEffect(() => {
        setOpenMobileSideNav(props.openMobileSideNav);
    }, [props.openMobileSideNav]);


    useEffect(() => {
        if(props.onStatusChange)
        {   
            props.onStatusChange(openMobileSideNav)
        }
    }, [openMobileSideNav]);

    const redirect = (url: string) => {
        if (url) navigate(url);
    };

    const handleLogout = async () => {
        await setLoading(true);
        let result = await api.userLogout();

        if (result == true) {
            removeCookie('user', { path: '/' });
            navigate('/');
        }
        setLoading(false);
    };

    const mobileMenuRef = useRef<HTMLLIElement | null>(null);
    //click on outside hide menu
    useEffect(() => {
        
        // Bind the event listener
        document.addEventListener('mousedown', handleOutsideClicks);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleOutsideClicks);
        };
    }, []);

           //create a function in your component to handleOutsideClicks
    const handleOutsideClicks = (event: any) => {
        if (
      
            mobileMenuRef.current &&
            !mobileMenuRef.current.contains(event.target)
        ) {
            setOpenMobileSideNav(false)
        }

        // console.log(mobileMenuRef.current.contains(event.target))
    };

    return (
        <div
       
            style={{
                top: mobileSideNavPad + 'px',
                paddingBottom: mobileSideNavPad - 15 + 'px'
            }}
            className={
                'mobile-side-nav ' + (openMobileSideNav == true ? 'show' : '')
            }
        >
            <div
            
                style={{
                    overflow: 'auto',
                    position: 'relative',
                    height: '100%',
                    direction: 'rtl'
                }}
            >
                <div
                    style={{
                        direction: 'ltr'
                    }}
                >
                    <ul
                         ref={mobileMenuRef}
                        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
                        id="accordionSidebar"
                    >
                        {sidebarSource.map(function (row, i) {
                            let className = 'nav-item';
                            let hasSub = false;

                            if (row.selected == true)
                                className = 'nav-item active';

                            if (row.subMenu && row.subMenu.length > 0)
                                hasSub = true;

                            return (
                                <li
                                    key={i}
                                    className={className}
                                    onClick={(e) =>
                                        hasSub ? null : redirect(row.url)
                                    }
                                >
                                    <MobileMenuOption data={row} />
                                </li>
                            );
                        })}
                        <li className="pt-5 pb-5">
                            <button
                                className="btn btn-light"
                                onClick={() => handleLogout()}
                            >
                                Logout
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

const MobileMenuOption = (props:any) => {
    const subMenuRef = useRef<HTMLLIElement | null>(null);
    const navigate = useNavigate();
    const [expand, setExpand] = useState(false);

    const data = props.data;
    const redirect = (url: string) => {
        if (url) navigate(url);
        setExpand(false);
    };



    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleSubOutsideClicks);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleSubOutsideClicks);
        };
    }, []);

    const handleSubOutsideClicks = (event: any) => {
        if (
           
            subMenuRef.current &&
            !subMenuRef.current.contains(event.target)
        ) {
            setExpand(false)
        }

        // console.log(mobileMenuRef.current.contains(event.target))
    };


    return (
        <>
            <a className="nav-link" onClick={() => setExpand(!expand)}>
                <img src={data.icon} />
                <span>{data.label}</span>
            </a>
            <div       ref={subMenuRef}>
            {data && data.subMenu && (
                <div
           
                 
                    id={data.label}
                    className={'collapse  ' + (expand == true ? ' show ' : ' ')}
                    aria-labelledby=""
                    data-parent="#accordionSidebar"
                >
                    <div className="bg-white py-2 collapse-inner rounded"    >
                        {data.subMenu.map((item, i) => {
                            return (
                                <a
                                    key={i}
                                    className="collapse-item"
                                    onClick={(e) => redirect(item.url)}
                                >
                                    {item.label}
                                </a>
                            );
                        })}
                    </div>
                </div>
            )}
            </div>
        </>
    );
};

export default Index;
