import { createContext, useLayoutEffect, useState, useEffect } from 'react';
import { axiosInstance } from '../apis/axios';
import moment from 'moment';
import api from '../apis';

import { useCookies } from 'react-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLoading } from './LoadingContext';
import { Modal } from 'antd';

// import { showNotiLoaderError } from '../components/general/antd-loader';
// import { WarningTwoTone } from '@ant-design/icons';
// import Config from '../config';

//handle user login status
const LoginStatusContext = createContext({});

const heartBeatInterval = 5000; //trigger every 5 sec;
let timer = null;

/////////////////////
//      this timer will keep check current login status, if token failed then logout user
///////////////////////
const WithCheckLoginStatus = ({ children }: any) => {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);

    useEffect(() => {
        return () => clearInterval(timer); // Clean up the interval on unmount
    }, []);

    //if logined
    useEffect(() => {
        if(cookies.user){
            startTimer();
        }else{
            clearInterval(timer);
        }
    }, [cookies]);

    const startTimer = () =>{
        clearInterval(timer);
        timer = setInterval(() => {
            if(cookies.user){
                checkHearBeat();
            }
        }, heartBeatInterval);
    }

    const checkHearBeat = async()=>{
        //check WithAxios.js for result
        let result = await api.getSessAck();
    }

    return (
        <LoginStatusContext.Provider value={{  }}>
            {/* {timeoutModal == true && (
                <Modal
                    title={
                        <div className="d-flex">
                            <WarningTwoTone
                                twoToneColor="#ed0d0d"
                                style={{
                                    fontSize: '16px',
                                    paddingTop: '2px'
                                }}
                            />
                            <div>Timeout Warning</div>
                        </div>
                    }
                    maskClosable={false}
                    keyboard={false}
                    closable={false}
                    open={timeoutModal}
                    okText="Stay logged on"
                    onOk={handleSessAck}
                    cancelText="Log off"
                    onCancel={handleLogout}
                >
                    <p>
                        You will automatically be logged off in {expiredTimer}{' '}
                        seconds
                    </p>
                </Modal>
            )} */}

            {children}
        </LoginStatusContext.Provider>
    );
};

export default WithCheckLoginStatus;
