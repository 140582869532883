import { useState, useEffect, useRef } from 'react';
import './index.css';

const Index = (props: any) => {
    let data = props.data || [];

    const [checkboxes, setCheckboxes] = useState<any>({});
    useEffect(() => {
        if (!props.id) {
            alert('checkbox group missing id');
        }
    }, []);

    useEffect(() => {
        // alert('here');
        // alert(JSON.stringify(checkboxes));
        let newArr = [];
        for (var key of Object.keys(checkboxes)) {
            if (checkboxes[key] == true) {
                newArr.push(key);
            }
        }
        console.log('newArr');
        console.log(newArr);
        if (props.onChange) {
            props.onChange(props.id, newArr);
        }
    }, [checkboxes]);

    const handleOnchange = (e: any) => {
        // alert(e.target.checked);
        setCheckboxes((prev: any) => {
            return { ...prev, [e.target.value]: e.target.checked };
        });

        // if(checkboxes)
        // {
        //     for()
        // }

        // setValue(e.target.value);
        // if (props.onChange) {
        //     props.onChange(props.id, e.target.value);
        // }
    };

    let itemClass = props.itemClass || ' col-xxxl-4 col-xl-4 col-lg-6 col-12 ';
    let specialInputClass =
        props.specialInputClass || ' col-xxxl-8 col-xl-8 col-lg-6 col-12 ';

    return (
        <div className="form-input">
            <div className="checkboxes-group-container ">
                <div className="row">
                    {' '}
                    {data.length > 0 &&
                        data.map((item: any, i: number) => {
                            return (
                                <div
                                    className={
                                        '' +
                                        (item.inputText == true
                                            ? specialInputClass
                                            : itemClass)
                                    }
                                >
                                    {' '}
                                    <div className="d-flex align-items-center gap-3  pt-2 pb-2 ">
                                        <div className="d-flex ">
                                            <input
                                                className="custom-checkbox "
                                                // name="chkRed"
                                                type="checkbox"
                                                value={item.value || ''}
                                                onChange={(e) =>
                                                    handleOnchange(e)
                                                }
                                            />
                                        </div>
                                        <div className="label">
                                            {item.label || ''}
                                        </div>
                                        {item.inputText == true && (
                                            <div className="d-flex align-items-center specify-input">
                                                (specify:
                                                <input
                                                    id=""
                                                    style={{
                                                        height: '30px'
                                                    }}
                                                    type="text"
                                                    // value={currentUsername}
                                                    className="custom border-bottom w-auto"
                                                    // onChange={({ target }) =>
                                                    //     onInputChange(target.id, target.value)
                                                    // }
                                                />
                                                )
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
            <div className="input-error">{props.error || ''}</div>
        </div>
    );
};

export default Index;
