import './index.css';
import useAuth from '../../../hooks/useAuth';

const Index = (props: any) => {
    const { auth, setAuth } = useAuth();

    return (
        <div
            className={
                'loader-container ' +
                (props.display == true ? ' active ' : ' close ') +
                (auth.mode == 'dark' ? ' dark ' : '  ')
            }
        >
            <div className="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Index;
