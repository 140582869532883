import { useState, useEffect, useMemo } from 'react';

import api from '../../../apis';
import moment from 'moment';

import FormInput from '../../general/form-control/form-item/input';
import useAuth from '../../../hooks/useAuth';

import { useLoading } from '../../../context/LoadingContext';

import { Modal } from 'antd';

const Index = (props) => {
    const { setLoading } = useLoading();
    const { auth, setAuth } = useAuth();
    useEffect(() => {}, []);

    const [inputs, setInputs] = useState<any>({});
    const [errors, setErrors] = useState<any>({});

    //validation settings
    const [rules, setRules] = useState<any>({
        old_password: {
            required: true,
            error_message: 'Please input current password'
        },
        new_password: {
            required: true,
            error_message: 'Please input new password'
        },
        re_new_password: {
            required: true,
            same_value: 'new_password',
            same_value_error_message:
                'New password and confirm password not match',
            error_message: 'Please input confirm password'
        }
    });

    const onInputChange = (id: string, newValue: string) => {
        setInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    const formValidation = (rules: any, inputs: any) => {
        if (!rules) return { valid: true, errors: {} };

        let valid = true;
        let errors: any = {};

        //validate rule
        for (var key in rules) {
            if (rules.hasOwnProperty(key)) {
                let item = rules[key];
                if (item.required == true && !inputs[key]) {
                    let error = item.error_message || 'Required';
                    errors[key] = error;
                    valid = false;
                } else if (item.requireIfExist) {
                    //if specific input exist,current input become required
                    let requireIfExist = item.requireIfExist;
                    let relatedkey = requireIfExist.key;
                    let relatedValue = requireIfExist.value;

                    //match type start check error
                    if (
                        relatedkey &&
                        relatedValue &&
                        inputs[relatedkey] &&
                        inputs[relatedkey] == relatedValue
                    ) {
                        if (!inputs[key]) {
                            let error = item.error_message || 'Required';
                            errors[key] = error;
                            valid = false;
                        }
                    }
                } //match value with
                if (item.same_value && inputs[key]) {
                    let same_value = item.same_value;

                    if (inputs[key] != inputs[same_value]) {
                        let error = item.same_value_error_message || 'Required';
                        errors[key] = error;
                        valid = false;
                    }
                } else {
                }

                // requireIfExist: { key: 'mode', value: 2 }
            }
        }

        return {
            valid,
            errors
        };
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        console.log('inputs', inputs);

        setLoading(true);
        let { valid, errors } = formValidation(rules, inputs);
        setErrors(errors);
        console.log(errors);
        if (valid == false) {
            //scroll to first error
            if (Object.keys(errors).length > 0) {
                let firstKey = Object.keys(errors)[0];
                let element = document.getElementById(firstKey);
                element?.scrollIntoView();
            }
            setLoading(false);
        } else {
            setLoading(true);

            let result = await api.changePassword(inputs);
            if (result.status == 0) {
                alert('Password changed successfully');
                if (props.setModelOpen) props.setModelOpen(false);
            } else if (result.status == 1) {
                let message = result?.message;

                if (message) {
                    if (typeof message === 'string') {
                        alert(message);
                    } else {
                        let text = '';
                        for (var key in message) {
                            if (message.hasOwnProperty(key)) {
                                let sentence = message[key];
                                if (key == 'new_password') {
                                    sentence = sentence.replace(
                                        '"new_password"',
                                        'New password'
                                    );
                                }
                                text += sentence + '\n';
                            }
                        }
                        alert(text);
                    }
                }
            }
            console.log('changePassword', result);

            setLoading(false);
            //submit to api
            // let contracts = handlePendingContracts(selectedRows);

            // if (contracts.length == 0) {
            //     alert('must select at least 1 item');
            // } else {
            //     let result = await api.submitMultiSettlement({
            //         bank: inputs.bank_list,
            //         contracts
            //     });
            //     console.log('result');
            //     console.log(result);

            //     if (result?.data?.fpx) {
            //         let fpx_url = result.data.fpx;
            //         window.open(fpx_url, '_blank', 'noopener,noreferrer');
            //         alert('Thank you, the form was submitted successfully');
            //         navigate(0);
            //         //requestGetAccountContractAPI(currentAccNo, inputs);
            //     }
            // }
        }

        // let result = await api.submitDepositManualBulk(currentAccNo, {
        //     pendingList
        // });
        // console.log('handleSubmit');
        // console.log(result);
        // if (result.status === 0) {
        //     alert('Thank you, the form was submitted successfully');
        //     //refresh page
        //     navigate(0);
        // } else {
        //     alert('submit failed');
        // }
    };

    return (
        <>
            {' '}
            <Modal
                title=""
                className={auth.mode == 'dark' ? ' dark ' : ' '}
                maskClosable={false}
                open={true}
                okText="Change Password"
                onOk={
                    async (e) => handleSubmit(e)
                    //      {
                    //     console.log(inputs);
                    //     //setOpenDetailModal(false);
                    // }
                }
                // okButtonProps={{ style: { display: 'none' } }}
                // cancelText="Close"
                onCancel={() => {
                    if (props.setModelOpen) props.setModelOpen(false);
                    // setOpenDetailModal(false);
                }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <form>
                    <div className="row ">
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <h5>Change Password</h5>
                        </div>
                    </div>
                    <div className="row pt-3">
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div>
                                Use this form to change your password. Once
                                changed, your new password will be in effect
                                nect time you login
                            </div>
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="form-input-container ">
                                <div className="label">Current Password:</div>
                                <FormInput
                                    id="old_password"
                                    type="password"
                                    value={inputs.old_password || ''}
                                    onChange={({ target }: any) =>
                                        onInputChange(target.id, target.value)
                                    }
                                    error={errors.old_password}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="form-input-container ">
                                <div className="label">New Password:</div>
                                <FormInput
                                    id="new_password"
                                    type="password"
                                    value={inputs.new_password || ''}
                                    onChange={({ target }: any) =>
                                        onInputChange(target.id, target.value)
                                    }
                                    error={errors.new_password}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="form-input-container ">
                                <div className="label">Confirm Password:</div>
                                <FormInput
                                    id="re_new_password"
                                    type="password"
                                    value={inputs.re_new_password || ''}
                                    onChange={({ target }: any) =>
                                        onInputChange(target.id, target.value)
                                    }
                                    error={errors.re_new_password}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default Index;
