import { handleRequestPost, handleRequestGet } from '../axios';
import {
    parseResult,
    parseSubmitResult,
    queryParamStr,
    queryParamStrArr
} from './GeneralFunc';

// export const getClientProfile = async (a_id = null) => {
//     let url = '/client/account/' + a_id + '/profile';
//     let result = await handleRequestGet(url, {});
//     return parseResult(result);
// };

export const validateManualDeposit = async (
    accountid: number,
    {
        mode, //manual deposit
        datetime,
        amount,
        currency,
        deposit_bank,
        deposit_bank_branch,
        file,
        drawee_bank,
        cheque_no = '',
        cheque_date = ''
    }: any
) => {
    // let param = {
    //     mode: 2,
    //     datetime: '2022-01-01 23:59:59',
    //     amount: 1,
    //     currency: 1,
    //     deposit_bank: 2,
    //     deposit_bank_branch: 'KL',
    //     file: '1665038783820-42c70ca8-4493-4b8d-94ba-00326cdf1e07.jpg',
    //     drawee_bank: 2,
    //     cheque_no: 'CH00001',
    //     cheque_date: '2022-01-01'
    // };

    let url = '/client/account/' + accountid + '/fund/deposit/manual/validate';
    let param = {};
    if (mode == 2) {
        //cheque
        param = {
            mode: Number(mode),
            datetime,
            amount: Number(amount),
            currency,
            deposit_bank: Number(deposit_bank),
            deposit_bank_branch,
            file,
            drawee_bank: Number(drawee_bank),
            cheque_no,
            cheque_date
        };
    } else if (mode == 3) {
        //cash
        param = {
            mode: Number(mode),
            datetime,
            amount: Number(amount),
            currency,
            deposit_bank: Number(deposit_bank),
            deposit_bank_branch,
            file
        };
    }

    console.log(param);
    let result = await handleRequestPost(url, param);
    console.log('validateManualDeposit response');
    console.log(result);

    return parseSubmitResult(result);
};

export const submitFPX = async (
    accountid: string,
    { bank, amount, currency = 1 }: any
) => {
    amount = Number(amount);

    let url = '/client/account/' + accountid + '/fund/deposit/fpx';
    let result = await handleRequestPost(url, { bank, amount, currency });

    return parseSubmitResult(result);
};

export const submitDepositManualBulk = async (
    accountid: string,
    { pendingList }: any
) => {
    let funds = [];
    for (var i = 0; i < pendingList.length; i++) {
        let row = pendingList[i];
        if (row.mode == 2) {
            //cheque
            funds.push({
                mode: Number(row.mode),
                datetime: row.datetime,
                amount: Number(row.amount),
                currency: row.currency || 1,
                deposit_bank: Number(row.deposit_bank),
                deposit_bank_branch: row.deposit_bank_branch,
                file: row.file,
                drawee_bank: Number(row.drawee_bank),
                cheque_no: row.cheque_no,
                cheque_date: row.cheque_date
            });
        } else if (row.mode == 3) {
            //cash
            funds.push({
                mode: Number(row.mode),
                datetime: row.datetime,
                amount: Number(row.amount),
                currency: row.currency || 1,
                deposit_bank: Number(row.deposit_bank),
                deposit_bank_branch: row.deposit_bank_branch,
                file: row.file
            });
        }
    }

    let url = '/client/account/' + accountid + '/fund/deposit/manual/bulk';
    let result = await handleRequestPost(url, { funds });

    console.log('submitDepositManualBulk');
    console.log(result);
    return parseSubmitResult(result);

    // example param
    // {
    //     "funds": [
    //         {
    //             "mode": 2,
    //             "datetime": "2022-01-01 23:59:59",
    //             "amount": 1,
    //             "currency": 1,
    //             "deposit_bank": 2,
    //             "deposit_bank_branch": "KL",
    //             "file": "1663806992865-6524536a-7a4b-4f47-af1d-5f7d18996f6c.pdf",
    //             "drawee_bank": 2,
    //             "cheque_no": "CH00001",
    //             "cheque_date": "2022-01-01"
    //         },

    //         {
    //             "mode": 3,
    //             "datetime": "2022-01-01 23:59:59",
    //             "amount": 1,
    //             "currency": 3,
    //             "deposit_bank": 3,
    //             "deposit_bank_branch": "KL",
    //             "file": "1663806992865-6524536a-7a4b-4f47-af1d-5f7d18996f6c.pdf"
    //         }
    //     ]
    // }
};

export const submitWithdrawal = async (
    accountid: string,
    { amount, currency = 1 }: any
) => {
    let url = '/client/account/' + accountid + '/fund/withdrawal';
    let result = await handleRequestPost(url, { amount, currency });
    console.log('validateManualDeposit response');
    console.log(result);

    return parseSubmitResult(result);
};

export const submitSettlement = async (
    accountid: string,
    { bank, contracts, currency = 1 }: any
) => {
    let url = '/client/account/' + accountid + '/fund/settlement';
    let result = await handleRequestPost(url, { bank, contracts, currency });
    return parseSubmitResult(result);
};

export const getFundingHistory = async (
    a_id = null,
    {
        //type=3&mode=1&status=1&size=10&page=1
        type = null,
        types = null, //[]
        mode = null,
        modes = null, //[]
        status = null,
        statuses = null, //[]
        rangeStart = null,
        rangeEnd = null,
        size = 10
    },
    page = 1
) => {
    let url = '/client/account/' + a_id + '/fund?';
    let queryString = '';

    queryString += queryParamStr('size', size, true);
    queryString += queryParamStr('page', page);

    queryString += queryParamStr('type', type);
    queryString += queryParamStrArr('statuses[]', types);

    queryString += queryParamStr('mode', mode);
    queryString += queryParamStrArr('modes[]', modes);

    queryString += queryParamStr('status', status);
    queryString += queryParamStrArr('statuses[]', statuses);

    queryString += queryParamStr('range[start]', rangeStart);
    queryString += queryParamStr('range[end]', rangeEnd);

    let fullUrl = url + queryString;
    let result = await handleRequestGet(fullUrl, {});
    //console.log('fullUrl', fullUrl);
    //console.log(result);

    return parseResult(result);
};

export const submitMultiSettlement = async ({
    bank,
    contracts,
    currency = 1
}: any) => {
    let url = '/client/fund/settlement';
    let result = await handleRequestPost(url, { bank, contracts, currency });
    return parseSubmitResult(result);
};

export const getMultiFundingHistory = async (
    acc_ids = null,
    {
        //type=3&mode=1&status=1&size=10&page=1
        type = null,
        types = null, //[]
        mode = null,
        modes = null, //[]
        status = null,
        statuses = null, //[]
        rangeStart = null,
        rangeEnd = null,
        size = 10,
        accounts = null
    },
    page = 1
) => {
    let url = '/client/fund?';
    let queryString = '';

    queryString += queryParamStr('size', size, true);
    queryString += queryParamStr('page', page);

    queryString += queryParamStr('type', type);
    queryString += queryParamStrArr('statuses[]', types);

    queryString += queryParamStr('mode', mode);
    queryString += queryParamStrArr('modes[]', modes);

    queryString += queryParamStr('status', status);
    queryString += queryParamStrArr('statuses[]', statuses);

    queryString += queryParamStr('range[start]', rangeStart);
    queryString += queryParamStr('range[end]', rangeEnd);

    queryString += queryParamStrArr('accounts[]', acc_ids);

    let fullUrl = url + queryString;
    let result = await handleRequestGet(fullUrl, {});
    //console.log('fullUrl', fullUrl);
    //console.log(result);

    return parseResult(result);
};
