import React from 'react';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from 'react-device-detect';

//old version **unused
function TopBanner({ data }) {
    return (
        <div className="TopBanner">
            <div className="hero bg-light img-right" style={{ height: '75vh' }}>
                <div className="container">
                    <div
                        className="row align-items-center"
                        style={{ height: '75vh' }}
                    >
                        <div className="col-lg-5 me-auto">
                            <span
                                className="d-block subheading mb-3"
                                data-aos="fade-up"
                                data-aos-delay="200"
                            ></span>
                            <h1
                                className="heading mb-3"
                                data-aos="fade-up"
                                data-aos-delay="200"
                            >
                                {data.name}
                            </h1>
                            <p
                                data-aos="fade-up"
                                data-aos-delay="300"
                                className="mb-5 text-black-50 text-justify"
                            >
                                {data.details}
                            </p>
                        </div>
                        <div
                            className="col-lg-6 image-wrap"
                            style={{
                                maxHeight: '75vh',
                                backgroundImage: `url("${data.imageURL}")`
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

//top banner for product and about us
function TopBannerV2({ data }) {
    const productStyle = {
        backgroundImage: `url("${data.imageURL}")`,
        maxHeight: '88vh',
        backgroundPosition: data.background ? 'right' : 'center'
    };

    const aboutStyle = {
        backgroundImage: `url("${data.imageURL}")`,
        maxHeight: '88vh'
        // backgroundPosition: 'initial',
    };

    const adjustFontSize = {
        fontSize: '20px'
    };

    return (
        <div className="hero hero_home bg-light img-right" style={{ height: '78vh' }}>
            <div
                className="img-wrap"
                style={data.style ? aboutStyle : productStyle}
            ></div>
            <div className="container">
                <div
                    className="row align-items-center"
                    style={{
                        height: '75vh',
                        paddingTop: data.style ? '15px' : '5px',
                        paddingBottom: '30px',
                    }}
                >
                    <div className="col-xl-5 col-lg-12 me-auto">
                        <h1 className="heading mb-3" 
                            data-aos="fade-down"
                            data-aos-delay="0">
                            <span style={{ textTransform: 'capitalize' }}>
                                {data.title}
                            </span>{' '}
                            {data.name}
                        </h1>
                        <p className="mb-3 text-black-50 text-justify bg-light p-2" 
                            data-aos="fade-up"
                            data-aos-delay="100">
                            {data.details}
                        </p>
                        <p className="mb-2 text-black-50 text-justify bg-light p-2"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            {data.detailsCont}
                        </p>
                        {/* <p data-aos="fade-up" data-aos-delay="400">
                                <a href="#" className="btn btn-primary me-4">
                                    See our projects
                                </a>{' '}
                                <a
                                    href="https://www.youtube.com/watch?v=mwtbEGNABWU"
                                    className="d-inline-flex align-items-center glightbox"
                                >
                                    {' '}
                                    <span className="icon-play me-3"></span>{' '}
                                    <span>Watch our video</span>
                                </a>
                            </p> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

//top product for home
const TopBannerHome = () => {
    return (
        <div className="hero hero_home bg-light" style={{ height: '78vh' }}>
            <div
                className="img-wrap"
                style={{
                    backgroundImage: `url("/img/FiNext/Official/vertical.jpg")`,
                    maxHeight: '83vh',
                    marginTop: isBrowser ? '7.5vh' : '0vh',
                    backgroundPosition: 'Top'
                }}
            ></div>
            <div className="container">
                <div
                    className="row align-items-center"
                    // style={{height: '80vh', }}
                >
                    <div className="col-xl-5 col-lg-12 ms-auto" style={{paddingBottom: '80px',}}>
                        <span
                            className="d-block subheading mb-3"
                            // data-aos="fade-down"
                            // data-aos-delay="100"
                        >
                            Welcome
                        </span>
                        <h1
                            className="heading mb-3"
                            data-aos="fade-down"
                            data-aos-delay="0"
                        >
                            Revolutionize Your Business from Trading to Backend
                            operations with Our Unified Enterprise{' '}
                            <span style={{ textTransform: 'capitalize' }}>
                                {/* FiNext */}
                                <img
                                    src="/img/FiNext/Official/Finext_Logo_nobg.png"
                                    style={{
                                        width: '180px',
                                        verticalAlign: 'baseline',
                                        paddingTop: '5px'
                                    }}
                                ></img>
                            </span>{' '}
                            <span style={{ whiteSpace: 'break-spaces' }}>
                                Solution
                            </span>
                        </h1>
                        <p
                            data-aos="fade-up"
                            data-aos-delay="100"
                            className="mb-5 text-black-50 text-justify"
                        >
                            Explore win-win solutions for both you and your
                            clients through our FiNext integrated
                            straight-through solution. By leveraging available
                            technology, we seamlessly integrate cutting-edge
                            customizable trading, risk management, BI dashboard,
                            and back office system to meet your expectations and
                            enabling you to gain a competitive edge.
                        </p>
                        {/* <p data-aos="fade-up" data-aos-delay="400">
                            <a href="#" className="btn btn-primary me-4">
                                See our projects
                            </a>{' '}
                            <a
                                href="https://www.youtube.com/watch?v=mwtbEGNABWU"
                                className="d-inline-flex align-items-center glightbox"
                            >
                                {' '}
                                <span className="icon-play me-3"></span>{' '}
                                <span>Watch our video</span>
                            </a>
                        </p> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

const TopBannerWordOnly = ({ data }) => {
    return (
        <div
            className="hero hero_pt bg-light img-right"
            style={{ height: '50vh' }}
        >
            <div className="container">
                <div
                    className="row align-items-center"
                    style={{ height: '50vh' }}
                >
                    <div
                        className="col-lg-6 mx-auto text-center"
                        style={{ paddingTop: '5vh' }}
                    >
                        <h1 className="heading mb-3">{data.title}</h1>
                        <p className="text-black-50">{data.description1}</p>
                        <p className="mb-5 text-black-50">
                            {data.description2}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { TopBanner, TopBannerV2, TopBannerHome, TopBannerWordOnly };
