import { useState, useEffect, useRef } from 'react';
import './index.css';
import api from '../../../../apis';

const Index = (props: any) => {
    const [didMount, setDidMount] = useState(false);
    const [cFile, setcFile] = useState<any>(null);
    const hiddenFileInput = useRef(null);

    const handleClick = (event: any) => {
        hiddenFileInput.current.click();
    };

    // Setting didMount to true upon mounting
    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (didMount == true) {
            if (
                props.value == undefined ||
                props.value == null ||
                props.value == ''
            ) {
                if (cFile) {
                    setcFile(null);
                    //reset file input
                    hiddenFileInput.current.value = '';

                    //  hiddenFileInput.current.files = null;
                }
            }
        }
    }, [props.value]);

    const handleChange = async (event: any) => {
        event.preventDefault();
        const fileUploaded = event.target.files[0];
        console.log(fileUploaded);
        let fileName = null;
        console.log(event.target.files);
        if (fileUploaded) {
            var formData = new FormData();
            formData.append('file', fileUploaded);

            let result = await api.uploadFile(formData);
            console.log('uploadFile');
            console.log(result);

            if (result.file) {
                fileName = result.file;

                console.log('fileName', fileName);
            } else {
                alert('upload file failed');
            }
        }

        if (props.onFileChange) {
            // props.onFileChange(event,result);
            // console.log(event);
            props.onFileChange(event, {
                file: fileName
            });
        }

        setcFile(fileName);
    };

    // const handleDisplayFile = async (event: any) => {
    //     event.preventDefault();
    //     alert('here');
    //     console.log('fileName', cFile);
    //     let result = await api.getTempFile(cFile);
    //     console.log('handleDisplayFile');
    //     console.log(result);
    // };

    const openInNewTab = (event: any) => {
        event.preventDefault();
        window.open('./file/' + cFile, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className="form-input">
            <button
                type="button"
                className="btn-primary-custom btn-light btn-md "
                onClick={handleClick}
            >
                <i className="fas fa-circle-plus " aria-hidden="true"></i>
                &nbsp;&nbsp; Upload
            </button>
            <input
                id={props.id}
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
            />
            <div className="pt-1">
                <a href="" onClick={(e) => openInNewTab(e)}>
                    {cFile}
                </a>
            </div>
            <div className="input-error">{props.error || ''}</div>
        </div>
    );
};

export default Index;
