import { useState, useEffect, useRef } from 'react';
import './index.css';

const Index = (props: PropsData) => {
    const inputRef = useRef(null);
    useEffect(() => {
        if (props.defaultChecked == true) inputRef.current.checked = true;
    }, []);
    const handleOnchange = (e: any) => {
        if (props.onChange) {
            props.onChange(e);
        }
    };

    return (
        <input
            ref={inputRef}
            type="checkbox"
            className={' cursor-pointer'}
            onChange={(e) => handleOnchange(e)}
        />
    );
};

export default Index;
