import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import api from '../../../apis';
import moment from 'moment';
import FormInput from '../../general/form-control/form-item/input';
import FormInputAmount from '../../general/form-control/form-item/input-amount';

import { useLoading } from '../../../context/LoadingContext';

import TreeSelect from '../../general/form-control/tree-select';
import { parseAPIResultToData } from '../../general/form-control/tree-select/helper';

import { numberWithCommas } from '../../general/convertor';

const Index = () => {
    const navigate = useNavigate();
    const { setLoading } = useLoading();

    useEffect(() => {}, []);

    //validation settings
    const [rules, setRules] = useState<any>({
        amount: {
            required: true,
            error_message: 'Please input withdrawal amount'
        }
    });

    const [currentAccNo, setCurrentAccNo] = useState('');
    const [currentUsername, setCurrentUsername] = useState('');
    const [availTrustAmount, setAvailTrustAmount] = useState(0);

    const [userAccTreeData, setUserAccTreeData] = useState<any>([]);
    const [treeSelectLoading, setTreeSelectLoading] = useState<boolean>(false);

    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        console.log(inputs);
        // if (currentAccNo) {
        //     requestGetAccountContractAPI(currentAccNo, searchInputs);
        // }
    }, [inputs]);

    const onInputChange = (id: string, newValue: string) => {
        setInputs((prevState) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    //tree select start
    const onTreeKeyinChange = (keywords: any) => {
        // console.log('onTreeKeyinChange');
        // console.log(keywords);
        setTreeSelectLoading(true);
        if (keywords.value || keywords.value == '')
            api.searchClientNameAccount(keywords.value).then((result: any) => {
                console.log(result);
                setTreeSelectLoading(false);
                if (result.clients) updateTreeSelect(result.clients);
            });
    };

    const onTreeSelectChange = async (selectInputObject: any) => {
        if (selectInputObject.value) {
            setCurrentAccNo(selectInputObject.value);

            requestGetTrustDetailAPI(selectInputObject.value);
            //requestGetAccountContractAPI(selectInputObject.value, inputs);
        } else {
            // setTableData([]);
        }
    };

    const requestGetAccountContractAPI = async (
        accNo: any,
        searchInputs: any
    ) => {
        setLoading(true);
        console.log(searchInputs);
        let result = await api.getAccountContra(accNo, searchInputs);
        console.log(result);
        if (result.contra) {
            let contra = result.contra;
            console.log(contra);
            //setTableData(contra);
            //  updateAccSummaryComponent(account);
        }
        setLoading(false);
    };

    const requestGetTrustDetailAPI = async (accNo: any) => {
        console.log('requestGetTrustDetailAPI');
        let result = await api.getTrustDetail(accNo);
        if (result) {
            setAvailTrustAmount(result.at_trustacct_cash_avail || 0);
            //setTableData(contra);
            //  updateAccSummaryComponent(account);
        }
    };

    const updateTreeSelect = (result: any) => {
        console.log(result);
        let newArr = parseAPIResultToData(result);
        setUserAccTreeData(newArr);
    };
    //tree select end

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        let { valid, errors } = formValidation(rules, inputs);

        console.log('valid', valid);
        console.log('errors', errors);

        // let result = await api.validateManualDeposit(currentAccNo, {});
        // console.log('validateManualDeposit');
        // console.log(result);
        if (!currentAccNo) {
            alert('Please select Account No');
        } else if (valid == false) {
            setErrors(errors);
            scrollToFirstError(errors);
            //scroll to first error
        } else {
            let result = await api.submitWithdrawal(currentAccNo, inputs);
            if (result.status == 0) {
                alert('Submit withdrawel successful');

                //refresh page
                navigate(0);
            } else {
                console.log(result);

                if (result?.message) {
                    let message = result?.message;
                    let sentence = '';

                    for (var key of Object.keys(message)) {
                        sentence += message[key];
                    }

                    alert(sentence);
                }
            }
        }
        setLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="pt-3 text-left ">
                <div className="row input-column-gap">
                    {/* filter */}
                    <div className="col-xxxl-2 col-xl-2 col-lg-3 col-md-4 col-12">
                        <div className="col-12 main-title">Withdrawal</div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="input-container">
                                <div className="label">
                                    Username / Account No{' '}
                                </div>
                                <TreeSelect
                                    treeMap={['username', 'accNo']}
                                    data={userAccTreeData}
                                    setLoading={treeSelectLoading}
                                    keyinChange={(keywords: any) =>
                                        onTreeKeyinChange(keywords)
                                    }
                                    onSelectChange={(selectInputObject: any) =>
                                        onTreeSelectChange(selectInputObject)
                                    }
                                    onSelectChangeParentValue={(
                                        parentValue: any
                                    ) => {
                                        setCurrentUsername(parentValue || '');
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="input-container ">
                                <div className="label">Name</div>
                                <input
                                    id="username"
                                    className="custom border-bottom"
                                    type="text"
                                    value={currentUsername}
                                    // onChange={({ target }) =>
                                    //     onInputChange(target.id, target.value)
                                    // }
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    {/* filter end div */}

                    {/* body */}
                    <div className="col-xxxl-10 col-xl-10 col-lg-9 col-md-8 col-12">
                        <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12">
                            <div className="custom-label">
                                Withdrawal Amount
                            </div>
                        </div>
                        <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12">
                            <div className="input-container ">
                                {/* <input
                                id="amount"
                                className="custom"
                                type="number"
                                value={inputs.amount}
                                onChange={({ target }) => {
                                    onInputChange(target.id, target.value);
                                }}
                                min={0}
                                // onInput={(e) => e.target.setCustomValidity('')}
                                // onInvalid={(e) =>
                                //     e.target.setCustomValidity(
                                //         'Your custom message'
                                //     )
                                // }
                                placeholder="Enter Amount"
                                required
                            /> */}

                                <FormInputAmount
                                    id="amount"
                                    type="number"
                                    onChange={({ target }: any) =>
                                        onInputChange(target.id, target.value)
                                    }
                                    error={errors.amount}
                                />
                            </div>
                        </div>
                        <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12 ">
                            {' '}
                            <div className="custom-label ">
                                Current Available Trust Amount
                            </div>
                        </div>
                        <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12">
                            {' '}
                            <div className="custom-label ">
                                {numberWithCommas(availTrustAmount, 2)}
                            </div>
                        </div>

                        <div className="col-12 pt-3">
                            <button
                                className="btn-primary-custom"
                                type="submit"
                            >
                                Submit &nbsp;&nbsp;
                                <i
                                    className="fas fa-chevron-right "
                                    aria-hidden="true"
                                ></i>
                            </button>
                        </div>
                    </div>
                    {/* body end div*/}
                </div>
            </div>
        </form>
    );
};

const formValidation = (rules: any, inputs: any) => {
    if (!rules) return { valid: true, errors: {} };

    let valid = true;
    let errors: any = {};

    //validate rule
    for (var key in rules) {
        if (rules.hasOwnProperty(key)) {
            let item = rules[key];
            if (item.required == true && !inputs[key]) {
                let error = item.error_message || 'Required';
                errors[key] = error;
                valid = false;
            } else if (item.requireIfExist) {
                //if specific input exist,current input become required
                let requireIfExist = item.requireIfExist;
                let relatedkey = requireIfExist.key;
                let relatedValue = requireIfExist.value;

                //match type start check error
                if (
                    relatedkey &&
                    relatedValue &&
                    inputs[relatedkey] &&
                    inputs[relatedkey] == relatedValue
                ) {
                    let error = item.error_message || 'Required';
                    errors[key] = error;
                    valid = false;
                }
            } else {
            }

            // requireIfExist: { key: 'mode', value: 2 }
        }
    }

    return {
        valid,
        errors
    };
};

const scrollToFirstError = (errors: any) => {
    if (Object.keys(errors).length > 0) {
        let firstKey = Object.keys(errors)[0];
        let element = document.getElementById(firstKey);
        element?.scrollIntoView();
    }
};

export default Index;
