import { useState, useEffect } from 'react';
import './index.css';
import moment from 'moment';

import event4 from '../../asset/images/event/1104.png';

import icShare from '../../asset/images/icon/share.png';
import icTwitter from '../../asset/images/icon/twitter.png';

const EventArticle = (props: any) => {
    const [fileContent, setFileContent] = useState(null);

    useEffect(() => {
        if (props.file_content) {
            setFileContent(props.file_content);
        }
    }, [props.file_content]);

    const downloadFile = () => {
        if (fileContent) {
            startDownloadFile(fileContent, fileContent);
        }
    };

    function startDownloadFile(filename: string, text: string) {
        const link = document.createElement('a');
        link.href = fileContent;
        link.download = 'test.pdf';
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <>
            <div className="container-fluid article">
                <div className="row">
                    <div className="col-lg-12 col-12  ">
                        <div className="row">
                            {/* <div className="col-12  article-title">
                                {props.title}
                            </div> */}
                            {/* <div className="col-12">
                                <div className="article-sub-header">
                                    <div className="section-info">
                                        {props.source
                                            ? props.source + ' • '
                                            : ''}
                                        {props.b_publish_date_start &&
                                            moment(
                                                props.b_publish_date_start
                                            ).format('MMM DD YYYY')}
                                    </div>
                                    <div className="section-share">
                                        SHARE THIS
                                        <img src={icShare} />
                                        <img src={icTwitter} />
                                    </div>
                                    <div className="content-text">
                                        {fileContent && (
                                            <button
                                                className="btn-primary-custom btn-md "
                                                onClick={() => downloadFile()}
                                            >
                                                Download{' '}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-12 pt-1 content-text">
                                <iframe
                                    style={{ height: props.layout === 'side' ? '82vh' : '75vh', width: '100%' }}
                                    src={fileContent}
                                    frameBorder="0"
                                    scrolling="auto"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventArticle;
