import axios from 'axios';
import {
    getSessKey,
    gcmEncrypt,
    gcmDecrypt,
    generateBearerTokenKey
} from '../crypto/msgCrypto';

import { generateEcdhKeys } from '../crypto/ecdhCrypto';
import Config from '../config';
import { getUserDetail, setLogout } from '../hooks/handleCookie';



let expiredTimer = null;
const axiosInstance = axios.create({
    baseURL: Config.baseURL,
    headers: {
        'Content-Type': 'application/octet-stream'
        // Authorization: `Bearer asdasd`
    }
});

axiosInstance.interceptors.request.use(
    function (config) {
        let method = config.method;
        let userDetail = getUserDetail();

        //AES encryption with ECDH sharedKey
        //encrypt body data
        //generate ECDH Key
        let { sharedKey, publicKey } = generateEcdhKeys();
        config.ECDHPublicKey = publicKey;
        config.ECDHSharedKey = sharedKey;

        if (method == 'get') {
            //get
            let encryptedBody = gcmEncrypt('{}', sharedKey);
            config.data = encryptedBody;
        } else {
            //post
            //let encryptedBody = gcmEncrypt(config.data, sharedKey);

            // let encryptedBody = '';

            if (userDetail && userDetail.token) {
                let token = userDetail.token;
                let key = getSessKey(token);

                let encryptedBody = gcmEncrypt(config.data, key);

                config.data = encryptedBody;
            } else {
                let encryptedBody = gcmEncrypt(config.data, sharedKey);

                config.data = encryptedBody;
            }
        }

        if (userDetail && userDetail.token) {
            let token = userDetail.token;
            //protected api
            config.headers['Authorization'] = `Bearer ${token}`;
            // config.headers['Ecdh-Public-Key'] = publicKey;
        } else {
            //public api
            config.headers['Ecdh-Public-Key'] = publicKey;
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        if (response.status === 200 && response.data) {
            // console.log('response?.headers', response?.headers);
            // let headers = response?.headers;
            // let expiredAt = headers['expired-at'];
            // let endDate = moment(expiredAt);
            // console.log(moment(expiredAt).format('YYYY-MM-DD hh:mm:ss'));

            // let duration = moment.duration(endDate.diff(moment()), 'minutes');
            // console.log("duration",duration)

            // let test = endDate.fromNow()
            // console.log("test",test)

            // console.log("reset timer")
            // clearTimeout(expiredTimer);
            // expiredTimer = setTimeout(function () {
            //     alert('timeout');
            // }, 5000);

            return response; //fetch data to display
        } else {
            alert(response.status);
            alert('Warning: Fail to get data. ' + response.data.message);
        }
    },
    (error) => {
        //----throw error to WithAxios.ts----//
        throw error

        //console.log(error);

        // if (error.response.status === 401) {
        //     let userDetail = getUserDetail();
        //     if (userDetail && userDetail.token) {
        //         alert('Session expired');
        //     }
        //     setLogout();
        //     window.location.href = './';
        // } else if (error.response.status === 500) {
        //     showNotiLoaderError();
        // }
    }
);

//used to call api
const handleRequestPost = async (url, param) => {
    //stringify param before going to next step
    let stringifyBody = JSON.stringify(param);
    // console.log('stringifyBody');
    // console.log(param);
    return axiosInstance
        .post(url, stringifyBody)
        .then(async function (response) {
            // handle success
            // console.log(response);
            if (response) {
                let status = response.status;

                if (status == 200) {
                    let data = response.data;
                    let userDetail = getUserDetail();

                    //logined
                    if (userDetail && userDetail.token) {
                        let token = userDetail.token;
                        // console.log("token")
                        // console.log(token)
                        let key = await generateBearerTokenKey(token);

                        // console.log('token', token);
                        // console.log('key', key);
                        let result = await gcmDecrypt(data, key);
                        // console.log('gcmDecrypt result', result);
                        return result;
                    } else {
                        const { config } = response;
                        //get sharedkey from config
                        let ECDHSharedKey = config.ECDHSharedKey;
                        let result = await gcmDecrypt(data, ECDHSharedKey);
                        
                        return result;
                    }
                } else {
                    alert('Request Failed');
                }
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        });
};

const handleRequestPut = async (url, param) => {
    //stringify param before going to next step
    let stringifyBody = JSON.stringify(param);
    // console.log('stringifyBody');
    // console.log(param);
    return axiosInstance
        .put(url, stringifyBody)
        .then(async function (response) {
            // handle success
            // console.log(response);
            if (response) {
                let status = response.status;

                if (status == 200) {
                    let data = response.data;
                    let userDetail = getUserDetail();

                    //logined
                    if (userDetail && userDetail.token) {
                        let token = userDetail.token;
                        // console.log("token")
                        // console.log(token)
                        let key = await generateBearerTokenKey(token);

                        // console.log('token', token);
                        // console.log('key', key);
                        let result = await gcmDecrypt(data, key);
                        // console.log('result', result);
                        return result;
                    } else {
                        const { config } = response;
                        //get sharedkey from config
                        let ECDHSharedKey = config.ECDHSharedKey;
                        let result = await gcmDecrypt(data, ECDHSharedKey);

                        return result;
                    }
                } else {
                    alert('Request Failed');
                }
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        });
};


const handleRequestGet = async (url, param,fullReponse = false) => {
    //stringify param before going to next step
    let stringifyBody = JSON.stringify(param);
    return axiosInstance
        .get(url, stringifyBody)
        .then(async function (response) {
            if (response) {
                let status = response.status;

                if (status == 200) {
                    let data = response.data;
                    let userDetail = getUserDetail();

                    if (userDetail && userDetail.token) {
                        let token = userDetail.token;
                        let key = await generateBearerTokenKey(token);
                        let result = await gcmDecrypt(data, key);
                        // console.log(key);
                        // console.log(result);
                        //console.log("gcmDecrypt ",result);
                        
                        if(fullReponse == true)
                        {
                            response.data = result;
                            return response
                        }else{
                            return result;
                        }
                        
                    } else {
                        const { config } = response;
                        //get sharedkey from config
                        let ECDHSharedKey = config.ECDHSharedKey;
                        let result = await gcmDecrypt(data, ECDHSharedKey);
                     
                        return result;
                    }
                } else {
                    alert('Request Failed');
                }
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        });
};

export { handleRequestPost,handleRequestPut, handleRequestGet, axiosInstance };
