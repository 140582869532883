import React from 'react';
import {Address, Phone, Email} from '../../../../../../constants/landing';

const Footer = () => {
    const getYear = new Date().getFullYear();
    return (
        <div className="site-footer" style={{paddingTop: "10px"}}>
            <div className="container border-top" style={{paddingTop: "70px"}}>
                <div className="row justify-content-between">
                    <div className="col-lg-6">
                        <div className="widget">
                            <h3 className="line-top"><i className="m-1 bi bi-geo-alt-fill"></i>  Address</h3>
                            <span className="d-block subheading mb-3">
                                Kuala Lumpur
                            </span>
                            <p className="mb-5">
                                {Address.KL}
                            </p>
                            <span className="d-block subheading mb-3">
                                Penang
                            </span>
                            <p className="mb-5">
                                {Address.Penang}
                            </p>
                        </div>
                    </div>
                    <div className="row col-lg-6">
                        <div className="col-lg-6">
                            <div className="widget">
                                <h3 className="line-top"><i className="m-1 bi bi-telephone-fill"></i>  Telephone</h3>
                                <p className="mb-5">{Phone.number}</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="widget">
                                <h3 className="line-top"><i className="m-1 bi bi-envelope-fill"></i>  Email</h3>
                                <p className="mb-5">{Email.emailaddress}</p>
                            </div>
                        </div>
                        {/* <div className="col-lg-6 align-self-end">
                            <div className="widget">
                                <h3 className="line-top">Connect with us</h3>
                                <ul className="social list-unstyled mb-5">
                                    <li>
                                        <a href="#">
                                            <span className="icon-facebook"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <span className="icon-twitter"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <span className="icon-instagram"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <span className="icon-dribbble"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <span className="icon-linkedin"></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="row justify-content-center text-center copyright">
                    <div className="col-md-12">
                        <p className="small text-black-50">
                            Copyright &copy;  {`${getYear} `}
                            {/* All rights reserved |  */}
                            <a href="/login" style={{cursor: "default",}}>FiNext </a> 
                            <a href="http://admin.finext.com.my/" target="_blank" style={{cursor: "default",}}>Sdn. </a> 
                            <a href="/" style={{cursor: "default",}}>Bhd.</a>  
                            {/* with{' '}
                            <i
                                className="icon-heart text-danger"
                                aria-hidden="true"
                            ></i>{' '} */}{' '}
                            {/* by{' '}
                            <a href="/"rel="FiNext">
                                FiNext
                            </a> */}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
