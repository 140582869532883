import { createRef, useRef, useState, useEffect, useContext } from 'react';
import { useLoading } from '../../../context/LoadingContext';
import api from '../../../apis';
import { useOutletContext } from 'react-router-dom';

const Index = (props: any) => {
    const { setIsIframe }: any = useOutletContext();

    const [tdsUrl, setTDSUrl] = useState('');
    const { loading, setLoading } = useLoading();
    useEffect(() => {
        //send IsIframe status to parent layer
        if (setIsIframe) setIsIframe(true);

        setLoading(true);
        api.getSessFeedAuth().then((result: any) => {
            let url = result.auth_feed_url;
            console.log('auth_feed_url');
            console.log(url);
            setTDSUrl(url);
            setLoading(false);
        });

        return () => {
            if (setIsIframe) setIsIframe(false);
        };
    }, []);

    return (
        <iframe
            src={tdsUrl}
            allowFullScreen
            style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: '100%',
                height: '100%',
                border: 'none',
                overflow: 'hidden'
            }}
        />
    );
};

export default Index;
