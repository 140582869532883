import { handleFormPost } from '../axiosFormData';
import { handleRequestGetFile } from '../axiosFile';
import { parseResult } from './GeneralFunc';

export const uploadFile = async (formBody: any) => {
    let url = '/file';
    let result = await handleFormPost(url, formBody);

    return parseResult(result);
};

export const getTempFile = async (fileName: string) => {
    let url = '/file/' + fileName + '?type=upload';
    let result = await handleRequestGetFile(url, {});
    console.log(result);
    return result;
};

export const getFile = async (accountid: string, fileName: string) => {
    // let url = '/file/' + fileName + '?type=upload';

    let url = '/client/account/' + accountid + '/file/' + fileName;
    let result = await handleRequestGetFile(url, {});
    console.log(result);
    return result;
};
