import { useState, useEffect, useLayoutEffect } from 'react';
import api from '../../../../../apis';

const Index = (props: any) => {
    useLayoutEffect(() => {
        //request currency api
        // api.getCurrency().then((result: any) => {
        //     console.log('currency', result);
        //     setCurrencyList(result);
        // });
    }, []);

    const [currencyList, setCurrencyList] = useState<any>([]);

    const handleChange = async (e: any) => {
        // alert('selected');
        // console.log(selectInputObject);
        if (props.onChange) {
            props.onChange({ target: e.target });
        }
    };

    return (
        <div className="input-container ">
            <div className="label">Currency </div>
            <select
                id={props.id || ''}
                className="custom"
                onChange={(e) => handleChange(e)}
            >
                <option value="">All</option>
                {currencyList.map(function (row: any, i: any) {
                    return <option value={row.c_id}>{row.c_code}</option>;
                })}
            </select>
        </div>
    );
};

export default Index;
