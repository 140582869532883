import { useState, useEffect, useMemo, useRef } from 'react';
import '../../asset/styles/funding-settlement-style.css';

import { useNavigate } from 'react-router-dom';
import api from '../../../apis';
import moment from 'moment';

import { useLoading } from '../../../context/LoadingContext';

import ReactTable from '../../general/form-control/react-table';
import TreeSelect from '../../general/form-control/tree-select';
import Checkbox from '../../general/form-control/checkbox';

import FormSelect from '../../general/form-control/form-item/select';

import { parseAPIResultToData } from '../../general/form-control/tree-select/helper';

import { numberWithCommas } from '../../general/convertor';

import UserAccTreeSelect from '../../general/form-control/user-acc-tree-select';
import { usePagination } from '../../tables/helper';

const Index = () => {
    const navigate = useNavigate();
    const { setLoading } = useLoading();

    useEffect(() => {
        setLoading(true);
        api.getBankList().then((result: any) => {
            // console.log('getBankList');
            // console.log(result);
            setBankList(result);
            // setStockData(result[0].stocks);
        });

        setLoading(false);
    }, []);

    const [rules, setRules] = useState<any>({
        bank_list: { required: true, error_message: 'Please select bank' }
    });

    const [currentAccNo, setCurrentAccNo] = useState([]);
    const [currentUsername, setCurrentUsername] = useState('');
    const [bankList, setBankList] = useState([]);

    const [userAccTreeData, setUserAccTreeData] = useState<any>([]);
    const [treeSelectLoading, setTreeSelectLoading] = useState<boolean>(false);

    const [selectedRows, setSelectedRows] = useState<any>({});
    const [totalAmount, setTotalAmount] = useState<any>(0);

    const [inputs, setInputs] = useState<any>({ paymentType: '', amount: '' });
    const [errors, setErrors] = useState<any>({});

    const [tableData, setTableData] = useState([]);
    const { tablePagination, setTablePagination } = usePagination();
    // const [tablePagination, setTablePagination] = useState<any>({
    //     currentPage: 1,
    //     totalRecords: 0
    // });

    useEffect(() => {
        if (currentAccNo.length > 0) {
            requestGetAccountContractAPI(currentAccNo, inputs);
        } else if (currentAccNo.length == 0) {
            handleResetTables();
        }
    }, [currentAccNo]);

    useEffect(() => {
        console.log(selectedRows);
        calculateTotalAmount(selectedRows);
    }, [selectedRows]);

    const handleResetTables = () => {
        //reset data
        setTableData([]);
        setTotalAmount(0);
        setSelectedRows({});
    };

    const onInputChange = (id: string, newValue: string) => {
        setInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    // api.getAccountContract(currentAccNo,{ statuses: [0, 3] }).then((result: any) => {
    //     // console.log('getBankList');
    //     // console.log(result);
    //     // setBankList(result);
    //     // setStockData(result[0].stocks);
    // });

    //tree select start
    const onTreeKeyinChange = (keywords: any) => {
        // console.log('onTreeKeyinChange');
        // console.log(keywords);
        setTreeSelectLoading(true);
        if (keywords.value || keywords.value == '')
            api.searchClientNameAccount(keywords.value).then((result: any) => {
                console.log(result);
                setTreeSelectLoading(false);
                if (result.clients) updateTreeSelect(result.clients);
            });
    };

    const onTreeSelectChange = async (selectInputObject: any) => {
        if (selectInputObject.value) {
            setCurrentAccNo(selectInputObject.value);

            requestGetAccountContractAPI(selectInputObject.value, inputs);
        } else {
            // setTableData([]);
        }
    };

    const requestGetAccountContractAPI = async (
        accNo: any,
        searchInputs: any,
        cPage = 1
    ) => {
        setLoading(true);
        // console.log(searchInputs);

        searchInputs['statuses'] = [0, 3];
        let result = await api.getMultiAccountContract(
            accNo,
            searchInputs,
            cPage
        );
        if (result.contracts) {
            let contracts = result.contracts;
            setTableData(contracts);
            // selectAllRow(contracts);
            //  updateAccSummaryComponent(account);
        }

        if (result.total) {
            // handlePagination('currentPage', cPage);
            // handlePagination('totalRecords', result.total);
            setTablePagination({
                currentPage: cPage,
                totalRecords: result.total
            });
        }
        setLoading(false);
    };

    const updateTreeSelect = (result: any) => {
        console.log(result);
        let newArr = parseAPIResultToData(result);
        setUserAccTreeData(newArr);
    };
    //tree select end

    //submit form
    const handleSubmit = async (e: any) => {
        e.preventDefault();

        setLoading(true);
        let { valid, errors } = formValidation(rules, inputs);
        setErrors(errors);
        console.log(errors);
        if (!currentAccNo) {
            alert('Please select Account No');
        } else if (valid == false) {
            //scroll to first error
            if (Object.keys(errors).length > 0) {
                let firstKey = Object.keys(errors)[0];
                let element = document.getElementById(firstKey);
                element?.scrollIntoView();
            }
        } else {
            //submit to api
            let contracts = handlePendingContracts(selectedRows);

            if (contracts.length == 0) {
                alert('must select at least 1 item');
            } else {
                let result = await api.submitMultiSettlement({
                    bank: inputs.bank_list,
                    contracts
                });
                console.log('result');
                console.log(result);

                if (result?.data?.fpx) {
                    let fpx_url = result.data.fpx;
                    window.open(fpx_url, '_blank', 'noopener,noreferrer');
                    alert('Thank you, the form was submitted successfully');
                    navigate(0);
                    //requestGetAccountContractAPI(currentAccNo, inputs);
                }
            }
        }
        setLoading(false);
    };

    const handlePendingContracts = (pendingList: any) => {
        let contracts = [];
        console.log(pendingList);

        for (var key in pendingList) {
            if (pendingList.hasOwnProperty(key)) {
                let row = pendingList[key];
                if (row.ctrt_id) {
                    contracts.push(row.ctrt_id);
                }
            }
        }
        return contracts;
    };

    const formValidation = (rules: any, inputs: any) => {
        if (!rules) return { valid: true, errors: {} };

        let valid = true;
        let errors: any = {};

        //validate rule
        for (var key in rules) {
            if (rules.hasOwnProperty(key)) {
                let item = rules[key];
                if (item.required == true && !inputs[key]) {
                    let error = item.error_message || 'Required';
                    errors[key] = error;
                    valid = false;
                } else if (item.requireIfExist) {
                    //if specific input exist,current input become required
                    let requireIfExist = item.requireIfExist;
                    let relatedkey = requireIfExist.key;
                    let relatedValue = requireIfExist.value;

                    //match type start check error
                    if (
                        relatedkey &&
                        relatedValue &&
                        inputs[relatedkey] &&
                        inputs[relatedkey] == relatedValue
                    ) {
                        if (!inputs[key]) {
                            let error = item.error_message || 'Required';
                            errors[key] = error;
                            valid = false;
                        }
                    }
                } else {
                }

                // requireIfExist: { key: 'mode', value: 2 }
            }
        }

        return {
            valid,
            errors
        };
    };

    //submit form end

    const handleSelectedRow = (e: any, rowIndex: number, rowData: any) => {
        // console.log('handleSelectedRow');
        // console.log(rowIndex);
        //table checkbox listener
        //track checked checbox data
        setSelectedRows((prevState: any) => {
            let checked = e.target.checked;
            const copy = { ...prevState };
            if (checked == true) {
                copy[rowIndex] = rowData;
            } else {
                delete copy[rowIndex];
            }
            return copy;
        });
    };

    const selectAllRow = (rowData: any) => {
        let newObj = {} as any;

        for (var i = 0; i < rowData.length; i++) {
            let row = rowData[i];
            newObj[i] = row;
        }

        setSelectedRows(newObj);
    };

    const calculateTotalAmount = (selectedRows: any) => {
        let totalAmount = 0;
        for (var key in selectedRows) {
            if (selectedRows.hasOwnProperty(key)) {
                let row = selectedRows[key];
                if (row.ctrt_value) {
                    totalAmount += Number(row.ctrt_value);
                }
            }
        }

        //Count Total Amount
        setTotalAmount(numberWithCommas(totalAmount, 2));
    };

    const tblColumns = useMemo(
        () => [
            {
                Header: 'select',
                accessor: 'select',
                Cell: ({ row, index }: any) => {
                    return (
                        <div className="px-1">
                            <Checkbox
                                onChange={(e: any) =>
                                    handleSelectedRow(
                                        e,
                                        row?.index,
                                        row?.original
                                    )
                                }
                                // defaultChecked
                            />
                        </div>
                    );
                }
            },
            {
                Header: 'Contract No',
                accessor: 'ctrt_contractno'
            },
            {
                Header: 'Purchase Date',
                accessor: 'ctrt_contractdate',
                Cell: (v: any) => {
                    if (v.value) return moment(v.value).format('DD-MM-YYYY');
                }
            },
            {
                Header: 'Due Date',
                accessor: 'ctrt_duedate',
                Cell: (v) => {
                    if (v.value) return moment(v.value).format('DD-MM-YYYY');
                }
            },
            {
                Header: 'Stock Code',
                accessor: 'ctrt_stockcode'
            },
            {
                Header: 'Stock Name',
                accessor: 't_name'
            },
            {
                Header: <div className="text-right">Quantity</div>,
                accessor: 'ctrt_quantity',
                Cell: (v) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value)}
                            </div>
                        );
                }
            },
            {
                Header: <div className="text-right">Price</div>,
                accessor: 'ctrt_price',
                Cell: (v) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value, 3)}
                            </div>
                        );
                }
            },
            {
                Header: <div className="text-right">Contract Value</div>,
                accessor: 'ctrt_value',
                Cell: (v) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value, 2)}
                            </div>
                        );
                }
            },
            {
                Header: <div className="text-right">Interest</div>,
                accessor: 'ctrt_interest',
                Cell: (v) => {
                    if (v.value != undefined)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value, 2)}
                            </div>
                        );
                }
            },
            {
                Header: 'Status',
                accessor: 'ctrt_status',
                Cell: (v) => {
                    switch (v.value) {
                        case 1:
                            return (
                                <div>
                                    <button className="btn btn-success">
                                        T+1
                                    </button>
                                </div>
                            );
                            break;
                        case 2:
                            return (
                                <div>
                                    <button className="btn btn-warning">
                                        T+2
                                    </button>
                                </div>
                            );
                            break;
                        case 3:
                            return (
                                <div>
                                    <button className="btn btn-danger">
                                        T+3
                                    </button>
                                </div>
                            );
                            break;
                    }
                }
            }
        ],
        []
    );

    return (
        <form onSubmit={handleSubmit}>
            <div className="pt-3 text-left funding-settlement-container">
                <div className="row">
                    {/* filter */}
                    <div className="col-xxxl-2 col-xl-2 col-lg-3 col-md-4 col-12">
                        <div className="col-12 main-title">Settlement</div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="input-container">
                                <div className="label">
                                    Username / Account No{' '}
                                </div>
                                <UserAccTreeSelect
                                    onChange={(value: string[]) => {
                                        console.log('UserAccTreeSelect', value);
                                        setCurrentAccNo(value);
                                    }}
                                    onChangeUsername={(val: string) =>
                                        setCurrentUsername(val)
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="input-container ">
                                <div className="label">Name</div>
                                <input
                                    id="username"
                                    className="custom border-bottom"
                                    type="text"
                                    value={currentUsername}
                                    // onChange={({ target }) =>
                                    //     onInputChange(target.id, target.value)
                                    // }
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    {/* filter end div */}

                    {/* body */}
                    <div className="col-xxxl-10 col-xl-10 col-lg-9 col-md-8 col-12">
                        <div className="col-md-12 col-12 pt-3">
                            <ReactTable
                                columns={tblColumns}
                                data={tableData}
                                selectedRows={selectedRows}
                                pageSize={10}
                                currentPage={tablePagination.currentPage}
                                totalRecords={tablePagination.totalRecords}
                                pageChange={(cPage: number) =>
                                    requestGetAccountContractAPI(
                                        currentAccNo,
                                        inputs,
                                        cPage
                                    )
                                }
                                hasPagination
                            />
                        </div>

                        <div className="col-12 ">
                            <div className="pt-3 total-summary-container">
                                <div className="item">
                                    <div className="title">
                                        {' '}
                                        Total Selected:{' '}
                                    </div>
                                    <div className="value">
                                        {Object.keys(selectedRows).length}
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="title"> Total Amount </div>
                                    <div className="value">{totalAmount}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12 pt-3">
                            <div className="input-container ">
                                <div className="label">Bank Name</div>
                                <FormSelect
                                    id="bank_list"
                                    value={inputs.bank_list || ''}
                                    onChange={({ target }: any) =>
                                        onInputChange(target.id, target.value)
                                    }
                                    error={errors.bank_list}
                                >
                                    <option value={''} disabled>
                                        Please select
                                    </option>
                                    {bankList.length > 0 &&
                                        bankList.map((item: any, i: number) => {
                                            return (
                                                <option value={item.bk_id}>
                                                    {item.bk_name}
                                                </option>
                                            );
                                        })}
                                </FormSelect>
                                {/* <select
                                id="bank_list"
                                className="custom"
                                value={inputs.bank_list || ''}
                                onChange={({ target }) =>
                                    onInputChange(target.id, target.value)
                                }
                            >
                                <option value={''} disabled>
                                    Please Select
                                </option>
                                {bankList.length > 0 &&
                                    bankList.map((item: any, i: number) => {
                                        return (
                                            <option value={item.bk_id}>
                                                {item.bk_name}
                                            </option>
                                        );
                                    })}
                            </select> */}
                            </div>
                        </div>

                        <div className="col-12 pt-3">
                            <button
                                className="btn-primary-custom btn-md"
                                type="submit"
                            >
                                Proceed &nbsp;&nbsp;
                                <i
                                    className="fas fa-chevron-right "
                                    aria-hidden="true"
                                ></i>
                            </button>
                        </div>
                    </div>
                    {/* body end div*/}
                </div>
            </div>
        </form>
    );
};

export default Index;
