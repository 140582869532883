import React from 'react';

const OurProductBanner = () => {
    return (
        <>
            <div className="section">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-7 mx-auto text-center">
                            <span className="d-block subheading mb-3">
                                Our Products
                            </span>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-4">
                            <h2 className="heading">TraderStation</h2>
                        </div>
                        <div className="col-lg-6">
                            <p className="text-black-50 text-justify">
                                Our trading system provides a highly
                                customizable layout, allowing the inclusion of
                                up to six components on each page. TraderStation (TDS) adapts
                                seamlessly to multiple devices and browsers with
                                mobile responsiveness to ensure optimal.
                            </p>
                            <p className="mt-5">
                                <a href="/tds" className="more-2">
                                    Learn more{' '}
                                    <span className="icon-arrow_forward"></span>
                                </a>
                            </p>
                        </div>
                    </div>

                    <div className="row g-1">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                            <div className="single-portfolio">
                                <a >
                                    <img
                                        src="/img/slide/slide-1.gif"
                                        alt="Image"
                                        className="img-fluid"
                                    />
                                    <div className="contents">
                                        <h3>TDS</h3>
                                        {/* <div className="cat">TraderStation</div> */}
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="single-portfolio">
                                <a>
                                    <img
                                        src="/img/slide/slide-2.gif"
                                        alt="Image"
                                        className="img-fluid"
                                    />
                                    <div className="contents">
                                        <h3>TDS</h3>
                                        {/* <div className="cat">TraderStation</div> */}
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="single-portfolio">
                                <a>
                                    <img
                                        src="/img/slide/slide-3.gif"
                                        alt="Image"
                                        className="img-fluid"
                                    />
                                    <div className="contents">
                                        <h3>TDS</h3>
                                        {/* <div className="cat">TraderStation</div> */}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section bg-light">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-4">
                            <h2 className="heading">
                                <span style={{ textTransform: 'capitalize' }}>FiNext </span> 
                                Back Office System (FIBOS)
                            </h2>
                        </div>
                        <div className="col-lg-6">
                            <p className="text-black-50 text-justify">
                                FiNext BO System (FIBOS) is architect for both
                                institutional and retail businesses, offering
                                Multi-Market, Multi-Currency, Multi Asset Class
                                and languages capability.
                            </p>
                            <p className="mt-5">
                                <a href="/bos" className="more-2">
                                    Learn more{' '}
                                    <span className="icon-arrow_forward"></span>
                                </a>
                            </p>
                        </div>
                    </div>

                    <div className="row g-1">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                            <div className="single-portfolio">
                                <a>
                                    <img
                                        src="/img/FiNext/BOS/FNX_CTer.jpg"
                                        alt="Image"
                                        className="img-fluid"
                                    />
                                    <div className="contents">
                                        <h3>BOS</h3>
                                        {/* <div className="cat">FIBOS</div> */}
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="single-portfolio">
                                <a>
                                    <img
                                        src="/img/FiNext/BOS/FNX_BOS2_Db.png"
                                        alt="Image"
                                        className="img-fluid"
                                    />
                                    <div className="contents">
                                        <h3>BOS</h3>
                                        {/* <div className="cat">FIBOS</div> */}
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="single-portfolio">
                                <a>
                                    <img
                                        src="/img/FiNext/BOS/FNX_FIBOS_CounterList.png"
                                        alt="Image"
                                        className="img-fluid"
                                    />
                                    <div className="contents">
                                        <h3>BOS</h3>
                                        {/* <div className="cat">FIBOS</div> */}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OurProductBanner;
