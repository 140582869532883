import './App.css';

import { Routes, Route, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import api from './apis';

//import 'antd/dist/antd.css';

import 'antd/dist/antd.min.css';

// import global css
import './components/asset/sb-admin-2/scss/sb-admin-2.scss';
import './components/asset/styles/layout-style.css';
import './components/asset/bootstrap-icons/bootstrap-icons.css';
import './components/asset/styles/main-style.css';
import './components/asset/font-awesome/css/all.css';
import './components/asset/style.css';

//public path
import Missing from './components/pages/public/Missing';
import Unauthorized from './components/pages/public/Unauthorized';
import PgLanding from './components/pages/public/PgLanding';
import PgLogin from './components/pages/public/PgLogin';

//public demo 
import UtradeLanding from './components/pages/public/demoPage/Utrade/UtradeLanding'; //utrade landing
import UtradeLogin from './components/pages/public/demoPage/Utrade/UtradeLogin'; //utrade login

//public landing page
//navigation
import MobileNav from './components/General/MobileNav';
import Nav from './components/pages/public/Landing/components/General/Nav';
import Footer from './components/pages/public/Landing/components/General/Footer';
//home
import LandingPage from './components/pages/public/Landing/LandingPage';
import PgHomePage from './components/pages/public/Landing/components/Home/PgHomePage';
import Tds from './components/pages/public/Landing/components/Product/Tds';
import Bos from './components/pages/public/Landing/components/Product/Bos';
import AboutPage from './components/pages/public/Landing/components/About/AboutPage';
import Contact from './components/pages/public/Landing/components/Contact/Contact';

//protected path
import Dashboard from './components/pages/protected/Dashboard';
import Portfolio from './components/pages/protected/Portfolio';
import TradingPlatform from './components/pages/protected/TradingPlatform';
import CustomerProfile from './components/pages/protected/CustomerProfile';
import AccountSummary from './components/pages/protected/AccountSummary';
import OutstandingPurchase from './components/pages/protected/OutstandingPurchase';
import OutstandingSales from './components/pages/protected/OutstandingSales';
import Contra from './components/pages/protected/Contra';
import ContractInformation from './components/pages/protected/ContractInformation';

import FundingDeposit from './components/pages/protected/FundingDeposit';
import FundingWithdrawal from './components/pages/protected/FundingWithdrawal';
import FundingSettlement from './components/pages/protected/FundingSettlement';
import FundingHistory from './components/pages/protected/FundingHistory';

import TradeHistory from './components/pages/protected/TradeHistory';
import Estatement from './components/pages/protected/Estatement';

import CorporateAction from './components/pages/protected/CorporateAction';

import ResearchReports from './components/pages/protected/ResearchReports';
import Announcement from './components/pages/protected/Announcement';

import DisplayTempFile from './components/pages/protected/DisplayTempFile';
import DisplayFile from './components/pages/protected/DisplayFile';

//import ChangePassword from './components/pages/protected/ChangePassword';

// layout container
import LayoutContainer from './components/layouts/LayoutContainer';

//eform
import EFormDataCorrection from './components/pages/protected/EFormDataCorrection';
import EFormCTACredit from './components/pages/protected/EFormCTACredit';

//forget password
import UpdateForgetPassword from './components/pages/public/UpdateForgetPassword';

//use global loading screen
import { LoadingProvider, useLoading } from './context/LoadingContext';

import WithAxios from './context/WithAxios';
import WithCheckLoginStatus from './context/WithCheckLoginStatus';

function App() {
    const { setLoading } = useLoading();
    const [doneInitial, setDoneInitial] = useState(false);

    const [cookies] = useCookies(['user']);

    useEffect(() => {
        //logined
        if (cookies.user) {
            axiosHearBeat();
        } else {
            setDoneInitial(true);
        }
    }, []);

    useEffect(() => {
        //check if logined

        var body = document.querySelector('body');
        if (cookies.user) {
            console.log('logined');
            console.log(cookies.user);
            body.style.setProperty('overflow', 'hidden');
        } else {
            //landing scrollbar
            body.style.setProperty('overflow', 'initial');
        }
    }, [cookies]);

    useEffect(() => {
        //landing page animation
        window.AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
            mirror: false
        });
    }, []);

    const axiosHearBeat = async () => {
        await api.heartbeat();
        setDoneInitial(true);
    };

    //if got user in cookis then show protected route, else show landing page / login page if login failed
    const SetRoutePaths = cookies.user ? ProtectedRoute() : PublicRoute(); 
    
    return (
        <LoadingProvider>
          
            <WithAxios>
                <WithCheckLoginStatus>
                    <Routes>{doneInitial ? SetRoutePaths : <></>}</Routes>
                </WithCheckLoginStatus>
            </WithAxios>
           
        </LoadingProvider>
    );
}

const ProtectedRoute = () => {
    return (
        <>
            <Route path="/" element={<LayoutContainer />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/trading-platform" element={<TradingPlatform />} />

                <Route path="/trade-history" element={<TradeHistory />} />

                <Route path="/customer-profile" element={<CustomerProfile />} />
                <Route path="/account-summary" element={<AccountSummary />} />
                <Route
                    path="/outstanding-purchase"
                    element={<OutstandingPurchase />}
                />
                <Route
                    path="/outstanding-sales"
                    element={<OutstandingSales />}
                />
                <Route path="/contra" element={<Contra />} />
                <Route
                    path="/contract-information"
                    element={<ContractInformation />}
                />
                <Route path="/funding-deposit" element={<FundingDeposit />} />
                <Route
                    path="/funding-withdrawal"
                    element={<FundingWithdrawal />}
                />
                <Route
                    path="/funding-settlement"
                    element={<FundingSettlement />}
                />
                <Route path="/funding-history" element={<FundingHistory />} />

                <Route path="/eStatement" element={<Estatement />} />

                <Route path="/corporate-action" element={<CorporateAction />} />

                <Route path="/research-reports" element={<ResearchReports />} />

                <Route path="/Announcement" element={<Announcement />} />
                <Route
                    path="/data-correction-request-form"
                    element={<EFormDataCorrection />}
                />
                <Route path="/cta-ecredit-form" element={<EFormCTACredit />} />
                <Route
                    path="reset_password"
                    element={<UpdateForgetPassword />}
                />
                {/* <Route
                    path="/change-password"
                    element={<ChangePassword />}
                /> */}
                <Route path="*" element={<Navigate to="/" />} />
            </Route>
            <Route path="/file/:file" element={<DisplayTempFile />} />
            <Route path="/file/:a_id/:file" element={<DisplayFile />} />
        </>
    );
};

const PublicRoute = () => {
    return (
        // <Route path="/">
        //     {/* public routes */}
        //     <Route path="/" element={<PgLanding />} />
        //     {/* } /> */}

        //     <Route path="unauthorized" element={<Unauthorized />} />
        //     {/* <Route path="/" element={<PgLanding />} /> */}
        //     <Route path="login" element={<PgLogin />} />
        //     <Route path="reset_password" element={<UpdateForgetPassword />} />

        //     {/* catch all */}
        //     <Route path="*" element={<Navigate to="/login" replace />} />
        // </Route>

        <>
            <Route path="/" element={<LandingPage />}>
                {/* landing page  */}
                {/* public routes */}
                <Route index element={<PgHomePage />} />
                <Route path="/home" element={<PgHomePage />} />
                <Route path="/tds" element={<Tds />} />
                <Route path="/bos" element={<Bos />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/contact" element={<Contact />} />

                {/* } /> */}
                {/* End landing page */}
            </Route>
            <Route path="login" element={<PgLogin />}></Route>
            <Route path="Utrade" element={<UtradeLanding />}></Route>
            <Route path="UtradeLogin" element={<UtradeLogin />}></Route>

            <Route path="unauthorized" element={<Unauthorized />} />
            {/* <Route path="/" element={<PgLanding />} /> */}
            {/* <Route
                                path="reset_password"
                                element={<UpdateForgetPassword />}
                            /> */}

            {/* catch all */}
            <Route path="*" element={<Navigate to="/home" replace />} />
        </>
    );
};

export default App;
