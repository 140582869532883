//parse result to object type
export function parseResult(result: any) {
    try {
        let paresedResult = JSON.parse(result);
        // console.log(paresedResult)
        if (paresedResult) {
            if (paresedResult.status == 1) {
                console.log(paresedResult);
            }
            return paresedResult.data;
        }
    } catch (error) {
        console.log(error);
    }
    return false;
}

export function parseSubmitResult(result: any) {
    try {
        let paresedResult = JSON.parse(result);
        // console.log(paresedResult)
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
        console.log(error);
    }
    return false;
}

//isFirst = 0 "a=xxx",isFirst = 1 "&a=xxx"
export function queryParamStr(
    queryKey: string,
    queryValue: any,
    isFirst = false
) {
    if (!queryValue && queryValue !== 0) return '';

    let str = '';
    if (isFirst == false) str += '&';

    str += queryKey + '=' + queryValue;
    return str;
}

export function queryParamStrArr(
    queryKey: string,
    queryValue: any,
    isFirst = false
) {
    if (!queryValue || queryValue.length <= 0) return '';

    let str = '';

    for (var i = 0; i < queryValue.length; i++) {
        let item = queryValue[i];

        if (isFirst == false) str += '&';
        str += queryKey + '=' + item;
    }

    return str;
}
