import { useState, useEffect, useMemo } from 'react';

import api from '../../../apis';
import moment from 'moment';
import { useLoading } from '../../../context/LoadingContext';

import ReactTable from '../../general/form-control/react-table';
import DetailTable from '../../general/info-control/table-detail';
import statusText from '../../../constants/property';
import StockTreeSelect from '../../general/form-control/stock-tree-select';
import ExchangeTreeSelect from '../../general/form-control/exchange-tree-select';
import UserAccTreeSelect from '../../general/form-control/user-acc-tree-select';

import { HeaderDiv } from '../../tables/helper';
import { parseAPIResultToData } from '../../general/form-control/tree-select/helper';

import { numberWithCommas } from '../../general/convertor';

import { usePagination } from '../../tables/helper';

import DateRangePicker from '../../general/form-control/date-rangepicker';

import { DatePicker, Tooltip } from 'antd';
const { RangePicker } = DatePicker;

const total_buy_sell_table_format = () => {
    const total_buy_sell_table_format = [
        {
            id: 'total',
            label: 'No of Contracts',
            type: 'quantity',
            value: '-'
        },
        {
            id: 'quantity',
            label: 'Quantity',
            type: 'quantity',
            value: '-'
        },
        {
            id: 'amount',
            label: 'Value',
            type: 'value',
            value: '-'
        }
    ];

    return total_buy_sell_table_format;
};

const Index = () => {
    const { setNotiLoading } = useLoading();
    const { tablePagination, setTablePagination } = usePagination();

    useEffect(() => {}, []);

    const [currentAccNo, setCurrentAccNo] = useState([]);
    const [inputs, setInputs] = useState<any>({});

    const [tableData, setTableData] = useState([]);
    // const [tablePagination, setTablePagination] = useState<any>({
    //     currentPage: 1,
    //     totalRecords: 0
    // });

    // usePagination;

    const [totalBuyData, setTotalBuyData] = useState<any>(null);
    const [totalSellData, setTotalSellData] = useState<any>(null);

    useEffect(() => {
        if (currentAccNo.length > 0) {
            setNotiLoading(true);
            console.log('inputs', inputs);
            // showNotiLoader();
            const delayDebounceFn = setTimeout(() => {
                requestGetTradeHistorytAPI(currentAccNo);
            }, 600);
            return () => clearTimeout(delayDebounceFn);
        } else if (currentAccNo.length == 0) {
            handleResetTables();
        }
    }, [currentAccNo, inputs]);

    useEffect(() => {}, [tableData]);

    const onInputChange = (id: string, newValue: string) => {
        setInputs((prevState) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    const onDropdownChange = (id: string, newValue: string) => {
        setInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    const onDateChange = ({ id, timestamp }: any) => {
        let cdate = '';
        if (timestamp) {
            cdate = moment(timestamp, 'DD-MM-YYYY').format('YYYY-MM-DD');
        }
        onDropdownChange(id, cdate);
    };

    const requestGetTradeHistorytAPI = async (accNo: any, cPage = 1) => {
        setNotiLoading(true);

        console.log(inputs);
        let result = await api.getTradeHistory(accNo, inputs, cPage);
        let resultSummary = await api.getTradeHistorySummary(accNo, inputs);

        console.log('getTradeHistory', result);
        // console.log('resultSummary', resultSummary.summary);
        if (result.contracts) {
            let contracts = result.contracts;
            //  console.log(contracts);
            setTableData(contracts);
            //  updateAccSummaryComponent(account);
        }

        if (result.total) {
            // handlePagination('currentPage', cPage);
            // handlePagination('totalRecords', result.total);
            setTablePagination({
                currentPage: cPage,
                totalRecords: result.total
            });
        }

        if (resultSummary.summary) {
            let summary = resultSummary.summary;
            //  console.log(contracts);
            handleTradeHistorySummary(summary);
            //  updateAccSummaryComponent(account);
        }

        // hideNotiLoader();
        setNotiLoading(false);
    };

    const updateTreeSelect = (result: any) => {
        console.log(result);
        let newArr = parseAPIResultToData(result);
        setUserAccTreeData(newArr);
    };
    //tree select end

    const handleSubmit = (e: any) => {
        e.preventDefault();
    };

    function truncate(source, size) {
        return source.length > size ? source.slice(0, size - 1) + '…' : source;
    }

    const tblColumns = useMemo(
        () => [
            // {
            //     Header: 'Date',
            //     id: 'date',
            //     accessor: 'entrytime',
            //     Cell: (v: any) => {
            //         if (v.value) return moment(v.value).format('DD-MM-YYYY');
            //     }
            // },
            // {
            //     Header: 'Order Time',
            //     id: 'order_time',
            //     accessor: 'entrytime',
            //     Cell: (v: any) => {
            //         if (v.value) return moment(v.value).format('hh:mm A');
            //     }
            // },
            {
                Header: 'Order Time',
                id: 'order_time',
                accessor: 'entrytime',
                Cell: (v: any) => {
                    if (v.value)
                        return moment(v.value).format('YYYY-MM-DD HH:mm:ss');
                }
            },
            {
                Header: 'Account No.',
                accessor: 'orderno'
            },
            {
                Header: 'Side',
                accessor: 'tradeside_name'
            },
            {
                Header: 'Symbol',
                accessor: 't_name',
                Cell: ({ row }) => {
                    let rowData = row?.original;
                    let e_name = rowData.e_name || '';

                    if (e_name) {
                        e_name = truncate(e_name, 5);
                    }

                    // console.log(row?.original)
                    return (
                        <div className="d-flex gap-2 align-items-center">
                            <Tooltip placement="topLeft" title={rowData.e_name}>
                                <div className="custom-tag cursor-pointer">
                                    {e_name}
                                </div>
                            </Tooltip>
                            <div>{rowData.t_name}</div>
                        </div>
                    );
                }
            },
            {
                Header: 'Currency',
                accessor: 'c_code'
            },
            {
                Header: <HeaderDiv content={'Order Quantity'} type={'value'} />,
                accessor: 'ordqty',
                Cell: (v: any) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value)}
                            </div>
                        );
                }
            },
            {
                Header: <div className="text-right">Order Prc.</div>,
                accessor: 'ordprc',
                Cell: (v: any) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value, 3)}
                            </div>
                        );
                }
            },
            {
                Header: <div className="text-right">Match Quantity</div>,
                accessor: 'quantity',
                Cell: (v: any) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value)}
                            </div>
                        );
                }
            },
            {
                Header: <div className="text-right">Average Prc.</div>,
                accessor: 'price',
                Cell: (v: any) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value, 4)}
                            </div>
                        );
                }
            },
            {
                Header: <div className="text-right">Match Amount</div>,
                accessor: 'amount',
                Cell: (v: any) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value, 2)}
                            </div>
                        );
                }
            },
            {
                Header: 'Order Type',
                accessor: 'ordtype_name'
            },
            {
                Header: 'Validity',
                accessor: 'validity_name'
            }
        ],
        []
    );

    const handleTradeHistorySummary = (summary = {}) => {
        let buy = summary.buy || {};
        let sell = summary.sell || {};

        if (buy) {
            let data = total_buy_sell_table_format();
            for (var i = 0; i < data.length; i++) {
                let col = data[i];
                let id = col.id;

                let cValue = buy[id]; //buy value

                col.value = cValue || '-';

                // alert(buy[id]);
                // alert(JSON.stringify(col));
                // if (buy[id]) col.value = buy[id];
            }

            setTotalBuyData([...data]);
        }

        if (sell) {
            let data = total_buy_sell_table_format();
            for (var z = 0; z < data.length; z++) {
                let col = data[z];
                let id = col.id;

                let cValue = sell[id]; //sell value
                col.value = cValue || '-';
            }
            // alert(JSON.stringify(data));
            setTotalSellData(data);
        }
    };

    const handleResetTables = () => {
        //reset data
        setTableData([]);
        setTotalBuyData(null);
        setTotalSellData(null);
        setNotiLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="pt-3 text-left ">
                <div className="row">
                    <div className="col-12 main-title">Trade History</div>
                </div>
                <div className="row input-column-gap">
                    {' '}
                    <div className="col-xxxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
                        <div className="input-container">
                            <div className="label">Username / Account No </div>
                            <UserAccTreeSelect
                                onChange={(value: string[]) => {
                                    console.log('UserAccTreeSelect', value);
                                    setCurrentAccNo(value);
                                }}
                            />
                            {/* <TreeSelect
                                treeMap={['username', 'accNo']}
                                data={userAccTreeData}
                                setLoading={treeSelectLoading}
                                keyinChange={(keywords: any) =>
                                    onTreeKeyinChange(keywords)
                                }
                                onSelectChange={(selectInputObject: any) =>
                                    onTreeSelectChange(selectInputObject)
                                }
                                onSelectChangeParentValue={(
                                    parentValue: any
                                ) => {
                                    setCurrentUsername(parentValue || '');
                                }}
                            /> */}
                        </div>
                    </div>
                    {/* <div className="col-xxxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
                        <div className="input-container">
                            <div className="label">Exchange</div>
                            <ExchangeTreeSelect
                                onChange={(value: string[]) => {
                                    console.log('ExchangeTreeSelect');
                                    console.log(value);
                                }}
                            />
                        </div>
                    </div> */}
                    <div className="col-xxxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
                        <div className="input-container ">
                            <div className="label">Exchange </div>
                            <ExchangeTreeSelect
                                onChange={(value: string[]) => {
                                    console.log('ExchangeTreeSelect');
                                    console.log(value);

                                    onInputChange('exchanges', value);
                                }}
                            />
                            {/* <InputStock
                                onChange={(selectInputObject: any) => {
                                    // alert(selectInputObject.value);
                                    return onDropdownChange(
                                        'stock',
                                        selectInputObject.value
                                    );
                                }}
                            /> */}
                        </div>
                    </div>
                </div>
                <div className="row input-column-gap">
                    {' '}
                    <div className="col-xxxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
                        {' '}
                        <div className="input-container ">
                            <div className="label">Side </div>
                            <select
                                id="side"
                                className="custom"
                                value={inputs.side || ''}
                                onChange={(e) =>
                                    onDropdownChange(
                                        e.target.id,
                                        e.target.value
                                    )
                                }
                            >
                                <option value={''}>All</option>
                                {Object.entries(statusText.tradeSides).map(
                                    ([key, value]) => {
                                        return (
                                            <option value={key}>{value}</option>
                                        );
                                    }
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="col-xxxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
                        {' '}
                        <div className="input-container ">
                            <div className="label">Stock Name</div>
                            <StockTreeSelect
                                filter={inputs}
                                onChange={(value: string[]) => {
                                    console.log('StockTreeSelect');
                                    console.log(value);

                                    onInputChange('stocks', value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-xxxxl-3 col-xl-4 col-lg-4 col-md-12 col-12">
                        <div className="input-container ">
                            <div className="label">Order Date </div>
                            <DateRangePicker
                                onDateChange={(obj: any) => onDateChange(obj)}
                            />
                            {/* <RangePicker
                                allowClear={true}
                                disabledDate={(current) => {
                                    // Can not select days before today and today
                                    return (
                                        current &&
                                        current >= moment().endOf('day')
                                    );
                                }}
                                className="w-100 daterangepicker custom"
                                format="DD-MM-YYYY"
                                // popupClassName="dark"
                                onChange={(dates, dateStrings) => {
                                    console.log(dateStrings);

                                    onDateChange({
                                        id: 'dateStart',
                                        timestamp: dateStrings[0]
                                    });

                                    onDateChange({
                                        id: 'dateEnd',
                                        timestamp: dateStrings[1]
                                    });
                                }}
                            /> */}
                            {/* <div
                                className="d-flex w-100"
                                style={{ gap: '20px' }}
                            >
                           
                                <div className="w-50">
                          
                                    <Datepicker
                                        id="dateStart"
                                        placeholder="from"
                                        // value={searchInputs.dateStart}
                                        // onChange={onDateChange}
                                    />
                                </div>
              
                                <div className="w-50">
                                    <Datepicker
                                        id="dateEnd"
                                        placeholder="to"
                                        // value={searchInputs.dateEnd}
                                        // onChange={onDateChange}
                                    />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="row input-column-gap"></div>
                <div className="row input-column-gap">
                    {' '}
                    <hr className="mt-4 mb-4" />
                    {/* ....... */}
                    <div className="col-md-12 col-12 pt-5">
                        <div className="row">
                            <div className="col-12 ">
                                <div className="row">
                                    <div className="col-md-6 col-12 pb-4">
                                        {/* total buy table */}
                                        <DetailTable
                                            data={
                                                totalBuyData ||
                                                total_buy_sell_table_format()
                                            }
                                            titleProps={{
                                                label: 'Total Purchase',
                                                bgColor: '#439782'
                                            }}
                                            itemProps={{
                                                bottomLine: false,
                                                className: 'col-md-4 col-12'
                                            }}
                                            labelProps={{
                                                className: 'text-center'
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6 col-12 pb-4">
                                        {/* total sell table */}
                                        <DetailTable
                                            data={
                                                totalSellData ||
                                                total_buy_sell_table_format()
                                            }
                                            titleProps={{
                                                label: 'Total Sales',
                                                bgColor: '#E0484C'
                                            }}
                                            itemProps={{
                                                bottomLine: false,
                                                className: 'col-md-4 col-12'
                                            }}
                                            labelProps={{
                                                className: 'text-center'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ....... */}
                    <div className="col-md-12 col-12 pt-5">
                        <ReactTable
                            columns={tblColumns}
                            data={tableData}
                            pageSize={10}
                            currentPage={tablePagination.currentPage}
                            totalRecords={tablePagination.totalRecords}
                            pageChange={(cPage: number) => {
                                // showNotiLoader();
                                requestGetTradeHistorytAPI(currentAccNo, cPage);
                            }}
                            hasPagination
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};

export default Index;
