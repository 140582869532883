export default {
    baseURL: '/api',
    version: 'v1.0.30',
    timerAlertCountdown: '120',
    Crypto: {
        ECDH: {
            PublicKeyB:
                'BAD1Fc9PG29SdYLnwP1qb3Tl8J+NAB2+XcofH3sUKqL/amZ7c1mXZINJkVJY+ifm93N+FV9N/mPmzTUacOXlXvn1NgHYRq3s00mrD6mNToJOM5MXSDdHdSwFeoIsOfA7FGU1ICce1lYQ4MeHoCiv0TJHm4iCYVLahOe8jXtrm+5NAaFfqQ=='
        }
    },
    fileUrl:"http://admin.finext.com.my/admin/api/file/"
};
