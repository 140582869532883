import { useState, useEffect } from 'react';
import './index.css';
import Config from '../../../config';

interface Property {
    data: Array<CardListItem>;
    onClick?: any;
}

type CardListItem = {
    title?: any;
    subTitle?: any;
    desc?: any;
    img?: string;
};

const isValidUrl = (urlString) => {
    try {
        return Boolean(new URL(urlString));
    } catch (e) {
        return false;
    }
};

const CardList = (props: Property) => {
    const [cardList, setCardList] = useState(props.data || []);

    useEffect(() => {
        setCardList(props.data);
    }, [props.data]);

    return (
        <div className="card-list-container">
            {cardList.map(function (row: any, i: number) {
                // let imgUrl = isValidUrl(row.img)
                //     ? row.img
                //     : Config.fileUrl + row.img;
                // console.log('row123 ', row);
                return (
                    <div
                        key={i}
                        onClick={() =>
                            props.onClick ? props.onClick(row) : null
                        }
                    >
                        <Card
                            img={row.img}
                            subTitle={row.subTitle}
                            title={row.title}
                            desc={row.desc}
                            category={row.category}
                        />
                    </div>
                );
            })}
        </div>
    );
};

const Card = (props: any) => {
    const [loaded, setLoaded] = useState(false);

    return (
        <>
            {/* loader */}
            {loaded ? null : (
                <div className="o-vertical-spacing item">
                    <h3 className="skeleton__headline">
                        <span
                            className="skeleton-box"
                            style={{ width: '55%' }}
                        ></span>
                    </h3>
                    <p>
                        <span
                            className="skeleton-box"
                            style={{ width: '80%' }}
                        ></span>
                        <span
                            className="skeleton-box"
                            style={{ width: '90%' }}
                        ></span>
                        <span
                            className="skeleton-box"
                            style={{ width: '83%' }}
                        ></span>
                        <span
                            className="skeleton-box"
                            style={{ width: '80%' }}
                        ></span>
                    </p>
                    {/* <div className="blog-post__meta">
                    <span
                        className="skeleton-box"
                        style={{ width: '70px' }}
                    ></span>
                </div> */}
                </div>
            )}
            {/* --------------- */}
            <div className="item" style={loaded ? {} : { display: 'none' }}>
                <img
                    className="img-div"
                    src={props.img}
                    onLoad={() => setLoaded(true)}
                />
                {props.category && (
                    <div className="custom-tag cursor-pointer category">
                        {props.category}
                    </div>
                )}

                <div className="sub-title">{props.subTitle}</div>
                <div className="title">{props.title}</div>
                <div className="desc">{props.desc}</div>
            </div>
        </>
    );
};

export default CardList;
