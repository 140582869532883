import { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import {
    useTable,
    useGroupBy,
    useExpanded,
    useFilters,
    useGlobalFilter
} from 'react-table';

import './index.css';

//data format
interface PropsData {
    columns?: Array[];
    data?: Array[];
    hideColumns?: Array[];
    groupbyID?: Array[];
    tableStyle?: any;
    tableClassname?: any;
    onRowClick?: any;
}

const defaultGroupbyID = 'e_name';
let prevHiddenColumns = [];
let prevGroupby = [];

const Index = ({
    columns,
    data,
    hideColumns,
    groupbyID,
    tableStyle,
    tableClassname,
    onRowClick
}: PropsData) => {
    useEffect(() => {}, []);

    /**
     * Custom Filter Fucntion ----
     * Only filter by: code * name
     */
    const ourGlobalFilterFunction = useCallback(
        (rows: Row<T>[], ids: IdType<T>[], query: string) => {
            // console.log('rows', rows);
            // console.log('query', query);
            return rows.filter((row) => {
                let lower_values = row.values['t_code'].toLowerCase();
                let lower_values2 = row.values['t_name']
                    ? row.values['t_name'].toLowerCase()
                    : '';

                let lower_query = query.toLowerCase();
                return (
                    lower_values.includes(lower_query) ||
                    lower_values2.includes(lower_query)
                );
            });
        },
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // setFilter is the key!!!
        setFilter,
        setGlobalFilter,
        state: { groupBy, expanded },
        toggleAllRowsExpanded,
        isAllRowsExpanded,
        setGroupBy,
        setHiddenColumns
    } = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: hideColumns || [],
                groupBy: groupbyID || defaultGroupbyID
            },
            getResetExpandedDeps: false,
            autoResetExpanded: false,
            // Use our custom function
            globalFilter: ourGlobalFilterFunction
        },
        useGlobalFilter,
        useFilters,
        useGroupBy,
        useExpanded // useGroupBy would be pretty useless without useExpanded ;)
    );

    // useEffect(() => {
    //     if (JSON.stringify(prevHiddenColumns) != JSON.stringify(hideColumns)) {
    //         if (hideColumns) {
    //             prevHiddenColumns = hideColumns || defaultGroupbyID;
    //             setHiddenColumns(hideColumns);
    //         }
    //     }
    // });

    useEffect(() => {
        // setGroupBy(['market']);
        toggleAllRowsExpanded(true);

        //setFilter('test');
    }, []);

    useEffect(() => {
        // setGroupBy(['test']);
        // setGroupBy(groupbyID);
        // if (JSON.stringify(prevGroupby) != JSON.stringify(groupbyID)) {
        //     if (groupbyID) {
        //         alert('ere');
        //         prevGroupby = groupbyID || [];
        //         setGroupBy(groupbyID);
        //         // if (
        //         //     JSON.stringify(prevHiddenColumns) !=
        //         //     JSON.stringify(hideColumns)
        //         // ) {
        //         //     if (hideColumns) {
        //         //         prevHiddenColumns = hideColumns || defaultGroupbyID;
        //         //         setHiddenColumns(hideColumns);
        //         //     }
        //         // }
        //     }
        // }
        // setExpanded(true);
        toggleAllRowsExpanded(true);
    }, [groupbyID]);

    useEffect(() => {
        // toggleAllRowsExpanded(true);
    }, [isAllRowsExpanded]);

    // We don't want to render all of the rows for this example, so cap
    // it at 100 for this use case
    const firstPageRows = rows.slice(0, 100);

    const handleFilterInputChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = e.currentTarget;
        setGlobalFilter(value);
    };

    return (
        <div style={{ overflow: 'auto', height: 'fit-content' }}>
            <div className="search-box-container p-1 just">
                <div className="search-box ">
                    {' '}
                    <input
                        placeholder="Search Code or Name"
                        onChange={handleFilterInputChange}
                    />
                    <i className={'fas fa-search '}></i>
                </div>
            </div>

            <div className="pt-3">
                <table
                    className={'react-table-group ' + (tableClassname || '')}
                    {...getTableProps()}
                    style={tableStyle}
                >
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => {
                                    return (
                                        <th
                                            {...column.getHeaderProps({
                                                style: { width: column.width }
                                            })}
                                        >
                                            {column.render('Header')}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {firstPageRows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    onClick={
                                        onRowClick
                                            ? (e) => onRowClick(row?.original)
                                            : null
                                    }
                                >
                                    {row.cells.map((cell) => {
                                        let colSpan = null;
                                        let positionAbsolute = false;
                                        if (cell.isAggregated) {
                                            //console.log(cell?.column?.colSpan);

                                            if (cell?.column?.colSpan) {
                                                colSpan = cell?.column?.colSpan;
                                            } else if (
                                                cell?.column?.colSpan === 0
                                            ) {
                                                return <></>;
                                            }

                                            // if (cell?.column.id == 'code') {
                                            //     positionAbsolute = true;
                                            //     colSpan = 2;
                                            // } else {
                                            // }

                                            // if (cell?.column.id == 'name') {
                                            //     //hide column for market column
                                            //     return <></>;
                                            // } else {
                                            // }
                                        }
                                        return (
                                            <td
                                                // For educational purposes, let's color the
                                                // cell depending on what type it is given
                                                // from the useGroupBy hook
                                                {...cell.getCellProps()}
                                                style={{
                                                    background: cell.isGrouped
                                                        ? '#0aff0082'
                                                        : cell.isAggregated
                                                        ? '#f1f3f9'
                                                        : cell.isPlaceholder
                                                        ? '#ff000042'
                                                        : 'white'
                                                    //  position:positionAbsolute ==true? "absolute":""
                                                }}
                                                className={
                                                    cell.isAggregated
                                                        ? ' isAggregated '
                                                        : ''
                                                }
                                                colSpan={colSpan}
                                            >
                                                {cell.isGrouped ? (
                                                    // If it's a grouped cell, add an expander and row count
                                                    <>
                                                        {/* {cell.original} */}
                                                        <b>
                                                            {cell.render(
                                                                'Cell'
                                                            )}{' '}
                                                            (
                                                            {row.subRows.length}
                                                            )
                                                        </b>
                                                    </>
                                                ) : cell.isAggregated ? (
                                                    // If the cell is aggregated, use the Aggregated
                                                    // renderer for cell
                                                    <>
                                                        {' '}
                                                        <b>
                                                            {cell.render(
                                                                'Aggregated'
                                                            )}
                                                        </b>
                                                    </>
                                                ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                                                    // Otherwise, just render the regular cell
                                                    cell.render('Cell')
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                        <tr>
                            {rows.length == 0 && (
                                <td
                                    colSpan={headerGroups[0]?.headers.length}
                                    className="no-data "
                                >
                                    No Data
                                </td>
                            )}
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            {/* <div>Showing the first 100 results of {rows.length} rows</div> */}
            {/* <div>Showing 1 to 9 of {rows.length} entries</div> */}
        </div>
    );
};

export default Index;
