import { useState, useEffect } from 'react';
import './index.css';
import moment from 'moment';

interface Property {
    opened: boolean;
    children?: any;
    onClosed?: any;
}


const PopoutModal = (props: Property) => {
    const [opened, setOpened] = useState(props.opened || []);
    const [fileContent, setFileContent] = useState(null);

    useEffect(() => {
        //alert(props.opened);
        setOpened(props.opened);
    }, [props.opened]);

    const handleClosed = () => {
        if (props.onClosed) {
            props.onClosed(false);
        }
    };

    //download file
    useEffect(() => {
        if (props.children.props.file_content) {
            setFileContent(props.children.props.file_content);
        }
    }, [props.children.props.file_content]);

    const downloadFile = () => {
        if (fileContent) {
            startDownloadFile(fileContent, fileContent);
        }
    };

    function startDownloadFile(filename: string, text: string) {
        const link = document.createElement('a');
        link.href = fileContent;
        link.download = 'test.pdf';
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    //download file
    
    //css for subtitle (source / date)
    const sectionStyle = {
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '18px',
        color: '#2b2e38',
        paddingLeft: '5px',
    }
    
    return (
        <div style={{ position: 'absolute', width: '100%' }}>
            <div
                className={
                    'react-modal ' + (opened == true ? ' show ' : ' hide ')
                }
            >
                <div className="content">
                    <div className="header" style={{padding: '0rem 2rem', alignItems: 'center', }}>
                        <div className="left-sec" style={{paddingTop: '2px', paddingRight: '5px'}}>
                            {/* <button onClick={() => handleClosed()}>
                                close
                            </button> */}
                            <i
                                className="fas fa-chevron-left "
                                aria-hidden="true"
                                onClick={() => handleClosed()}
                            ></i>
                        </div>
                        <div className="article-title" style={{display:'flex', alignItems:'center', flexDirection:'column',}}>
                            {props.children.props.title}
                            <div className="article" style={sectionStyle}>
                                <div className="article-sub-header">
                                    <div className="section-info">
                                        {props.children.props.source ? props.children.props.source + ' • ' : ''}
                                        {props.children.props.b_publish_date_start && moment(props.children.props.b_publish_date_start).format('MMM DD YYYY')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-sec">
                            <div className="content-text">
                                {fileContent && (
                                    <button className="btn-primary-custom btn-md" onClick={() => downloadFile()} style={{width: '120px', float: 'right',}}>
                                        Download
                                    </button>
                                )}
                                </div>
                        </div>
                    </div>
                    <div
                        className={
                            'body' + (opened == true ? ' show ' : ' hide ')
                        }
                    >
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopoutModal;
