import React from 'react';
import { TopBannerV2, TopBannerWordOnly } from '../General/TopBanner';

const AboutPage = () => {
    const img_Whoweare = {
        display: 'flex',
        alignItems: 'center'
    };

    return (
        <div className="AboutUS">
            {/* <TopBannerWordOnly
                data={{
                    title: 'About Us'
                }}
            /> */}

            <TopBannerV2
                data={{
                    name: ' About Us',
                    details:
                        'At FiNext, our mission is to help financial institutions streamline operations and improve efficiency, to better serve clients and grow businesses. ' +
                        "We understand the challenges that financial institution face in today's fast-paced and ever-evolving financial market. " +
                        "That's why we have developed a suite of innovative products and services that are designed to make life easier and businesses more successful. " +
                        'Our team of experts has years of experience in the financial market and a deep understanding of the technology that drives it. ' +
                        'We work closely with our customers to understand specific needs and develop customized solutions that meet unique requirements.',
                    detailsCont:
                        'We believe that technology should empower financial institution, not replacing them. ' +
                        'That is why our solutions are designed to augment the skills and expertise of our customers, to make informed decisions and achieve goals more effectively. ' +
                        'At FiNext, we are dedicated to providing the highest level of customer service and support.' +
                        'We are here to assist every step of the way, from product implementation to ongoing training and support.',
                    imageURL: '/img/FiNext/Official/FNXKL.jpg',
                    style: true
                }}
            />

            <div className="section pb-5">
                <div className="container" style={{ width: '90%' }}>
                    {/* <div className="row mb-5">
                        <div className="col-lg-7">
                            <span className="d-block subheading mb-3">
                                About
                            </span>
                            <h2 className="heading">Who We Are</h2>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-lg-6 mb-5" style={img_Whoweare}>
                            <div className="img-about">
                                <img
                                    src="/img/FiNext/Official/whoweare_selection2_cut.jpg"
                                    alt="Image"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center section-counter">
                            {/* <h3 className="fw-bold mb-4">About us</h3> */}
                            <p className="text-black-50 text-justify">
                                FiNext stands as a Bursa Malaysia approved
                                Independent Software Vendor (ISV), a recently
                                emerged startup committed to delivering
                                inventive Fintech solutions. Despite our nascent
                                status, our team boasts a rich wealth of
                                experience that draws from both vendor and
                                broker perspectives. This extensive knowledge
                                base allows us to deeply comprehend financial
                                instituion's needs across trading front-end and
                                back-end operations.
                            </p>

                            <p className="text-black-50 text-justify">
                                As a result, we have meticulously designed our
                                system through the lens of a financial
                                instituion's viewpoint, ensuring its alignment
                                with business anticipations. Our transition from
                                resource-intensive desktop systems to
                                streamlined web-based alternatives underscores
                                our commitment to mobility, flexibility,
                                user-friendliness, and scalability. This
                                strategic approach empowers financial
                                institution to optimize operational efficiency
                                and output.
                            </p>

                            <p className="text-black-50 mb-5 text-justify">
                                Within the FiNext system lies the capacity to
                                adeptly manage unforeseen surges in demand, all
                                while steadfastly upholding IT security
                                compliance and delivering budget-conscious
                                solutions.
                            </p>

                            {/* <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    <div className="mb-3">
                                        <div className="counter">
                                            <span className="countup">
                                                3424
                                            </span>{' '}
                                            <span className="text-black-50">
                                                Projects
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="counter">
                                            <span className="countup">291</span>{' '}
                                            <span className="text-black-50">
                                                Clients
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="mb-3">
                                        <div className="counter">
                                            <span className="countup">
                                                2390
                                            </span>{' '}
                                            <span className="text-black-50">
                                                Employees
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="counter">
                                            <span className="countup">25</span>{' '}
                                            <span className="text-black-50">
                                                Years of Experience
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <p className="mt-5">
                                <a href="#" className="more-2">
                                    Learn more{' '}
                                    <span className="icon-arrow_forward"></span>
                                </a>
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="section pb-5 bg-light">
                <div className="container" style={{ width: '90%' }}>
                    <div className="row mb-5">
                        <div className="col-lg-7 mx-auto text-center">
                            <span className="d-block subheading mb-3">
                                Our Mission
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 align-self-center section-counter p-2">
                            <p className="text-black-50 text-justify">
                                At FiNext, our mission is to help financial
                                institutions streamline operations and improve
                                efficiency, to better serve clients and grow
                                businesses. We understand the challenges that
                                financial institution face in today's fast-paced
                                and ever-evolving financial market. That's why
                                we have developed a suite of innovative products
                                and services that are designed to make life
                                easier and businesses more successful. Our team
                                of experts has years of experience in the
                                financial market and a deep understanding of the
                                technology that drives it. We work closely with
                                our customers to understand specific needs and
                                develop customized solutions that meet unique
                                requirements.
                            </p>

                            <p className="text-black-50 text-justify">
                                We believe that technology should empower
                                financial institution, not replacing them. That
                                is why our solutions are designed to augment the
                                skills and expertise of our customers, to make
                                informed decisions and achieve goals more
                                effectively. At FiNext, we are dedicated to
                                providing the highest level of customer service
                                and support.We are here to assist every step of
                                the way, from product implementation to ongoing
                                training and support.
                            </p>
                        </div>

                        <div className="col-lg-6 mb-5" style={img_Whoweare}>
                            <div className="img-about">
                                <img
                                    src="/img/FiNext/Official/FNXKL.jpg"
                                    alt="Image"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

        </div>
    );
};

export default AboutPage;
