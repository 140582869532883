import React, { useEffect, useState } from 'react';

import './assets/style.css';

import TopHeader from './components/topHeader';
import Footer from './components/footer';

const topLogoNav = () => {
    return (
        <nav
            id="main-navigation-wrapper"
            className="navbar navbar-default navbar-expand-lg navbar-light"
        >
            <div className="container" style={{width:'1140px'}}>
                <div className="logo navbar-brand">
                    <a
                        href="/Utrade"
                        className="logo-desktop"
                    >
                        <img
                            src="https://www.utrade.com.my/img/logo.png"
                            alt="UOB Kay Hian"
                        />
                    </a>
                    <a
                        href="/Utrade"
                        className="logo-mobile"
                    >
                        <img
                            src="https://www.utrade.com.my/img/logo.png"
                            alt="Awesome Image"
                        />
                    </a>
                </div>
                <div className="navbar-header ">
			<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navigation" aria-controls="main-navigation" aria-expanded="false" aria-label="Toggle navigation">
		    		<span className="navbar-toggler-icon"></span>
		  	</button>

            </div>
                <div id="main-navigation" className="collapse navbar-collapse" role="navigation">
                    <ul id="header-menu" className="nav navbar-nav mr-auto float-right ">
                        <li className="dropdown">
                            <a className=""  href="/Utrade"><i className="fa fa-home font-size-18px" aria-hidden="true"></i></a></li>
                        <li className="dropdown">
                            <a className="cd-menu"  href="/UtradeLogin">
                                <p className="C33B32">
                                    <i className="fa fa-lock font-size-18px" aria-hidden="true"></i><b> LOGIN</b>
                                </p>
                            </a>
                        </li>
                        <li className="dropdown"><a className="cd-menu"  href="#">Open An Account</a></li>
                        <li className="dropdown">
                            <a href="#">Why UTRADE</a>
                        </li>
                        <li className="dropdown">
                            <a href="#">Products &amp; Services</a>
                        </li>
                        <li className="dropdown d-none">
                            <a href="#">Platforms</a>
                        </li>
                        <li className="dropdown">
                            <a href="#">Tools</a>
                        </li>
                        <li className="dropdown"><a href="#">Research</a></li>
                        <li className="dropdown"><a href="#">Seminars</a></li>
                        <li className="dropdown"><a href="#">Promotion</a></li>
                        <li className="dropdown"><a href="#">Contact Us</a></li>
                        <li className="dropdown">
                            <a href="#">FAQ</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

const landingBody = () => {
    return(
        <div className="container" style={{width:'1140px'}}>
            <div className="row-fluid">
                <div id="page-wrapper">

                    <section className="Our-services">
                            <div className="row" style={{height: '500px'}}>
                                <div className="col-lg-3">
                                        <div className="homepage-sidebox-smn">
                                            <h6 className="subTitle">Education &gt; Seminars</h6>
                                            <div className="homepage-seminar">
                                                            <div className="row homepage_smn_content">
                                                                <div className="col-md-12"><b>[WEBINAR] Stock Trading Masterclass: Fundamental Analysis</b></div>
                                                                <div className="col-md-12 homepage-seminar-small"><small>18 Nov 2023<br/>
                                                                    09:30 am - 12:30 pm</small>
                                                                </div>
                                                            </div><br/>					</div>
                                            <div><a href="#" className="subTitle">more</a></div>
                                        </div>
                                        <div className="homepage-sidebox-anc">
                                            <h6 className="subTitle">Important Notice</h6>
                                            <div className="homepage-important-notice">
                                                <div className="row">
                                                    <div className="col-md-1"><small> 1. </small></div>
                                                    <div className="col-md-10"><small>Public Notice - UOB KAY HIAN Is Not An Authorized Marketing Agent For LUMINOR ASSETS BERHAD</small></div>
                                                    <div className="col-md-1"><small> 2. </small></div>
                                                    <div className="col-md-10"><small>Share Consolidation - MMAG HOLDINGS BERHAD</small></div>
                                                    <div className="col-md-1"><small> 3. </small></div>
                                                    <div className="col-md-10"><small>Share Consolidation - GREEN OCEAN CORPORATION BERHAD</small></div>							
                                                </div>
                                            </div>
                                            <div><a href="#" className="subTitle">more</a></div>
                                        </div>
                                </div>
                                <div className="col-lg-8 h-500" style={{paddingRight: '0px'}}>
                                    <div data-p="170.00 h-500">
                                        <a href="#">
                                            <img className="img-fluid banner" data-u="image" src="https://www.utrade.com.my/img/Banner/hero-2023-utrade-3-beta-001.jpeg" alt="Stock Trading Masterclass: Fundamental &amp; Technical Analysis" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-1" style={{paddingLeft: '0px'}}>
                                    <div className="no-gutter">
                                            <div className="boxright">
                                                <table className = "wid-100p height-100p" align="center">
                                                    <tr><td><a href="#" className="thumbnailNavItem"> 
                                                        <i className="fa fa-line-chart"></i>
                                                        &nbsp;Equities
                                                    </a></td></tr>
                                                    <tr><td><a href="#" className="thumbnailNavItem">
                                                        <i className="fa fa-bar-chart"></i> 
                                                        &nbsp;Foreign<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Trading
                                                    </a></td></tr>
                                                    <tr><td><a href="#" className="thumbnailNavItem"> 
                                                        <i className="fa fa-pie-chart"></i>  
                                                        &nbsp;ETFs
                                                    </a></td></tr>
                                                    <tr><td><a href="#" className="thumbnailNavItem"> 
                                                        <i className="fa fa-cogs"></i>  
                                                        &nbsp;Structured<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Warrants
                                                    </a></td></tr>
                                                    <tr><td><a href="#" className="thumbnailNavItem">
                                                        <i className="fa fa-bullseye"></i>
                                                        &nbsp;Futures
                                                    </a></td></tr>
                                                    <tr><td><a href="#" className="thumbnailNavItem">
                                                        <i className="fa fa-balance-scale"></i>
                                                        &nbsp;Margin<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Financing
                                                    </a></td></tr>
                                                    <tr><td><a href="#" className="thumbnailNavItem"> 
                                                        <i className="fa fa-handshake-o"></i> 
                                                        &nbsp;Custodian<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Services
                                                    </a></td></tr>
                                                </table>
                                            </div>
                                    </div>
                                </div>
                            </div>
                    </section>

                    <section className="Our-services pt-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 header">
                                    <h2 style={{color: '#181818', marginTop: '100px'}}>Market Overview</h2>
                                </div>
                            </div>
                            <h3 className="subTitle">MARKET AT A GLANCE</h3>
                            <div className="row">
                                <div className="col-md-8 col-sm-12">
                                    <div className="boxChartGenie">
                                        <div id="TabbedPanelsResearch" className="TabbedPanels">
                                            <ul className="TabbedPanelsTabGroup">
                                                <li className="TabbedPanelsTab" tabIndex={0}>Malaysia</li>
                                                <li className="TabbedPanelsTab" tabIndex={0}>Regional</li>
                                            </ul>
                                            <div className="TabbedPanelsContentGroup">
                                                <div className="TabbedPanelsContent">
                                                    <div id="research_slider" className="marketwrapper">
                                                        <div className="contentdiv">
                                                                        <div className="date">
                                                                            03 May<br/>07:49 am
                                                                        </div>
                                                                        <h4 className="title1">the FBMKLCI traded in a tight range of 1,600-1,615 last Friday, suggesting that investors are cautious in an uncertain market.</h4>
                                                                        <p>Given no significant change, we maintain our neutral stance that the index may consolidate further on a lack of buying interest.</p>
                                                                        <a href="#" target="_top"><small>See More</small></a>
                                                                    </div><div className="contentdiv">
                                                                        <div className="date">
                                                                            30 Apr<br/>08:02 am
                                                                        </div>
                                                                        <h4 className="title1">the FBMKLCI had a pullback and further consolidated to close modestly higher at 1,608.50 on Wednesday</h4>
                                                                        <p>The correction looks set to continue as selling pressure grows as reflected by the negative reading in the RSI. Weaker regional sentiments as well as an absence of domestic catalysts may drag the index lower in the near term</p>
                                                                        <a href="#" target="_top"><small>See More</small></a>
                                                                    </div><div className="contentdiv">
                                                                        <div className="date">
                                                                            28 Apr<br/>08:13 am
                                                                        </div>
                                                                        <h4 className="title1">the FBMKLCI had a pullback and consolidated to close lower at 1,606.68 yesterday. </h4>
                                                                        <p>The correction looks set to continue as selling pressure grows as reflected by the negative reading in the RSI. Weaker regional sentiments as well as an absence of domestic catalysts may drag the index lower in the near-term. </p>
                                                                        <a href="#" target="_top"><small>See More</small></a>
                                                                    </div>								</div>
                                                    <div id="paginate-research_slider" className="pagination text-right" style={{display: 'block'}}>	
                                                        <a href="#" className="prev"></a> <a href="#" className="next"></a>	
                                                    </div>
                                                </div>
                                                <div className="TabbedPanelsContent">
                                                    <div id="research_slider_regional" className="marketwrapper">
                                                        <div className="contentdiv">
                                                                        <div className="date">
                                                                            03 May<br/>07:50 am
                                                                        </div>
                                                                        <h4 className="title1">US stocks were lower after the close on Friday, as losses in the oil & gas, technology and basic materials sectors led shares lower.</h4>
                                                                        <p>At the close in the NYSE, the DJIA fell 0.54%, while the S&P 500 index declined 0.72%, and the NASDAQ Composite index lost 0.85%. Falling stocks outnumbered advancing ones on the NYSE by 2,131 to 908 and 90 ended unchanged; on the Nasdaq Stock Exchange, 2,196 fell and 994 advanced, while 92 ended unchanged</p>
                                                                        <a href="#" target="_top"><small>See More</small></a>
                                                                    </div><div className="contentdiv">
                                                                        <div className="date">
                                                                            30 Apr<br/>08:03 am
                                                                        </div>
                                                                        <h4 className="title1">US stocks were higher after the close on Thursday, as gains in the Telecoms, Financials and Oil & Gas sectors led shares higher.</h4>
                                                                        <p>At the close in NYSE, the DJIA gained 0.71%, while the S&P 500 index gained 0.68%, and the NASDAQ Composite index climbed 0.22%. Rising stocks outnumbered declining ones on the NYSE by 1,563 to 1,438 and 94 ended unchanged; on the NASDAQ Stock Exchange, 1,815 fell and 1,370 advanced, while 103 ended unchanged.</p>
                                                                        <a href="#" target="_top"><small>See More</small></a>
                                                                    </div><div className="contentdiv">
                                                                        <div className="date">
                                                                            28 Apr<br/>08:14 am
                                                                        </div>
                                                                        <h4 className="title1">U.S. stocks were mixed after the close on Tuesday, as gains in the Oil & Gas, Financials and Industrials sectors led shares higher while losses in the Utilities, Consumer Goods and Telecoms sectors led shares lower</h4>
                                                                        <p>At the close in NYSE, the Dow Jones Industrial Average added 0.01%, while the S&P 500 index fell 0.02%, and the NASDAQ Composite index declined 0.34%. Rising stocks outnumbered declining ones on the New York Stock Exchange by 1505 to 1500 and 126 ended unchanged; on the Nasdaq Stock Exchange, 1810 fell and 1369 advanced, while 103 ended unchanged. </p>
                                                                        <a href="#" target="_top"><small>See More</small></a>
                                                                    </div>								</div>
                                                    <div id="paginate-research_slider_regional" className="pagination text-right" style={{display: 'block'}}>	
                                                        <a href="#" className="prev"></a> <a href="#" className="next"></a>	
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 padding-right-10px">
                                    <div className="boxChartGenie">
                                        <h6 className="subTitle">CHARTGENIE</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-8 col-sm-12">
                                    <div className="boxIndices">
                                        <h6 className="subTitle">NEWS</h6>
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item"><a className="nav-link active" href="#MYNews" data-toggle="tab" role="tab" aria-selected="true">Malaysia</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#USNews" data-toggle="tab" role="tab" aria-selected="false">United States</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#SGNews" data-toggle="tab" role="tab" aria-selected="false">Singapore</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#HKNews" data-toggle="tab" role="tab" aria-selected="false">Hong Kong</a></li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="MYNews" className="tab-pane show  active" role="tabpanel" aria-labelledby="MYNews">							
                                            </div>
                                            <div id="USNews" className="tab-pane" role="tabpanel" aria-labelledby="USNews">							
                                            </div>
                                            <div id="SGNews" className="tab-pane" role="tabpanel" aria-labelledby="SGNews">							
                                            </div>
                                            <div id="HKNews" className="tab-pane" role="tabpanel" aria-labelledby="HKNews">							
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 padding-right-10px">
                                    <div className="boxIndices">
                                        <h6 className="subTitle">INDICES</h6>
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item"><a className="nav-link active" href="#KLCI" data-toggle="tab">KLCI</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#worldIndices" data-toggle="tab">World Indices</a></li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="KLCI" className="tab-pane show active">
                                            </div>
                                            <div id="worldIndices" className="tab-pane">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}


const UtradeLanding = () => {
    return (
        <div className="utradeBody">
            <TopHeader />
            {topLogoNav()}
            { landingBody() }
            <Footer />
        </div>
    );
};

export default UtradeLanding;
