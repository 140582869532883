import { useState, useEffect, useRef } from 'react';
import './index.css';
import moment from 'moment';
import { TimePicker } from 'antd';

//import Timepicker from '../../../general/form-control/timepicker';

const Index = (props: any) => {
    const [val, setVal] = useState(null);
    const inputDateFormat = 'hh:mm';
    const outputDateFormat = 'hh:mm';

    useEffect(() => {
        if (props.value) {
            // let value = '';
            if (moment(props.value, inputDateFormat).isValid()) {
                // It has as valid date
                let value = moment(props.value, inputDateFormat);
                setVal(value);
            } else {
                // It doesn't
            }
        } else {
            setVal(null);
        }
    }, [props.value]);

    const onBlur = () => {
        setVal(val);
        let target = {
            id: props.id,
            value: moment(val, inputDateFormat).format(outputDateFormat)
        };

        props?.onChange({ target });
    };

    return (
        //form-input
        <div className="form-input">
            {/* <div>
                <Timepicker
                    id={props.id}
                    value={props.value || ''}
                    onChange={({ target }: any) => {
                        if (props.onChange) {
                            props.onChange({ target });
                        }
                    }}
                />
            </div> */}
            <TimePicker
                id={props.id}
                value={val}
                allowClear={true}
                onSelect={(time) => {
                    setVal(time);
                }}
                onChange={(time: any, timeString: any) => {
                    setVal(time);
                    if (props.onChange) {
                        let target = {
                            id: props.id,
                            value: moment(time, inputDateFormat).format(
                                outputDateFormat
                            )
                        };
                        props.onChange({
                            target
                        });
                    }
                }}
                // onChange={(time, timeString) =>
                //     onInputChange('bankinhour', timeString)
                // }
                // defaultOpenValue={moment('00:00', 'hh:mm')}
                format={inputDateFormat}
                inputReadOnly={true}
                className="timepicker custom"
                onBlur={onBlur}
                popupStyle={{
                    border: '1px solid #c1c6cf',
                    borderRadius: '8px',
                    padding: '1px',
                    boxShadow:
                        '0px 10px 15px -3px rgb(0 0 0 / 10%), 0px 4px 6px -2px rgb(0 0 0 / 5%)'
                }}
                placeholder={props.placeholder || ''}
            />

            <div className="input-error">{props.error || ''}</div>
        </div>
    );
};

export default Index;
