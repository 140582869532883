import { useState, useEffect, useRef } from 'react';
import './index.css';

interface Property {
    treeMap: any; //['username', 'accNo'] treeMap[0] = parent,treeMap[1] = child
    data: Array<SourceDataFormat>; //
    onSelectChange?: any; //select vailable value
    onSelectChangeParentValue?: any; //return selected parent value
    keyinChange: any; //typing change to search value
    setLoading: boolean; //display loading icon
}

// //data format
// interface PropsData {
//     value: string;
//     label?: string;
//     exist?: boolean;
// }

//current value format
interface CurrentValueFormat {
    value: string;
    label: string;
    exist: boolean;
}

//data format
type SourceDataFormat = {
    props: SourceDataPropsFormat;
    children: Array<SourceDataChildrenFormat>;
};

type SourceDataPropsFormat = {
    value: string;
    label: string;
};

type SourceDataChildrenFormat = {
    value: string;
    label: string;
};

const Index = (props: Property) => {
    useEffect(() => {}, []);
    const dropdownRef = useRef(null);
    const [treeMap, setTreeMap] = useState(props.treeMap || []);
    const [focused, setFocused] = useState(false);
    const [defaultSelected, setDefaultSelected] = useState(null);
    const [sourceData, setSourceData] = useState(props.data);
    const [filteredData, setFilteredData] = useState(props.data);
    // console.log(props.data);
    const [currentValue, setValue] = useState<CurrentValueFormat>({
        value: '',
        label: '',
        exist: false
    });

    const [preSelect, setPreSelect] = useState({});

    useEffect(() => {
        // if (props.defaultValue) {
        //     handleValueChange(props.defaultValue, true);
        // }
        // console.log('here');
        // console.log('sourceData', sourceData);
        // getFirstValue();
    }, []);

    useEffect(() => {
        if (!defaultSelected) {
            getFirstValue();
        }
    }, [sourceData]);

    useEffect(() => {
        if (defaultSelected) {
            handleValueChange(defaultSelected, true);
            setFocused(false);
        }
    }, [defaultSelected]);

    const getFirstValue = () => {
        if (treeMap[0] && treeMap[1]) {
            for (var i = 0; i < sourceData.length; i++) {
                let row = sourceData[i];
                let children = row.children;
                if (i == 0) {
                    for (var z = 0; z < children.length; z++) {
                        let child = children[z];

                        if (z == 0) {
                            let value1 = row.props.value;
                            let value2 = child.value;

                            setDefaultSelected({
                                [treeMap[0]]: value1,
                                [treeMap[1]]: value2
                            });
                        }
                    }
                }
            }
        }
    };

    useEffect(() => {
        // console.log('currentValue');
        // console.log(currentValue);
        //typing delay
        const delayDebounceFn = setTimeout(() => {
            if (
                currentValue &&
                currentValue.value != undefined &&
                currentValue.exist == false &&
                props.keyinChange
            ) {
                //pass keyin change to parent
                props.keyinChange(currentValue);
            }
        }, 400);

        return () => clearTimeout(delayDebounceFn);
    }, [currentValue]);

    useEffect(() => {
        //upedate new state
        setSourceData(props.data || []);
    }, [props.data]);

    useEffect(() => {
        //refresh if state updated
        // handleValueChange("", false, true);
        setFilteredData(sourceData || []);
    }, [sourceData]);

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleOutsideClicks);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleOutsideClicks);
        };
    }, [focused, currentValue]);

    // useEffect(() => {
    //     if (focused == false) {
    //         alert('asd');
    //     }
    // }, [focused]);

    //create a function in your component to handleOutsideClicks
    const handleOutsideClicks = (event: any) => {
        if (
            focused &&
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            //  console.log(currentValue);
            //when closed search result box, check value exist and update
            if (currentValue.exist == false) {
                handleValueChange('');
            }
            setFocused(false);
        }
    };

    const handleValueChange = (
        targetObjectValue: object,
        selected = false,
        stayFocus = false
    ) => {
        // console.log('targetObjectValue', targetObjectValue);
        // console.log('selected', selected);
        // console.log('sourceData', sourceData);

        let countMatched = 0;
        let cloneData: any = sourceData.map((item) => ({ ...item })) || [];
        let filteredData: any = [];

        let parentKey = treeMap[0];
        let childKey = treeMap[1];
        if (!parentKey) alert('invalid parentKey,please check treeMap[0]');
        if (!childKey) alert('invalid childKey,please check treeMap[1]');

        let parentValue = targetObjectValue[parentKey];
        let childValue = targetObjectValue[childKey];
        //input value from textbox
        // let inputValue = targetObjectValue['inputValue'];

        //console.log(targetObjectValue);
        // alert(childValue);

        //selected object detail
        let foundedTargetObject = {};
        if (parentValue || childValue) {
            let targetValue = childValue;

            //filter original data
            filteredData = cloneData.filter((el, index, arr) => {
                //render search result if countMatched < 100
                if (countMatched < 100) {
                    //if no input the return the original
                    if (targetValue === '') {
                        return el;
                    }
                    //return the item which contains the user input
                    else {
                        countMatched++;
                        if (el.props && el.props.value) {
                            let props = el.props;

                            //return if parent value = true
                            if (
                                props.value
                                    .toString()
                                    .toLowerCase()
                                    .includes(
                                        targetValue.toString().toLowerCase()
                                    )
                            ) {
                                return true;
                            }

                            let children = el.children || [];
                            if (children.length > 0) {
                                let found = false;
                                //let foundedChildrenData = [];
                                for (var i = 0; i < children.length; i++) {
                                    let child = children[i];
                                    let childValue = child.value || '';
                                    // console.log(childValue);

                                    if (
                                        childValue.toString().toLowerCase() ==
                                        targetValue.toString().toLowerCase()
                                    ) {
                                        // console.log('match');
                                        // console.log(childValue);
                                        // console.log(targetValue);

                                        found = true;
                                        foundedTargetObject = child;
                                        //foundedChildrenData.push(child);
                                        //console.log(child);
                                    }
                                }

                                // console.log(foundedChildrenData);
                                // console.log(arr[index]);
                                // children = foundedChildrenData;
                                //cloneData[index].children = foundedChildrenData;
                                //return true if children matched = true
                                return found;
                            }
                        }
                    }
                }
            });
        }

        let inputBoxValue = childValue;
        // if (inputValue) inputBoxValue = inputValue;
        //set input box value

        setValue({
            label: foundedTargetObject.label || '',
            value: foundedTargetObject.value || '',
            exist: selected
        });

        // console.log('filteredData');
        // console.log(filteredData);

        if (!childValue) {
            setFilteredData(cloneData);
        } else {
            setFilteredData(filteredData || []);
        }

        //when typing show result list, clear all then show all result
        if ((inputBoxValue || inputBoxValue == '') && selected == false) {
            setFocused(true);
        } else {
            setFocused(false);
            // if (selected == true) {
            //pass current value to parent
            if (props.onSelectChange) {
                props.onSelectChange(foundedTargetObject);
                if (props.onSelectChangeParentValue) {
                    props.onSelectChangeParentValue(parentValue);
                }
            }
            //}
        }
    };

    const handleKeyinChange = (targetValue: string) => {
        setValue({
            label: targetValue || '',
            value: targetValue || '',
            exist: false
        });
    };

    return (
        <div ref={dropdownRef} id={props.id} className="tree-select ">
            <input
                type="search"
                autoComplete="none"
                value={currentValue.label || ''}
                onChange={(e) => handleKeyinChange(e.target.value)}
                onClick={() => setFocused(true)}
            />
            <i
                className={
                    'cursor-pointer input-arrow fas ' +
                    (focused == true ? ' fa-chevron-up ' : ' fa-chevron-down ')
                }
                onClick={() => setFocused(true)}
            ></i>

            <div
                className={
                    'lds-spinner tree-loader ' +
                    (props.setLoading == true ? ' show ' : '  ')
                }
            >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <div
                className={
                    'dropdown-list' +
                    (focused == true ? ' show ' : '') +
                    (filteredData.length > 0 ? '' : ' pointerEventNone ')
                }
            >
                {/* {JSON.stringify(filteredData)} */}
                <ul>
                    {currentValue &&
                        filteredData.map((item, i) => {
                            let props = item.props;
                            let children = item.children;

                            // console.log(item);
                            // console.log(children);
                            // console.log('rerender');
                            return (
                                <TreeNode
                                    key={i}
                                    currentValue={currentValue}
                                    data={props}
                                    children={children || []}
                                    onTreeNodeChange={(
                                        targetObjectValue: string,
                                        selected = false
                                    ) =>
                                        handleValueChange(
                                            targetObjectValue,
                                            selected
                                        )
                                    }
                                    treeMap={treeMap}
                                />
                            );
                        })}
                </ul>
            </div>
        </div>
    );
};

const TreeNode = (props: any) => {
    //const [currentValue, setCurrentValue] = useState(props.currentValue || '');
    const [data, setData] = useState(props.data || {});
    const [children, setChildren] = useState(props.children || []);

    const [expand, setExpand] = useState(props.expand || true);
    const [treeMap, setTreeMap] = useState(props.treeMap || true);

    const [preSelectValue, setPreSelectValue] = useState({});

    // useEffect(() => {
    //     setCurrentValue(currentValue);
    // }, [props.currentValue]);

    // useEffect(() => {
    //     console.log(preSelectValue);
    // }, [preSelectValue]);

    useEffect(() => {
        //update new state
        setData(props.data);
        // console.log('props.data');
        // console.log(props.data);
    }, [props.data]);

    useEffect(() => {
        setChildren(props.children);
    }, [props.children]);

    useEffect(() => {
        setTreeMap(props.treeMap);
    }, [props.treeMap]);

    const handleValueChange = (targetObjectValue: string, selected = false) => {
        if (props.onTreeNodeChange) {
            props.onTreeNodeChange(targetObjectValue, selected);
        }
    };

    const toogleExpand = (e) => {
        e.stopPropagation();
        setExpand(!expand);
    };

    // key press event
    // const handlePreSelect = (e, value) => {
    //     console.log(e.target.nextSibling);
    //     let nextTarget = e.target.nextSibling;
    //     nextTarget.style.background = '#f1f5f9';
    //     console.log(value);
    // };

    return (
        <>
            <li
                className={
                    'option unselectable' +
                    (props.currentValue == data.value ? ' selected ' : '')
                }
                value={data.value}
            >
                <div className="">
                    <i
                        className={
                            'tree-arrow fas ' +
                            (expand == true
                                ? ' fa-caret-up '
                                : ' fa-caret-down')
                        }
                        onClick={(e) => toogleExpand(e)}
                    ></i>
                    <span className="pl-2 ">{data.label}</span>
                </div>
            </li>
            {expand == true &&
                children.length > 0 &&
                children.map((item, i) => {
                    //console.log('item.value');
                    // console.log(props.currentValue);
                    // console.log(item.value);
                    // if (props.currentValue.value == item.value) {
                    //     console.log(props.currentValue);
                    //     console.log(item);
                    // }

                    return (
                        <li
                            key={i}
                            className={
                                'option pl-5' +
                                (props.currentValue.value == item.value
                                    ? ' selected '
                                    : '')
                            }
                            value={item.value}
                            // onClick={() =>
                            //     handleValueChange(row.value, true)
                            // }
                            // onMouseOver={(e) =>
                            //     handlePreSelect(e, { value: item })
                            // }
                            onClick={() =>
                                handleValueChange(
                                    {
                                        [treeMap[0]]: data.value,
                                        [treeMap[1]]: item.value
                                    },
                                    true
                                )
                            }
                        >
                            {item.label}
                        </li>
                    );
                })}
        </>
    );
};

export default Index;
