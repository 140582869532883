import { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import './index.css';

import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import ReactTable from '../../general/form-control/react-table';

import FormInput from '../../general/form-control/form-item/input';
import FormSelect from '../../general/form-control/form-item/select';
import FormDatePicker from '../../general/form-control/form-item/date-picker';
import FormTimePicker from '../../general/form-control/form-item/time-picker';
import FormInputFile from '../../general/form-control/form-item/input-file';
import FormInputAmount from '../../general/form-control/form-item/input-amount';

import api from '../../../apis';
import { useLoading } from '../../../context/LoadingContext';
import { numberWithCommas } from '../../general/convertor';

import property from '../../../constants/property';
const manualTransferType = property.manualTransferType;

const Index = (props: any) => {
    const navigate = useNavigate();
    const { setLoading } = useLoading();

    useLayoutEffect(() => {
        setLoading(true);
        api.getBankList().then((result: any) => {
            setLoading(false);
            console.log('getBankList');
            console.log(result);
            setBankList(result);
            // setStockData(result[0].stocks);
        });
        setLoading(false);
    }, []);

    useEffect(() => {
        setCurrentAccNo(props.currentAccNo);
    }, [props.currentAccNo]);

    useEffect(() => {
        setCurrentUsername(props.currentUsername);
    }, [props.currentUsername]);

    useEffect(() => {
        setCurrentAccCode(props.currentAccCode);
    }, [props.currentAccCode]);

    // {
    //     "mode": 2,
    //     "datetime": "2022-01-01 23:59:59",
    //     "amount": 1,
    //     "currency": 1,
    //     "deposit_bank": 2,
    //     "deposit_bank_branch": "KL",
    //     "file": "1663806992865-6524536a-7a4b-4f47-af1d-5f7d18996f6c.pdf",
    //     "drawee_bank": 2,
    //     "cheque_no": "CH00001",
    //     "cheque_date": "2022-01-01"
    // }

    //validation settings
    const [rules, setRules] = useState<any>({
        mode: { required: true, error_message: 'Please input type' },
        cheque_no: {
            requireIfExist: { key: 'mode', value: 2 }, //require if mode = 2
            error_message: 'Please input chequre no'
        },
        cheque_date: {
            requireIfExist: { key: 'mode', value: 2 }, //require if mode = 2
            error_message: 'Please select cheque date'
        },
        datetime: {
            required: true,
            error_message: 'Please select bank in date'
        },
        bankinhour: {
            required: true,
            error_message: 'Please select bank in time'
        },
        amount: { required: true, error_message: 'Please input amount' },
        currency: { required: false, error_message: 'Please select currency' },
        drawee_bank: {
            requireIfExist: { key: 'mode', value: 2 }, //require if mode = 2
            error_message: 'Please select drawee bank'
        },
        deposit_bank: {
            required: true,
            error_message: 'Please select depopsit bank'
        },
        deposit_bank_branch: {
            required: true,
            error_message: 'Please select bank branch'
        },
        file: { required: true, error_message: 'Please upload file' }
    });

    const depositFormat = {
        mode: null,
        datetime: null, //moment().unix(),
        amount: null,
        currency: 1,
        deposit_bank: null,
        deposit_bank_branch: null,
        file: null,
        drawee_bank: null,
        cheque_no: '',
        cheque_date: '',
        bankinhour: null
    };
    const [currentAccNo, setCurrentAccNo] = useState<any>('');
    const [currentAccCode, setCurrentAccCode] = useState<any>('');
    const [currentUsername, setCurrentUsername] = useState<any>('');

    const [inputs, setInputs] = useState<any>(depositFormat || {});
    const [errors, setErrors] = useState<any>({});

    const [bankList, setBankList] = useState<any>([]);

    const [pendingList, setPendingList] = useState<any>([]);
    const [totalDepositAmount, setTotalDepositAmount] = useState<number>(0);

    const onInputChange = (id: string, newValue: string) => {
        setInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    useEffect(() => {
        console.log(inputs);
    }, [inputs]);

    useEffect(() => {
        console.log('update pending list');
        calculateTotalPendingDeposit(pendingList);
    }, [pendingList]);

    const handleDeleteRow = (rowIndex: any) => {
        //delete by row index
        setPendingList((l: any) =>
            l.filter((e: any, i: any) => i !== rowIndex)
        );
    };

    function getBankName(bank_id: string) {
        let bank_name = bank_id;
        for (var i = 0; i < bankList.length; i++) {
            let bank = bankList[i];
            if (bank_id == bank.bk_id) {
                bank_name = bank.bk_name;
            }
        }
        console.log(bank_name);
        return bank_name;
    }

    const tblColumns = [
        {
            Header: 'Type',
            accessor: 'mode',
            Cell: (v: any) => {
                if (v.value)
                    return (
                        <div className="">{manualTransferType[v.value]}</div>
                    );
            }
        },
        {
            Header: 'Bank in Date',
            accessor: 'datetime'
        },
        {
            Header: 'Name',
            accessor: 'currentUsername'
        },
        {
            Header: 'Account No.',
            accessor: 'currentAccCode'
        },
        {
            Header: 'Drawee Bank',
            accessor: 'drawee_bank',
            Cell: (v: any) => {
                if (v.value) {
                    return <div className="">{getBankName(v.value)}</div>;
                }
            }
        },
        {
            Header: 'Cheque No',
            accessor: 'cheque_no'
        },
        {
            Header: 'Cheque Date',
            accessor: 'cheque_date',
            Cell: (v: any) => {
                if (v.value) return moment(v.value).format('DD-MM-YYYY');
            }
        },
        {
            Header: 'Deposit Bank',
            accessor: 'deposit_bank',
            Cell: (v: any) => {
                if (v.value) {
                    return <div className="">{getBankName(v.value)}</div>;
                }
            }
        },
        {
            Header: 'Branch',
            accessor: 'deposit_bank_branch'
        },
        {
            Header: <div className="text-right">Amount (RM)</div>,
            accessor: 'amount',
            Cell: (v: any) => {
                if (v.value)
                    return (
                        <div className="text-right">
                            {numberWithCommas(Number(v.value), 2)}
                        </div>
                    );
            }
        },
        {
            Header: 'Attachment',
            accessor: 'file',
            Cell: (v: any) => {
                return (
                    <a
                        href=""
                        onClick={(e) => {
                            e.preventDefault();
                            return window.open(
                                './file/' + v.value,
                                '_blank',
                                'noopener,noreferrer'
                            );
                        }}
                    >
                        {v.value}
                    </a>
                );

                // window.open('./img/' + cFile, '_blank', 'noopener,noreferrer');
            }
        },

        {
            Header: '',
            accessor: 'action',
            Cell: ({ row }: any) => {
                return (
                    <div>
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleDeleteRow(row?.index)}
                        >
                            Delete
                        </button>
                    </div>
                );
            }
        }
    ];

    const addPendingRow = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        let { valid, errors } = formValidation(rules, inputs);
        setErrors(errors);
        console.log('valid', valid);
        console.log('errors', errors);

        if (!currentAccNo) {
            alert('Please select Account No');
        } else if (valid == false) {
            //scroll to first error
            if (Object.keys(errors).length > 0) {
                let firstKey = Object.keys(errors)[0];
                let element = document.getElementById(firstKey);
                element?.scrollIntoView();
            }
        } else {
            // {
            //     "mode": 2,
            //     "datetime": "2022-01-01 23:59:59",
            //     "amount": 1,
            //     "currency": 1,
            //     "deposit_bank": 2,
            //     "deposit_bank_branch": "KL",
            //     "file": "1663806992865-6524536a-7a4b-4f47-af1d-5f7d18996f6c.pdf",
            //     "drawee_bank": 2,
            //     "cheque_no": "CH00001",
            //     "cheque_date": "2022-01-01"
            // }

            inputs.datetime =
                moment(inputs.datetime).format('YYYY-MM-DD') +
                ' ' +
                inputs.bankinhour +
                ':00'; //combine to become timestamp

            if (inputs.cheque_date) {
                inputs.cheque_date = moment(inputs.cheque_date).format(
                    'YYYY-MM-DD'
                );
            }

            let result = await api.validateManualDeposit(currentAccNo, inputs);
            console.log('validateManualDeposit');
            console.log(result);

            if (result.status === 0) {
                //if no error
                console.log(inputs);
                console.log(pendingList);
                inputs.currentAccCode = currentAccCode;
                inputs.currentUsername = currentUsername;

                setPendingList([...pendingList, inputs]);

                //reset form
                setInputs(depositFormat);
            } else {
                let message = 'validate failed';
                if (result?.message) {
                    message = JSON.stringify(result.message);
                }

                alert(message);
            }
        }
        setLoading(false);
    };

    const formValidation = (rules: any, inputs: any) => {
        if (!rules) return { valid: true, errors: {} };

        let valid = true;
        let errors: any = {};

        //validate rule
        for (var key in rules) {
            if (rules.hasOwnProperty(key)) {
                let item = rules[key];
                if (item.required == true && !inputs[key]) {
                    let error = item.error_message || 'Required';
                    errors[key] = error;
                    valid = false;
                } else if (item.requireIfExist) {
                    //if specific input exist,current input become required
                    let requireIfExist = item.requireIfExist;
                    let relatedkey = requireIfExist.key;
                    let relatedValue = requireIfExist.value;

                    //match type start check error
                    if (
                        relatedkey &&
                        relatedValue &&
                        inputs[relatedkey] &&
                        inputs[relatedkey] == relatedValue
                    ) {
                        if (!inputs[key]) {
                            let error = item.error_message || 'Required';
                            errors[key] = error;
                            valid = false;
                        }
                    }
                } else {
                }

                // requireIfExist: { key: 'mode', value: 2 }
            }
        }

        return {
            valid,
            errors
        };
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        console.log(pendingList);
        let result = await api.submitDepositManualBulk(currentAccNo, {
            pendingList
        });
        console.log('handleSubmit');
        console.log(result);
        if (result.status === 0) {
            alert('Thank you, the form was submitted successfully');
            //refresh page
            navigate(0);
        } else {
            alert('submit failed');
        }
    };

    const calculateTotalPendingDeposit = (pendingList: any) => {
        console.log(pendingList);
        let totalDeposit = 0;
        for (var i = 0; i < pendingList.length; i++) {
            let row = pendingList[i];
            let amount = Number(row.amount);
            totalDeposit += amount;
        }
        setTotalDepositAmount(totalDeposit);
    };

    const onTimePickerChange = (id, time, timeString) => {
        console.log(time, timeString);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="row ">
                <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12">
                    <div className="form-input-container ">
                        <div className="label">Type</div>
                        <FormSelect
                            id="mode"
                            value={inputs.mode || ''}
                            onChange={({ target }: any) =>
                                onInputChange(target.id, target.value)
                            }
                            error={errors.mode}
                        >
                            <option value={''} disabled>
                                Please select
                            </option>
                            {Object.entries(manualTransferType).map(
                                ([key, value]) => {
                                    return <option value={key}>{value}</option>;
                                }
                            )}
                        </FormSelect>
                    </div>
                </div>
                <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12">
                    <div className="form-input-container ">
                        <div className="label">Amount</div>
                        <FormInputAmount
                            id="amount"
                            type="number"
                            value={inputs.amount || ''}
                            onChange={({ target }: any) =>
                                onInputChange(target.id, target.value)
                            }
                            error={errors.amount}
                        />
                    </div>
                </div>
            </div>
            {/* display if mode = cheque */}
            {inputs.mode && inputs.mode == 2 ? (
                <div className="row ">
                    <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12">
                        <div className="form-input-container ">
                            <div className="label">Cheque No.</div>
                            <FormInput
                                id="cheque_no"
                                type="text"
                                value={inputs.cheque_no || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.cheque_no}
                            />
                        </div>
                    </div>

                    <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12">
                        <div className="form-input-container">
                            <div className="label">Cheque Date</div>
                            <FormDatePicker
                                id="cheque_date"
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.cheque_date}
                            />
                        </div>
                    </div>
                </div>
            ) : null}

            {/* *************** */}

            <div className="row ">
                {' '}
                <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12">
                    <div className="form-input-container ">
                        <div className="label">Deposit Bank</div>
                        <FormSelect
                            id="deposit_bank"
                            value={inputs.deposit_bank || ''}
                            onChange={({ target }: any) =>
                                onInputChange(target.id, target.value)
                            }
                            error={errors.deposit_bank}
                        >
                            <option value={''} disabled>
                                Please select
                            </option>
                            {bankList.length > 0 &&
                                bankList.map((item: any, i: number) => {
                                    return (
                                        <option value={item.bk_id}>
                                            {item.bk_name}
                                        </option>
                                    );
                                })}
                        </FormSelect>
                    </div>
                </div>
                <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12">
                    <div className="form-input-container ">
                        <div className="label">Bank Branch</div>
                        <FormInput
                            id="deposit_bank_branch"
                            type="text"
                            value={inputs.deposit_bank_branch || ''}
                            onChange={({ target }: any) =>
                                onInputChange(target.id, target.value)
                            }
                            error={errors.deposit_bank_branch}
                        />
                    </div>
                </div>
            </div>
            <div className="row ">
                {' '}
                {inputs.mode && inputs.mode == 2 ? (
                    <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12">
                        <div className="form-input-container ">
                            <div className="label">Drawee Bank</div>
                            <FormSelect
                                id="drawee_bank"
                                value={inputs.drawee_bank || ''}
                                onChange={({ target }: any) =>
                                    onInputChange(target.id, target.value)
                                }
                                error={errors.drawee_bank}
                            >
                                <option value={''} disabled>
                                    Please select
                                </option>
                                {bankList.length > 0 &&
                                    bankList.map((item: any, i: number) => {
                                        return (
                                            <option value={item.bk_id}>
                                                {item.bk_name}
                                            </option>
                                        );
                                    })}
                            </FormSelect>
                        </div>
                    </div>
                ) : null}
                <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12">
                    <div className="form-input-container ">
                        <div className="label">Bank In Date</div>
                        <div className="d-flex w-100" style={{ gap: '20px' }}>
                            {' '}
                            <div className="w-50">
                                {' '}
                                <FormDatePicker
                                    id="datetime"
                                    placeholder="Please Select"
                                    value={inputs.datetime || ''}
                                    onChange={({ target }: any) =>
                                        onInputChange(target.id, target.value)
                                    }
                                    error={errors.datetime}
                                />
                            </div>
                            <div className="w-50">
                                {/* <FormTimePicker
                                    id="bankinhour"
                                    onChange={(time, timeString) =>
                                        onInputChange('bankinhour', timeString)
                                    }
                                    // defaultOpenValue={moment('00:00', 'hh:mm')}
                                    format={'hh:mm'}
                                    inputReadOnly={true}
                                    className="timepicker custom"
                                    popupStyle={{
                                        border: '1px solid #c1c6cf',
                                        borderRadius: '8px',
                                        padding: '1px',
                                        boxShadow:
                                            '0px 10px 15px -3px rgb(0 0 0 / 10%), 0px 4px 6px -2px rgb(0 0 0 / 5%)'
                                    }}
                                />{' '} */}

                                <FormTimePicker
                                    id="bankinhour"
                                    value={inputs.bankinhour || ''}
                                    placeholder="Please Select"
                                    // hint="mm/dd/yyyy"
                                    // name="start-time"
                                    onChange={({ target }: any) => {
                                        onInputChange(target.id, target.value);
                                    }}
                                    error={errors.bankinhour}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xxxl-3 col-xl-5 col-lg-5 col-md-6 col-12">
                    <div className="form-input-container ">
                        <div className="label">File Upload</div>
                        <FormInputFile
                            id="file"
                            value={inputs.file || ''}
                            onFileChange={(e: any, result: any) => {
                                console.log('onFileChange');
                                console.log(e.target.id);
                                console.log(result);
                                onInputChange(e.target.id, result.file);
                            }}
                            error={errors.file}
                        />
                        {/* <BtnUpload
                            id="file"
                            onFileChange={(e: any, result: any) => {
                                console.log('onFileChange');
                                console.log(e.target.id);
                                console.log(result);
                                onInputChange(e.target.id, result.file);
                            }}
                        /> */}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 pt-3">
                    <button
                        onClick={(e) => addPendingRow(e)}
                        type="button"
                        className="btn-primary-custom btn-md"
                    >
                        Add &nbsp;&nbsp;
                        <i
                            className="fas fa-chevron-right "
                            aria-hidden="true"
                        ></i>
                    </button>
                </div>
            </div>
            <div className="row ">
                <div className="col-md-12 col-12 pt-5">
                    {/* pending submit list */}
                    <ReactTable
                        // headerStyle={{
                        //     background: 'rgb(67, 151, 130)'
                        // }}
                        columns={tblColumns}
                        data={pendingList}
                    />
                    {/* {' '} */}
                    <div className="pt-3 pr-2 d-flex justify-content-end">
                        <b className="funding-total-deposit-amount">
                            Total Deposit Amount
                        </b>
                        &nbsp; &nbsp;
                        <b>{numberWithCommas(totalDepositAmount, 2) || '-'}</b>
                    </div>
                    <div className="pt-3 pr-2 d-flex justify-content-end">
                        <button
                            type="submit"
                            className="btn-primary-custom btn-md"
                        >
                            Submit &nbsp;&nbsp;
                            <i
                                className="fas fa-chevron-right "
                                aria-hidden="true"
                            ></i>
                        </button>
                    </div>

                    {/* <hr className="custom-hr" /> */}
                </div>
            </div>
        </form>
    );
};

export default Index;
