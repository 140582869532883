import { createRef, useRef, useState, useEffect, useLayoutEffect } from 'react';
import { Link, useNavigate, useLocation, Navigate } from 'react-router-dom';
import DetailTable from '../../general/info-control/table-detail';
import moment from 'moment';
import api from '../../../apis';
import '../../asset/styles/dashboard-style.css';

import DoughtnutExample from '../../general/charts/multi-thickness-doughtnut';

import logoBursa from '../../asset/images/logo/logo_bursa.png';
import logoHS from '../../asset/images/logo/logo_hs.png';
import logoNikkei from '../../asset/images/logo/logo_nikkei.png';
import logoSSE from '../../asset/images/logo/logo_sse.png';

import event1 from '../../asset/images/event/1101.png';
import event2 from '../../asset/images/event/1102.png';
import event3 from '../../asset/images/event/1103.png';

import eventIC1 from '../../asset/images/event/logo_bursa.svg';
import eventIC2 from '../../asset/images/event/logo_ppl.svg';
import eventIC3 from '../../asset/images/event/logo_pinterest.svg';

import CardList from '../../general/card-list';
import Modal from '../../general/modal';
import ModalTnc from '../../general/modal-tnc';
import Config from '../../../config';
import TheWaitIsOverArticle from '../../general/dummy-article/the-wait-is-over';
import EventArticle from '../../general/article/eventArticle';

import { useGlobalVariable } from '../../../context/GlobalVariableProvider';

const Index = () => {
    const { showTnc, setShowTnc } = useGlobalVariable();

    useLayoutEffect(() => {
        // @ts-ignore
        window.AOS.init({
            duration: 300,
            once: true,
            mirror: true,
            startEvent: 'load'
        });
    }, []);

    const chartSource = [
        {
            color: '#3268F6',
            name: 'Stock',
            value: '206,500.24 (63.80%)'
        },
        {
            color: '#05CEB8',
            name: 'Futures',
            value: '50,492.22 (15.60%)'
        },
        {
            color: '#F8CC46',
            name: 'Funds',
            value: '44,666.20 (13.80%)'
        },
        {
            color: '#CF05C3',
            name: 'Cash',
            value: '22,009.43 (6.80%)'
        }
    ];

    return (
        <div className="pt-3 ">
            <ModalTnc display={showTnc} />
            <MarketWatchDIV />
            <div className="fluid-container ">
                <div className="row gx-0 justify-content-center">
                    <div className="col-xl-7 col-12 p-0 ">
                        <div className="d-flex justify-content-center align-itmes-center">
                            <div className="container pt-4 summary-container ">
                                <div className="row gx-0">
                                    <div className="col-12 title">
                                        <h4>Summary</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="summary-detail-container pt-2">
                                            <div className="item">
                                                <div className="label">
                                                    Total Assets
                                                </div>
                                                <div className="value">
                                                    323,668.08
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="label">
                                                    Opening Trading Limit
                                                </div>
                                                <div className="value">
                                                    323,668.08
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="label">
                                                    Floating PnL
                                                </div>
                                                <div className="value positive">
                                                    63,684.65
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xxl-6 col-md-5 col-sm-6 col-xs-6 d-flex justify-content-center">
                                        <DoughtnutExample />
                                    </div>
                                    <div className="col-xxl-5 col-md-5 col-sm-6 col-xs-6 pt-5 d-flex align-items-center">
                                        <div className="chart-detail-container ">
                                            {chartSource.map(function (row, i) {
                                                return (
                                                    <div
                                                        key={i}
                                                        className="item"
                                                    >
                                                        <div
                                                            className="color-box"
                                                            style={{
                                                                background:
                                                                    row.color
                                                            }}
                                                        />
                                                        <div className="content">
                                                            <div className="name">
                                                                {row.name}
                                                            </div>
                                                            <div className="value">
                                                                {row.value}
                                                            </div>
                                                        </div>
                                                        <div className="arrow">
                                                            <i className="fa-solid fa-angle-right"></i>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-left pt-5">
                            {' '}
                            <EventSectionDIV />
                        </div>
                    </div>

                    <div className="col-xl-4 col-12">
                        <ContractSummaryDIV />
                        <div className="pt-4 text-left">
                            <AnnoucementSectionDIV />
                        </div>

                        <div className="pt-4 text-left">
                            <ResearchReportSectionDIV />
                        </div>
                        {/* <OutstandingPositionDIV /> */}
                    </div>
                </div>
                {/* <div className="row gx-0 p-0 ">
                    <div className="col-md-7 col-12  text-left pt-5">
                        <EventSectionDIV />
                    </div>
                    <div className="col-md-5 col-12  text-left "></div>
                </div> */}

                {/* <div className="row gx-0 p-0 pt-5 ">
                    <div className="col-md-7 col-12  text-left">
                        <ResearchReportSectionDIV />
                    </div>
                </div> */}
            </div>
        </div>
    );
};

const MarketWatchDIV = () => {
    return (
        <div className="market-watch-container  p-0 ">
            <div className="blocks">
                <div className="market-watch-indices ">
                    <div className="title">Market Watch</div>
                    <div className="sub-title">
                        Indices &nbsp;
                        <i
                            className="fas fa-chevron-down pb-1"
                            aria-hidden="true"
                        ></i>
                    </div>
                </div>
                <div className="item ">
                    <div className="stock-container">
                        <div>
                            <img src={logoNikkei} />
                        </div>
                        <div className="block label">
                            <div className="title">NIKKEI 225</div>
                            <div className="sub-title">26, 788.47 JPY</div>
                        </div>
                        <div className="block positive">
                            <div>+145.08</div>
                            <div>(0.54%)</div>
                        </div>
                    </div>
                </div>
                <div className="item ">
                    <div className="stock-container">
                        <div>
                            <img src={logoSSE} />
                        </div>
                        <div className="block label">
                            <div className="title">SSE COMPOSITE</div>
                            <div className="sub-title">3228.0608 CNY</div>
                        </div>
                        <div className="block negative">
                            <div>-53.6831</div>
                            <div>(-1.64%)</div>
                        </div>
                    </div>
                </div>
                <div className="item ">
                    <div className="stock-container">
                        <div>
                            <img src={logoHS} />
                        </div>
                        <div className="block negative label">
                            <div className="title">HSI</div>
                            <div className="sub-title">20297.73 HKD</div>
                        </div>
                        <div className="block negative">
                            <div>-453.49</div>
                            <div>(2.19%)</div>
                        </div>
                    </div>
                </div>
                <div className="item ">
                    <div className="stock-container">
                        <div>
                            <img src={logoBursa} />
                        </div>
                        <div className="block label">
                            <div className="title">FTSE BURSA</div>
                            <div className="sub-title">1418.44 MYR</div>
                        </div>
                        <div className="block negative">
                            <div>-1.62</div>
                            <div>(0.11%)</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ContractSummaryDIV = () => {
    const acc_summary_table_format = [
        {
            id: 'u_username1',
            label: 'Purchase Contracts (MYR)',
            value: '36,876.08'
        },
        {
            id: 'u_username',
            label: 'Overdue Purchase Contracts (MYR)',
            value: '23,656.06'
        },
        {
            id: 'p_fullname',
            label: 'Sales Contracts (MYR)',
            value: '12,656.00'
        },
        { id: 'd_p_fullname', label: 'Contra Gain (MYR)', value: '2,812.90' },
        // { id: 'p_nationality', label: 'Nationality', value: '' },
        {
            id: 'last_trade_time',
            label: 'Contra Loss (MYR)',
            value: '12,656.00'
        }
    ];

    return (
        <div className="contract-summary-container">
            <div className="title text-left pt-4 pb-3">
                <h4>Outstanding Position</h4>
            </div>
            <div
                className="contract-summary-table-container"
                style={{ height: 'auto' }}
            >
                {acc_summary_table_format.map(function (row: any, i: any) {
                    let colorClass = '';
                    if (row.value && row.value.match('-')) {
                        colorClass = 'red';
                    }

                    let itemStyle = '';
                    if (i == 0) {
                        itemStyle = 'header';
                    }

                    return (
                        <div className={'row m-0 ' + itemStyle} key={i}>
                            <div className="col-xxl-7 col-6 label">
                                {row.label}
                            </div>
                            <div
                                className={
                                    'col-xxl-5 col-6 value ' + colorClass
                                }
                            >
                                {row.value}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const OutstandingPositionDIV = () => {
    const oustanding_position_data = [
        {
            id: 'Purchase Contracts',
            title: 'Purchase Contracts',
            currency: 'MYR',
            value: '36,876.08'
        },
        {
            id: 'Overdue Purchase Contracts',
            title: 'Overdue Purchase Contracts',
            currency: 'MYR',
            value: '23,656.06'
        },
        {
            id: 'Sales Contracts',
            title: 'Sales Contracts',
            currency: 'MYR',
            value: '12,656.00'
        },
        {
            id: 'Overdue Purchase Contracts',
            title: 'Overdue Purchase Contracts',
            currency: 'MYR',
            value: '23,656.06'
        },
        {
            id: 'Contra Gain',
            title: 'Contra Gain',
            currency: 'MYR',
            value: '2,812.90'
        },
        {
            id: 'Contra Loss',
            title: 'Contra Loss',
            currency: 'MYR',
            value: '256.06'
        }
    ];

    return (
        <div className="outstanding-container pt-4">
            <h4 className="">Outstanding Position</h4>
            <div className="row gy-3 pt-3">
                {oustanding_position_data.map(function (row, i) {
                    return (
                        <div
                            key={i}
                            className="col-lg-auto col-md-5  col-sm-6  col-12 d-flex justify-content-left"
                        >
                            <div
                                className={
                                    'card-block ' + (i == 0 ? 'active' : '')
                                }
                            >
                                <div className="title">{row.title}</div>
                                <div>
                                    <div className="currency">
                                        {row.currency}
                                    </div>
                                    <div className="value">{row.value}</div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const EventSectionDIV = () => {
    const [eventList, setEventList] = useState([]);
    const [openModel, setOpenModel] = useState(false);
    const [modelProps, setModelProps] = useState({});

    useLayoutEffect(() => {
        api.getAnnoucementList({ type: 3 }).then((result: any) => {
            console.log('getEventList', result);
            if (result.announcements) {
                let arrData = [];
                let list = result.announcements;
                for (var i = 0; i < list.length; i++) {
                    let row = list[i];
                    let bg = ['#F5F578', '#C2EC93', '#96E4A8'];
                    let title = row.title;
                    let b_publish_date_start = row.b_publish_date_start;
                    let day = moment(b_publish_date_start).format('DD');
                    let month = moment(b_publish_date_start).format('MMM');
                    let file_thumbnail = row.file_thumbnail;
                    let file_content = row.file_content;
                    let htmlfile = Config.fileUrl + file_content;
                    let source = row.source;
                    let obj = {
                        img: file_thumbnail
                            ? Config.fileUrl + file_thumbnail
                            : event1,
                        subTitle: <></>,
                        title: title,
                        desc: '',
                        file_content: htmlfile,
                        b_publish_date_start: b_publish_date_start,
                        source
                    };
                    arrData.push(obj);
                }
                setEventList(arrData);
            }
        });
    }, []);

    // const eventSource = [
    //     {
    //         img: event1,
    //         subTitle: (
    //             <>
    //                 <img src={eventIC1} /> Bursa Malaysia • 22 Jul 2022
    //             </>
    //         ),
    //         title: 'The Bursa Marketplace Virtual Fair 2022 is back!',
    //         desc: 'Gain the latest market insights, engage with corporate leaders from top listed companies, get access to free investment tools to boost your financial planning...'
    //     },
    //     {
    //         img: event2,
    //         subTitle: (
    //             <>
    //                 <img src={eventIC2} /> mplusonline • 15 Jul 2022
    //             </>
    //         ),
    //         title: 'Understanding the Bollinger Bands Indicator For Trading ',
    //         desc: "Elky is a Dealer's Representative by profession. He has been in the industry since the year 2012, started in Futures & Options, to..."
    //     },
    //     {
    //         img: event3,
    //         subTitle: (
    //             <>
    //                 <img src={eventIC3} /> PINS • 8 Jul 2022
    //             </>
    //         ),
    //         title: 'Pinterest’s mood board comes through',
    //         desc: 'Struggling social platform Pinterest has been manifesting a recovery for its share price, and turns out the universe has been listening.'
    //     }
    // ];

    // let dummyData = [...eventSource, ...eventSource, ...eventSource];

    const handleArticle = (row: any) => {
        console.log(row);
        setOpenModel((oldValue) => true);
        setModelProps(row);
    };

    return (
        <div className="row gx-0 ">
            <div className="col-12 event-title">
                Upcoming Events &nbsp;
                <i className="fa-solid fa-angle-right"></i>
            </div>
            <div className="border">
                <Modal
                    opened={openModel || false}
                    onClosed={(openModel: boolean) => setOpenModel(openModel)}
                >
                    {/* <TheWaitIsOverArticle /> */}
                    <div className="border">
                        <EventArticle {...modelProps} />
                    </div>
                </Modal>
            </div>
            <div className="col-12">
                <CardList
                    data={eventList || []}
                    onClick={(row: any) => handleArticle(row)}
                />
            </div>
        </div>
    );
};

const AnnoucementSectionDIV = () => {
    let navigate = useNavigate();
    const [annoucemnetList, setAnnoucemnetList] = useState([]);

    useLayoutEffect(() => {
        api.getAnnoucementList({ type: 2 }).then((result: any) => {
            console.log('getAnnoucementList', result);
            if (result.announcements) {
                let arrData = [];
                let list = result.announcements;
                for (var i = 0; i < list.length; i++) {
                    let row = list[i];
                    let bg = ['#F5F578', '#C2EC93', '#96E4A8'];
                    let title = row.title;
                    let b_id = row.b_id;
                    let b_publish_date_start = row.b_publish_date_start;
                    let day = moment(b_publish_date_start).format('DD');
                    let month = moment(b_publish_date_start).format('MMM');

                    let obj = {
                        bg: bg[i % 3],
                        day: day,
                        month: month,
                        title: title,
                        b_id
                    };
                    arrData.push(obj);
                }
                setAnnoucemnetList(arrData);
            }
        });
    }, []);

    // const annoucementSource = [
    //     {
    //         bg: '#F5F578',
    //         day: '27',
    //         month: 'JUN',
    //         title: 'Trade & Win Cash Reward of Up to RM153,000 by Bursa Malaysia'
    //     },
    //     {
    //         bg: '#C2EC93',
    //         day: '07',
    //         month: 'APR',
    //         title: 'Bursa Malaysia Invest Shariah Retail Campaign 2022'
    //     },
    //     {
    //         bg: '#96E4A8',
    //         day: '16',
    //         month: 'FEB',
    //         title: 'Unrelated Mobile Application'
    //     },
    //     {
    //         bg: '#05CD99',
    //         day: '05',
    //         month: 'FEB',
    //         title: 'First Islamic Participating Organisation To Offer Syariah Discre...'
    //     }
    // ];

    return (
        <div className="annoucements-container w-100">
            <div className="title">Announcements</div>
            <div className="list">
                {annoucemnetList.map(function (row, i) {
                    return (
                        <div className="item" key={i}>
                            <div
                                className="date"
                                style={{ background: row.bg }}
                            >
                                <div className="day">{row.day}</div>
                                <div className="month">{row.month}</div>
                            </div>
                            <div
                                className="title"
                                onClick={() =>
                                    navigate('announcement', {
                                        state: { b_id: row.b_id }
                                    })
                                }
                            >
                                {row.title}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const ResearchReportSectionDIV = () => {
    let navigate = useNavigate();
    const [reportList, setReportList] = useState([]);

    useLayoutEffect(() => {
        api.getResearchList({}).then((result: any) => {
            console.log('getResearchList', result);
            if (result) {
                let arrData = [];
                let list = result.researches;
                for (var i = 0; i < list.length; i++) {
                    let row = list[i];

                    let title = row.category + ' - ' + row.title;
                    let b_publish_date_start = row.b_publish_date_start || null;
                    let description = row.description;
                    let b_id = row.b_id;

                    let obj = {
                        date: moment(b_publish_date_start).format(
                            'MMM DD YYYY'
                        ),
                        title: title,
                        desc: description,
                        b_id
                    };
                    arrData.push(obj);
                }
                setReportList(arrData);
            }
        });
    }, []);

    const reportSource = [
        {
            date: '15 Jul 2022',
            title: 'Market Pulse - Relief Rebound',
            desc: 'The FBM KLCI bounced off 52-week low to outperform the mixed regional markets, supported by bargain hunting activities in selected index heavyweights. '
        },
        {
            date: '14 Jul 2022',
            title: 'Market Pulse - Pressured By Red Hot Inflation',
            desc: 'The FBM KLCI slipped as rebound attempt stalled amid broad-based selldown and negative cue from Wall Street. Investors are beset by mounting recession fears following US economic release which saw...'
        },
        {
            date: '07 Jul 2022',
            title: 'Technical Focus - Pantech Group Holdings Bhd - Tapping Onto Elevated Crude Oil Prices',
            desc: 'The FBM KLCI bounced off 52-week low to outperform the mixed regional markets, supported by bargain hunting activities in selected index heavyweights. '
        }
    ];

    return (
        <div className="research-reports-container">
            <div className="title">Research Reports</div>
            <ul
                className="nav nav-tabs custom-nav"
                id="research-reports-tabs"
                role="tablist"
            >
                <li className="nav-item" role="presentation">
                    <button
                        className="nav-link active"
                        id="latest-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#latest"
                        type="button"
                        role="tab"
                        aria-controls="latest"
                        aria-selected="true"
                    >
                        Latest
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className="nav-link"
                        id="past-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#past"
                        type="button"
                        role="tab"
                        aria-controls="past"
                        aria-selected="false"
                    >
                        Past Reports
                    </button>
                </li>
            </ul>
            <div className="tab-content pt-3" id="myTabContent">
                <div
                    className="tab-pane show active"
                    id="latest"
                    role="tabpanel"
                    aria-labelledby="latest-tab"
                >
                    <div className="report-blocks-container">
                        {reportList.map(function (row, i) {
                            return (
                                <div
                                    className="item"
                                    key={i}
                                    onClick={() =>
                                        navigate('research-reports', {
                                            state: { b_id: row.b_id }
                                        })
                                    }
                                >
                                    <div className="date">{row.date}</div>
                                    <div className="title">{row.title}</div>
                                    <div className="desc">{row.desc}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div
                    className="tab-pane "
                    id="past"
                    role="tabpanel"
                    aria-labelledby="past-tab"
                ></div>
            </div>
        </div>
    );
};
export default Index;
