import { useState, useEffect, useRef } from 'react';
import './index.css';

import moment from 'moment';
import useAuth from '../../../../hooks/useAuth';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const Index = (props: any) => {
    /* @ts-ignore */
    const { auth } = useAuth();
    const mode = auth.mode;

    return (
        <RangePicker
            disabledDate={(current) => {
                if (props.maxCurrentDate) {
                    // Can not select days before today and today
                    return current && current >= moment().endOf('day');
                } else {
                    return null;
                }
            }}
            // placeholder={['', '']}
            className="w-100 daterangepicker custom"
            popupClassName={mode == 'dark' ? ' dark ' : ' '}
            format="DD-MM-YYYY"
            onChange={(dates, dateStrings) => {
                if (props.onDateChange) {
                    props.onDateChange({
                        id: props.dateStartId || 'dateStart',
                        timestamp: dateStrings[0]
                    });

                    props.onDateChange({
                        id: props.dateEndId || 'dateEnd',
                        timestamp: dateStrings[1]
                    });
                }
            }}
        />
    );
};

export default Index;
