import { numberWithCommas } from '../general/convertor';
import { useState } from 'react';
import moment from 'momnet';

export const HeaderDiv = (props: any) => {
    let content = props.content || null;
    let type = props.content || null;

    if (type && type !== '')
        return <div className="text-right">{content}</div>; //number
    else return <div>{content}</div>; //text
};

export const ColumnDiv = (props: any) => {
    let content = props.content || null;
    let type = props.content || null;

    if (type && type !== '') {
        let decimal = null;
        let type = props.content || null;

        if (type == 'value') {
        }
        return (
            <div className="text-right">
                {numberWithCommas(content, decimal)}
            </div>
        );
    }
    //number
    else return <div>{content}</div>; //text
};

export const usePagination = () => {
    const [tablePagination, setTablePagination] = useState<any>({
        currentPage: 1,
        totalRecords: 0
    });

    return {
        tablePagination,
        setTablePagination
    };
};
