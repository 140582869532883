import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from 'react-device-detect';

import '../../../asset/landing/fonts/icomoon/style.css';
import '../../../asset/landing/fonts/flaticon/font/flaticon.css';
import '../../../asset/landing/css/tiny-slider.css';
import '../../../asset/landing/css/aos.css';
import '../../../asset/landing/css/glightbox.min.css';
import '../../../asset/landing/css/style.css';

//navigation
import Nav from './components/General/Nav';
import Footer from './components/General/Footer';

//homepage
import HomeProduct from './components/Home/HomeProduct';

//about us
import AboutPage from './components/About/AboutPage';

//contact us
import Contact from './components/Contact/Contact';

const LandingPage = () => {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'lightTheme');

    const MargintopBrowswer = {
        marginTop: '87px'
    };

    const MargintopMobile = {
        marginTop: '6.5vh'
    };

    const select = (el, all = false) => {
        el = el.trim();
        if (all) {
            return [...document.querySelectorAll(el)];
        } else {
            return document.querySelector(el);
        }
    };
    const handleChangeTheme = (e) => {
        e.preventDefault();
        const isCurrentDark = theme === 'darkTheme';
        setTheme(isCurrentDark ? 'lightTheme' : 'darkTheme');
    };

    useEffect(() => {
        /**
         * Easy on scroll event listener
         */
        const onscroll = (el, listener) => {
            el.addEventListener('scroll', listener);
        };

        /**
         * Back to top button
         */
        let backtotop = select('.back-to-top');
        if (backtotop) {
            const toggleBacktotop = () => {
                if (window.scrollY > 100) {
                    backtotop.classList.add('active');
                } else {
                    backtotop.classList.remove('active');
                }
            };
            window.addEventListener('load', toggleBacktotop);
            onscroll(document, toggleBacktotop);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('theme', theme);
      }, [theme]);

    return (
        <div className={theme}>
            <Nav />
            {/* <Outlet /> */}
            <div
                className="pageController"
                style={isBrowser ? MargintopBrowswer : MargintopMobile}
            >
                <Outlet />
            </div>
            {/* Preloader */}
            {/* <div id="overlayer"></div>
            <div className="loader">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div> */}
            <Footer />

            <a
                href="#"
                className="back-to-top d-flex align-items-center justify-content-center"
            >
                <i className="bi bi-arrow-up-short"></i>
            </a>
            <a
                href="#"
                className="themechanged d-flex align-items-center justify-content-center"
                onClick={handleChangeTheme}
                style={{background: theme=="lightTheme" ? "#000" : "#fff"}}
            >
                <i
                    className={
                        theme == 'lightTheme'
                            ? 'bi bi-moon-fill'
                            : 'bi bi-sun-fill'
                    }
                    style={{color: theme == 'lightTheme'? "#fff": "#000",}}
                ></i>
            </a>
        </div>
    );
};

export default LandingPage;
