import { handleRequestPost, handleRequestGet } from '../axios';
import { parseResult, queryParamStr, queryParamStrArr } from './GeneralFunc';

export const searchClientNameAccount = async (keywords = null) => {
    let url = '/client/account/options?status=1&size=100&page=1';
    if (keywords) url += '&term=' + keywords;
    let result = await handleRequestGet(url, {});
    return parseResult(result);
};

export const getClientProfile = async (a_id = null) => {
    let url = '/client/account/' + a_id + '/profile';
    let result = await handleRequestGet(url, {});
    return parseResult(result);
};

export const getClientAccDetail = async (a_id = null) => {
    let url = '/client/account/' + a_id + '/trust/currency/1'; //1 = myr currency
    let result = await handleRequestGet(url, {});
    return parseResult(result);
};

// /client/account/2/contract?currency=1&type=0&stock=58&range[start]=2022-01-01&range[end]=2022-12-31&size=100&page=1

export const getAccountContra = async (
    a_id = null,
    {
        currency = null,
        type = 0, //0 = outstanding purchase, 1 = outstanding sales
        size = 10
    },
    page = 1
) => {
    let url = '/client/account/' + a_id + '/contra?';

    let queryString = '';
    queryString += queryParamStr('currency', currency, true);
    queryString += queryParamStr('type', type);
    queryString += queryParamStr('size', size);
    queryString += queryParamStr('page', page);

    // queryString += queryParamStrArr('accounts[]', a_id);

    let fullUrl = url + queryString;
    let result = await handleRequestGet(fullUrl, {});
    console.log(fullUrl);
    console.log(result);

    return parseResult(result);
};

export const getMultiAccountContra = async (
    acc_ids = null,
    {
        currency = null,
        type = 0, //0 = outstanding purchase, 1 = outstanding sales
        size = 10
    },
    page = 1
) => {
    let url = '/client/contra?';

    let queryString = '';
    queryString += queryParamStr('currency', currency, true);
    queryString += queryParamStr('type', type);
    queryString += queryParamStr('size', size);
    queryString += queryParamStr('page', page);

    queryString += queryParamStrArr('accounts[]', acc_ids);

    let fullUrl = url + queryString;
    let result = await handleRequestGet(fullUrl, {});
    console.log(fullUrl);
    console.log(result);

    return parseResult(result);
};

//{{host}}/client/account/2/contra?currency=1&type=0&size=100&page=1

export const getAccountContract = async (
    a_id = null,
    {
        currency = null,
        type = null, //0 = gain, 1=lose
        stock = null,
        dateStart = null,
        dateEnd = null,
        size = 10,
        statuses = null, // [0,3]
        exchanges = null
    },
    page = 1
) => {
    let url = '/client/account/' + a_id + '/contract?';
    let queryString = '';

    queryString += queryParamStr('currency', currency, true);
    queryString += queryParamStr('type', type);
    queryString += queryParamStr('stock', stock);
    queryString += queryParamStr('range[start]', dateStart);
    queryString += queryParamStr('range[end]', dateEnd);
    queryString += queryParamStr('size', size);
    queryString += queryParamStr('page', page);

    queryString += queryParamStrArr('statuses[]', statuses);
    queryString += queryParamStrArr('exchanges[]', exchanges);

    let fullUrl = url + queryString;
    let result = await handleRequestGet(fullUrl, {});

    console.log('fullUrl', fullUrl);
    console.log(result);

    return parseResult(result);
};

export const getAccountContractDetail = async (
    a_id: string,
    ctrt_id: string
) => {
    let url = '/client/account/' + a_id + '/contract/' + ctrt_id;
    let result = await handleRequestGet(url, {});
    return parseResult(result).contract || {};
};

export const getAccountContractSummary = async (
    a_id: string,
    {
        currency = null,
        stock = null,
        dateStart = null,
        dateEnd = null,
        type = null,
        types = null,
        status = null,
        statuses = null
        // page = 1
    }
) => {
    let url = '/client/account/' + a_id + '/contract/summary?';
    let queryString = '';
    queryString += queryParamStr('stock', stock, true);
    queryString += queryParamStr('type', type);
    queryString += queryParamStr('range[start]', dateStart);
    queryString += queryParamStr('range[end]', dateEnd);

    let fullUrl = url + queryString;
    let result = await handleRequestGet(fullUrl, {});
    console.log('fullUrl', url);
    return parseResult(result) || {};
};

//multi accounts
export const getMultiAccountContract = async (
    acc_ids = null,
    {
        currency = null,
        type = null, //0 = gain, 1=lose
        stock = null,
        dateStart = null,
        dateEnd = null,
        size = 10,
        statuses = null, // [0,3]
        types = null,
        exchanges = null,
        stocks = null
    },
    page = 1
) => {
    let url = '/client/contract?';
    let queryString = '';

    queryString += queryParamStr('currency', currency, true);
    queryString += queryParamStr('type', type);
    queryString += queryParamStr('stock', stock);
    queryString += queryParamStr('range[start]', dateStart);
    queryString += queryParamStr('range[end]', dateEnd);
    queryString += queryParamStr('size', size);
    queryString += queryParamStr('page', page);

    queryString += queryParamStrArr('types[]', types);
    queryString += queryParamStrArr('statuses[]', statuses);

    queryString += queryParamStrArr('exchanges[]', exchanges);
    queryString += queryParamStrArr('stocks[]', stocks);

    queryString += queryParamStrArr('accounts[]', acc_ids);

    let fullUrl = url + queryString;
    let result = await handleRequestGet(fullUrl, {});

    console.log('fullUrl', fullUrl);
    console.log(result);

    return parseResult(result);
};

export const getMultiAccountContractSummary = async (
    acc_ids = null,
    {
        currency = null,
        type = null, //0 = gain, 1=lose
        stock = null,
        dateStart = null,
        dateEnd = null,
        statuses = null, // [0,3]
        types = null,
        exchanges = null,
        stocks = null
    }
) => {
    let url = '/client/contract/summary?';
    let queryString = '';

    queryString += queryParamStr('currency', currency, true);
    queryString += queryParamStr('type', type);
    queryString += queryParamStr('stock', stock);
    queryString += queryParamStr('range[start]', dateStart);
    queryString += queryParamStr('range[end]', dateEnd);

    queryString += queryParamStrArr('types[]', types);
    queryString += queryParamStrArr('statuses[]', statuses);

    queryString += queryParamStrArr('exchanges[]', exchanges);
    queryString += queryParamStrArr('stocks[]', stocks);

    queryString += queryParamStrArr('accounts[]', acc_ids);

    let fullUrl = url + queryString;
    let result = await handleRequestGet(fullUrl, {});

    console.log('fullUrl', fullUrl);
    console.log(result);

    return parseResult(result);
};

export const getMultiAccountContractDetail = async (ctrt_id: string) => {
    let url = '/client/contract/' + ctrt_id;
    let result = await handleRequestGet(url, {});
    return parseResult(result).contract || {};
};

//multi accounts end
