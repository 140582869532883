import { createContext, useLayoutEffect, useState, useEffect } from 'react';
import { axiosInstance } from '../apis/axios';
import moment from 'moment';
import api from '../apis';
import {
    gcmDecrypt,
    generateBearerTokenKey
} from '../crypto/msgCrypto';
import { getUserDetail } from '../hooks/handleCookie';

import { useCookies } from 'react-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLoading } from './LoadingContext';
import { Modal } from 'antd';

import { showNotiLoaderError } from '../components/general/antd-loader';

import { WarningTwoTone } from '@ant-design/icons';

import Config from '../config';
// let expiredTimer: any = null;

const AxiosContext = createContext({});

//handle expired dialog
const WithAxios = ({ children }: any) => {
    let location = useLocation();
    const query = new URLSearchParams(location.search);
    const mode = query.get('mode');
    const navigate = useNavigate();

    const { setLoading, setNotiExpired,setNotiExpiredMsg } = useLoading();
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    //const [mode, setMode] = useState(null);

    useLayoutEffect(() => {
        //setMode(mode);
    }, []);

    //timer modal
    const [timeoutModal, setTimeoutModal] = useState(false);
    //expired timeout
    const [expiredTimer, setExpiredTimer] = useState(null);
    //expired date
    const [expiredDate, setExpiredDate] = useState(null);

    useEffect(() => {
        window.addEventListener('storage', (event) => {
            window.localStorage.setItem('sessionListner', '');

            let newValue = event.newValue;

            console.log(event.key, newValue);

            switch (newValue) {
                case 'refresh':
                    setTimeoutModal(false);
                    api.heartbeat();
                    break;
                case 'logout':
                    setTimeoutModal(false);
                    resetUserCookie();
                    break;
            }

            setExpiredTimer(null);
        });

        return () => {
            // bc.close();
        };
    }, []);

    // useEffect(() => {
    //     document.addEventListener('visibilitychange', function () {
    //         if (document.hidden) {
    //             // console.log('not active');
    //             // tab is now inactive
    //             // temporarily clear timer using clearInterval() / clearTimeout()
    //         } else {
    //             // tab is active again
    //             // restart timers
    //             // console.log('active');
    //         }
    //     });
    // }, []);

    //axios response
    useEffect(() => {
        axiosInstance.interceptors.response.use(
            async (response: any) => {
                if(!response) return null;
                
                if (response.status === 200 && response.data) {

                    let headers = response?.headers;
                    let expiredAt = headers['expired-at'];
                    console.log('next expired-at', expiredAt);
                    if (expiredAt && mode != 'mobile') {
                        //set current expiredDate
                        setExpiredDate(expiredAt);
                    }

                    return response; //fetch data to display
                }
                return response;
            },
            async (error: any) => {     
                console.log(error);
 
                // console.log(error.response.status)
                if (
                    error.response.status === 401
                    ||
                    error.response.status === 402
                    ||
                    error.response.status === 404
                    // ||
                    // error.response.status === 0
                    // ||
                    // error.response.status === 502
                ) {
                   
                    try {
                        let userDetail = getUserDetail();
                        if (userDetail && userDetail.token) {
                            let data = error.response.data;
                            let token = userDetail.token;
                            let key = await generateBearerTokenKey(token);
                            let result = await gcmDecrypt(data, key);
    
                            if(result){
                                let pResult = JSON.parse(result);
                                if(pResult.message && pResult.message !="Unauthorized"){
                                    setNotiExpiredMsg(pResult.message)
                                }
                            }
                          
    
                          //  console.log("handle123 key",key)
                           // console.log("handle123 gcmDecrypt",result)
                        }
    
                        resetUserCookie();
                        setNotiExpired(true);
                    } catch (error) {
                        
                    }
                    
                   

                } else if (error.response.status === 500) {
                    showNotiLoaderError();
                }

            
            }
        );
    }, [axiosInstance]);

    //refresh poout countdown
    useEffect(() => {
       console.log("expiredDate",expiredDate)
        //setTimeoutModal(false);
        setExpiredTimer(null);
        let expiredTimeout = null;
        if (expiredDate && cookies.user) {
            // console.log('set expired date,start timer');
            let expiredMoment = moment(expiredDate, 'YYYY-MM-DDTHH:mm:ss');
            let duration = moment.duration(expiredMoment.diff(moment()));

            let timerAlertCountdown = Config.timerAlertCountdown || 0;
            let triggerCountdownAt =
                duration.asMilliseconds() - timerAlertCountdown * 1000;
            // console.log('ori expiredDate', expiredDate);
           // console.log('set expiredAt', expiredDate);
            // console.log('duration', duration.asMilliseconds());
            // console.log('count down at ', triggerCountdownAt);

            //if overtime trigger timer immediately
            if (triggerCountdownAt < 0) {
                triggerCountdownAt = 1000;
                // console.log('dected timeout');
            }

            expiredTimeout = setTimeout(function () {
                // console.log(
                //     'start timer',
                //     moment(expiredDate).format('YYYY-MM-DD HH:mm:ss')
                // )
                setExpiredTimer(null);
                let call_ack = api.getSessAck().then((result) => {
                    let newExpiredAt = result.headers['expired-at'];

                  console.log('newExpiredAt', newExpiredAt);
                    setExpiredTimer(timerAlertCountdown);
                    if (expiredDate == newExpiredAt) {
                        console.log('open modal');
                        setTimeoutModal(true);
                    }
                });
            }, triggerCountdownAt);
        }
        return () => {
            clearTimeout(expiredTimeout);
        };
    }, [expiredDate]);

    //render countdown seconds
    useEffect(() => {
        let TimerInterval = null;
        // console.log(expiredTimer); //countdown seconds

        if (expiredTimer) {
            TimerInterval =
                expiredTimer > 0 &&
                setInterval(() => {
                    setExpiredTimer((time) => time - 1);
                }, 1000);
        } else if (expiredTimer == 0 && expiredTimer != null) {
            // console.log(expiredTimer);
            if (expiredTimer == 0) {
                console.log(
                    'timeout, trigger logout',
                    moment().format('YYYY-MM-DD HH:mm:ss')
                );
                console.log('expiredTimer', expiredTimer);
                handleLogout();
                setNotiExpired(true);
                //alert('trigger logout');
            }
        }

        return () => {
            clearInterval(TimerInterval);
        };
    }, [expiredTimer]);

    const handleSessAck = async () => {
        await api.heartbeat();

        setTimeoutModal(false);
        setExpiredTimer(null); //stop count down

        console.log('refresh');
        window.localStorage.setItem('sessionListner', 'refresh');
    };

    const handleLogout = async () => {
        await setLoading(true);
        let result = await api.userLogout();
        if (result == true) {
            resetUserCookie();
        }
        setLoading(false);
        setExpiredTimer(null);
        window.localStorage.setItem('sessionListner', 'logout');
    };

    const resetUserCookie = async () => {
        removeCookie('user', { path: '/' });
        navigate('/');
        setTimeoutModal(false);
        setExpiredTimer(null);
    };

    // return children;

    return (
        <AxiosContext.Provider value={{ setTimeoutModal }}>
            {timeoutModal == true && (
                <Modal
                    title={
                        <div className="d-flex">
                            <WarningTwoTone
                                twoToneColor="#ed0d0d"
                                style={{
                                    fontSize: '16px',
                                    paddingTop: '2px'
                                }}
                            />
                            <div>Timeout Warning</div>
                        </div>
                    }
                    maskClosable={false}
                    keyboard={false}
                    closable={false}
                    open={timeoutModal}
                    okText="Stay logged on"
                    onOk={handleSessAck}
                    cancelText="Log off"
                    onCancel={handleLogout}
                >
                    <p>
                        You will automatically be logged off in {expiredTimer}{' '}
                        seconds
                    </p>
                </Modal>
            )}

            {children}
        </AxiosContext.Provider>
    );
};

export default WithAxios;
