import React from 'react';

//homepage
import { TopBannerHome } from '../General/TopBanner';
import HomeProduct from './HomeProduct';
import OurProductBanner from './OurProductBanner';
import LoginButton from './LoginButton';

const PgHomePage = () => {
    return (
        <div className="Homepage">
            {/* <Top /> */}
            <TopBannerHome />
            {/* <HomeProduct /> */}
            <OurProductBanner />
            {/* <LoginButton /> */}
        </div>
    );
};

export default PgHomePage;
