import { useState, useLayoutEffect, useMemo } from 'react';
import api from '../../../apis';

import { useParams } from 'react-router-dom';

import CTACreditFrom from '../../forms/cta-ecredit-form';

const Index = () => {
    useLayoutEffect(() => {}, []);

    return <CTACreditFrom />;
};

export default Index;
