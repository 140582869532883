import { useState, useEffect, useRef } from 'react';
import './index.css';

const Index = (props: any) => {
    useEffect(() => {
        return () => {
            // alert('destroy component');
            if (props.onChange) {
                props.onChange({
                    target: {
                        id: props.id,
                        value: ''
                    }
                });
            }
        };
    }, []);
    return (
        <div className="form-input">
            {props.type == 'textarea' ? (
                <textarea
                    id={props.id}
                    rows="4"
                    cols="50"
                    className={'custom custom-text w-100 ' + props.className}
                    value={props.value}
                    onChange={({ target }) => {
                        if (props.onChange) {
                            props.onChange({ target });
                        }
                    }}
                />
            ) : (
                <input
                    id={props.id}
                    className={'custom custom-text ' + props.className}
                    type={props.type || 'text'}
                    value={props.value}
                    onChange={({ target }) => {
                        if (props.onChange) {
                            props.onChange({ target });
                        }
                    }}
                />
            )}

            <div className="input-error">{props.error || ''}</div>
        </div>
    );
};

export default Index;
