import { useState, useEffect, useMemo } from 'react';
import './index.css';

import api from '../../../apis';

import ReactTable from '../../general/form-control/react-table';
import { useNavigate } from 'react-router-dom';

import FormInput from '../../general/form-control/form-item/input';
import FormSelect from '../../general/form-control/form-item/select';
import FormInputAmount from '../../general/form-control/form-item/input-amount';

import { useLoading } from '../../../context/LoadingContext';

const Index = (props: any) => {
    const navigate = useNavigate();
    const { setLoading } = useLoading();

    useEffect(() => {
        setLoading(true);
        api.getBankList().then((result: any) => {
            setLoading(false);
            // console.log('getBankList');
            // console.log(result);
            setBankList(result);
            // setStockData(result[0].stocks);
        });
        setLoading(false);
    }, []);

    useEffect(() => {
        setCurrentAccNo(props.currentAccNo);
    }, [props.currentAccNo]);

    //validation settings
    const [rules, setRules] = useState<any>({
        bank: { required: true, error_message: 'Please select bank' },
        amount: { required: true, error_message: 'Please input amount' }
    });

    const [currentAccNo, setCurrentAccNo] = useState<any>('');
    const [bankList, setBankList] = useState([]);

    const [inputs, setInputs] = useState<any>({ paymentType: '' });
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        console.log(inputs);
    }, [inputs]);

    const onInputChange = (id: string, newValue: string) => {
        setInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Payment initiated by Clients',
                accessor: '1'
            },
            {
                Header: '',
                accessor: '2'
            },
            {
                Header: `Funds reflected in Client's Trading Limit`,
                accessor: '3'
            }
        ],
        []
    );

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);

        let { valid, errors } = formValidation(rules, inputs);
        setErrors(errors);
        // console.log('valid', valid);
        // console.log('errors', errors);

        if (!currentAccNo) {
            alert('Please select Account No');
        } else if (valid == false) {
            //scroll to first error
            if (Object.keys(errors).length > 0) {
                let firstKey = Object.keys(errors)[0];
                let element = document.getElementById(firstKey);
                element?.scrollIntoView();
            }
        } else {
            // {
            //     "mode": 2,
            //     "datetime": "2022-01-01 23:59:59",
            //     "amount": 1,
            //     "currency": 1,
            //     "deposit_bank": 2,
            //     "deposit_bank_branch": "KL",
            //     "file": "1663806992865-6524536a-7a4b-4f47-af1d-5f7d18996f6c.pdf",
            //     "drawee_bank": 2,
            //     "cheque_no": "CH00001",
            //     "cheque_date": "2022-01-01"
            // }

            //if no error
            let result = await api.submitFPX(currentAccNo, inputs);
            console.log('submit fpx result');
            console.log(result);

            if (result?.data?.fpx && result?.status == 0) {
                let fpx_url = result.data.fpx;
                window.open(fpx_url, '_blank', 'noopener,noreferrer');

                alert('Thank you, the form was submitted successfully');
                //refresh page
                navigate(0);
            } else if (result?.message) {
                let message = result?.message;
                let sentence = '';

                for (var key of Object.keys(message)) {
                    sentence += message[key];
                }

                alert(sentence);
            }
        }
        setLoading(false);
    };

    const formValidation = (rules: any, inputs: any) => {
        if (!rules) return { valid: true, errors: {} };

        let valid = true;
        let errors: any = {};

        //validate rule
        for (var key in rules) {
            if (rules.hasOwnProperty(key)) {
                let item = rules[key];
                if (item.required == true && !inputs[key]) {
                    let error = item.error_message || 'Required';
                    errors[key] = error;
                    valid = false;
                } else if (item.requireIfExist) {
                    //if specific input exist,current input become required
                    let requireIfExist = item.requireIfExist;
                    let relatedkey = requireIfExist.key;
                    let relatedValue = requireIfExist.value;

                    //match type start check error
                    if (
                        relatedkey &&
                        relatedValue &&
                        inputs[relatedkey] &&
                        inputs[relatedkey] == relatedValue
                    ) {
                        let error = item.error_message || 'Required';
                        errors[key] = error;
                        valid = false;
                    }
                } else {
                }

                // requireIfExist: { key: 'mode', value: 2 }
            }
        }

        return {
            valid,
            errors
        };
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="funding-deposit-container">
                <div className="row ">
                    <div className="col-xxl-6 col-lg-6 col-md-8 col-sm-8 col-xs-12 col-12">
                        <div className="col-12">
                            <div className="form-input-container ">
                                <div className="label">Drawee Bank</div>
                                <div className="custom-input-div">
                                    <FormSelect
                                        id="bank"
                                        className="w-100"
                                        value={inputs.bank || ''}
                                        onChange={({ target }: any) =>
                                            onInputChange(
                                                target.id,
                                                target.value
                                            )
                                        }
                                        error={errors.bank}
                                    >
                                        <option value={''} disabled>
                                            Please select
                                        </option>
                                        {bankList.length > 0 &&
                                            bankList.map(
                                                (item: any, i: number) => {
                                                    return (
                                                        <option
                                                            value={item.bk_id}
                                                        >
                                                            {item.bk_name}
                                                        </option>
                                                    );
                                                }
                                            )}
                                    </FormSelect>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-input-container ">
                                <div className="label">Amount</div>
                                <div className="custom-input-div">
                                    <FormInputAmount
                                        id="amount"
                                        type="number"
                                        value={inputs.amount || ''}
                                        onChange={({ target }: any) =>
                                            onInputChange(
                                                target.id,
                                                target.value
                                            )
                                        }
                                        error={errors.amount}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-6 col-lg-6 col-md-8 col-sm-8 col-xs-12 col-12 ">
                            <div className="col-12 ">
                                <div className="form-input-container ">
                                    <div className="label"></div>
                                    <div className="custom-text">
                                        <div>
                                            Minimum transaction limit (RM): 100
                                        </div>
                                        <div>
                                            Maximum transaction limit (RM):
                                            50,000
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-6 col-lg-6 col-md-8 col-sm-8 col-xs-12 col-12 ">
                            <div className="col-12">
                                <div className="form-input-container" style={{paddingRight: '0px'}}>
                                    <div className="label">
                                        Remarks (optional)
                                    </div>
                                    <div className="custom-input-div">
                                        <textarea
                                            className="custom"
                                            id="remarks"
                                            rows={4}
                                            cols={50}
                                            onChange={({ target }) =>
                                                onInputChange(
                                                    target.id,
                                                    target.value
                                                )
                                            }
                                            style={{ resize: 'none' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-6 col-lg-6 col-md-8 col-sm-8 col-xs-12 col-12 ">
                            <div className="col-12 pt-3">
                                <div
                                    className="row-submit"
                                    style={{ paddingRight: '0px' }}
                                >
                                    <button className="btn-primary-custom">
                                        Proceed &nbsp;&nbsp;
                                        <i
                                            className="fas fa-chevron-right "
                                            aria-hidden="true"
                                        ></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {' '}
                    <div className="col-md-12 col-12 pt-4">
                        {' '}
                        {/* <div style={{ width: '100%', maxWidth: '936px' }}> */}
                        <div style={{ width: '100%' }}>
                            <ReactTable
                                headerStyle={{
                                    background: 'rgb(67, 151, 130)'
                                }}
                                columns={columns}
                                data={[
                                    {
                                        1: 'Monday to Friday',
                                        2: 'Before 4:45pm',
                                        3: 'Same business day upon confirmation'
                                    },
                                    {
                                        1: 'Monday to Friday',
                                        2: 'After 4:45pm',
                                        3: 'Next business day upon confirmation'
                                    },
                                    {
                                        1: 'Saturday, Sunday & Public Holiday',
                                        2: '',
                                        3: 'Next business day upon confirmation'
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default Index;
