import property from '../../../constants/property';

///////////
//status integer to status text convertor
//////////

const Index = (id, status) => {
    // console.log('convert');
    // console.log(id);
    // console.log(status);

    //match status in constant file
    if (!property[id] && !property[id][status]) {
        return id;
    } else {
        //return matched text
        return property[id][status];
    }
};

export default Index;
