export function parseAPIResultToData(result: any, selectedValue = null) {
    let newArr = [];
    let parentsKeys = [];
    let parentMapping = {};
    let firstValue = null;

    result.forEach(function (item, i) {
        let row = {
            title: item.p_fullname,
            value: item.p_fullname + '-' + i,
            // checkable: true,
            checkable: selectedValue ? false : true, // if user selected on child then set another node to cheeckble false
            isLeaf: true
        };

        let p_fullname = item.p_fullname;
        parentsKeys.push(item.p_fullname + '-' + i);

        if (item.accounts && item.accounts.length > 0) {
            let children = item.accounts;
            let newChildrenArr = [];

            let disabled = selectedValue ? true : false; //if got selectedValue set to true

            children.forEach(function (item) {
                if (selectedValue && selectedValue == item.a_id) {
                    disabled = false;
                }

                let row = {
                    title: item.a_code,
                    value: item.a_id,
                    // key: item.a_id + '-' + i,
                    disabled: disabled
                };
                newChildrenArr.push(row);

                if (!parentMapping[item.a_id]) {
                    parentMapping[item.a_id] = p_fullname;
                }

                if (item.a_id && !firstValue) {
                    firstValue = {
                        title: item.a_code,
                        value: item.a_id
                    };
                }
            });

            if (disabled == false) {
                for (let i = 0; i < newChildrenArr.length; i++) {
                    let row = newChildrenArr[i];
                    row.disabled = false;
                }

                //set parent to true
                row.checkable = true;
            }

            row['children'] = newChildrenArr;
        }
        newArr.push(row);
    });

    return {
        data: newArr,
        parentsKeys: parentsKeys,
        parentMapping,
        firstValue
    };
}
