import { useState, useEffect, useRef } from 'react';
import './index.css';
import { parseAPIResultToData } from './helper';
import api from '../../../../apis';

import { Tree, TreeSelect } from 'antd';

const TreeNode = Tree.TreeNode;

const Index = (props: any) => {
    const [treeData, setTreeData] = useState([]);

    const [searchKey, setSearchKey] = useState('');
    const [value, setValue] = useState([]);
    const [filterInputs, setFilterInputs] = useState({});

    const [treeExpandedKeys, setTreeExpandedKeys] = useState();
    const [dataMap, setDataMap] = useState({});

    useEffect(() => {
        // requestGetStockOption();
    }, []);

    useEffect(() => {
        if (JSON.stringify(props.filter) !== JSON.stringify(filterInputs)) {
            let filter = props.filter;

            let triggerApi = false;
            console.log('filter', filter);
            //if selected and not same, trigger api
            if (filter && filter.exchanges != filterInputs.exchanges) {
                if (filter.exchanges == '') {
                    //when reset exchanges input,clear stock input too
                    setValue([]);
                } else {
                    triggerApi = true;
                }
            }

            if (filter && filter.stocks != value) {
                triggerApi = true;
            }

            if (triggerApi == true) {
                //console.log('props.filter', props.filter);
                // alert('not same');
                setFilterInputs(props.filter);
            }
        }
    }, [props.filter]);

    useEffect(() => {
        if (Object.keys(filterInputs).length > 0) {
            const delayDebounceFn = setTimeout(() => {
                requestGetStockOption();
            }, 600);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [filterInputs]);

    const requestGetStockOption = () => {
        return api.getStockOptions(filterInputs).then((result: any) => {
            console.log('getStockOptions');
            console.log(result);
            // let clients = result.clients;
            if (result) {
                let parseResult = parseAPIResultToData(result);
                let data = parseResult.data;
                let parentsKeys = parseResult.parentsKeys;
                let mapping = parseResult.mapping;
                // setTreeData([]);
                //setLoading(true);
                //setTreeData(parseResult);
                setTreeData((prevState) => [...data]);
                setTreeExpandedKeys(parentsKeys);
                setDataMap(mapping);
                //setLoading(false);
                // treeData = parseResult;
                console.log(data);
            }

            // setTreeSelectLoading(false);
            // if (result.clients) updateTreeSelect(result.clients);
        });
    };

    const renderTreeNodes = (data) => {
        return data.map((item) => {
            if (item.children) {
                return (
                    <TreeNode title={item.title} key={item.key} dataRef={item}>
                        {renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode {...item} dataRef={item} />;
        });
    };

    const onChange = (newValue: string[]) => {
        setValue(newValue);

        let newArrResult = [];
        for (var i = 0; i < newValue.length; i++) {
            newArrResult.push(dataMap[newValue[i]]);
        }

        if (props.onChange) {
            props.onChange(newArrResult);
        }

        console.log('onChange ', newValue);
        console.log('newArrResult ', newArrResult);
    };

    const onSearch = (newValue: string) => {
        console.log('onSearch ', newValue);
        // setSearchKey(newValue);
        //setTreeData([{ title: 'Expand to load', key: '1' }]);
        // setValue(newValue);

        setFilterInputs((prevState) => ({
            ...prevState,
            term: newValue
        }));
    };

    // const onTreeLoad = (newValue: string) => {
    //     // setValue(newValue);
    // };

    const onTreeExpand = (expandedKeys) => {
        setTreeExpandedKeys(expandedKeys);
    };

    return (
        <TreeSelect
            value={value}
            onChange={onChange}
            onSearch={onSearch}
            onTreeExpand={onTreeExpand}
            treeExpandedKeys={treeExpandedKeys}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            // loadData={onLoadData}
            style={{ width: '100%' }}
            treeDefaultExpandAll={true}
            treeCheckable={true}
            allowClear={true}
            treeData={treeData.map((d, i) => ({
                title: d.title,
                value: d.value,
                match: d.match,
                treeDefaultExpandAll: true,
                checkable: d.checkable,
                key: d.key,
                children: d.children
            }))}
        />
        //     {renderTreeNodes(treeData)}
        // </TreeSelect>
    );
};

export default Index;
