import React from 'react';

const TopHeader = () => {
    return (
        <>
            <div className="top-line">
                <div className="top-header">
                    <div className="container" style={{width:'1140px'}}>
                        <div className="row">
                            <div className="col-md-5 col-sm-12">
                                <ul className="info">
                                    <li>
                                        <i
                                            className="fa fa-volume-control-phone"
                                            aria-hidden="true"
                                        ></i>
                                        <a href="#">
                                            +603 2147 1888{' '}
                                        </a>
                                    </li>
                                    <li style={{marginLeft: '5px'}}>
                                        <i
                                            className="fa fa-envelope-o"
                                            aria-hidden="true"
                                        ></i>
                                        <a href="#">
                                            mycustomerservice@uobkayhian.com{' '}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-7 col-sm-6">
                                <nav id="nav">
                                    <ul id="navigation2">
                                        <li>
                                            <a href="#">
                                                You are in{' '}
                                                <b>UTRADE MALAYSIA</b>{' '}
                                                <i
                                                    className="fa fa-caret-down"
                                                    aria-hidden="true"
                                                ></i>
                                            </a>
                                            <ul>
                                                <li>
                                                    <a
                                                        href="#"
                                                    >
                                                        UTRADE HONG KONG
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                    >
                                                        UTRADE INDONESIA
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                    >
                                                        UTRADE SINGAPORE
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                    >
                                                        UTRADE THAILAND
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul id="navigation">
                                        <li>
                                            <a href="/UtradeLogin">
                                                Login
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                Open An Account
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopHeader;
