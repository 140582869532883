import icHome from '../asset/images/nav-menu-icon/home.svg';
import icTradingPlatform from '../asset/images/nav-menu-icon/trading_platform.svg';
import icUser from '../asset/images/nav-menu-icon/user.svg';
import icTrade from '../asset/images/nav-menu-icon/my_trade.svg';
import icPortfolio from '../asset/images/nav-menu-icon/portfolio.svg';
import icFunding from '../asset/images/nav-menu-icon/funding.svg';
import icEstatement from '../asset/images/nav-menu-icon/estatement.svg';
import icEforms from '../asset/images/nav-menu-icon/eforms.svg';
import icCorporate_actions from '../asset/images/nav-menu-icon/corporate_actions.svg';
import icSupport from '../asset/images/nav-menu-icon/support.svg';
import icAnnouncement from '../asset/images/nav-menu-icon/announcement.svg';
import icResearch from '../asset/images/nav-menu-icon/research.svg';

interface NavOptions {
    url?: string;
    label: string;
    icon: any;
    selected?: boolean;
    subMenu?: Array<SubMenu>;
}

interface SubMenu {
    url: string;
    label: string;
    selected?: boolean;
}

const sidebarSource: NavOptions[] = [
    {
        url: '/',
        label: 'Dashboard',
        icon: icHome
    },
    {
        url: '/trading-platform',
        label: 'Trading Platform',
        icon: icTradingPlatform
    },
    {
        label: 'My Account',
        icon: icUser,
        subMenu: [
            {
                url: '/customer-profile',
                label: 'Customer Profile'
            },
            {
                url: '/account-summary',
                label: 'Account Summary'
            },
            {
                url: '/outstanding-purchase',
                label: 'Outstanding Purchase'
            },
            {
                url: '/outstanding-sales',
                label: 'Outstanding Sales'
            },
            {
                url: '/contra',
                label: 'Contra'
            },
            {
                url: '/contract-information',
                label: 'Contract Information'
            }
        ]
    },
    {
        url: '',
        label: 'My Trades',
        icon: icTrade,
        subMenu: [
            {
                url: '/trade-history',
                label: 'Trade History'
            }
        ]
    },
    {
        url: '/portfolio',
        label: 'Portfolio',
        icon: icPortfolio
    },
    {
        url: '',
        label: 'Funding',
        icon: icFunding,
        subMenu: [
            {
                url: '/funding-deposit',
                label: 'Deposit'
            },
            {
                url: '/funding-withdrawal',
                label: 'Withdrawal'
            },
            {
                url: '/funding-settlement',
                label: 'Settlement'
            },
            {
                url: '/funding-history',
                label: 'History'
            }
        ]
    },
    {
        url: '/eStatement',
        label: 'eStatement',
        icon: icEstatement
    },
    {
        url: '',
        label: 'eForms',
        icon: icEforms,
        subMenu: [
            {
                url: '/data-correction-request-form',
                label: 'Data Correction Form'
            },
            {
                url: '/cta-ecredit-form',
                label: 'CTA eCREDIT Form'
            }
        ]
    },
    {
        url: '/corporate-action',
        label: 'Corporate Actions',
        icon: icCorporate_actions
    },
    {
        url: '/research-reports',
        label: 'Research Reports',
        icon: icResearch
    },
    {
        url: '/announcement',
        label: 'Announcement',
        icon: icAnnouncement
    },
    {
        url: 'Support',
        label: 'Support',
        icon: icSupport
    }
];

export default sidebarSource;
