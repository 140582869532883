import { Outlet, useLocation } from 'react-router-dom';
import { createRef, useRef, useState, useEffect, useContext } from 'react';
// import '../../asset/sb-admin-2/scss/sb-admin-2.scss';

// import '../../asset/styles/layout-style.css';

import Header from '../Header';
import Topbar from '../../navigation/Topbar';

import useAuth from '../../../hooks/useAuth';

const Layout = () => {
    const [isIframe, setIsIframe] = useState(false);
    const [headerElementHeight, setHeaderElementHeight] = useState(0);

    /* @ts-ignore */
    const { auth } = useAuth();
    const mode = auth.mode;

    useEffect(() => {
        let headerElement = document.getElementsByClassName(
            'top-menu-header-container'
        );
        console.log(headerElement);
        let offsetHeight = headerElement[0].offsetHeight;
        setHeaderElementHeight(offsetHeight);
        console.log(offsetHeight);
    }, []);

    //redirect page scroll to top
    let location = useLocation();
    useEffect(() => {
        let divElement = document.getElementById('top-menu-page-container');
        divElement.scroll({
            top: 0, //scroll to the bottom of the element
            behavior: 'smooth' //auto, smooth, initial, inherit
        });
    }, [location]);

    return (
        <div className="App">
            <div
                className={'layout-container ' + (mode == 'dark' ? 'dark' : '')}
            >
                <div className="main-container fluid-container overflow-hidden">
                    <div className="row top-menu-header-container">
                        <Header />
                        <Topbar />
                    </div>
                    <div
                        id="top-menu-page-container"
                        className="row top-menu-page-container"
                        style={{
                            position: 'relative',
                            height: `calc(100vh - ${headerElementHeight}px)`
                        }}
                    >
                        {isIframe == false ? (
                            <div
                                className={
                                    'col-12  ' +
                                    (isIframe == false ? ' pl-5 pr-5 ' : ' ')
                                }
                            >
                                <div
                                    className={
                                        'page-content pb-1 ' +
                                        (isIframe == false
                                            ? ' '
                                            : ' iframe-block position-relative')
                                    }
                                >
                                    <Outlet context={{ setIsIframe }} />
                                </div>
                                {isIframe == false && (
                                    <footer className="pt-5 pb-3">
                                        <div className="footer-container protected">
                                            © 2022 FiNext Sdn. Bhd.
                                        </div>
                                    </footer>
                                )}
                            </div> // independent iframe to avoid boostrap padding
                        ) : (
                            <Outlet context={{ setIsIframe }} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
