import {
    handleRequestGet,
    handleRequestPost,
    handleRequestPut
} from '../axios';
import { parseResult, parseSubmitResult } from './GeneralFunc';

export const heartbeat = async () => {
    let url = '/sess/heartbeat';
    let result = await handleRequestGet(url, {});
    console.log('heartbeat', result);
    return result;
};

export const getSessAck = async () => {
    let url = '/sess/ack';

    //get full response included header
    let result = await handleRequestGet(url, {}, true);

    try {
        if(result && result.data){
            console.log('result sess/ack', result);
            let paresedResult = JSON.parse(result.data);
            result.data = paresedResult;
        }
        // console.log(paresedResult)
        return result;
    } catch (error) {
        console.log(error);
    }
};

export const getSessFeedAuth = async () => {
    let url = '/sess/feed/auth';
    let result = await handleRequestGet(url, {});
    console.log('sessFeedAuth', result);

    return parseResult(result) || {};
};

export const changePassword = async ({ old_password, new_password }: any) => {
    let url = '/sess/password';
    let param = { old_password, new_password };
    console.log(param);
    let result = await handleRequestPut(url, param);
    console.log('change password response');
    console.log(result);

    return parseSubmitResult(result);
};

export const sessForgetPassword = async ({ email, nric }: any) => {
    let url = '/sess/forgot_password';
    let param = { email, nric };
    let result = await handleRequestPost(url, param);

    console.log(param);
    console.log('forgot password response');
    console.log(result);

    return parseSubmitResult(result);
};

export const sessValidateForgetPassword = async ({ reset_token }: any) => {
    let url = '/sess/reset_password/validate';
    let param = { reset_token };
    let result = await handleRequestPost(url, param);
    return parseSubmitResult(result);
};

export const sessUpdateForgetPassword = async ({
    password,
    reset_token
}: any) => {
    let url = '/sess/reset_password';
    let param = { password, reset_token };
    let result = await handleRequestPost(url, param);

    console.log(param);
    console.log('forgot password response');
    console.log(result);

    return parseSubmitResult(result);
};

export const agreeTnc = async () => {
    let url = '/sess/tnc/agree';
    let param = {};
    let result = await handleRequestPost(url, param);

    console.log(param);
    console.log('agreeTnc');
    console.log(result);

    return parseSubmitResult(result);
};
