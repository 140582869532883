import React from 'react';
import { TopBannerV2 } from '../General/TopBanner';
import { ProductLeftDesc, ProductRightDesc } from '../General/ProductImgDetail';

const Tds = () => {
    const TDS_image = [
        {
            id: 1,
            imageURL: '/img/FiNext/TDS/finextwebsitephotofortds/1.png',
            description: 'coming soon.'
        },
        {
            id: 2,
            imageURL: '/img/FiNext/TDS/finextwebsitephotofortds/2.png',
            description: 'coming soon.'
        },
        {
            id: 3,
            imageURL: '/img/FiNext/TDS/finextwebsitephotofortds/3.png',
            description: 'coming soon.'
        },
        {
            id: 4,
            imageURL: '/img/FiNext/TDS/finextwebsitephotofortds/4.png',
            description: 'coming soon.'
        },
        {
            id: 5,
            imageURL: '/img/FiNext/TDS/finextwebsitephotofortds/5.png',
            description: 'coming soon.'
        },
        {
            id: 6,
            imageURL: '/img/FiNext/TDS/finextwebsitephotofortds/6.png',
            description: 'coming soon.'
        },
        {
            id: 7,
            imageURL: '/img/FiNext/TDS/finextwebsitephotofortds/7.png',
            description: 'coming soon.'
        },
        {
            id: 8,
            imageURL: '/img/FiNext/TDS/finextwebsitephotofortds/9.png',
            description: 'coming soon.'
        },
        {
            id: 9,
            imageURL: '/img/FiNext/TDS/finextwebsitephotofortds/11.png',
            description: 'coming soon.'
        }
    ];

    return (
        <div className="TdsProduct">
            <TopBannerV2
                data={{
                    title: '',
                    name: 'TraderStation',
                    details:
                        'Our trading system provides a highly customizable layout, allowing the inclusion of up to six components on each page. TDS adapts seamlessly to multiple devices and browsers with mobile responsiveness to ensure optimal ',
                    imageURL: '/img/slide/slide-1.gif',
                    style: false,
                    background: true
                }}
            />

            <div className="section">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-7 mx-auto text-center">
                            <span className="d-block subheading mb-3">
                                Advantages
                            </span>
                            <h2 className="heading">
                                The Advantages of TDS Trading Solution
                            </h2>
                        </div>
                    </div>
                    <div className="row mt-5 px-2">
                        <div className="col-md-6 col-lg-6">
                            <div className="feature">
                                <img
                                    src="/components/asset/landing/images/svg/006-clipboard.svg"
                                    alt="Image"
                                    className="img-fluid"
                                />
                                <h3 className="heading-h3 mb-3">Efficiency</h3>
                                <p className="text-black-50">
                                    Our solution is efficient, automating the
                                    trading process from start to finish,
                                    freeing up your time to focus on other areas
                                    of your business.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="feature">
                                <img
                                    src="/components/asset/landing/images/svg/003-engineer.svg"
                                    alt="Image"
                                    className="img-fluid"
                                />
                                <h3 className="heading-h3 mb-3">
                                    Intuitive Interface
                                </h3>
                                <p className="text-black-50">
                                    Our intuitive interface such as multiple
                                    exchanges in one page makes it easy for
                                    traders to leverage technology to its full
                                    extent.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="feature">
                                <img
                                    src="/components/asset/landing/images/svg/002-crane.svg"
                                    alt="Image"
                                    className="img-fluid"
                                />
                                <h3 className="heading-h3 mb-3">
                                    Increased Transparency
                                </h3>
                                <p className="text-black-50">
                                    Our solution provides increased transparency
                                    with real-time, customizable reporting to
                                    make informed decisions.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="feature">
                                <img
                                    src="/components/asset/landing/images/svg/002-crane.svg"
                                    alt="Image"
                                    className="img-fluid"
                                />
                                <h3 className="heading-h3 mb-3">Reduce Risk</h3>
                                <p className="text-black-50">
                                    Our customizable risk management options
                                    coupled with intuitive risk monitoring
                                    system reduces risk exposure.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <>
                {/* {TDS_image.map((item) => {
                    return ((item.id % 2 !== 0) ? <ProductLeftDesc data={{imgpath:item.imageURL, key:item.id, description: item.description}} /> : <ProductRightDesc data={{imgpath:item.imageURL, key:item.id, description: item.description}} />);
                })} */}
            </>
        </div>
    );
};

export default Tds;
