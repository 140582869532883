import { useState, useEffect, useLayoutEffect, useMemo } from 'react';

import api from '../../../apis';
import moment from 'moment';

import { useLoading } from '../../../context/LoadingContext';

import ReactTable from '../../general/form-control/react-table';
import statusText from '../../../constants/property';

import DetailTable from '../../general/info-control/table-detail';
import { formGroupTableData } from '../../general/info-control/table-detail/helper';

import UserAccTreeSelect from '../../general/form-control/user-acc-tree-select';
import StockTreeSelect from '../../general/form-control/stock-tree-select';
import ExchangeTreeSelect from '../../general/form-control/exchange-tree-select';

import DateRangePicker from '../../general/form-control/date-rangepicker';

import { numberWithCommas } from '../../general/convertor';
import { usePagination } from '../../tables/helper';
import { HeaderDiv } from '../../tables/helper';

import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const total_purchase_sales_table_format = () => {
    const total_buy_sell_table_format = [
        {
            id: 'total',
            label: 'No of Contracts',
            type: 'quantity',
            value: '-'
        },
        {
            id: 'quantity',
            label: 'Quantity',
            type: 'quantity',
            value: '-'
        },
        {
            id: 'value',
            label: 'Value',
            type: 'value',
            value: '-'
        }
    ];

    return total_buy_sell_table_format;
};

const contract_detail_table_format = [
    { id: 'ctrt_contractno', label: 'Contract Number', value: '' },
    {
        id: 'ctrt_type',
        label: 'Contract Type',
        statusToText: 'contractTypes',
        value: ''
    },
    {
        id: 'ctrt_contractdate',
        label: 'Contract Date',
        value: '',
        type: 'timestamp'
    },
    { id: 'ctrt_duedate', label: 'Due Date', value: '', type: 'timestamp' },
    { id: 't_name', label: 'Stock Name', value: '' },
    {
        id: 'c_name',
        label: 'Currency',
        value: ''
    },
    { id: 'ctrt_quantity', label: 'Quantity.', type: 'quantity', value: '' },
    {
        id: 'ctrt_price',
        label: 'Price',
        type: 'value',
        value: ''
    },
    {
        id: 'ctrt_value',
        label: 'Contract Value',
        type: 'value',
        value: ''
    },
    { id: 'ctrt_proceeds', label: 'Proceeds', type: 'value', value: '' },
    { id: 'ctrt_brokerage', label: 'Brokerage', type: 'value', value: '' },
    { id: 'ctrt_clearingfee', label: 'Clearing Fee', type: 'value', value: '' },
    { id: 'ctrt_stampduty', label: 'Stamp Duty', type: 'value', value: '' },
    {
        id: 'ctrt_misccharges',
        label: 'Misc. Charges',
        type: 'value',
        value: ''
    },
    { id: 'ctrt_blockrate', label: 'Book Rate', type: 'value', value: '' },
    { id: 'ctrt_taxcharges', label: 'GST/SST Amount', type: 'value', value: '' }
];

const Index = () => {
    const { setNotiLoading } = useLoading();

    const [currentAccNo, setCurrentAccNo] = useState([]);
    const [currentUsername, setCurrentUsername] = useState('');

    //instance searchInputs
    const [searchInputs, setSearchInputs] = useState<any>({
        currency: null,
        type: null,
        stock: null, //get first key,
        dateStart: null,
        dateEnd: null
    });

    const { tablePagination, setTablePagination } = usePagination();
    const [tableData, setTableData] = useState([]);
    // const [tablePagination, setTablePagination] = useState<any>({
    //     currentPage: 1,
    //     totalRecords: 0
    // });

    const [totalBuyData, setTotalBuyData] = useState<any>(null);
    const [totalSellData, setTotalSellData] = useState<any>(null);

    const [contractDetailTableData, setContractDetailTableData] =
        useState<any>(null);

    useEffect(() => {
        if (currentAccNo.length > 0) {
            setNotiLoading(true);
            console.log('inputs', searchInputs);
            // showNotiLoader();
            const delayDebounceFn = setTimeout(() => {
                requestGetAccountContractAPI(currentAccNo);
            }, 600);

            return () => clearTimeout(delayDebounceFn);
        } else if (currentAccNo.length == 0) {
            handleResetTables();
        }
    }, [currentAccNo, searchInputs]);

    const handleResetTables = () => {
        //reset data
        setTableData([]);
        setTotalBuyData(null);
        setTotalSellData(null);
        setContractDetailTableData(contract_detail_table_format);
        setNotiLoading(false);
    };

    const onDropdownChange = (id: string, newValue: string) => {
        setSearchInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    const onInputChange = (id: string, newValue: string) => {
        setSearchInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Contract Type',
                accessor: 'type'
            },
            {
                Header: 'Contra Date',
                accessor: 'ctrt_contractdate',
                Cell: (v: any) => {
                    if (v.value) return moment(v.value).format('DD-MM-YYYY');
                }
            },
            {
                Header: 'Due Date',
                accessor: 'ctrt_duedate',
                Cell: (v: any) => {
                    if (v.value) return moment(v.value).format('DD-MM-YYYY');
                }
            },
            {
                Header: 'Exchange',
                accessor: 'e_name'
            },
            {
                Header: 'Stock Name',
                accessor: 't_name'
            },
            {
                Header: <div className="text-right">Quantity</div>,
                accessor: 'ctrt_quantity',
                Cell: (v) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value)}
                            </div>
                        );
                }
            },
            {
                Header: 'Currency',
                accessor: 'c_code'
            },
            {
                Header: <div className="text-right">Price</div>,
                accessor: 'ctrt_price',
                Cell: (v) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(Number(v.value), 4)}
                            </div>
                        );
                }
            },

            {
                Header: <div className="text-right">Contract Value</div>,
                accessor: 'ctrt_value',
                Cell: (v) => {
                    if (v.value)
                        return (
                            <div className="text-right">
                                {numberWithCommas(v.value, 2)}
                            </div>
                        );
                }
            }
        ],
        []
    );

    const onDateChange = ({ id, timestamp }: any) => {
        let cdate = '';
        if (timestamp) {
            cdate = moment(timestamp, 'DD-MM-YYYY').format('YYYY-MM-DD');
        }
        onDropdownChange(id, cdate);
    };

    //request api
    const requestGetAccountContractAPI = async (accNo: any, cPage = 1) => {
        setNotiLoading(true);

        // console.log(searchInputs);
        let result = await api.getMultiAccountContract(
            accNo,
            searchInputs,
            cPage
        );

        let contractSummary = await api.getMultiAccountContractSummary(
            accNo,
            searchInputs
        );

        console.log('getMultiAccountContract', result);
        console.log('contractSummary', contractSummary);

        // console.log(result);
        if (result.contracts) {
            let contracts = result.contracts;
            setTableData(contracts);
        }

        if (result.total) {
            // handlePagination('currentPage', cPage);
            // handlePagination('totalRecords', result.total);
            setTablePagination({
                currentPage: cPage,
                totalRecords: result.total
            });
        }

        if (contractSummary.summary) {
            let summary = contractSummary.summary;
            //  console.log(contracts);
            handleContractInformationSummary(summary);
        }
        setNotiLoading(false);
    };
    //request end

    const updateContractDetailTable = async (result: any) => {
        let tableData = formGroupTableData(
            contract_detail_table_format,
            result
        );
        setContractDetailTableData(tableData);
    };

    const handleTableRowClick = async (row: any) => {
        let rowData = row?.original;
        let ctrt_id = rowData.ctrt_id;
        // console.log(rowData);
        if (ctrt_id) {
            setNotiLoading(true);
            let result = await api.getMultiAccountContractDetail(ctrt_id);
            console.log('getAccountContractDetail');
            // console.log(result);
            updateContractDetailTable(result);
            setNotiLoading(false);
        }
    };

    const handleContractInformationSummary = (summary = {}) => {
        let buy = summary.purchase || {};
        let sell = summary.sales || {};

        if (buy) {
            let data = total_purchase_sales_table_format();
            for (var i = 0; i < data.length; i++) {
                let col = data[i];
                let id = col.id;

                let cValue = buy[id]; //buy value
                col.value = cValue || '-';
                // alert(buy[id]);
                // alert(JSON.stringify(col));
                // if (buy[id]) col.value = buy[id];
            }
            setTotalBuyData([...data]);
        }

        if (sell) {
            let data = total_purchase_sales_table_format();
            for (var z = 0; z < data.length; z++) {
                let col = data[z];
                let id = col.id;

                let cValue = sell[id]; //sell value
                col.value = cValue || '-';
            }
            // alert(JSON.stringify(data));
            setTotalSellData(data);
        }
    };

    return (
        <div className="pt-3 text-left ">
            <div className="row">
                <div className="col-12 main-title">Contract Information</div>
                <div className="col-xxxl-3 col-xl-4 col-lg-4 col-md-6 col-12 ">
                    <div className="input-container">
                        <div className="label">Username / Account No </div>
                        <UserAccTreeSelect
                            onChange={(value: string[]) => {
                                console.log('UserAccTreeSelect', value);
                                setCurrentAccNo(value);
                            }}
                            onChangeUsername={(val: string) =>
                                setCurrentUsername(val)
                            }
                        />
                    </div>
                </div>
                <div className="col-xxxl-3 col-xl-4 col-lg-4 col-md-6 col-12 ">
                    <div className="input-container ">
                        <div className="label">Name</div>
                        <input
                            id="username"
                            className="custom border-bottom"
                            type="text"
                            value={currentUsername}
                            // onChange={({ target }) =>
                            //     onInputChange(target.id, target.value)
                            // }
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xxxl-3 col-xl-4 col-lg-4 col-md-6 col-12">
                    {' '}
                    <div className="input-container ">
                        <div className="label">Exchange </div>
                        <ExchangeTreeSelect
                            onChange={(value: string[]) => {
                                onInputChange('exchanges', value);
                            }}
                        />
                    </div>
                </div>
                <div className="col-xxxl-3 col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="input-container ">
                        <div className="label">Stock </div>
                        <StockTreeSelect
                            filter={searchInputs}
                            onChange={(value: string[]) => {
                                console.log('StockTreeSelect');
                                console.log(value);

                                onInputChange('stocks', value);
                            }}
                        />
                    </div>
                </div>

                {/* <div className="col-xxxl-3 col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="input-container ">
                        <div className="label">Currency </div>
                        <select
                            id="currency"
                            className="custom"
                            value={searchInputs.currency}
                            onChange={(e) =>
                                onDropdownChange(e.target.id, e.target.value)
                            }
                        >
                            {statusText.currenciesList.map((item, i) => {
                                return (
                                    <option value={item.c_id}>
                                        {item.c_name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div> */}
            </div>
            <div className="row">
                <div className="col-xxxl-3 col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="input-container ">
                        <div className="label">Contract Type</div>
                        <select
                            id="type"
                            className="custom"
                            value={searchInputs.type || ''}
                            onChange={(e) =>
                                onDropdownChange(e.target.id, e.target.value)
                            }
                        >
                            <option value={''}>All</option>
                            {Object.entries(statusText.contractTypes).map(
                                ([key, value]) => {
                                    return <option value={key}>{value}</option>;
                                }
                            )}
                        </select>
                    </div>
                </div>
                <div className="col-xxxl-3 col-xl-4 col-lg-4 col-md-12 col-12">
                    <div className="input-container ">
                        <div className="label">Contract Date </div>
                        <DateRangePicker
                            maxCurrentDate={true}
                            onDateChange={(obj: any) => onDateChange(obj)}
                        />
                        {/* <RangePicker
                            disabledDate={(current) => {
                                // Can not select days before today and today
                                return (
                                    current && current >= moment().endOf('day')
                                );
                            }}
                            className="w-100 daterangepicker custom"
                            format="DD-MM-YYYY"
                            onChange={(dates, dateStrings) => {
                                console.log(dateStrings);

                                onDateChange({
                                    id: 'dateStart',
                                    timestamp: dateStrings[0]
                                });

                                onDateChange({
                                    id: 'dateEnd',
                                    timestamp: dateStrings[1]
                                });
                            }}
                        /> */}
                    </div>
                </div>
            </div>
            <div className="row">
                {' '}
                <div className="col-xxxl-9 col-lg-7 col-12 pt-5">
                    <div className="row">
                        <div className="col-12 ">
                            <div className="row">
                                <div className="col-xxxl-6 col-12 pb-4">
                                    <DetailTable
                                        data={
                                            totalBuyData ||
                                            total_purchase_sales_table_format()
                                        }
                                        titleProps={{
                                            label: 'Total Purchase',
                                            bgColor: '#439782'
                                        }}
                                        itemProps={{
                                            bottomLine: false,
                                            className: 'col-md-4 col-12'
                                        }}
                                        labelProps={{
                                            className: 'text-center'
                                        }}
                                    />
                                </div>
                                <div className="col-xxxl-6 col-12 pb-4">
                                    <DetailTable
                                        data={
                                            totalSellData ||
                                            total_purchase_sales_table_format()
                                        }
                                        titleProps={{
                                            label: 'Total Sales',
                                            bgColor: '#E0484C'
                                        }}
                                        itemProps={{
                                            bottomLine: false,
                                            className: 'col-md-4 col-12'
                                        }}
                                        labelProps={{
                                            className: 'text-center'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <ReactTable
                                columns={columns}
                                data={tableData}
                                rowClick={handleTableRowClick}
                                pageSize={10}
                                currentPage={tablePagination.currentPage}
                                totalRecords={tablePagination.totalRecords}
                                pageChange={(cPage: number) =>
                                    requestGetAccountContractAPI(
                                        currentAccNo,
                                        cPage
                                    )
                                }
                                hasPagination
                            />
                        </div>
                    </div>
                </div>
                <div className="col-xxxl-3 col-lg-5 col-12 pt-5">
                    <DetailTable
                        data={
                            contractDetailTableData ||
                            contract_detail_table_format
                        }
                        titleProps={{
                            label: 'Contract Details',
                            bgColor: '#00218F'
                        }}
                        itemProps={{
                            bottomLine: false,
                            className: 'col-md-6 col-12'
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Index;
