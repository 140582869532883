import { useState, useEffect, useRef } from 'react';
import './index.css';

const Index = (props: any) => {
    useEffect(() => {
        return () => {
            // alert('destroy component');
            if (props.onChange) {
                props.onChange({
                    target: {
                        id: props.id,
                        value: ''
                    }
                });
            }
        };
    }, []);
    return (
        //form-input
        <div className="form-input">
            <select
                id={props.id}
                className={'custom ' + props.className}
                value={props.value || ''}
                // onChange={({ target }) => onInputChange(target.id, target.value)}
                onChange={({ target }) => {
                    if (props.onChange) {
                        props.onChange({ target });
                    }
                }}
            >
                {props.children}
            </select>
            <div className="input-error">{props.error || ''}</div>
        </div>
    );
};

export default Index;
