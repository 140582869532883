import {
    useLayoutEffect,
    useRef,
    useState,
    useEffect,
    useContext
} from 'react';
import '../../asset/styles/top-bar-container-style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import sidebarSource from '../nav-config';

interface Props {
    show?: boolean;
}

const Index = (props: Props) => {
    const location = useLocation();

    const [sidebarProps, setSidebarProps] = useState(sidebarSource);

    useEffect(() => {}, []);

    useEffect(() => {
        let lastSegment = location.pathname;
        initSideOptions(sidebarSource, lastSegment);
    }, [location]);

    const initSideOptions = (data: any, lastSegment: String) => {
        let foundPage = false;
        let newArr: any = [];

        //check selected option and highlight it
        for (var i = 0; i < sidebarSource.length; i++) {
            let row = data[i];
            data[i].selected = false;
            if (row.url == lastSegment) {
                data[i].selected = true;
                foundPage = true;
            }

            if (row.subMenu && row.subMenu.length > 0) {
                //check submenu data
                for (var z = 0; z < row.subMenu.length; z++) {
                    //default set to false;
                    data[i].subMenu[z].selected = false;

                    let innerRow = row.subMenu[z];
                    if (innerRow.url == lastSegment) {
                        //selected submenu option
                        data[i].subMenu[z].selected = true;
                        //selected parent option
                        data[i].selected = true;

                        foundPage = true;
                    }
                }
            }

            newArr.push(row);
        }

        if (foundPage == false && data.length > 0) {
            data[0].selected = true;
        }

        setSidebarProps(newArr);
    };

    return (
        <>
            {props.show != false ? (
                <div className="top-nav-container">
                    <ul className="top-nav-navbar">
                        {sidebarProps.map(function (row, i) {
                            let className = '';
                            if (row.selected == true) className = ' active';

                            let compt = <TopMenuItem row={row} key={i} />;
                            // let compt = (
                            //     <li
                            //         className={className}
                            //         key={i}
                            //         onClick={(e) => redirect(row.url)}
                            //     >
                            //         <div className="item">
                            //             <div className="item-content">
                            //                 <img src={row.icon} />
                            //                 <div className="">{row.label}</div>
                            //             </div>
                            //             {/* render if submenu data exist */}
                            //             {row.subMenu && (
                            //                 <div className="sub-menu">
                            //                     {row.subMenu &&
                            //                         row.subMenu.length > 0 &&
                            //                         row.subMenu.map(function (
                            //                             row,
                            //                             i
                            //                         ) {
                            //                             let className = 'item';
                            //                             //check selected option
                            //                             if (
                            //                                 row.selected == true
                            //                             ) {
                            //                                 className =
                            //                                     'item active';
                            //                             }

                            //                             return (
                            //                                 <div
                            //                                     className={
                            //                                         className
                            //                                     }
                            //                                     onClick={(e) =>
                            //                                         redirect(
                            //                                             row.url
                            //                                         )
                            //                                     }
                            //                                 >
                            //                                     <div
                            //                                         id={row.url}
                            //                                     >
                            //                                         {row.label}
                            //                                     </div>
                            //                                 </div>
                            //                             );
                            //                         })}
                            //                 </div>
                            //             )}
                            //         </div>
                            //     </li>
                            // );

                            return compt;
                        })}
                    </ul>
                </div>
            ) : null}
        </>
    );
};

const TopMenuItem = (props: any) => {
    const itemRef = useRef();
    let navigate = useNavigate();
    let row = props.row;
    let className = '';
    if (row.selected == true) className = ' active';

    const [itemPosition, setItemPosition] = useState({});

    const redirect = (url: string) => {
        if (url) navigate(url);
    };

    useEffect(() => {
        if (itemRef.current && row.subMenu) {
            let bound = itemRef.current.getBoundingClientRect();
            setItemPosition(bound);
        }
    }, []);

    // resize listener
    useLayoutEffect(() => {
        function updateSize() {
            let bound = itemRef.current.getBoundingClientRect();
            setItemPosition(bound);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (
        <li className={className} onClick={(e) => redirect(row.url)}>
            <div className="item" ref={itemRef}>
                <div className="item-content">
                    <img src={row.icon} />
                    <div className="">{row.label}</div>
                </div>
                {/* render if submenu data exist */}
                {row.subMenu && (
                    <div
                        className="sub-menu"
                        style={{
                            left: itemPosition?.x + 15 + 'px' || '0',
                            top: itemPosition?.y + 15 + 'px' || '0'
                        }}
                    >
                        {row.subMenu &&
                            row.subMenu.length > 0 &&
                            row.subMenu.map(function (row, i) {
                                let className = 'item';
                                //check selected option
                                if (row.selected == true) {
                                    className = 'item active';
                                }

                                return (
                                    <div
                                        className={className}
                                        onClick={(e) => redirect(row.url)}
                                    >
                                        <div id={row.url}>{row.label}</div>
                                    </div>
                                );
                            })}
                    </div>
                )}
            </div>
        </li>
    );
};

export default Index;
