import { useState, useEffect, useRef } from 'react';
import './index.css';

import { parseAPIResultToData } from './helper';
import api from '../../../../apis';

import { Tree, TreeSelect } from 'antd';
const TreeNode = Tree.TreeNode;

const Index = (props: any) => {
    const [oriData, setOriData] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [value, setValue] = useState(null);

    const [parentMapping, setParentMapping] = useState('');

    const [searchKey, setSearchKey] = useState('');
    const [treeExpandedKeys, setTreeExpandedKeys] = useState();
    useEffect(() => {
        requestSearchClientNameAccount();
    }, []);

    useEffect(() => {
        if (searchKey) {
            const delayDebounceFn = setTimeout(() => {
                requestSearchClientNameAccount();
            }, 600);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchKey]);

    const requestSearchClientNameAccount = () => {
        return api.searchClientNameAccount(searchKey).then((result: any) => {
            console.log('searchClientNameAccount');
            console.log(result);

            let clients = result.clients;
            if (clients) {
                setOriData(clients);
                let parseResult = parseAPIResultToData(clients);
                let data = parseResult.data;
                let parentsKeys = parseResult.parentsKeys;
                let parentMapping = parseResult.parentMapping;
                let firstValue = parseResult.firstValue;

                // setTreeData([]);
                //setLoading(true);
                //setTreeData(parseResult);
                setTreeData((prevState) => [...data]);
                setTreeExpandedKeys(parentsKeys);
                setParentMapping(parentMapping);

                onChangePostBack([firstValue.value], clients, parentMapping);
                setValue([firstValue.value]);

                // onChange([firstValue.value]);
                //setLoading(false);
                // treeData = parseResult;
                //console.log(data);
            }

            // setTreeSelectLoading(false);
            // if (result.clients) updateTreeSelect(result.clients);
        });
    };

    const renderTreeNodes = (data) => {
        return data.map((item) => {
            if (item.children) {
                return (
                    <TreeNode title={item.title} key={item.key} dataRef={item}>
                        {renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode {...item} dataRef={item} />;
        });
    };

    const onChange = (newValue: string[]) => {
        setValue(newValue);
        onChangePostBack(newValue, oriData, parentMapping);
    };

    const onChangePostBack = (newValue: string[], oriData, parentMapping) => {
        if (props.onChange) {
            // console.log('onChange ', newValue);
            props.onChange(newValue);

            if (newValue.length == 1) {
                let parseResult = parseAPIResultToData(oriData, newValue[0]);
                let data = parseResult.data;
                // console.log('oriData', oriData);
                // console.log('newValue[0]', newValue[0]);
                // console.log('parseResult', parseResult);
                setTreeData((prevState) => [...data]);
            } else if (newValue.length == 0) {
                let parseResult = parseAPIResultToData(oriData);
                let data = parseResult.data;
                setTreeData((prevState) => [...data]);

                if (props.onChangeUsername) {
                    props.onChangeUsername('');
                }
            }

            //return current username
            if (newValue.length >= 1) {
                if (props.onChangeUsername) {
                    props.onChangeUsername(parentMapping[newValue[0]]);
                }
            }
        }
    };

    // const restrictCurrentNode = (newValue: string[]) => {
    //     treeData;
    // };

    const onSearch = (newValue: string) => {
        console.log('onSearch ', newValue);
        setSearchKey(newValue);
        //setTreeData([{ title: 'Expand to load', key: '1' }]);
        // setValue(newValue);
    };

    const onTreeExpand = (expandedKeys) => {
        setTreeExpandedKeys(expandedKeys);
    };

    return (
        <TreeSelect
            popupClassName={'user-acc-tree-select-container'}
            treeCheckable={true}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            value={value || []}
            onChange={onChange}
            onSearch={onSearch}
            onTreeExpand={onTreeExpand}
            treeExpandedKeys={treeExpandedKeys}
            // loadData={onLoadData}
            style={{ width: '100%' }}
            treeDefaultExpandAll={true}
            allowClear={true}
            treeData={treeData.map((d, i) => ({
                title: d.title,
                value: d.value,
                match: d.match,
                treeDefaultExpandAll: true,
                checkable: d.checkable,
                key: d.key,
                children: d.children
            }))}
        />
    );
};

export default Index;
