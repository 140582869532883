import React from "react";

const Footer = () => {
    return (
        <div className="container-lg">
            <div id="footer">
                <footer className="main-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 social-icons" style={{textAlign:'left',}}>
                                <h3>Disclaimer</h3>
                                <p>Whilst care and effort has been taken to ensure the accuracy of the information provided herein, 
                                UOB Kay Hian Securities (M) Sdn Bhd does not warrant the information expressed or implied is absolutely true, correct, 
                                timely or fit for any particular purpose or use. We are not liable for any loss or damage arising from the use of this information. 
                                Use of this site may involve the electronic transmission of personal financial information. 
                                Using this site is consent to such transmission of this information; such consent is effective at all times when using this site. 
                                Usage of UOB Kay Hian Internet Trading constitutes agreement of the Terms and Conditions Governing Internet Trading. 
                                Please read it carefully before you invest. Certain information displayed on this site are links from public domain sites; 
                                if we have in any way violated any copyrights and other proprietary rights, kindly inform us immediately and we will remove the indicated content from this website.
                                </p>
                                
                            </div>
                            
                            <div className="col-md-3 get-in-touch" style={{textAlign:'left',}}>
                                <h3 className="get-in-touch-title"><a href="#">Contact Us</a>&nbsp;&nbsp;<small>|</small>&nbsp;&nbsp;
                                <a href="#">About Us</a>&nbsp;&nbsp;<small>|</small>&nbsp;&nbsp;
                                <a href="#">Career</a></h3>
                                <ul style={{paddingLeft: '0'}}>
                                    <li><i className="fa fa-map-marker" aria-hidden="true"></i>Ground &amp; 19th Floor Menara Keck Seng, 203, Jalan Bukit Bintang, 55100, Kuala Lumpur</li>
                                    <li><i className="fa fa-clock-o" aria-hidden="true"></i>Mon to Fri - 08:30am - 05:30pm</li>
                                    <li><i className="fa fa-phone" aria-hidden="true"></i>(603) 2147 1888</li>
                                    <li><i className="fa fa-envelope-o" aria-hidden="true"></i><a href="#">mycustomerservice@uobkayhian.com </a></li>
                                    <li><i className="fa fa-facebook-square" aria-hidden="true"></i><a href="#">UTRADE Malaysia</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
                
                <section className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="float-left">
                                <div>
                                    <a href="#"><u>Securities</u></a>
                                    | <a href="#"><u>Futures (Individual)</u></a>
                                    | <a href="#"><u>Futures (Corporate)</u></a>
                                    | <a href="#"><u>Investment in Unlisted Products</u></a>
                                    | <a href="#"><u>Privacy Policy</u></a>
                                    | <a href="#"><u>Anti Bribery & Corruption Policy</u></a>
                                    | <a href="#"><u>Whistleblowing Policy</u></a>
                                    | <a href="#"><u>T&C for UTrade</u></a> 
                                    <br /> All rights reserved. 
                                    <br /> Company Name : UOB Kay Hian Securities (M) Sdn Bhd
                                    <br /> Registration No. : 199001003423 (194990-K)
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Footer;