import { useState, useEffect, useMemo } from 'react';
import '../../asset/styles/research-reposts-style.css';

import api from '../../../apis';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useLoading } from '../../../context/LoadingContext';

import CardList from '../../general/card-list';
import Modal from '../../general/modal';

import Config from '../../../config';

import TheWaitIsOverArticle from '../../general/dummy-article/the-wait-is-over';
import EventArticle from '../../general/article/eventArticle';

import Datepicker from '../../general/form-control/datepicker';
import DateRangePicker from '../../general/form-control/date-rangepicker';

import event1 from '../../asset/images/event/1101.png';
import event2 from '../../asset/images/event/1102.png';
import event3 from '../../asset/images/event/1103.png';

import eventIC1 from '../../asset/images/event/logo_bursa.svg';
import eventIC2 from '../../asset/images/event/logo_ppl.svg';
import eventIC3 from '../../asset/images/event/logo_pinterest.svg';

import StockTreeSelect from '../../general/form-control/stock-tree-select';
import ExchangeTreeSelect from '../../general/form-control/exchange-tree-select';

import useAuth from '../../../hooks/useAuth';

import { DatePicker, Pagination } from 'antd';
const { RangePicker } = DatePicker;

const Index = () => {
    const { state } = useLocation();
    const { setLoading } = useLoading();
    const [modelProps, setModelProps] = useState({});
    const [researchTypes, setResearchTypes] = useState<any>([]);
    const [reportList, setReportList] = useState([]);

    const [inputs, setInputs] = useState({ paymentType: '' });
    const [openModel, setOpenModel] = useState(false);

    const [cResearchType, setcResearchType] = useState<any>(null);
    const [dateStart, setDateStart] = useState<any>(null);
    const [dateEnd, setDateEnd] = useState<any>(null);
    const [cTerm, setcTerm] = useState<any>(null);
    const [cExchange, setcExchange] = useState<any>([]);
    const [cStock, setcStock] = useState<any>([]);
    const [cPage, setcPage] = useState<any>(1);
    const [totalRecord, setTotalRecord] = useState<any>(10);
    
    const { auth, setAuth } = useAuth();

    useEffect(() => {
        getResearchCategory();
    }, []);

    useEffect(() => {
        console.log(inputs);
        // if (currentAccNo) {
        //     requestGetAccountContractAPI(currentAccNo, searchInputs);
        // }
    }, [inputs]);

    useEffect(() => {
        getResearchCategoryList();
    }, [cResearchType, cPage]);

    useEffect(() => {
        getResearchCategoryList();
    }, [dateEnd]);

    useEffect(() => {
        //typing delay
        const delayDebounceFn = setTimeout(() => {
            if (cTerm) {
                //pass keyin change to parent
                getResearchCategoryList();
            }
        }, 700);
        return () => clearTimeout(delayDebounceFn);
    }, [cTerm]);
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getResearchCategoryList();
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [cExchange, cStock]);

    const getResearchCategory = () => {
        const { b_id } = state || {};

        setLoading(true);
        api.getResearchCategory().then((result: any) => {
            if (result) {
                setResearchTypes(result);
                getResearchCategoryList(b_id);
            }
        });
    };

    const getResearchCategoryList = (c_b_id = null) => {
        setLoading(true);

        api.getResearchList({
            page: cPage,
            category: parseInt(cResearchType),
            dateStart: dateStart?.date,
            dateEnd: dateEnd?.date,
            term: cTerm?.value,
            exchanges: cExchange,
            stocks: cStock
        }).then((result: any) => {
            console.log('getResearchList', result);
            if (result.total) {
                setTotalRecord(result.total);
            }
            if (result.researches) {
                console.log('result.researches', result.researches);
                let arrData = [];
                let list = result.researches;
                for (var i = 0; i < list.length; i++) {
                    let row = list[i];

                    let title = row.category + ' - ' + row.title;
                    let b_publish_date_start = row.b_publish_date_start || null;
                    let description = row.description;
                    let file_thumbnail = row.file_thumbnail
                        ? Config.fileUrl + row.file_thumbnail
                        : event1;
                    let file_content = row.file_content;
                    let htmlfile =
                        Config.fileUrl + file_content + '#toolbar=0&navpanes=0';
                    let b_id = row.b_id;
                    let sourceText = row.source ? row.source + ' • ' : '';
                    let obj = {
                        img: file_thumbnail,
                        date: moment(b_publish_date_start).format(
                            'MMM DD YYYY'
                        ),
                        b_publish_date_start: b_publish_date_start,
                        title: title,
                        subTitle: (
                            <>
                                {sourceText +
                                    moment(b_publish_date_start).format(
                                        'DD MMM YYYY'
                                    )}
                                {/* Bursa Malaysia • 22 Jul 2022 */}
                            </>
                        ),
                        desc: description,
                        file_content: htmlfile,
                        source: row.source,
                        category: row.category,
                        file_type: 'research',
                        layout: auth.layout,
                        b_id
                    };
                    arrData.push(obj);

                    if (b_id == c_b_id) {
                        //selected article
                        setModelProps(obj);
                        setOpenModel(true);
                    }
                }
                setReportList(arrData);
            }

            setLoading(false);
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
    };

    const onDateChange = ({ id, timestamp }: any) => {
        let cdate = '';
        if (timestamp) {
            cdate = moment(timestamp, 'DD-MM-YYYY').format('YYYY-MM-DD');
        }

        console.log('id, timestamp', id, timestamp);
        //  onDropdownChange(id, cdate);
        if (id == 'dateStart') {
            setDateStart({ id, date: cdate });
        } else if (id == 'dateEnd') {
            setDateEnd({ id, date: cdate });
        }
    };

    const onTermChange = (id: string, value: string) => {
        setcTerm({ id, value });
    };
    const onExchangeChange = (value: any) => {
        setcExchange(value);
    };
    const onStockChange = (value: any) => {
        setcStock(value);
    };

    const onDropdownChange = (id: string, newValue: string) => {
        setInputs((prevState: any) => ({
            ...prevState,
            [id]: newValue
        }));
    };

    const rawEventSource = [
        {
            img: event1,
            subTitle: (
                <>
                    <img src={eventIC1} /> Bursa Malaysia • 22 Jul 2022
                </>
            ),
            title: 'The Bursa Marketplace Virtual Fair 2022 is back!',
            desc: 'Gain the latest market insights, engage with corporate leaders from top listed companies, get access to free investment tools to boost your financial planning...'
        },
        {
            img: event2,
            subTitle: (
                <>
                    <img src={eventIC2} /> mplusonline • 15 Jul 2022
                </>
            ),
            title: 'Understanding the Bollinger Bands Indicator For Trading ',
            desc: "Elky is a Dealer's Representative by profession. He has been in the industry since the year 2012, started in Futures & Options, to..."
        },
        {
            img: event3,
            subTitle: (
                <>
                    <img src={eventIC3} /> PINS • 8 Jul 2022
                </>
            ),
            title: 'Pinterest’s mood board comes through',
            desc: 'Struggling social platform Pinterest has been manifesting a recovery for its share price, and turns out the universe has been listening.'
        }
    ];

    const eventSource = [
        ...rawEventSource,
        ...rawEventSource,
        ...rawEventSource,
        ...rawEventSource
    ];

    const handleArticle = (row: any) => {
        console.log(row);
        setOpenModel((oldValue) => true);
        setModelProps(row);
    };

    const onPaginationChange = (page: any) => {
        setcPage(page);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="pt-3 text-left">
                <div className="row">
                    <div className="col-xxxl-2 col-xl-2 col-lg-3 col-md-4 col-12">
                        <div className="col-12 main-title">
                            Research Reports
                        </div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            {' '}
                            <div className="input-container ">
                                <div className="label">Research Type</div>
                                <select
                                    className="custom"
                                    defaultValue={'all'}
                                    onChange={(e) => {
                                        setcResearchType(e.target.value);
                                    }}
                                >
                                    <option value={''}>All</option>
                                    {researchTypes.map((row: any) => {
                                        return (
                                            <option value={row.rc_id}>
                                                {row.rc_name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            {' '}
                            <div className="input-container ">
                                <div className="label">Exchange</div>
                                <ExchangeTreeSelect
                                    onChange={(value: string[]) => {
                                        onExchangeChange(value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="input-container ">
                                <div className="label">Keyword</div>
                                {/* <select
                                className="custom"
                                defaultValue={''}
                            ></select> */}
                                <input
                                    id="keyword"
                                    className="custom-keyword "
                                    type="text"
                                    // value={currentUsername}
                                    onChange={({ target }) =>
                                        onTermChange(target.id, target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="input-container ">
                                <div className="label">Stock Name</div>
                                <StockTreeSelect
                                    filter={{
                                        exchanges: [...cExchange]
                                    }}
                                    onChange={(value: string[]) => {
                                        // console.log('StockTreeSelect');
                                        // console.log(value);
                                        onStockChange(value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-xxxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <div className="input-container sideFilter">
                                <div className="label"> Date </div>
                                <DateRangePicker
                                    onDateChange={(obj: any) =>
                                        onDateChange(obj)
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-xxxl-10 col-xl-10 col-lg-9 col-md-8 col-12">
                        <Modal
                            opened={openModel || false}
                            onClosed={(openModel: boolean) =>
                                setOpenModel(openModel)
                            }
                        >
                            {/* <TheWaitIsOverArticle /> */}
                            <EventArticle {...modelProps} />
                        </Modal>

                        <CardList
                            data={reportList || []}
                            onClick={(row: any) => handleArticle(row)}
                        />
                        {/* <div className="row">
                            <div className="col-12"> */}
                        <div className="d-flex justify-content-center">
                            {' '}
                            <Pagination
                                onChange={onPaginationChange}
                                defaultCurrent={1}
                                total={totalRecord}
                            />
                        </div>
                        {/* </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default Index;
