import { useState, useEffect, useCallback } from 'react';
import './index.css';

import Datepicker from '../../../general/form-control/datepicker';

const Index = (props: any) => {
    const [didMount, setDidMount] = useState(false);
    const [reset, setReset] = useState<any>(false);

    const [updatedKey, setUpdatedKey] = useState<any>(Math.random() * 10);

    // Setting didMount to true upon mounting
    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (props.value == '' && didMount == true) {
            //reset datepicker
            setUpdatedKey(Math.random() * 10);
        }
    }, [props.value]);

    return (
        //form-input
        <div className="form-input">
            <>
                <Datepicker
                    key={updatedKey}
                    id={props.id}
                    value={props.value || ''}
                    onChange={({ id, timestamp }: any) => {
                        if (props.onChange) {
                            let target = {
                                id: id,
                                value: timestamp
                            };
                            props.onChange({ target });
                        }
                    }}
                    placeholder={props.placeholder || ''}
                    reset={reset}
                />

                <div className="input-error">{props.error || ''}</div>
            </>
        </div>
    );
};

export default Index;
