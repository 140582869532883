import { useState, useEffect, useLayoutEffect } from 'react';
import { useTable, usePagination } from 'react-table';
// import {
//     showNotiLoader,
//     hideNotiLoader,
//     showNotiLoaderError
// } from '../../antd-loader';

import { message } from 'antd';

import './index.css';

//data format
interface PropsData {
    headerStyle?: any;
    columns?: Array[];
    data?: Array[];
    rowClick?: any;
    currentPage?: number;
    totalRecords?: number;
    pageSize?: number;
    pageChange?: any;
    hasPagination?: boolean;
    selectedRows?: any; //active style set to selected rows
}

const Index = ({
    headerStyle,
    columns,
    data,
    rowClick,
    hasPagination,
    currentPage,
    totalRecords,
    pageSize,
    pageChange,
    selectedRows
}: PropsData) => {
    const _pageSize = pageSize || 10;
    const pageRange = 5;

    const [visiblePages, setVisiblePages] = useState([]);
    const [tableTotalPage, setTableTotalPage] = useState(0);

    // const [startLoad, setStartLoad] = useState(false);
    // useEffect(() => {
    //     if (startLoad == true) showNotiLoader();
    // }, [startLoad]);

    // const [rowSelection, setRowSelection] = useState({});

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        // Get the state from the instance
        state: { pageIndex }
        //pageSize,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: currentPage - 1 || 0, //first page
                pageSize: _pageSize
            },
            state: {
                //rowSelection,
                pageIndex: currentPage - 1 || 0 //first page
            },
            //onRowSelectionChange: setRowSelection,
            manualPagination: true, // Tell the usePagination
            // hook that we'll handle our own data fetching
            // This means we'll also have to provide our own
            // pageCount.
            pageCount: Math.ceil((totalRecords || 0) / _pageSize)
        },
        usePagination
    );

    useEffect(() => {
        //  alert('change');
    }, [pageIndex]);

    useEffect(() => {
        if (totalRecords) {
            getVisiblePages(pageIndex, _pageSize, totalRecords || 0);
            // setCurrentPage(pagination.currentPage);
        }
    }, [data]);

    const getVisiblePages = (pageIndex, _pageSize, total) => {
        // console.log('getVisiblePages');
        // console.log(total);
        // console.log('pageIndex', pageIndex);
        //  let currentPage = pageIndex + 1;
        let countPage = Math.ceil(total / _pageSize);
        setTableTotalPage(countPage);

        if (pageRange % 2 == 0) pageRange++;
        let distance = pageRange / 2;

        let toLeft = parseInt(distance);
        let toRight = parseInt(distance);

        // console.log('toLeft', toLeft);
        // console.log(currentPage);
        // console.log('countPage', countPage);

        let arr = [];
        //move left
        for (var i = 0; i < toLeft; i++) {
            let goLeft = toLeft - i;
            // console.log('count left');
            //console.log(pageIndex - goLeft);
            if (pageIndex - goLeft >= 0) {
                // console.log('added');
                arr.push(pageIndex - goLeft);
            } else {
                toRight++;
            }
        }

        arr.push(pageIndex);
        //move right
        for (var z = 0; z < toRight; z++) {
            let goRight = z + 1;
            if (pageIndex + goRight < countPage) {
                arr.push(pageIndex + goRight);
            }
        }

        //console.log(arr);
        setVisiblePages(arr);
    };

    function handlePageRedirect(pageIndex) {
        // alert(pageIndx);
        gotoPage(pageIndex);
        if (pageChange) {
            // setStartLoad(true);
            // showNotiLoader();
            pageChange(pageIndex + 1);
        }
    }

    // console.log('currentPage', currentPage);
    // console.log('pageIndex', pageIndex);

    // Render the UI for your table
    return (
        <>
            <div className="react-table-container" style={{ overflow: 'auto' }}>
                <table
                    className="react-table"
                    {...getTableProps()}
                    cellSpacing="0"
                    cellPadding="0"
                >
                    <thead style={headerStyle ? headerStyle : null}>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            // console.log(headerGroups);
                            prepareRow(row);
                            return (
                                <tr
                                    className={
                                        ' ' +
                                        (rowClick ? ' cursor-pointer ' : '') +
                                        (selectedRows && selectedRows[i]
                                            ? ' selected '
                                            : '')
                                    }
                                    {...row.getRowProps()}
                                    {...(rowClick && {
                                        onClick: () => rowClick(row)
                                    })}
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                        <tr>
                            {rows.length == 0 && (
                                <td
                                    colSpan={headerGroups[0]?.headers.length}
                                    className="no-data "
                                >
                                    No Data
                                </td>
                            )}
                        </tr>
                    </tbody>
                </table>

                {hasPagination == true && rows.length > 0 && (
                    // pagination component

                    <div className="pagination-container">
                        <div className="page-info">
                            <span>
                                Showing {(currentPage - 1) * _pageSize + 1} to{' '}
                                {currentPage * _pageSize > totalRecords
                                    ? totalRecords
                                    : (pageIndex + 1) * _pageSize}{' '}
                                of {totalRecords} entries
                            </span>
                        </div>
                        <div className="pagination-list">
                            <button
                                className="previous-btn"
                                onClick={
                                    () => handlePageRedirect(currentPage - 2) //previous pageIndex
                                }
                                disabled={!canPreviousPage}
                            >
                                Previous
                            </button>
                            {pageIndex > 0 && pageIndex >= pageRange - 1 && (
                                <>
                                    <div
                                        className={'pagination-btn '}
                                        onClick={() => handlePageRedirect(0)}
                                    >
                                        1
                                    </div>
                                    ...
                                </>
                            )}
                            {visiblePages &&
                                visiblePages.map((page, index, array) => {
                                    return (
                                        <div
                                            className={
                                                'pagination-btn ' +
                                                (currentPage - 1 == page
                                                    ? ' current '
                                                    : '')
                                            }
                                            onClick={() =>
                                                handlePageRedirect(page)
                                            }
                                        >
                                            {page + 1}
                                        </div>
                                    );
                                })}
                            {currentPage <
                                tableTotalPage - (pageRange / 2 + 1) &&
                                tableTotalPage > 1 && (
                                    <>
                                        {' '}
                                        ...
                                        <div
                                            className={'pagination-btn '}
                                            onClick={() =>
                                                handlePageRedirect(
                                                    tableTotalPage - 1
                                                )
                                            }
                                        >
                                            {tableTotalPage}
                                        </div>
                                    </>
                                )}
                            <button
                                className="next-btn"
                                onClick={
                                    () => handlePageRedirect(currentPage) // next pageIndex
                                }
                                disabled={!canNextPage}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Index;
