// import moment from 'moment';

// import ConvertStatusToText from '../../status-to-text';

function numberWithCommas(x: any, decimalPlace = 0) {
    if (!x) return x;

    if (!decimalPlace) {
        return x.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
        // return x.toFixed(decimalPlace).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        var parts = x.toFixed(decimalPlace).split('.');
        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;
        return (
            numberPart.replace(thousands, ',') +
            (decimalPart ? '.' + decimalPart : '')
        );
    }
}

export const formGroupTableData = (originalTableFormat: any, result: any) => {
    let newTableData: any = [];
    originalTableFormat.forEach(function (item: any) {
        let id = item.id;
        let label = item.label;
        let value = item.value;
        let statusToText = item.statusToText || null;
        let type = item.type;

        if (Array.isArray(id)) {
            //console.log(typeof id);
            let combineValue = '';
            for (var i = 0; i < id.length; i++) {
                let key = id[i];
                if (result[key]) {
                    if (i > 0) {
                        let idsBetween = item.idsBetween;
                        if (idsBetween) combineValue += idsBetween;
                        else combineValue += ' ';
                    }
                    combineValue += result[key];
                }
            }
            value = combineValue;
        } else {
            if (result[id] || result[id] === 0) {
                value = result[id].toString();

                //if datetime covert to localtime
                // if (type == 'date') {
                //     let localtime = moment(value).format('DD-MM-YYYY');
                //     value = localtime;
                // } else if (type == 'value') {
                //     //convert to decimal with comma
                //     value = numberWithCommas(Number(value), 2);
                // } else if (type == 'quantity') {
                //     //convert to decimal without comma
                //     value = numberWithCommas(Number(value), 0);
                // } else if (type == 'timestamp') {
                //     //convert to decimal without comma
                //     value = moment(value).format('YYYY-MM-DD HH:MM:SS');
                // }

                //convert status to text
                // if (statusToText) {
                //     alert(value);
                //     // value = ConvertStatusToText(statusToText, value);
                // }
            } else {
                //main id data not found then try search backup id
                let ifIdEmpty = item.ifIdEmpty;

                if (ifIdEmpty) {
                    if (result[ifIdEmpty] || result[ifIdEmpty] === 0) {
                        value = result[ifIdEmpty].toString();
                    }
                }
            }
        }

        let obj = {
            id,
            label,
            value,
            type
        };
        if (statusToText) obj.statusToText = statusToText;
        newTableData.push(obj);
    });
    return newTableData;
};
