import React, { useState, useEffect } from 'react';

const Nav = () => {
    const inlineNav = {
        // paddingTop: '30px',
        // paddingBottom: '30px',
        paddingLeft: '20px',
        paddingRight: '20px'
    };

    useEffect(() => {
        var jsCloneNavs = document.querySelectorAll('.js-clone-nav');
        var siteMobileMenuBody = document.querySelector(
            '.site-mobile-menu-body'
        );

        jsCloneNavs.forEach((nav) => {
            var navCloned = nav.cloneNode(true);
            navCloned.setAttribute('class', 'site-nav-wrap');
            siteMobileMenuBody?.appendChild(navCloned);
        });

        setTimeout(function () {
            var hasChildrens = document
                .querySelector('.site-mobile-menu')
                ?.querySelectorAll(' .has-children');

            var counter = 0;
            hasChildrens?.forEach((hasChild) => {
                var refEl = hasChild.querySelector('a');

                var newElSpan = document.createElement('span');
                newElSpan.setAttribute('class', 'arrow-collapse collapsed');

                // prepend equivalent to jquery
                hasChild.insertBefore(newElSpan, refEl);

                var arrowCollapse = hasChild.querySelector('.arrow-collapse');
                arrowCollapse?.setAttribute('data-toggle', 'collapse');
                arrowCollapse?.setAttribute(
                    'data-target',
                    '#collapseItem' + counter
                );

                var dropdown = hasChild.querySelector('.dropdown');
                dropdown?.setAttribute('class', 'collapsed');
                dropdown?.setAttribute('id', 'collapseItem' + counter);

                counter++;
            });
        }, 1000);
    }, []);

    useEffect(() => {
        var menuToggle = document.querySelectorAll('.js-menu-toggle');
    });

    const DisplayMobileMenu = (event) => {
        event.preventDefault();
        var menuToggle = document.querySelectorAll('.js-menu-toggle');
        var menuTog;
        menuToggle.forEach((mtoggle) => {
            menuTog = mtoggle;
            mtoggle.addEventListener('click', (event) => {
                if (document.body.classList.contains('offcanvas-menu')) {
                    document.body.classList.remove('offcanvas-menu');
                    mtoggle.classList.remove('active');
                    menuTog.classList.remove('active');
                } else {
                    document.body.classList.add('offcanvas-menu');
                    mtoggle.classList.add('active');
                    menuTog.classList.add('active');
                }
            });
        });

        var specifiedElement = document.querySelector('.site-mobile-menu');
        var mt, mtoggleTemp;
        var isClickInside = specifiedElement?.contains(event.target);
        menuToggle.forEach((mtoggle) => {
            mtoggleTemp = mtoggle;
            mt = mtoggle.contains(event.target);
        });

        if (!isClickInside && !mt) {
            if (document.body.classList.contains('offcanvas-menu')) {
                document.body.classList.remove('offcanvas-menu');
                mtoggleTemp.classList.remove('active');
            }
        }
    };

    return (
        <>
            <div
                id="site-mobile-menu"
                className="site-mobile-menu site-navbar-target"
                style={{top: "0",}}
            >
                <div className="site-mobile-menu-header">
                    <div className="site-mobile-menu-close">
                        <span
                            className="icofont-close js-menu-toggle"
                            onClick={DisplayMobileMenu}
                        ></span>
                    </div>
                </div>
                <div className="site-mobile-menu-body"></div>
            </div>
            <nav className="site-nav" style={inlineNav}>
                <div className="container">
                    <div className="site-navigation">
                        {/* <a href="/" className="logo m-0 float-start"
                            style={{
                                backgroundImage: `url("/img/FiNext/logo_finext.png")`,
                                backgroundSize : "contain",
                                backgroundRepeat: "no-repeat"
                                
                            }}>
                            FiNext
                            <span className="text-primary"></span>{' '}
                        </a> */}

                        <a href="/" className="logo m-0 float-start" style={{ width: "120px", top: "0" }}>
                            <img src="/img/FiNext/Official/Finext_Logo_nobg.png" alt="" style={{ width: "100%", }}/>
                        </a>

                        <ul className="js-clone-nav d-none d-lg-inline-block text-start site-menu float-start">
                            <li className="">
                                <a href="/">Home</a>
                            </li>
                            <li className="has-children">
                                <a
                                // href="#product"
                                >
                                    Products
                                </a>
                                <ul className="dropdown">
                                    <li>
                                        <a href="/tds">TraderStation</a>
                                    </li>
                                    <li>
                                        <a href="/bos">FIBOS</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="/about">About Us</a>
                            </li>
                            <li>
                                <a href="/contact">Contact Us</a>
                            </li>
                        </ul>

                        <a
                            href="#"
                            className="burger ml-auto float-end site-menu-toggle js-menu-toggle d-inline-block d-lg-none"
                            data-toggle="collapse"
                            data-target="#main-navbar"
                            onClick={DisplayMobileMenu}
                        >
                            <span></span>
                        </a>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Nav;
