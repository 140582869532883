import { handleRequestGet } from '../axios';
import { parseResult, queryParamStr, queryParamStrArr } from './GeneralFunc';

export const getTncDetail = async () => {
    let url = '/broadcast/tnc?';
    let queryString = '';
    // queryString += queryParamStr('year', year, true);
    let fullUrl = url + queryString;
    let result = await handleRequestGet(fullUrl, {});
    console.log('getTncDetail', result);
    return parseResult(result).tnc || null;
};

export const getResearchList = async ({
    size = 10,
    page = 1,
    category = null,
    dateStart = null,
    dateEnd = null,
    term = null,
    exchanges = null,
    stocks = null
}) => {
    let url = '/broadcast/research?';
    let queryString = '';
    queryString += queryParamStr('size', size, true);
    queryString += queryParamStr('page', page);
    queryString += queryParamStr('category', category);
    queryString += queryParamStr('term', term);
    queryString += queryParamStrArr('exchanges[]', exchanges);
    queryString += queryParamStrArr('stocks[]', stocks);

    if (dateStart && dateEnd) {
        queryString += queryParamStr('range[start]', dateStart);
        queryString += queryParamStr('range[end]', dateEnd);
    }
    let fullUrl = url + queryString;
    console.log('getResearchList fullUrl', fullUrl);
    let result = await handleRequestGet(fullUrl, {});

    return parseResult(result) || null;
};

export const getAnnoucementList = async ({
    size = 10,
    page = 1,
    type = null,
    dateStart = null,
    dateEnd = null,
    term = null
}) => {
    let url = '/broadcast/announcement?';

    let queryString = '';
    queryString += queryParamStr('size', size, true);
    queryString += queryParamStr('page', page);
    queryString += queryParamStr('type', type);
    queryString += queryParamStr('term', term);
    if (dateStart && dateEnd) {
        queryString += queryParamStr('range[start]', dateStart);
        queryString += queryParamStr('range[end]', dateEnd);
    }

    let fullUrl = url + queryString;
    console.log('fullUrl', fullUrl);
    let result = await handleRequestGet(fullUrl, {});
    console.log('result', result);
    return parseResult(result) || null;
};
