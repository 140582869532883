import { handleRequestGet } from '../axios';
import { parseResult, queryParamStr, queryParamStrArr } from './GeneralFunc';

export const getPortfolio = async (acc_ids = null, { size = 10 }, page = 1) => {
    let url = '/client/portfolio?';
    let queryString = '';
    // queryString += queryParamStr('size', size, true);
    // queryString += queryParamStr('page', page);
    queryString += queryParamStrArr('accounts[]', acc_ids);

    let fullUrl = url + queryString;
    let result = await handleRequestGet(fullUrl, {});

    console.log('fullUrl', fullUrl);
    console.log(result);

    return parseResult(result);
};
