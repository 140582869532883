import { createRef, useRef, useState, useEffect, useContext } from 'react';
import './index.css';
import useAuth from '../../../hooks/useAuth';
import '../../asset/styles/header-container-style.css';

import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import api from '../../../apis';
import { useLoading } from '../../../context/LoadingContext';

import avatarImg from '../../asset/images/undraw_profile.svg';
import UobLogo from '../../asset/images/uobKayHian.png';
import MobileSideMenu from '../MobileSideMenu';

import ChangePassword from '../../../components/pages/protected/ChangePassword';

import config from '../../../config';

import { Menu } from 'antd';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

const defaultSetting = () => {
    return {
        layout: 'side',
        theme: 'basic'
    };
};

function useMounted() {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);
    return isMounted;
}

const Index = () => {
    let location = useLocation();
    const isMounted = useMounted();
    const [cookies, setCookie, removeCookie] = useCookies(['user']);

    //create constant reference to the useRef();
    const menuRef = useRef<HTMLLIElement | null>(null);
    const profilePopOutRef = useRef<HTMLLIElement | null>(null);
    const [avatarMenu, setAvatarMenu] = useState(false);
    const [lockAvataMenu, setLockAvataMenu] = useState(false);

    const [userName, setUserName] = useState('');
    const [userLastLogin, setUserLastLogin] = useState('');

    useEffect(() => {
        let settings: any = localStorage.getItem('settings');
        if (!settings) {
            let obj = defaultSetting();
            localStorage.setItem('settings', JSON.stringify(obj));

            // if (cookies.user) {
            //     alert('her');
            // }
        } else {
            console.log('init settings', settings);
            let parsedSettings = JSON.parse(settings);

            let theme = parsedSettings.theme;
            const query = new URLSearchParams(location.search);
            if (query) {
                let querytheme = query.get('theme');
                if (querytheme == 'dark') {
                    theme = querytheme;
                }
            }

            setAuth({
                ...auth,
                layout: parsedSettings.layout,
                mode: theme
            });
        }
    }, []);

    useEffect(() => {
        //check if logined
        if (cookies.user) {
            if (cookies.user.user) {
                let userDetail = cookies.user.user;
                setUserName(userDetail.username);
                if (userDetail.last_login)
                    setUserLastLogin(
                        moment(
                            userDetail.last_login,
                            'YYYY-MM-DDTHH:mm:ss'
                        ).format('YYYY-MM-DD HH:mm:ss')
                    );
            }
        }
    }, [cookies]);

    const toggleAvatarMenu = () => {
        setAvatarMenu(!avatarMenu);
    };

    /* @ts-ignore */
    const { auth, setAuth } = useAuth();
    useEffect(() => {
        if (isMounted) {
            if (auth) {
                // console.log('auth ', auth);
                let layout = auth.layout;
                let mode = auth.mode;

                let settings = defaultSetting();

                if (layout) settings.layout = layout;
                if (mode) settings.theme = mode;

                let csettings: any = localStorage.getItem('settings');
                let json = JSON.stringify(settings);

                if (json != csettings) {
                    // console.log('json ', json);
                    // console.log('csettings ', csettings);
                    localStorage.setItem('settings', json);
                }
            }
        }
    }, [auth]);

    useEffect(() => {
        if (auth.layout) {
            setSelectedLayout(auth.layout);
        }

        if (auth.mode) {
            setSelectedMode(auth.mode);
        }
    }, []);

    const [selectedMode, setSelectedMode] = useState('basic');
    const handleModelChange = (e: any) => {
        setSelectedMode(e.target.value);
        setAuth({ ...auth, mode: e.target.value });
    };

    const [selectedLayout, setSelectedLayout] = useState('side');
    const handleLayoutChange = (e: any) => {
        setSelectedLayout(e.target.value);
        setAuth({ ...auth, layout: e.target.value });
    };

    const mobilemenuIconRef = useRef<HTMLLIElement | null>(null);
    const [openMobileSideNav, setOpenMobileSideNav] = useState(false);
    const handleOpenMobileSideNav = () => {
        // alert(openMobileSideNav)
        setOpenMobileSideNav(!openMobileSideNav);
    };

    //click on outside hide menu
    useEffect(() => {
        // console.log('avatarMenu', avatarMenu);
        // Bind the event listener
        document.addEventListener('mousedown', handleOutsideClicks);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleOutsideClicks);
        };
    }, [avatarMenu, lockAvataMenu]);

    //create a function in your component to handleOutsideClicks
    const handleOutsideClicks = (event: any) => {
        if (
            avatarMenu &&
            lockAvataMenu == false &&
            menuRef.current &&
            !menuRef.current.contains(event.target) &&
            profilePopOutRef.current &&
            !profilePopOutRef.current.contains(event.target)
        ) {
            setAvatarMenu(false);
        }
    };

    return (
        <>
            <div className="header-container">
                <div
                    ref={mobilemenuIconRef}
                    className="mobile-side-menu-btn"
                    onClick={() => handleOpenMobileSideNav()}
                >
                    <button
                        id="sidebarToggleTop"
                        className="btn btn-link d-md-none rounded-circle mr-3"
                    >
                        <i className="fa fa-bars"></i>
                    </button>
                </div>

                <nav className="navbar navbar-expand navbar-light topbar static-top ">
                    <div className="pl-5  navbar-title">
                        <img src={UobLogo} alt="" />
                        {/* TraderStation{' '} */}
                        <small style={{ fontSize: '14px' }}>
                           {' '}{config.version}
                        </small>
                    </div>

                    {/* <!-- Topbar Navbar --> */}
                    <ul className="navbar-nav ml-auto ">
                        {/* <div style={{ height: '50px' }} className="pt-3">
                            <select
                                className="underline"
                                value={selectedLayout}
                                onChange={(e) => handleLayoutChange(e)}
                            >
                                <option value="side">Side</option>
                                <option value="top">Top</option>
                            </select>
                        </div>
                        &nbsp;
                        <div style={{ height: '50px' }} className="pt-3">
                            <select
                                className="underline"
                                value={selectedMode}
                                onChange={(e) => handleModelChange(e)}
                            >
                                <option value="basic">Basic</option>
                                <option value="dark">Dark</option>
                            </select>
                        </div> */}
                        {/* <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
                        <li className="nav-item dropdown no-arrow d-sm-none">
                            <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                id="searchDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <i className="fas fa-search fa-fw"></i>
                            </a>
                            {/* <!-- Dropdown - Messages --> */}
                            <div
                                className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                                aria-labelledby="searchDropdown"
                            >
                                <form className="form-inline mr-auto w-100 navbar-search">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control bg-light border-bottom small"
                                            placeholder="Search for..."
                                            aria-label="Search"
                                            aria-describedby="basic-addon2"
                                        />
                                        <div className="input-group-append">
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                            >
                                                <i className="fas fa-search fa-sm"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </li>
                        {/* <li className="nav-item  no-arrow mx-1">
                            <i className="nav-link fas fa-search fa-fw"></i>
                        </li> */}
                        {/* <!-- Nav Item - Alerts --> */}
                        <li className="nav-item dropdown no-arrow mx-1">
                            {/* <!-- Dropdown - Alerts --> */}
                            <div
                                className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                aria-labelledby="alertsDropdown"
                            >
                                <h6 className="dropdown-header">
                                    Alerts Center
                                </h6>
                                <a
                                    className="dropdown-item d-flex align-items-center"
                                    href="#"
                                >
                                    <div className="mr-3">
                                        <div className="icon-circle bg-primary">
                                            <i className="fas fa-file-alt text-white"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">
                                            December 12, 2019
                                        </div>
                                        <span className="font-weight-bold">
                                            A new monthly report is ready to
                                            download!
                                        </span>
                                    </div>
                                </a>
                                <a
                                    className="dropdown-item d-flex align-items-center"
                                    href="#"
                                >
                                    <div className="mr-3">
                                        <div className="icon-circle bg-success">
                                            <i className="fas fa-donate text-white"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">
                                            December 7, 2019
                                        </div>
                                        $290.29 has been deposited into your
                                        account!
                                    </div>
                                </a>
                                <a
                                    className="dropdown-item d-flex align-items-center"
                                    href="#"
                                >
                                    <div className="mr-3">
                                        <div className="icon-circle bg-warning">
                                            <i className="fas fa-exclamation-triangle text-white"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">
                                            December 2, 2019
                                        </div>
                                        Spending Alert: We've noticed unusually
                                        high spending for your account.
                                    </div>
                                </a>
                                <a
                                    className="dropdown-item text-center small text-gray-500"
                                    href="#"
                                >
                                    Show All Alerts
                                </a>
                            </div>
                        </li>
                        {/* <div className="topbar-divider d-none d-sm-block"></div> */}
                        {/* <!-- Nav Item - User Information --> */}
                        <li
                            ref={menuRef}
                            className={
                                'nav-item dropdown no-arrow  ' +
                                (avatarMenu ? 'show' : '')
                            }
                        >
                            <a
                                className="nav-link dropdown-toggle hover-unset"
                                id="userDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded={avatarMenu}
                                onClick={() => toggleAvatarMenu()}
                            >
                                <div>
                                    <div className="">
                                        {' '}
                                        Last Login: {userLastLogin}
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end gap-3 pt-1">
                                        <div className="">
                                            <i className="fas fa-bell fa-fw"></i>
                                            {/* <!-- Counter - Alerts --> */}
                                            <span className="mt-1 badge badge-danger badge-counter">
                                                3+
                                            </span>
                                        </div>
                                        <div className="hover-custom">
                                            {' '}
                                            <img
                                                className="img-profile rounded-circle"
                                                src={avatarImg}
                                                style={{
                                                    width: '35px',
                                                    height: '35px',
                                                    position: 'relative',
                                                    top: '-3px'
                                                }}
                                            />
                                            &nbsp;&nbsp;
                                            <span className="mr-2 d-none d-lg-inline small">
                                                {userName}
                                            </span>
                                            <i
                                                className="fas fa-chevron-down pb-1"
                                                aria-hidden="true"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </a>

                            {/* <!-- Dropdown - User Information --> */}
                            <div
                                ref={profilePopOutRef}
                                className={
                                    'dropdown-menu dropdown-menu-right shadow animated--grow-in ' +
                                    (avatarMenu ? 'show' : '')
                                }
                                aria-labelledby="userDropdown"
                            >
                                <HeaderMenu
                                    onLockMenu={(status) =>
                                        setLockAvataMenu(status)
                                    }
                                    onMenuOpen={(status) =>
                                        setAvatarMenu(status)
                                    }
                                />
                                {/* <a className="dropdown-item">
                                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Profile
                                </a>
                                <a className="dropdown-item">
                                    <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Settings
                                </a>
                                <a className="dropdown-item">
                                    <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Activity Log
                                </a>
                                <div className="dropdown-divider"></div>
                                <a
                                    className="dropdown-item"
                                    data-toggle="modal"
                                    data-target="#logoutModal"
                                    onClick={() => handleLogout()}
                                >
                                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Logout
                                </a> */}
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>

            <MobileSideMenu
                openMobileSideNav={openMobileSideNav}
                onStatusChange={(status: any) => {
                    setOpenMobileSideNav(status);
                }}
            />
        </>
    );
};

const HeaderMenu = (props: any) => {
    const isMounted = useMounted();
    const [menuLocation, setMenuLocation] = useState('side');
    const [theme, setTheme] = useState('basic');
    const selectedKeys = [menuLocation, theme];

    const [displayChangePassword, setDisplayChangePassword] = useState(false);

    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();
    const { setLoading } = useLoading();
    const [cookies, setCookie, removeCookie] = useCookies(['user']);

    // const [selectedKeys, setSelectedKeys] = useState([menuLocation, theme]);

    useEffect(() => {
        if (auth.layout) {
            //set current layout mode
            setMenuLocation(auth.layout);
        }

        if (auth.mode) {
            //set current layout mode
            setTheme(auth.mode);
        }
    }, []);

    useEffect(() => {
        if (isMounted) {
            if (auth) {
                if (auth.layout) {
                    //set current layout mode
                    setMenuLocation(auth.layout);
                }

                if (auth.mode) {
                    //set current layout mode
                    setTheme(auth.mode);
                }
            }
        }
    }, [auth]);

    // useEffect(() => {
    //     console.log(selectedKeys);
    // }, [selectedKeys]);
    // alert(auth.layout);
    function getItem(
        label: React.ReactNode,
        key?: React.Key | null,
        icon?: React.ReactNode,
        children?: any
        // type?: 'group',
    ): any {
        return {
            key,
            icon,
            children,
            label
            //   type,
        };
    }

    const items = [
        // getItem(
        //     'Profile',
        //     'profile',
        //     <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>,
        //     null
        // ),

        getItem(
            'Settings',
            'sub2',
            <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>,
            [
                getItem('Menu Location', 'menu_location', null, [
                    getItem('Side', 'side'),
                    getItem('Top', 'top')
                ]),
                getItem('Theme', 'theme', null, [
                    getItem('Light', 'basic'),
                    getItem('Dark', 'dark')
                ])
            ]
        ),
        getItem(
            'Change Password',
            'change-password',
            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>,
            null
        ),

        // getItem(
        //     'Activity Log',
        //     'sub4',
        //     <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>,
        //     null
        // ),

        getItem(
            'Logout',
            'logout_user',
            <i className="fas fa-sign-out fa-sm fa-fw mr-2 text-gray-400"></i>,
            null
        )
    ];

    const onClick = (e) => {
        console.log('click', e);
        if (e.key == 'logout_user') {
            handleLogout();
        } else if (e.key == 'side' || e.key == 'top') {
            handleLayoutChange(e.key);
        } else if (e.key == 'basic' || e.key == 'dark') {
            handleModelChange(e.key);
        } else if (e.key == 'change-password') {
            setDisplayChangePassword(true);
        }
    };

    const handleLayoutChange = (value: string) => {
        setAuth({ ...auth, layout: value });
        setMenuLocation(value);
    };

    const handleModelChange = (value: string) => {
        setAuth({ ...auth, mode: value });
        setTheme(value);
    };

    const handleLogout = async () => {
        await setLoading(true);
        let result = await api.userLogout();

        if (result == true) {
            removeCookie('user', { path: '/' });
            navigate('/Utrade');
        }
        setLoading(false);
    };

    const onOpenChange = (openKeys) => {
        // console.log('openKeys', openKeys);
        if (openKeys.length > 0) {
            if (props.onLockMenu()) {
                props.onLockMenu(true);
            }
        } else {
            if (props.onLockMenu) {
                props.onLockMenu(false);
            }
        }
    };

    return (
        <div className="headerMenuContainer">
            <Menu
                selectedKeys={selectedKeys} //disabled selected effect
                onClick={onClick}
                onOpenChange={onOpenChange}
                style={{ width: '100%', top: '100px' }}
                mode="vertical"
                items={items}
            />
            {displayChangePassword == true ? (
                <ChangePassword
                    setModelOpen={(status) => setDisplayChangePassword(status)}
                />
            ) : null}
        </div>
    );
};
export default Index;
